import { BackButton } from '@components/shared/buttons/BackButton';
import { useRootData } from '@hooks/hook';
import { DictionariesConstants } from '@services/DictionariesConstants';
import { PharmacyDto } from '@services/src/models';
import { Divider, Space } from 'antd';
import React from 'react';
import { useHistory } from 'react-router';
import { IDictionaryStore } from 'stores/DictionaryStore';

import styles from '../styles/PharmacyEdit.module.less';

interface IProps {
	pharmacy: PharmacyDto | undefined;
}

export const PharmacyMenu = (props: IProps) => {
	const history = useHistory();

	const { pharmacy } = props;

	const dictionaryStore: IDictionaryStore = useRootData(
		(store) => store.dictionaryStore
	);

	const getClassByContractId = (contractId: number): string => {
		const dictionary = dictionaryStore.getDictionary(
			DictionariesConstants.NHFContract
		);

		const dictionaryItem = dictionary?.values?.find(
			(x) => x.id === contractId
		);

		if (dictionaryItem) {
			switch (dictionaryItem.codeName) {
				case 'CONTRACTED':
					return styles.inContract;
				case 'REPORTED_TO_NHF':
					return styles.duringApplication;
				case 'REJECTED_BY_NHF':
					return styles.outContract;
			}
		}

		return '';
	};

	const getStatusByContractValue = (pharmacy: PharmacyDto) => {
		return (
			<div
				className={`${styles.status} ${getClassByContractId(
					pharmacy.nhfContractId!
				)}`}
			>
				{dictionaryStore.getValueById(
					DictionariesConstants.NHFContract,
					pharmacy.nhfContractId!
				)}
			</div>
		);
	};

	return (
		<>
			<Divider />
			<div className={styles.menu}>
				<div></div>
				<BackButton
					customAction={() => {
						history.push('/pharmacies');
					}}
				/>
				<div></div>
				{pharmacy && (
					<Space direction="horizontal">
						<h2>
							{`${pharmacy.name} - 
							${
								pharmacy.voivodeshipId
									? dictionaryStore.getValueById(
											DictionariesConstants.Voivodeships,
											pharmacy.voivodeshipId
									  )
									: ''
							}`}
						</h2>
						{pharmacy.nhfContractId &&
							getStatusByContractValue(pharmacy)}
					</Space>
				)}
			</div>
			<Divider />
		</>
	);
};
