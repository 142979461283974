import { InternalTaxNoteType } from '@services/src/models';
import React from 'react';

import styles from '../styles/PharmacyNote.module.less';
import { OverpayNoteDetails } from './OverpayNoteDetails';

interface IProps {
	editMode: boolean;
	readOnly: boolean;
	groupId: string;
	internalTaxNoteType: InternalTaxNoteType;
	overpayNoteId?: string;
	returnNoteId: string;
}

export const OverpayNote = (props: IProps) => {
	return (
		<div className={styles.note}>
			<OverpayNoteDetails
				editMode={props.editMode}
				readOnly={props.readOnly}
				groupId={props.groupId}
				internalTaxNoteType={props.internalTaxNoteType}
				overpayNoteId={props.overpayNoteId}
				returnNoteId={props.returnNoteId}
			/>
		</div>
	);
};
