import { QuestionCircleOutlined } from '@ant-design/icons';
import { getEssityApiClient } from '@services/EssityApi';
import { OrderRealizationDto } from '@services/src/models';
import { Col, Modal, Row } from 'antd';
import React from 'react';

interface IProps {
	onAccept: () => void;
	mode: mode;
	nhfRealizationId: string;
}

export type mode = 'cancelation' | 'restore';

export const OrdersPairedConfirmationModal = async (props: IProps) => {
	const api = await getEssityApiClient();

	await api.order
		.getOrdersRealizationByNhfRealization({
			nhfRealizationId: props.nhfRealizationId,
		})
		.then((result: OrderRealizationDto[]) => {
			Modal.confirm({
				icon: <QuestionCircleOutlined />,
				centered: true,
				okText: "Yes, I'm sure",
				cancelText: 'No, go back',
				content: (
					<Row style={{ marginBottom: 10 }}>
						<Col span={24}>
							{`You are about to ${props.mode === 'cancelation'
									? 'cancel'
									: 'restore'
								} following orders:`}
						</Col>

						{result.map((x: OrderRealizationDto) => {
							return (
								<b>
									Realization ID = {x.realizationId} (eZWM ID
									= {x.nhfRealizationId})
								</b>
							);
						})}

						<Col span={24}>
							{`Are you sure you want to ${props.mode === 'cancelation'
									? 'cancel'
									: 'restore'
								} them ?`}
						</Col>
					</Row>
				),
				onOk() {
					props.onAccept();
				},
			});
		});
};
