import { useRootData } from '@hooks/hook';
import { Col, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { ReactNode } from 'react';
import { Redirect } from 'react-router';
import { IAccountDetailsStore } from 'stores/AccountDetailsStore';

import EssityLayout from '../layout/Layout';

interface IPrimaryViewProps {
	content: ReactNode;
}

const SecuredPrimaryView = (props: IPrimaryViewProps) => {
	const accountDetailsStore: IAccountDetailsStore = useRootData(
		(store) => store.accountDetailsStore
	);

	return accountDetailsStore.oidcUser?.get() ? (
		<EssityLayout>
			<Row>
				<Col xs={24}>{props.content}</Col>
			</Row>
		</EssityLayout>
	) : (
		<Redirect to="/login" />
	);
};

export default observer(SecuredPrimaryView);
