import {
	EssityColumnProps,
	filterType,
} from '@components/shared/paginatedEssityTable/GridHelper';
import formatHelpers from '@helper/formatHelpers';
import { OrderGroupDto } from '@services/src/models';
import { Badge } from 'antd';
import React from 'react';

export const OrderGroupsTableColumns: EssityColumnProps<OrderGroupDto>[] = [
	{
		sorter: true,
		dataIndex: 'year',
		key: 'year',
		title: 'Year',
		filter: 'equals' as filterType
	},
	{
		sorter: true,
		dataIndex: 'month',
		key: 'month',
		title: 'Month',
		filter: 'equals' as filterType
	},
	{
		sorter: true,
		dataIndex: 'importDate',
		key: 'importDate',
		title: 'Updated',
		render: (value?: Date) => formatHelpers.formatDate(value),
	},
	{
		sorter: true,
		dataIndex: 'ordersQty',
		key: 'ordersQty',
		title: 'Orders',
	},
	{
		sorter: true,
		dataIndex: 'reportedQty',
		key: 'reportedQty',
		title: 'Reported',
		render: (value: string, row: OrderGroupDto) => (
			<div>
				{value}
				{row.toReportQty !== undefined && (
					<Badge
						count={-row.toReportQty}
						style={{ backgroundColor: '#d9001b' }}
						offset={[2, -5]}
					/>
				)}
			</div>
		),
	},
	{
		sorter: true,
		dataIndex: 'toCorrectReportQty',
		key:'toCorrectReportQty',
		title: 'To correct',
	},
	{
		sorter: true,
		dataIndex: 'refund',
		key: 'refund',
		title: 'Refund',
	},
	{
		sorter: true,
		dataIndex: 'value',
		key: 'value',
		title: 'Value',
	},
];
