import { IsolatedModal } from '@components/shared/modals/IsolatedModal';
import { useRootData } from '@hooks/hook';
import { FarmapromPharmacyDto } from '@services/src/models';
import { Button, Modal } from 'antd';
import React, { useState } from 'react';
import { IGridStore } from 'stores/GridStore';

import { FarmapromPharmaciesTable } from './FarmapromPharmaciesTable';

interface IProps {
	disabled?: boolean;
	onPharmacySelect: (pharmacy: FarmapromPharmacyDto | null) => void;
}

export const FarmapromPharmaciesModal = (props: IProps) => {
	const [visible, setVisible] = useState<boolean>();

	const gridStore: IGridStore = useRootData((store) => store.gridStore);

	const handleCancel = () => {
		setVisible(false);
		gridStore.resetGrid('farmapromPharmacies');
	};

	return (
		<>
			<Button
				type="primary"
				shape="round"
				size="small"
				style={{ width: '125px' }}
				disabled={props.disabled}
				onClick={() => {
					setVisible(true);
				}}
			>
				FP view
			</Button>
			{visible && (
				<IsolatedModal
					title="Farmaprom pharmacies"
					visible={visible}
					width="70vw"
					centered
					cancelButtonProps={{ hidden: true }}
					okButtonProps={{ hidden: true }}
					maskClosable={false}
					onCancel={handleCancel}
					closable={true}
					destroyOnClose
					footer={<Button type="primary" onClick={() => {
						props.onPharmacySelect(null);
						setVisible(false);
					}}>without FP ID</Button>}
				>
					<FarmapromPharmaciesTable
						onPharmacySelect={(pharmacy: FarmapromPharmacyDto) => {
							props.onPharmacySelect(pharmacy);
							setVisible(false);
						}}
					/>
				</IsolatedModal>
			)}
		</>
	);
};
