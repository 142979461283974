import QuickFilters from '@components/quickFilters/QuickFilters';
import { PharmacyTable } from '@components/shared/modals/pharmacyFinder/PharmacyTable';
import { PharmacyDto } from '@services/src/models';
import { Button } from 'antd';
import { showWarning } from 'layout/Modals';
import React, { useState } from 'react';

import { IsolatedModal } from '../IsolatedModal';

export type mode = 'single' | 'multiple';

interface IProps {
	readOnly: boolean;
	onPharmacySelect: (
		pharmacies: PharmacyDto[],
		selectedAll?: boolean
	) => void;
	validatePlaceId: boolean;
	mode: mode;
	customTitle?: string;
	style?: React.CSSProperties | undefined;
}

export const PharmacyFinder = (props: IProps) => {
	const [visible, setVisible] = useState<boolean>();

	const handleCancel = () => {
		setVisible(false);
	};

	return (
		<>
			<Button
				type="primary"
				size="middle"
				shape="round"
				disabled={props.readOnly}
				style={props.style}
				onClick={() => {
					setVisible(true);
				}}
			>
				{props.customTitle || 'Find pharmacy'}
			</Button>
			{visible && (
				<IsolatedModal
					title={props.customTitle || 'Find pharmacy'}
					visible={visible}
					width="70vw"
					onCancel={handleCancel}
				>
					<QuickFilters hide={true} />
					<PharmacyTable
						allowExtendedSelection={props.mode === 'multiple'}
						onSelect={(
							pharmacies: PharmacyDto[],
							selectedALl?: boolean
						) => {
							if (
								props.validatePlaceId &&
								pharmacies.some((x) => !x.placeId)
							) {
								showWarning(
									'Unable to select pharmacy with empty SAP ID'
								);
								return;
							}

							props.onPharmacySelect(pharmacies, selectedALl);
							setVisible(false);
						}}
					/>
				</IsolatedModal>
			)}
		</>
	);
};
