import { autorun } from 'mobx';
import { useLocalStore } from 'mobx-react-lite';
import React, { useEffect } from 'react';

import { accountDetailsStore } from './AccountDetailsStore';
import { dictionaryStore } from './DictionaryStore';
import { globalSearchStore } from './GlobalSearchStore';
import { gridStore } from './GridStore';
import { tableColumnStore } from './TableColumnStore';

const createStores = () => ({
	gridStore,
	tableColumnStore,
	dictionaryStore,
	accountDetailsStore,
	globalSearchStore,
});

export type IStores = ReturnType<typeof createStores>;
export const storeContext = React.createContext<IStores | null>(null);

export const StoreProvider: React.FC = ({ children }) => {
	const store = useLocalStore(createStores);

	useEffect(() => {
		autorun(() => {
			if (store.accountDetailsStore.oidcUser.get()) {
				store.accountDetailsStore.initialize();
			}
		});
	}, [store.accountDetailsStore.oidcUser.get()]);

	return (
		<storeContext.Provider value={store}>{children}</storeContext.Provider>
	);
};

export default StoreProvider;
