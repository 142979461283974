import { IsolatedContainer } from '@components/shared/IsolatedContainer';
import { IsolatedModal } from '@components/shared/modals/IsolatedModal';
import { Button, Modal } from 'antd';
import React, { useState } from 'react';

import { IdentifiersTable } from './IdentifiersTable';

interface IProps {
	disabled?: boolean;
	voivodeshipId?: number;
	kodKom?: number;
}

export const IdentifiersModal = (props: IProps) => {
	const [visible, setVisible] = useState<boolean>();

	const handleCancel = () => {
		setVisible(false);
	};

	return (
		<>
			<Button
				type="primary"
				shape="round"
				size="small"
				style={{ width: '125px' }}
				disabled={props.disabled || !props.voivodeshipId}
				onClick={() => {
					setVisible(true);
				}}
			>
				All dist. numbers
			</Button>
			{visible && (
				<IsolatedModal
					title="Distinguishing numbers"
					visible={visible}
					onCancel={handleCancel}
				>
					<IdentifiersTable
						voivodeshipId={props.voivodeshipId!}
						kodKom={props.kodKom!}
					/>
				</IsolatedModal>
			)}
		</>
	);
};
