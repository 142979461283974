import clsx from 'clsx';
import React from 'react';
import { Link } from 'react-router-dom';
import { Menu, MenuButton, MenuItem, useMenuState } from 'reakit/Menu';

import { MenuItemSchema, SubMenuItemSchema } from './MenuItems';

declare type Placement =
	| 'auto-start'
	| 'auto'
	| 'auto-end'
	| 'top-start'
	| 'top'
	| 'top-end'
	| 'right-start'
	| 'right'
	| 'right-end'
	| 'bottom-end'
	| 'bottom'
	| 'bottom-start'
	| 'left-end'
	| 'left'
	| 'left-start';

export interface ISubMenuItem {
	item: SubMenuItemSchema;
	placement: Placement;
	hideParentMenu?: () => void;
}

const SubMenu = React.forwardRef((props: any | ISubMenuItem, ref: any) => {
	const {
		hideParentMenu,
		className,
		placement,
		permissionValidator,
		item,
		...rest
	} = props;
	const menu = useMenuState({ placement: placement });

	const renderMenuItem = (item: MenuItemSchema) => {
		return (
			<MenuItem
				{...menu}
				as={Link}
				to={`/${item.path}`}
				className="ant-menu-item sub-menu-item"
				onClick={() => {
					menu.hide();
					if (hideParentMenu) {
						hideParentMenu();
					}
				}}
			>
				{item.icon}
				<span>{item.text}</span>
			</MenuItem>
		);
	};

	const renderSubMenuItem = (item: SubMenuItemSchema) => {
		return (
			<MenuItem
				{...menu}
				as={SubMenu}
				hideParentMenu={menu.hide}
				item={item}
				permissionValidator={props.permissionValidator}
				placement="auto"
				className="ant-menu-item ant-menu-submenu-vertical-left"
			>
				{item.icon}
				<span>{item.text}</span>
			</MenuItem>
		);
	};
	return (
		<>
			<MenuButton
				ref={ref}
				{...menu}
				{...rest}
				aria-label={`${item.text}`}
				className={clsx('ant-menu-submenu', className)}
			>
				<div className="ant-menu-submenu-title">
					{item.icon}
					<span>{item.text}</span>
					<i className="ant-menu-submenu-arrow"></i>
				</div>
			</MenuButton>
			<Menu
				{...menu}
				aria-label={`${item.text}`}
				className="ant-menu ant-menu-sub profi-menu-vertical"
			>
				{item.items.map((x: any) => {
					if (x.items === undefined) {
						const item = x as MenuItemSchema;
						return renderMenuItem(item);
					} else {
						const item = x as SubMenuItemSchema;
						return renderSubMenuItem(item);
					}
				})}
			</Menu>
		</>
	);
});

export default SubMenu;
