import { BackButton } from '@components/shared/buttons/BackButton';
import { Divider } from 'antd';
import React from 'react';

import styles from '../styles/NHFSettlementsOrders.module.less';

export const NHFSettlementsOrdersMenu = () => {
	return (
		<>
			<Divider />
			<div className={styles.menu}>
				<div></div>
				<BackButton />
			</div>
			<Divider />
		</>
	);
};
