
import { observer } from 'mobx-react';
import { User } from 'oidc-client';
import React, { useEffect } from 'react';
import {
    RouteComponentProps,
    withRouter,
} from 'react-router-dom';


import { GlobalUserManager } from './userManager';

interface IProps { switchView: JSX.Element }
type IPropsWithRouter = IProps & RouteComponentProps;

const returnUrl = 'returnUrl';

const AuthenticatedComponent: React.FC<IPropsWithRouter> = (props) => {

    useEffect(() => {
        if (
            props.location.pathname === '/authCallback' ||
            props.location.pathname === '/login' ||
            props.location.pathname === '/silentRenew'
        ) {
            return;
        }
        GlobalUserManager.UserManager()
            .getUser()
            .then((getUser: User | null) => {
                if (!getUser || getUser.expired) {
                    if (props.location.pathname !== '/') {
                        sessionStorage.setItem(returnUrl, props.location.pathname);
                        GlobalUserManager.UserManager().signinRedirect();
                    }
                }

            });
    }, []);

    return <>
        {props.switchView}
    </>

};
const Authenticated = withRouter(AuthenticatedComponent);
export default observer(Authenticated);
