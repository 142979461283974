/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from "@azure/ms-rest-js";
import * as Models from "../models";
import * as Mappers from "../models/pharmaciesMappers";
import { PolandPharmacyRxAPIContext } from "../polandPharmacyRxAPIContext";

/** Class representing a Pharmacies. */
export class Pharmacies {
  private readonly client: PolandPharmacyRxAPIContext;

  /**
   * Create a Pharmacies.
   * @param {PolandPharmacyRxAPIContext} client Reference to the service client.
   */
  constructor(client: PolandPharmacyRxAPIContext) {
    this.client = client;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updateSAPIdentifier(options?: Models.PharmaciesUpdateSAPIdentifierOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  updateSAPIdentifier(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  updateSAPIdentifier(options: Models.PharmaciesUpdateSAPIdentifierOptionalParams, callback: msRest.ServiceCallback<void>): void;
  updateSAPIdentifier(options?: Models.PharmaciesUpdateSAPIdentifierOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      updateSAPIdentifierOperationSpec,
      callback);
  }
}

// Operation Specifications
const serializer = new msRest.Serializer(Mappers);
const updateSAPIdentifierOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "external/pharmacy/updateSAPIdentifier",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.UpdateSAPIdentifierCommand
  },
  responses: {
    200: {},
    default: {}
  },
  serializer
};
