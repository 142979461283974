import { IsolatedModal } from '@components/shared/modals/IsolatedModal';
import { ajaxByUser, ajaxCatchSilently } from '@helper/api';
import { getEssityApiClient } from '@services/EssityApi';
import {
	PharmacyTaxNoteDto,
	PharmacyTaxNotePaymentConfirmationDto,
} from '@services/src/models';
import { Button, Col, Divider, Popconfirm, Row, Space, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import styles from '../styles/PharmacyNotes.module.less';
import EssityTable from 'layout/EssityTable';
import { EssityColumnProps } from '@components/shared/paginatedEssityTable/GridHelper';
import formatHelpers from '@helper/formatHelpers';
import { AddPaymentConfirmationModal } from './AddPaymentConfirmationModal';
import { EditPaymentConfirmationModal } from './EditPaymentConfirmationModal';

interface IProps {
	taxNote: PharmacyTaxNoteDto;
	refresh: () => void;
}

export const AssignPaymentConfirmationModal = (props: IProps) => {
	const [visible, setVisible] = useState<boolean>();
	const [isBusy, setIsBusy] = useState<boolean>();
	const [paymentsConfirmation, setPaymentsConfirmation] = useState<
		PharmacyTaxNotePaymentConfirmationDto[]
	>();
	useEffect(() => {
		if (props.taxNote.id) {
			if (visible) {
				refreshOperations();
			}
		}
	}, [props.taxNote.id, visible]);
	const Operations = (
		refresh: () => void
	): EssityColumnProps<PharmacyTaxNotePaymentConfirmationDto> => {
		return {
			title: '',
			key: 'operation',
			fixed: 'right',
			render: (item: PharmacyTaxNotePaymentConfirmationDto) => (
				<Space direction="horizontal">
					<EditPaymentConfirmationModal paymentConfirmation={item} refresh={refresh} />
					{DeleteAction(item, refresh)}
				</Space>
			),
		};
	};
	const refreshOperations = async () => {
		setIsBusy(true);
		ajaxCatchSilently(() =>
			getEssityApiClient().then((api) =>
				api.pharmacyTaxNotePaymentConfirmation
					.getByPharmacyTaxNoteId(props.taxNote.id!)
					.then(
						(result: PharmacyTaxNotePaymentConfirmationDto[]) => {
							setPaymentsConfirmation(result);
						}
					)
					.finally(() => setIsBusy(false))
			)
		);
	};
	const columns: EssityColumnProps<PharmacyTaxNotePaymentConfirmationDto>[] = [
		{
			sorter: false,
			dataIndex: 'amount',
			key: 'amount',
			title: 'Value',
			width: 150,
		},
		{
			sorter: false,
			dataIndex: 'confirmationDate',
			key: 'confirmationDate',
			title: 'Date',
			render: (value?: Date) => formatHelpers.formatDate(value),
			width: 150,
		},

		Operations(refreshOperations),

	];

	const handleCancel = () => {
		setVisible(false);
		setPaymentsConfirmation([]);
		props.refresh();
	};

	const DeleteAction = (
		item: PharmacyTaxNotePaymentConfirmationDto,
		refresh: () => void
	) => {
		return (
			<Popconfirm
				title="Are you sure to delete payment confirmation?"
				okText="Yes"
				cancelText="No"
				placement="bottom"
				onConfirm={() => {
					ajaxByUser('Successfully deleted payment confirmation.', () =>
						getEssityApiClient().then((api) =>
							api.pharmacyTaxNotePaymentConfirmation.deletePaymentConfirmation
								({
									body: {
										pharmacyTaxNotePaymentConfirmationId: item.id,
									},
								})
								.then(() => refresh())
						)
					);
				}}
			>
				<Tooltip title="Delete">
					<Button shape="round" size="small" danger ghost>
						Delete
					</Button>
				</Tooltip>
			</Popconfirm>
		);
	};

	return (
		<>
			<Button
				type="primary"
				shape="round"
				size="small"
				onClick={() => {
					setVisible(true);
				}}
			>
				Confirm
			</Button>

			{visible && (
				<IsolatedModal
					title="Return confirmation"
					visible={visible}
					width="23vw"
					centered
					cancelButtonProps={{ hidden: true }}
					okButtonProps={{ hidden: true }}
					maskClosable={false}
					onCancel={handleCancel}
					closable={true}
					destroyOnClose
				>
					<AddPaymentConfirmationModal
						taxNote={props.taxNote}
						refresh={refreshOperations}
					></AddPaymentConfirmationModal>

					<Divider />
					<Row>
						<EssityTable
							columns={columns}
							loading={isBusy}
							dataSource={paymentsConfirmation}
							size="small"
							pagination={false}
						/>
					</Row>
					<Row>
						<Col span={16}>
							<p className={styles.confirmationSummary} >
								Summary:{' '}
								{paymentsConfirmation?.reduce((sum, current) => sum + current.amount!, 0)} PLN
							</p>
						</Col>
					</Row>
					<Divider />

					<Row>
						<Col offset={20}>
							<Button
								type="primary"
								shape="round"
								size="large"

								style={{
									marginTop: 10,
								}}
								onClick={() =>
									handleCancel()
								}
							>
								Save
							</Button>
						</Col>
					</Row>
				</IsolatedModal>
			)}
		</>
	);
};
