import { Modal } from 'antd';
import { ModalProps } from 'antd/lib/modal';
import React, { useRef, useState } from 'react';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';

import { IsolatedContainer } from '../IsolatedContainer';

interface IProps {
	children: React.ReactNode;
}

export const IsolatedModal = (props: IProps & ModalProps) => {
	const [bounds, setBounds] = useState({
		left: 0,
		top: 0,
		bottom: 0,
		right: 0,
	});
	const draggleRef = useRef<HTMLDivElement>(null);
	const [disabled, setDisabled] = useState(true);

	const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
		const { clientWidth, clientHeight } = window.document.documentElement;
		const targetRect = draggleRef.current?.getBoundingClientRect();
		if (!targetRect) {
			return;
		}
		setBounds({
			left: -targetRect.left + uiData.x,
			right: clientWidth - (targetRect.right - uiData.x),
			top: -targetRect.top + uiData.y,
			bottom: clientHeight - (targetRect.bottom - uiData.y),
		});
	};

	return (
		<IsolatedContainer>
			<Modal
				width="30vw"
				centered
				cancelButtonProps={{ hidden: true }}
				okButtonProps={{ hidden: true }}
				maskClosable={false}
				closable={true}
				modalRender={(modal) => (
					<Draggable
						bounds={bounds}
						onStart={(event, uiData) => onStart(event, uiData)}
						disabled={disabled}
					>
						<div ref={draggleRef}>{modal}</div>
					</Draggable>
				)}
				destroyOnClose
				{...props}
				title={
					<p
						style={{ width: '100%', cursor: 'move' }}
						onMouseOver={() => {
							if (disabled) {
								setDisabled(false);
							}
						}}
						onMouseOut={() => {
							setDisabled(true);
						}}
					>
						{props.title}
					</p>
				}
			>
				{props.children}
			</Modal>
		</IsolatedContainer>
	);
};
