import { ajaxCatchSilently } from '@helper/api';
import { getEssityApiClient } from '@services/EssityApi';
import { VoivodeshipIdentifierDto } from '@services/src/models';
import EssityTable from 'layout/EssityTable';
import React, { useEffect } from 'react';
import { useState } from 'react';

import { IdentifiersTableColumns } from './IdentifiersTableColumns';

interface IProps {
	voivodeshipId: number;
	kodKom: number;
}

export const IdentifiersTable = (props: IProps) => {
	const [identifiers, setIdentifiers] = useState<
		VoivodeshipIdentifierDto[]
	>();
	const [isBusy, setIsBusy] = useState<boolean>(false);

	useEffect(() => {
		setIsBusy(true);
		ajaxCatchSilently(
			() =>
				getEssityApiClient().then((api) =>
					api.voivodeship.getVoivodeshipIdentifiers({
						voivodeshipId: props.voivodeshipId,
						kodKom: props.kodKom,
					})
				),
			(result: VoivodeshipIdentifierDto[]) => {
				setIdentifiers(result);
				setIsBusy(false);
			},
			() => {
				setIsBusy(false);
			}
		);
	}, []);

	return (
		<EssityTable
			loading={isBusy}
			columns={IdentifiersTableColumns}
			dataSource={identifiers}
			pagination={false}
		/>
	);
};
