import { AccountDto } from '@services/src/models';

import { AccountRoles } from './Roles';

export default class PermissionValidator {
	private account: AccountDto;

	constructor(account: AccountDto) {
		this.account = account;
	}

	public hasRole(role: AccountRoles): boolean {
		const accountRoles = this.account?.roles?.map((x) => x.name);

		return accountRoles !== undefined && accountRoles.includes(role);
	}

	public hasAnyRole(roles: AccountRoles[]): boolean {
		let isValid = false;
		const accountRoles = this.account?.roles?.map((x) => x.name);

		for (let i = 0; i < roles.length; i++) {
			isValid =
				accountRoles !== undefined && accountRoles.includes(roles[i]);

			if (isValid) {
				return isValid;
			}
		}

		return isValid;
	}
}
