import { PharmacyType } from '@services/src/models';
import { Col, Row } from 'antd';
import { Field } from 'formik';
import { FFieldLabel, FInput, FRadioGroup } from 'forms/FormikFormItems';
import React from 'react';
import styles from '../styles/PharmacyEdit.module.less';

interface IProps {
	readOnly: boolean;
}

export const GeneralData = (props: IProps) => (
	<Col span={12}>
		<h2 className="header-text">General data</h2>

		<div className={styles.description}>
			<Row>
				<Col span={12}>
					<div className="fieldbox">
						<FFieldLabel label="Name" />
						<Field
							component={FInput}
							placeholder="Name"
							name="name"
							readOnly={true}
						/>
					</div>
				</Col>
				<Col span={11} offset={1}>
					<div className="fieldbox">
						<FFieldLabel label="Company Name" />
						<Field
							component={FInput}
							placeholder="Company Name"
							name="companyName"
							readOnly={props.readOnly}
						/>
					</div>
				</Col>
			</Row>

			<Row>
				<Col span={12}>
					<div className="fieldbox">
						<FFieldLabel label="Pharmacy Chain" />
						<Field
							component={FInput}
							placeholder="Pharmacy Chain"
							name="pharmacyChain"
							readOnly={true}
						/>
					</div>
				</Col>
				<Col span={11} offset={1}>
					<div className="fieldbox">
						<FFieldLabel label="NIP" />
						<Field
							component={FInput}
							placeholder="NIP"
							name="taxNumber"
							readOnly={props.readOnly}
						/>
					</div>
				</Col>
			</Row>
			<Row>
				<Col span={12}>
					<div className="fieldbox">
						<FFieldLabel label="Type" />
						<Field
							disabled={props.readOnly}
							style={{ width: '100%' }}
							defaultValue={PharmacyType.PHARMACY}
							values={[
								PharmacyType.PHARMACY,
								PharmacyType.MEDICALSHOP,
							]}
							labels={['apteka', 'sklep medyczny']}
							component={FRadioGroup}
							name="type"
						/>
					</div>
				</Col>
				<Col span={11} offset={1}>
					<div className="fieldbox">
						<FFieldLabel label="IBAN" />
						<Field
							component={FInput}
							placeholder="IBAN"
							name="iban"
							readOnly={props.readOnly}
						/>
					</div>
				</Col>
			</Row>
		</div>
	</Col>
);
