import { DeleteOutlined } from '@ant-design/icons';
import DictionaryFilter from '@components/shared/DictionaryFilter';
import { ajaxByUser, ajaxCatch } from '@helper/api';
import { useRootData } from '@hooks/hook';
import { DictionariesConstants } from '@services/DictionariesConstants';
import { getEssityApiClient } from '@services/EssityApi';
import { PriceListDto, PriceListGroup } from '@services/src/models';
import { Button, Divider, Popconfirm, Radio, Space, Tooltip } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import EssityTable from 'layout/EssityTable';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { IDictionaryStore } from 'stores/DictionaryStore';
import { IGridStore } from 'stores/GridStore';

import { ImportPriceListFileModal } from './ImportPriceListFileModal';
import { GetPriceListsTableColumns } from './PriceListsTableColumns';
import styles from './styles/PriceLists.module.less';

interface IProps {
	setSelectedPriceListId: (id?: string) => void;
}

const PriceListsMenu = (props: IProps) => {
	const dictionaryStore: IDictionaryStore = useRootData(
		(store) => store.dictionaryStore
	);

	const gridStore: IGridStore = useRootData((store) => store.gridStore);
	const [priceLists, setPriceLists] = useState<PriceListDto[]>();
	const [selectedPriceListId, setSelectedPriceListId] = useState<string>();
	const [
		selectedPriceListGroup,
		setSelectedPriceListGroup,
	] = useState<PriceListGroup>();

	const [selectedVoivodeshipIds, setSelectedVoivodeshipIds] = useState<
		number[]
	>();
	const [isBusy, setIsBusy] = useState<boolean>(false);

	const refreshPriceLists = () => {
		setIsBusy(true);
		ajaxCatch(() =>
			getEssityApiClient().then((api) =>
				api.priceList
					.all({
						priceListGroup: selectedPriceListGroup,
						voivodeshipId: selectedVoivodeshipIds,
					})
					.then((result) => {
						if (result) {
							setPriceLists(result);
						}
					})
					.finally(() => setIsBusy(false))
			)
		);
	};

	useEffect(() => refreshPriceLists(), [
		selectedPriceListGroup,
		selectedVoivodeshipIds,
	]);

	const columns = [
		...GetPriceListsTableColumns(),
		{
			key: 'operation',
			fixed: 'right',
			width: 50,
			render: (item: PriceListDto) => (
				<Tooltip title="Delete">
					<Popconfirm
						title="Are you sure to delete price list?"
						okText="Yes"
						cancelText="No"
						placement="bottom"
						onConfirm={() => {
							ajaxByUser(
								'Successfully deleted price list',
								() =>
									getEssityApiClient().then((api) =>
										api.priceList.deleteMethod({
											priceListId: item.id,
										})
									),
								() => {
									props.setSelectedPriceListId(undefined);
									setSelectedPriceListId(undefined);
									refreshPriceLists();
									gridStore.searching.set(true);
								},
								() => {}
							);
						}}
					>
						<Button
							type="primary"
							shape="circle"
							size="small"
							icon={<DeleteOutlined />}
						/>
					</Popconfirm>
				</Tooltip>
			),
		},
	];

	return (
		<>
			<Divider />
			<div className={styles.menu}>
				<div />
				<div>
					<h2 className="header-text">Price lists</h2>
					<div className={styles.filters}>
						<DictionaryFilter
							className="item"
							mode="multiple"
							name="Voivodeship"
							onChange={(items: number[]) => {
								setSelectedVoivodeshipIds(items);
								props.setSelectedPriceListId(undefined);
								setSelectedPriceListId(undefined);
								gridStore.searching.set(true);
							}}
							dictionary={dictionaryStore.getDictionary(
								DictionariesConstants.Voivodeships
							)}
						/>

						<Space
							direction="horizontal"
							className={styles.radioFilters}
						>
							<Radio.Group
								onChange={(event: RadioChangeEvent) => {
									setSelectedPriceListGroup(
										event.target.value
									);
									props.setSelectedPriceListId(undefined);
									setSelectedPriceListId(undefined);
									gridStore.searching.set(true);
								}}
								defaultValue={undefined}
							>
								<Radio value={PriceListGroup.Auxiliaries}>
									Auxiliaries
								</Radio>
								<Radio
									value={PriceListGroup.OrthopedicProducts}
								>
									Orthopedic Products
								</Radio>
								<Radio value={undefined}>All</Radio>
							</Radio.Group>
						</Space>
					</div>
					<div className={styles.table}>
						<EssityTable
							loading={isBusy}
							gridName="priceLists"
							columns={columns}
							dataSource={priceLists}
							getRowClassName={(
								record: PriceListDto,
								index: number
							) => {
								if (record.id === selectedPriceListId) {
									return styles.markedRow;
								}
							}}
							scroll={{ y: 160}}
							onRow={(record: PriceListDto, rowIndex: number) => {
								return {
									onClick: (event: any) => {
										if (record.id !== selectedPriceListId) {
											props.setSelectedPriceListId(
												record.id!
											);
											setSelectedPriceListId(record.id);
											gridStore.searching.set(true);
										}
									},
								};
							}}
							pagination={false}
						/>
					</div>
				</div>
				<div className={styles.importButton}>
					<ImportPriceListFileModal
						onSubmit={async () => {
							setPriceLists([]);
							refreshPriceLists();
						}}
					/>
				</div>
			</div>
		</>
	);
};

export default observer(PriceListsMenu);
