import { IsolatedModal } from '@components/shared/modals/IsolatedModal';
import { ajaxByUser } from '@helper/api';
import { getEssityApiClient } from '@services/EssityApi';
import { PharmacyDto, RegisterAccountCommand } from '@services/src/models';
import { Button, Select } from 'antd';
import { getBusinessRoles } from 'authorization/Roles';
import { Field, Formik, FormikActions } from 'formik';
import {
	FFieldLabel,
	FInput,
	FPasswordInput,
	FSelect,
} from 'forms/FormikFormItems';
import { CenteredRow } from 'layout/CenteredRow';
import React, { useState } from 'react';

import { AccountRegisterValidationSchema } from './AccountRegisterValidationSchema';

interface IProps {
	onCreate: () => void;
	pharmacy?: PharmacyDto;
}

export const AccountRegisterModal = (modalProps: IProps) => {
	const [visible, setVisible] = useState<boolean>(false);

	const handleCreate = (
		values: RegisterAccountCommand,
		actions: FormikActions<RegisterAccountCommand>
	) => {
		const accountRole = (values.accountRoles as unknown) as string;

		ajaxByUser('Successfully registred new account.', () =>
			getEssityApiClient().then((api) =>
				api.account
					.registerUser({
						body: {
							displayName: values.displayName,
							givenName: values.givenName,
							surname: values.surname,
							userPrincipalName: values.userPrincipalName,
							password: values.password,
							accountRoles: [
								{
									name: accountRole,
									pharmacyId: modalProps.pharmacy?.id,
								},
							],
						},
					})
					.then(() => {
						modalProps.onCreate();
						setVisible(false);
						actions.resetForm();
					})
			)
		);
	};

	const handleCancel = (actions: FormikActions<RegisterAccountCommand>) => {
		actions.resetForm();
		setVisible(false);
	};

	const RenderUserFields = () => {
		return (
			<>
				<FFieldLabel label="Display name" />
				<Field
					component={FInput}
					placeholder="Display name"
					name="displayName"
					readOnly={false}
				/>
				<FFieldLabel label="Given name" />
				<Field
					component={FInput}
					placeholder="Given name"
					name="givenName"
					readOnly={false}
				/>
				<FFieldLabel label="Surname" />
				<Field
					component={FInput}
					placeholder="Surname"
					name="surname"
					readOnly={false}
				/>
				<FFieldLabel label="E-mail" />
				<Field
					component={FInput}
					placeholder="E-mail"
					name="userPrincipalName"
					readOnly={false}
				/>
				<FFieldLabel label="Password" />
				<Field
					component={FPasswordInput}
					placeholder="Password"
					name="password"
					readOnly={false}
				/>
				<FFieldLabel label="Repeat password" />
				<Field
					component={FPasswordInput}
					placeholder="Repeat Password"
					name="passwordConfirmation"
					readOnly={false}
				/>
				<FFieldLabel label="Account role" />
				<Field
					component={FSelect}
					placeholder="Account role"
					name="accountRoles"
					readOnly={false}
					children={getBusinessRoles().map((x, i) => (
						<Select.Option key={i} value={x[0]}>
							{x[1]}
						</Select.Option>
					))}
				/>
			</>
		);
	};

	const handleRender = (actions: FormikActions<RegisterAccountCommand>) => {
		return (
			<IsolatedModal
				title="Register new account"
				visible={visible}
				onCancel={() => handleCancel(actions)}
				closable={true}
			>
				<RenderUserFields />

				<CenteredRow style={{ marginTop: '15px' }}>
					<Button
						type="primary"
						size="large"
						shape="round"
						onClick={() => actions.submitForm()}
					>
						Register
					</Button>
				</CenteredRow>
			</IsolatedModal>
		);
	};

	return (
		<>
			<Button
				size="large"
				type="primary"
				shape="round"
				onClick={() => {
					setVisible(true);
				}}
			>
				Register account
			</Button>

			<Formik
				validateOnChange
				validateOnBlur
				enableReinitialize
				isInitialValid={false}
				initialValues={{}}
				validationSchema={AccountRegisterValidationSchema}
				onSubmit={(
					values,
					actions: FormikActions<RegisterAccountCommand>
				) => handleCreate(values, actions)}
				render={handleRender}
			/>
		</>
	);
};
