import { RcCustomRequestOptions } from 'antd/lib/upload/interface';
import { showErrorModal } from 'layout/Modals';

export const MimeTypes = {
	CSV: 'application/vnd.ms-excel',
	TEXTCSV: 'text/csv',
	XML: 'text/xml',
	XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
};

export const IsFileExtensionValid = (
	options: RcCustomRequestOptions,
	allowedExtensions: string[]
) => {
	if (!allowedExtensions.includes(options.file.type)) {
		showErrorModal(
			`The file cannot be imported due to an invalid extension.`
		);
		return false;
	}
	return true;
};
