import { InternalTaxNoteType } from '@services/src/models';

export const valueMap = new Map<InternalTaxNoteType, string>([
	[InternalTaxNoteType.Correction, 'Correction'],
    [InternalTaxNoteType.Debit, 'Without note'],
    [InternalTaxNoteType.Loss, 'Loss'],
    [InternalTaxNoteType.Main, 'Main'],
    [InternalTaxNoteType.PayLoss, 'Pay Loss'],
    [InternalTaxNoteType.Return, 'Return'],
    [InternalTaxNoteType.Overpay, 'Overpay'],
]);

export const map = (value: InternalTaxNoteType): string => valueMap.get(value) ?? '';