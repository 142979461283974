import { EssityColumnProps } from '@components/shared/paginatedEssityTable/GridHelper';

export const SendToSAPTableColumns: EssityColumnProps<any>[] = [
	{
		sorter: false,
		dataIndex: 'month',
		key: 'month',
		title: 'Month',
		width: 120,
	},
	{
		sorter: false,
		dataIndex: 'year',
		key: 'year',
		title: 'Year',
		width: 120,
	},
	{
		sorter: false,
		dataIndex: 'amount',
		key: 'amount',
		title: 'Pharmacy Value',
		width: 140,
	},
];
