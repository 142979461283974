import { IsolatedModal } from '@components/shared/modals/IsolatedModal';
import { ajaxByUser, ajaxCatch } from '@helper/api';
import { getEssityApiClient } from '@services/EssityApi';
import { getManualOperationTypes } from '@services/mappers/ManualOperationType';
import {
	PharmacyTaxNoteGroupManualOperationDto,
	UpdatePharmacyTaxNoteGroupManualOperationCommand,
} from '@services/src/models';
import { Button, Select } from 'antd';
import { Field, Formik, FormikActions, FormikProps } from 'formik';
import {
	FDatePicker,
	FFieldLabel,
	FInputNumber,
	FSelect,
} from 'forms/FormikFormItems';
import { CenteredRow } from 'layout/CenteredRow';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { ManualOperationValidationSchema } from './ManualOperationValidationSchema';

interface IProps {
	refresh: () => void;
	operationId: string;
}

export const EditManualOperationModal = (props: IProps) => {
	const [visible, setVisible] = useState<boolean>();
	const [manualOperation, setManualOperation] =
		useState<PharmacyTaxNoteGroupManualOperationDto>();

	useEffect(() => {
		if (visible) {
			ajaxCatch(
				() =>
					getEssityApiClient().then((api) =>
						api.pharmacyTaxNoteGroupManualOperation
							.getById(props.operationId)
							.then(
								(
									result: PharmacyTaxNoteGroupManualOperationDto
								) => {
									setManualOperation(result);
								}
							)
					),
				() => {},
				() => {},
				false
			);
		}
	}, [visible]);

	const handleCancel = () => {
		setVisible(false);
	};

	const handleUpdate = (
		values: UpdatePharmacyTaxNoteGroupManualOperationCommand,
		actions: FormikActions<UpdatePharmacyTaxNoteGroupManualOperationCommand>
	) => {
		ajaxByUser('Successfully updated operation.', () =>
			getEssityApiClient().then((api) =>
				api.pharmacyTaxNoteGroupManualOperation
					.update({
						body: {
							id: props.operationId,
							date: moment(values.date).toDate(),
							value: values.value,
							manualOperationType: values.manualOperationType,
						},
					})
					.then(() => {
						setVisible(false);
						props.refresh();
						actions.resetForm();
					})
			)
		);
	};

	const getInitialValues = () => {
		return {
			id: manualOperation?.id,
			date: moment(manualOperation?.date),
			value: manualOperation?.value,
			manualOperationType: manualOperation?.manualOperationType,
		};
	};

	return (
		<>
			<Button
				type="primary"
				shape="round"
				size="small"
				ghost
				onClick={() => {
					setVisible(true);
				}}
			>
				Edit
			</Button>
			{visible && manualOperation && (
				<IsolatedModal
					title="Edit manual operation"
					visible={visible}
					width="23vw"
					centered
					cancelButtonProps={{ hidden: true }}
					okButtonProps={{ hidden: true }}
					maskClosable={false}
					onCancel={handleCancel}
					closable={true}
					destroyOnClose
				>
					<Formik
						validateOnChange={false}
						validateOnBlur={true}
						initialValues={getInitialValues()}
						enableReinitialize
						validationSchema={ManualOperationValidationSchema}
						onSubmit={(values: any, actions: FormikActions<any>) =>
							handleUpdate(values, actions)
						}
						render={(formikProps: FormikProps<any>) => (
							<>
								<FFieldLabel label="Type" />
								<Field
									component={FSelect}
									placeholder="Type"
									name="manualOperationType"
									readOnly={false}
									children={getManualOperationTypes().map(
										(x, i) => (
											<Select.Option key={i} value={x[0]}>
												{x[1]}
											</Select.Option>
										)
									)}
								/>

								<FFieldLabel label="Value" />
								<Field
									component={FInputNumber}
									min={0.01}
									placeholder="Value"
									name="value"
									readOnly={false}
								/>

								<FFieldLabel label="Date" />
								<Field
									component={FDatePicker}
									name="date"
									mode="date"
									placeholder="Date"
									readOnly={false}
									style={{ width: '100%' }}
								/>
								<CenteredRow style={{ marginTop: '15px' }}>
									<Button
										type="primary"
										size="large"
										shape="round"
										onClick={() => formikProps.submitForm()}
									>
										Save
									</Button>
								</CenteredRow>
							</>
						)}
					></Formik>
				</IsolatedModal>
			)}
		</>
	);
};
