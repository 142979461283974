import {
	CreatePharmacyCommand,
	PharmacyDto,
	PharmacyType,
	UpdatePharmacyCommand,
} from '@services/src/models';
import moment, { Moment } from 'moment';

export class PharmacyEditDto {
	id?: string;
	name?: string;
	companyName?: string;
	city?: string;
	street?: string;
	voivodeshipId?: number;
	taxNumber?: string;
	placeId?: number;
	endDateOfEssityContract?: Moment;
	dateOfJoiningEssityNet?: Moment;
	type?: PharmacyType;
	endContractReasonId?: number;
	nhfContractId?: number;
	essityPaperContractNumber?: string;
	healthFundID?: string;
	farmapromPharmacyId?: number;
	kodKom?: number;
	login?: string;
	kam?: string;
	mail?: string;
	phoneNumber?: string;
	salesRepresentative?: string;
	postalCode?: string;
	district?: number;
	pharmacyChain?: string;
	description?: string;
	iban?: string;
	identifier?: number;
	businessContinuity?: string;
	isPharmacyHasNhfContract?: boolean;

	constructor(dto: PharmacyDto | undefined) {
		if (!dto) {
			return;
		}

		this.id = dto.id;
		this.name = dto.name;
		this.companyName = dto.companyName;
		this.city = dto.city;
		this.street = dto.street;
		this.voivodeshipId = dto.voivodeshipId;
		this.taxNumber = dto.taxNumber;
		this.placeId = dto.placeId;
		this.endDateOfEssityContract = dto.endDateOfEssityContract
			? moment(dto.endDateOfEssityContract)
			: undefined;
		this.dateOfJoiningEssityNet = dto.dateOfJoiningEssityNet
			? moment(dto.dateOfJoiningEssityNet)
			: undefined;
		this.type = dto.type;
		this.endContractReasonId = dto.endContractReasonId;
		this.nhfContractId = dto.nhfContractId;
		this.essityPaperContractNumber = dto.essityPaperContractNumber;
		this.healthFundID = dto.healthFundID;
		this.farmapromPharmacyId = dto.farmapromPharmacyId;
		this.kodKom = dto.kodKom;
		this.login = dto.login;
		this.kam = dto.kam;
		this.mail = dto.mail;
		this.phoneNumber = dto.phoneNumber;
		this.salesRepresentative = dto.salesRepresentative;
		this.postalCode = dto.postalCode;
		this.district = dto.district;
		this.pharmacyChain = dto.pharmacyChain;
		this.description = dto.description;
		this.iban = dto.iban;
		this.identifier = dto.identifier;
		this.businessContinuity = dto.businessContinuity;
		this.isPharmacyHasNhfContract = dto.isPharmacyHasNhfContract;
	}

	toCreateCommand(): CreatePharmacyCommand {
		return {
			name: this.name,
			companyName: this.companyName,
			city: this.city,
			street: this.street,
			voivodeshipId: this.voivodeshipId,
			taxNumber: this.taxNumber,
			type: this.type,
			// placeId: this.placeId,
			endDateOfEssityContract:
				this.endDateOfEssityContract?.toDate() || undefined,
			dateOfJoiningEssityNet:
				this.dateOfJoiningEssityNet?.toDate() || undefined,
			endContractReasonId: this.endContractReasonId,
			nhfContractId: this.nhfContractId,
			essityPaperContractNumber: this.essityPaperContractNumber,
			healthFundID: this.healthFundID,
			farmapromPharmacyId: this.farmapromPharmacyId,
			kodKom: this.kodKom,
			login: this.login,
			kam: this.kam,
			mail: this.mail,
			phoneNumber: this.phoneNumber,
			salesRepresentative: this.salesRepresentative,
			postalCode: this.postalCode,
			district: this.district,
			pharmacyChain: this.pharmacyChain,
			description: this.description,
			iban: this.iban,
			identifier: this.identifier,
			businessContinuity: this.businessContinuity,
		};
	}

	toUpdateCommand(): UpdatePharmacyCommand {
		return {
			id: this.id,
			name: this.name,
			companyName: this.companyName,
			city: this.city,
			street: this.street,
			voivodeshipId: this.voivodeshipId,
			taxNumber: this.taxNumber,
			// placeId: this.placeId,
			endDateOfEssityContract:
				this.endDateOfEssityContract?.toDate() || undefined,
			dateOfJoiningEssityNet:
				this.dateOfJoiningEssityNet?.toDate() || undefined,
			type: this.type,
			endContractReasonId: this.endContractReasonId,
			nhfContractId: this.nhfContractId,
			essityPaperContractNumber: this.essityPaperContractNumber,
			healthFundID: this.healthFundID,
			farmapromPharmacyId: this.farmapromPharmacyId,
			kodKom: this.kodKom,
			login: this.login,
			kam: this.kam,
			mail: this.mail,
			phoneNumber: this.phoneNumber,
			salesRepresentative: this.salesRepresentative,
			postalCode: this.postalCode,
			district: this.district,
			pharmacyChain: this.pharmacyChain,
			description: this.description,
			iban: this.iban,
			identifier: this.identifier,
			businessContinuity: this.businessContinuity,
		};
	}
}
