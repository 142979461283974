import { AccountRoles } from '@authorization/Roles';

import { MenuItemSchema, SubMenuItemSchema } from './MenuItems';

export const menuItems: Array<MenuItemSchema | SubMenuItemSchema> = [
	{
		path: 'orderGroups',
		text: 'NHF Reports',
		key: 'orderGroups',
		activePrefix: ['orderGroups', 'order'],
	},
	{
		path: 'nhfSettlements',
		text: 'NHF Notes',
		key: 'nhfSettlements',
		activePrefix: 'nhfSettlements',
	},
	{
		path: 'pharmacyPayments',
		text: 'Pharmacies Payments',
		key: 'pharmacyPayments',
		activePrefix: [
			'pharmacyPayments',
			'pharmacyNote',
			'correctionNote',
			'lossNote',
			'overpayNote'
		],
	},
	{
		path: 'pharmacies',
		text: 'Clients',
		key: 'pharmacies',
		activePrefix: 'pharmacies',
	},
	{
		path: 'reports',
		text: 'Reports',
		key: 'reports',
		activePrefix: 'reports',
	},
	{
		roles: [AccountRoles.BUSINESS_ADMIN],
		path: 'sendingPayments',
		text: 'Sending Payments',
		key: 'sendingPayments',
		activePrefix: 'sendingPayments',
	},
];
