import {
	ArrowRightOutlined,
	CloudDownloadOutlined
} from '@ant-design/icons';
import { EssityColumnProps } from '@components/shared/paginatedEssityTable/GridHelper';
import { ajaxByUser, ajaxCatch } from '@helper/api';
import formatHelpers from '@helper/formatHelpers';
import { getEssityApiClient } from '@services/EssityApi';
import { OrderReferencesDto, OrderReferenceType, SearchType } from '@services/src/models';
import { Button, Col, Collapse, Descriptions, Skeleton, Space, Tooltip, } from 'antd';
import { CenteredRow } from 'layout/CenteredRow';
import EssityTable from 'layout/EssityTable';
import React, { useState } from 'react';
import styleModule from './styles/OrderLines.module.less';
import * as OrderReferenceTypeMapper from '@services/mappers/OrderReferenceType';
import { DownloadFile } from '@components/shared/file/FileDownloadFunctions';

interface IProps {
	orderId: string;
	theme?: { [key: string]: string };

}
interface IState {
	orderReferences?: OrderReferencesDto[];
}
export const OrderReferences = (props: IProps) => {
	const OrderReferencesTableColumns: EssityColumnProps<OrderReferencesDto>[] = [
		{
			sorter: false,
			dataIndex: 'referenceType',
			key: 'referenceType',
			title: 'Name',
			render: (value: OrderReferenceType) => {
				return OrderReferenceTypeMapper.map(value)
			},
		},
		{
			sorter: false,
			dataIndex: 'createdDate',
			key: 'createdDate',
			title: 'Date',
			render: (value?: Date) => formatHelpers.formatDate(value),

		},
		{
			sorter: false,
			dataIndex: 'details',
			key: 'details',
			title: 'Details',
		},
		{
			title: '',
			key: 'operation',
			fixed: 'right',
			width: 50,
			render: (item: OrderReferencesDto) => {
				switch (item.referenceType) {
					case OrderReferenceType.REPORTFILE:
					case OrderReferenceType.REPORTCORRECTIONFILE:
					case OrderReferenceType.RETURNDOCUMENT:
						return (
							<Tooltip title="Download">
								<Button
									type="primary"
									shape="circle"
									size="small"
									onClick={() =>
										ajaxByUser('Successfully downloaded file', () =>
											getEssityApiClient().then((api) =>
												api.document
													.getById(item.identifierId!)
													.then((result) => {
														DownloadFile({
															response: result,
														});
													})
											)
										)
									}
									icon={<CloudDownloadOutlined />}
								/>
							</Tooltip>
						)
					case OrderReferenceType.NOTE:
					case OrderReferenceType.CORRECTIONNOTE:
						return <Button
							type="primary"
							shape="circle"
							size="small"
							href={`nhfSettlements?notename=${item.details ?? ""}`}
							icon={<ArrowRightOutlined />}
						/>

				}
			}
		},
	];

	const styles = props.theme ? props.theme : styleModule;
	const [isBusy, setIsBusy] = useState<boolean>(false);
	const columns: EssityColumnProps<OrderReferencesDto>[] = [
		...OrderReferencesTableColumns,

	];
	const [state, setState] = useState<IState>({

		orderReferences: undefined,
	});

	const onChange = (key: any) => {
		if (props.orderId && state.orderReferences == undefined) {
			setIsBusy(true);
			ajaxCatch(() =>
				getEssityApiClient().then((api) =>
					api.order.getOrderReferencesByOrderId({ orderId: props.orderId }).then((result) => {
						if (result) {
							setState({
								...state,
								orderReferences: result,
							});
						}
					}).finally(() => setIsBusy(false))
				)
			);
		}
	};


	return (
		<div className={styles.details}>
			<div className={styles.section}>
				<Collapse ghost
					onChange={onChange}
					bordered={false}
					expandIconPosition={'right'}
				>
					<Collapse.Panel
						header={

							<Space className="header-text">
								Related documents
							</Space>
						}
						key="1">
						{state.orderReferences ?
							(
								<>
									<EssityTable
										columns={columns}
										className={styles.table}
										loading={isBusy}
										dataSource={state.orderReferences}
										pagination={false}
									/>
								</>
							)
							: <>
								<CenteredRow>
									<Col span={24}>
										<Skeleton />
									</Col>
								</CenteredRow>
							</>}

					</Collapse.Panel>
				</Collapse>
			</div>
		</div>
	);
};
