import { CloudUploadOutlined } from '@ant-design/icons';
import { DownloadFile } from '@components/shared/file/FileDownloadFunctions';
import {
	IsFileExtensionValid,
	MimeTypes,
} from '@components/shared/file/IsFileExtensionValid';
import { DoneView } from '@components/shared/import/DoneView';
import { ExceptionView } from '@components/shared/import/ExceptionView';
import { LoadingView } from '@components/shared/import/LoadingView';
import { IsolatedContainer } from '@components/shared/IsolatedContainer';
import { IsolatedModal } from '@components/shared/modals/IsolatedModal';
import { ImportStatus } from '@components/shared/types/importStatus';
import { ajaxByUser, ajaxCatchSilently } from '@helper/api';
import formatHelpers from '@helper/formatHelpers';
import { getEssityApiClient } from '@services/EssityApi';
import { ImportModelImportPharmaciesOptionalParams } from '@services/src/models';
import { Button, Col, Modal } from 'antd';
import Dragger from 'antd/lib/upload/Dragger';
import {
	RcCustomRequestOptions,
	UploadChangeParam,
	UploadFile,
} from 'antd/lib/upload/interface';
import { Formik, FormikActions, FormikProps } from 'formik';
import { FFieldLabel } from 'forms/FormikFormItems';
import { CenteredRow } from 'layout/CenteredRow';
import React, { useState } from 'react';
import { gridStore } from 'stores/GridStore';

const ImportPharmacies = () => {
	const [visible, setVisible] = useState<boolean>(false);
	const [file, setFile] = useState<File | undefined>();
	const [fileList, setFileList] = useState<UploadFile[] | undefined>();

	const handleModalCancel = (
		props: FormikProps<ImportModelImportPharmaciesOptionalParams>
	) => {
		setVisible(false);
		setFile(undefined);
		setFileList(undefined);
		props.resetForm();
		setProgressState(ImportStatus.Init);
	};

	const [progressState, setProgressState] = useState<ImportStatus>(
		ImportStatus.Init
	);

	const uploadProps = {
		name: 'file',
		multiple: false,
		customRequest: async (options: RcCustomRequestOptions) => {
			const { onSuccess, onError } = options;

			if (!IsFileExtensionValid(options, [MimeTypes.XLSX])) {
				onError(
					{ name: 'Error', message: 'Invalid file extension' },
					'Import failed',
					options.file
				);
				return;
			}

			setFile(options.file);
			onSuccess({}, options.file);
		},
		onChange: (info: UploadChangeParam) => {
			if (info.file.status !== 'removed') {
				setFileList([info.file]);
			}
		},
		onRemove: () => {
			setFile(undefined);
			setFileList(undefined);
		},
		progress: {
			strokeColor: {
				'0%': '#108ee9',
				'100%': '#87d068',
			},
			strokeWidth: 1,
			format: (percent: any) =>
				formatHelpers.formatPercent(percent, true),
		},
		accept: '.xlsx',
		showUploadList: {
			showRemoveIcon: true,
		},
	};

	const AddFileView = (
		props: FormikProps<ImportModelImportPharmaciesOptionalParams>
	) => {
		return (
			<>
				<p>
					In order to import pharmacies data, you have to use template
					.xls file. You can download it{' '}
					<a
						onClick={() => {
							ajaxByUser('Successfully downloaded template', () =>
								getEssityApiClient().then((api) =>
									api.pharmacy
										.getImportTemplate()
										.then((result) => {
											DownloadFile({
												response: result,
											});
										})
								)
							);
						}}
					>
						here
					</a>
					.
				</p>
				<Col span={24}>
					<FFieldLabel label="XLSX" />

					<Dragger {...uploadProps} fileList={fileList}>
						<p className="ant-upload-drag-icon">
							<CloudUploadOutlined />
						</p>
						<p className="ant-upload-text">
							Click or drag file to this area to import
						</p>
						<p>(.xlsx)</p>
					</Dragger>
					<CenteredRow style={{ marginTop: '15px' }}>
						<Button
							type="primary"
							size="large"
							shape="round"
							disabled={props.isSubmitting || file === undefined}
							onClick={() => {
								if (!props.isSubmitting) {
									props.submitForm();
								}
							}}
						>
							Import pharmacies
						</Button>
					</CenteredRow>
				</Col>
			</>
		);
	};

	const getViewByImportStatus = (
		props: FormikProps<ImportModelImportPharmaciesOptionalParams>
	) => {
		switch (progressState) {
			case ImportStatus.Init:
				return <AddFileView {...props} />;
			case ImportStatus.Done:
				return <DoneView />;
			case ImportStatus.Exception:
				return (
					<ExceptionView
						title="Import error"
						message="Please verify if file is correct and try to import again."
					/>
				);
			case ImportStatus.Loading:
				return <LoadingView />;
		}
	};

	const handleModalRender = (
		props: FormikProps<ImportModelImportPharmaciesOptionalParams>
	) => {
		return (
			<IsolatedModal
				title="Import pharmacies data"
				visible={visible}
				onCancel={() => handleModalCancel(props)}
			>
				{getViewByImportStatus(props)}
			</IsolatedModal>
		);
	};

	const handleCreate = (
		values: any,
		actions: FormikActions<ImportModelImportPharmaciesOptionalParams>
	) => {
		setProgressState(ImportStatus.Loading);

		ajaxCatchSilently(
			() =>
				getEssityApiClient().then((api) =>
					api.importModel.importPharmacies({
						file: file,
					})
				),
			() => {
				setProgressState(ImportStatus.Done);
				actions.setSubmitting(false);
				gridStore.searching.set(true);
			},
			() => {
				setProgressState(ImportStatus.Exception);
				actions.setSubmitting(false);
			}
		);
	};

	return (
		<>
			<Button
				type="primary"
				shape="round"
				size="large"
				onClick={() => setVisible(true)}
			>
				Import pharmacies
			</Button>
			<Formik
				validateOnChange
				validateOnBlur
				enableReinitialize
				isInitialValid={false}
				initialValues={{}}
				onSubmit={(
					values,
					actions: FormikActions<ImportModelImportPharmaciesOptionalParams>
				) => handleCreate(values, actions)}
				render={handleModalRender}
			/>
		</>
	);
};

export default ImportPharmacies;
