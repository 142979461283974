/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from "@azure/ms-rest-js";
import * as Models from "../models";
import * as Mappers from "../models/priceListMappers";
import * as Parameters from "../models/parameters";
import { PolandPharmacyRxAPIContext } from "../polandPharmacyRxAPIContext";

/** Class representing a PriceList. */
export class PriceList {
  private readonly client: PolandPharmacyRxAPIContext;

  /**
   * Create a PriceList.
   * @param {PolandPharmacyRxAPIContext} client Reference to the service client.
   */
  constructor(client: PolandPharmacyRxAPIContext) {
    this.client = client;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.PriceListAllResponse>
   */
  all(options?: Models.PriceListAllOptionalParams): Promise<Models.PriceListAllResponse>;
  /**
   * @param callback The callback
   */
  all(callback: msRest.ServiceCallback<Models.PriceListDto[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  all(options: Models.PriceListAllOptionalParams, callback: msRest.ServiceCallback<Models.PriceListDto[]>): void;
  all(options?: Models.PriceListAllOptionalParams | msRest.ServiceCallback<Models.PriceListDto[]>, callback?: msRest.ServiceCallback<Models.PriceListDto[]>): Promise<Models.PriceListAllResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      allOperationSpec,
      callback) as Promise<Models.PriceListAllResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  deleteMethod(options?: Models.PriceListDeleteMethodOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  deleteMethod(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteMethod(options: Models.PriceListDeleteMethodOptionalParams, callback: msRest.ServiceCallback<void>): void;
  deleteMethod(options?: Models.PriceListDeleteMethodOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      deleteMethodOperationSpec,
      callback);
  }
}

// Operation Specifications
const serializer = new msRest.Serializer(Mappers);
const allOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "priceList/PriceList",
  queryParameters: [
    Parameters.priceListGroup1,
    Parameters.voivodeshipId0
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "PriceListDto"
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const deleteMethodOperationSpec: msRest.OperationSpec = {
  httpMethod: "DELETE",
  path: "priceList/PriceList",
  queryParameters: [
    Parameters.priceListId0
  ],
  responses: {
    200: {},
    default: {}
  },
  serializer
};
