import { EssityColumnProps } from '@components/shared/paginatedEssityTable/GridHelper';
import formatHelpers from '@helper/formatHelpers';
import { VoivodeshipContractDto } from '@services/src/models';

export const ContractsTableColumns: EssityColumnProps<VoivodeshipContractDto>[] = [
	{
		dataIndex: 'mainContractNumber',
		key: 'mainContractNumber',
		title: 'Main contract',
	},
	{
		dataIndex: 'contractNumber',
		key: 'contractNumber',
		title: 'Additional contract',
	},
	{
		dataIndex: 'contractStartDate',
		key: 'contractStartDate',
		title: 'Start date',
		render: (value?: Date) => formatHelpers.formatDate(value),
	},
	{
		dataIndex: 'contractEndDate',
		key: 'contractEndDate',
		title: 'End date',
		render: (value?: Date) => formatHelpers.formatDate(value),
	},
];
