/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from "@azure/ms-rest-js";
import * as Models from "../models";
import * as Mappers from "../models/pharmacyTaxNotePaymentConfirmationMappers";
import * as Parameters from "../models/parameters";
import { PolandPharmacyRxAPIContext } from "../polandPharmacyRxAPIContext";

/** Class representing a PharmacyTaxNotePaymentConfirmation. */
export class PharmacyTaxNotePaymentConfirmation {
  private readonly client: PolandPharmacyRxAPIContext;

  /**
   * Create a PharmacyTaxNotePaymentConfirmation.
   * @param {PolandPharmacyRxAPIContext} client Reference to the service client.
   */
  constructor(client: PolandPharmacyRxAPIContext) {
    this.client = client;
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<Models.PharmacyTaxNotePaymentConfirmationGetByPharmacyTaxNoteIdResponse>
   */
  getByPharmacyTaxNoteId(id: string, options?: msRest.RequestOptionsBase): Promise<Models.PharmacyTaxNotePaymentConfirmationGetByPharmacyTaxNoteIdResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  getByPharmacyTaxNoteId(id: string, callback: msRest.ServiceCallback<Models.PharmacyTaxNotePaymentConfirmationDto[]>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  getByPharmacyTaxNoteId(id: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.PharmacyTaxNotePaymentConfirmationDto[]>): void;
  getByPharmacyTaxNoteId(id: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.PharmacyTaxNotePaymentConfirmationDto[]>, callback?: msRest.ServiceCallback<Models.PharmacyTaxNotePaymentConfirmationDto[]>): Promise<Models.PharmacyTaxNotePaymentConfirmationGetByPharmacyTaxNoteIdResponse> {
    return this.client.sendOperationRequest(
      {
        id,
        options
      },
      getByPharmacyTaxNoteIdOperationSpec,
      callback) as Promise<Models.PharmacyTaxNotePaymentConfirmationGetByPharmacyTaxNoteIdResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.PharmacyTaxNotePaymentConfirmationAssignPaymentConfirmationResponse>
   */
  assignPaymentConfirmation(options?: Models.PharmacyTaxNotePaymentConfirmationAssignPaymentConfirmationOptionalParams): Promise<Models.PharmacyTaxNotePaymentConfirmationAssignPaymentConfirmationResponse>;
  /**
   * @param callback The callback
   */
  assignPaymentConfirmation(callback: msRest.ServiceCallback<string>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  assignPaymentConfirmation(options: Models.PharmacyTaxNotePaymentConfirmationAssignPaymentConfirmationOptionalParams, callback: msRest.ServiceCallback<string>): void;
  assignPaymentConfirmation(options?: Models.PharmacyTaxNotePaymentConfirmationAssignPaymentConfirmationOptionalParams | msRest.ServiceCallback<string>, callback?: msRest.ServiceCallback<string>): Promise<Models.PharmacyTaxNotePaymentConfirmationAssignPaymentConfirmationResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      assignPaymentConfirmationOperationSpec,
      callback) as Promise<Models.PharmacyTaxNotePaymentConfirmationAssignPaymentConfirmationResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.PharmacyTaxNotePaymentConfirmationChangeStateResponse>
   */
  changeState(options?: Models.PharmacyTaxNotePaymentConfirmationChangeStateOptionalParams): Promise<Models.PharmacyTaxNotePaymentConfirmationChangeStateResponse>;
  /**
   * @param callback The callback
   */
  changeState(callback: msRest.ServiceCallback<string>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  changeState(options: Models.PharmacyTaxNotePaymentConfirmationChangeStateOptionalParams, callback: msRest.ServiceCallback<string>): void;
  changeState(options?: Models.PharmacyTaxNotePaymentConfirmationChangeStateOptionalParams | msRest.ServiceCallback<string>, callback?: msRest.ServiceCallback<string>): Promise<Models.PharmacyTaxNotePaymentConfirmationChangeStateResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      changeStateOperationSpec,
      callback) as Promise<Models.PharmacyTaxNotePaymentConfirmationChangeStateResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.PharmacyTaxNotePaymentConfirmationUpdatePaymentConfirmationResponse>
   */
  updatePaymentConfirmation(options?: Models.PharmacyTaxNotePaymentConfirmationUpdatePaymentConfirmationOptionalParams): Promise<Models.PharmacyTaxNotePaymentConfirmationUpdatePaymentConfirmationResponse>;
  /**
   * @param callback The callback
   */
  updatePaymentConfirmation(callback: msRest.ServiceCallback<string>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  updatePaymentConfirmation(options: Models.PharmacyTaxNotePaymentConfirmationUpdatePaymentConfirmationOptionalParams, callback: msRest.ServiceCallback<string>): void;
  updatePaymentConfirmation(options?: Models.PharmacyTaxNotePaymentConfirmationUpdatePaymentConfirmationOptionalParams | msRest.ServiceCallback<string>, callback?: msRest.ServiceCallback<string>): Promise<Models.PharmacyTaxNotePaymentConfirmationUpdatePaymentConfirmationResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      updatePaymentConfirmationOperationSpec,
      callback) as Promise<Models.PharmacyTaxNotePaymentConfirmationUpdatePaymentConfirmationResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.PharmacyTaxNotePaymentConfirmationDeletePaymentConfirmationResponse>
   */
  deletePaymentConfirmation(options?: Models.PharmacyTaxNotePaymentConfirmationDeletePaymentConfirmationOptionalParams): Promise<Models.PharmacyTaxNotePaymentConfirmationDeletePaymentConfirmationResponse>;
  /**
   * @param callback The callback
   */
  deletePaymentConfirmation(callback: msRest.ServiceCallback<string>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  deletePaymentConfirmation(options: Models.PharmacyTaxNotePaymentConfirmationDeletePaymentConfirmationOptionalParams, callback: msRest.ServiceCallback<string>): void;
  deletePaymentConfirmation(options?: Models.PharmacyTaxNotePaymentConfirmationDeletePaymentConfirmationOptionalParams | msRest.ServiceCallback<string>, callback?: msRest.ServiceCallback<string>): Promise<Models.PharmacyTaxNotePaymentConfirmationDeletePaymentConfirmationResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      deletePaymentConfirmationOperationSpec,
      callback) as Promise<Models.PharmacyTaxNotePaymentConfirmationDeletePaymentConfirmationResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.PharmacyTaxNotePaymentConfirmationDeletePaymentConfirmationsResponse>
   */
  deletePaymentConfirmations(options?: Models.PharmacyTaxNotePaymentConfirmationDeletePaymentConfirmationsOptionalParams): Promise<Models.PharmacyTaxNotePaymentConfirmationDeletePaymentConfirmationsResponse>;
  /**
   * @param callback The callback
   */
  deletePaymentConfirmations(callback: msRest.ServiceCallback<string>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  deletePaymentConfirmations(options: Models.PharmacyTaxNotePaymentConfirmationDeletePaymentConfirmationsOptionalParams, callback: msRest.ServiceCallback<string>): void;
  deletePaymentConfirmations(options?: Models.PharmacyTaxNotePaymentConfirmationDeletePaymentConfirmationsOptionalParams | msRest.ServiceCallback<string>, callback?: msRest.ServiceCallback<string>): Promise<Models.PharmacyTaxNotePaymentConfirmationDeletePaymentConfirmationsResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      deletePaymentConfirmationsOperationSpec,
      callback) as Promise<Models.PharmacyTaxNotePaymentConfirmationDeletePaymentConfirmationsResponse>;
  }
}

// Operation Specifications
const serializer = new msRest.Serializer(Mappers);
const getByPharmacyTaxNoteIdOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "pharmacyTaxNotePaymentConfirmation/PharmacyTaxNotePaymentConfirmation/{id}",
  urlParameters: [
    Parameters.id1
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "PharmacyTaxNotePaymentConfirmationDto"
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const assignPaymentConfirmationOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "pharmacyTaxNotePaymentConfirmation/PharmacyTaxNotePaymentConfirmation/assignPaymentConfirmation",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.AssignPaymentConfirmationCommand
  },
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "String"
        }
      }
    },
    default: {}
  },
  serializer
};

const changeStateOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "pharmacyTaxNotePaymentConfirmation/PharmacyTaxNotePaymentConfirmation/changeState",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.PharmacyTaxNotePaymentConfirmationChangeStateCommand
  },
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "String"
        }
      }
    },
    default: {}
  },
  serializer
};

const updatePaymentConfirmationOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "pharmacyTaxNotePaymentConfirmation/PharmacyTaxNotePaymentConfirmation/updatePaymentConfirmation",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.UpdatePharmacyTaxNotePaymentConfirmationCommand
  },
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "String"
        }
      }
    },
    default: {}
  },
  serializer
};

const deletePaymentConfirmationOperationSpec: msRest.OperationSpec = {
  httpMethod: "DELETE",
  path: "pharmacyTaxNotePaymentConfirmation/PharmacyTaxNotePaymentConfirmation/deletePaymentConfirmation",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.DeletePaymentConfirmationCommand
  },
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "String"
        }
      }
    },
    default: {}
  },
  serializer
};

const deletePaymentConfirmationsOperationSpec: msRest.OperationSpec = {
  httpMethod: "DELETE",
  path: "pharmacyTaxNotePaymentConfirmation/PharmacyTaxNotePaymentConfirmation/deletePaymentConfirmations",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.DeletePaymentConfirmationsCommand
  },
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "String"
        }
      }
    },
    default: {}
  },
  serializer
};
