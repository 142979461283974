/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from "@azure/ms-rest-js";
import * as Models from "../models";
import * as Mappers from "../models/pharmacyTaxNotePaymentMappers";
import * as Parameters from "../models/parameters";
import { PolandPharmacyRxAPIContext } from "../polandPharmacyRxAPIContext";

/** Class representing a PharmacyTaxNotePayment. */
export class PharmacyTaxNotePayment {
  private readonly client: PolandPharmacyRxAPIContext;

  /**
   * Create a PharmacyTaxNotePayment.
   * @param {PolandPharmacyRxAPIContext} client Reference to the service client.
   */
  constructor(client: PolandPharmacyRxAPIContext) {
    this.client = client;
  }

  /**
   * @param [options] The optional parameters
   * @returns
   * Promise<Models.PharmacyTaxNotePaymentGetPharmacyTaxNotePaymentWithNotesForPharmacyResponse>
   */
  getPharmacyTaxNotePaymentWithNotesForPharmacy(options?: Models.PharmacyTaxNotePaymentGetPharmacyTaxNotePaymentWithNotesForPharmacyOptionalParams): Promise<Models.PharmacyTaxNotePaymentGetPharmacyTaxNotePaymentWithNotesForPharmacyResponse>;
  /**
   * @param callback The callback
   */
  getPharmacyTaxNotePaymentWithNotesForPharmacy(callback: msRest.ServiceCallback<Models.PharmacyTaxNotePaymentWithNotesDto[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getPharmacyTaxNotePaymentWithNotesForPharmacy(options: Models.PharmacyTaxNotePaymentGetPharmacyTaxNotePaymentWithNotesForPharmacyOptionalParams, callback: msRest.ServiceCallback<Models.PharmacyTaxNotePaymentWithNotesDto[]>): void;
  getPharmacyTaxNotePaymentWithNotesForPharmacy(options?: Models.PharmacyTaxNotePaymentGetPharmacyTaxNotePaymentWithNotesForPharmacyOptionalParams | msRest.ServiceCallback<Models.PharmacyTaxNotePaymentWithNotesDto[]>, callback?: msRest.ServiceCallback<Models.PharmacyTaxNotePaymentWithNotesDto[]>): Promise<Models.PharmacyTaxNotePaymentGetPharmacyTaxNotePaymentWithNotesForPharmacyResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      getPharmacyTaxNotePaymentWithNotesForPharmacyOperationSpec,
      callback) as Promise<Models.PharmacyTaxNotePaymentGetPharmacyTaxNotePaymentWithNotesForPharmacyResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.PharmacyTaxNotePaymentGetPharmacyTaxNotePaymentWithNotesResponse>
   */
  getPharmacyTaxNotePaymentWithNotes(options?: Models.PharmacyTaxNotePaymentGetPharmacyTaxNotePaymentWithNotesOptionalParams): Promise<Models.PharmacyTaxNotePaymentGetPharmacyTaxNotePaymentWithNotesResponse>;
  /**
   * @param callback The callback
   */
  getPharmacyTaxNotePaymentWithNotes(callback: msRest.ServiceCallback<Models.PharmacyTaxNotePaymentWithNotesDto[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getPharmacyTaxNotePaymentWithNotes(options: Models.PharmacyTaxNotePaymentGetPharmacyTaxNotePaymentWithNotesOptionalParams, callback: msRest.ServiceCallback<Models.PharmacyTaxNotePaymentWithNotesDto[]>): void;
  getPharmacyTaxNotePaymentWithNotes(options?: Models.PharmacyTaxNotePaymentGetPharmacyTaxNotePaymentWithNotesOptionalParams | msRest.ServiceCallback<Models.PharmacyTaxNotePaymentWithNotesDto[]>, callback?: msRest.ServiceCallback<Models.PharmacyTaxNotePaymentWithNotesDto[]>): Promise<Models.PharmacyTaxNotePaymentGetPharmacyTaxNotePaymentWithNotesResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      getPharmacyTaxNotePaymentWithNotesOperationSpec,
      callback) as Promise<Models.PharmacyTaxNotePaymentGetPharmacyTaxNotePaymentWithNotesResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  changeState(options?: Models.PharmacyTaxNotePaymentChangeStateOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  changeState(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  changeState(options: Models.PharmacyTaxNotePaymentChangeStateOptionalParams, callback: msRest.ServiceCallback<void>): void;
  changeState(options?: Models.PharmacyTaxNotePaymentChangeStateOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      changeStateOperationSpec,
      callback);
  }
}

// Operation Specifications
const serializer = new msRest.Serializer(Mappers);
const getPharmacyTaxNotePaymentWithNotesForPharmacyOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "portal/pharmacyTaxNotePayment/PharmacyTaxNotePayment/paymentWithNotes",
  queryParameters: [
    Parameters.pharmacyTaxNoteGroupId1
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "PharmacyTaxNotePaymentWithNotesDto"
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const getPharmacyTaxNotePaymentWithNotesOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "pharmacyTaxNotePayment/PharmacyTaxNotePayment/paymentWithNotes",
  queryParameters: [
    Parameters.pharmacyTaxNoteGroupId1
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "PharmacyTaxNotePaymentWithNotesDto"
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const changeStateOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "pharmacyTaxNotePayment/PharmacyTaxNotePayment/changeState",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.ChangeStatePharmacyTaxNotePaymentCommand
  },
  responses: {
    200: {},
    default: {}
  },
  serializer
};
