import { IsolatedModal } from '@components/shared/modals/IsolatedModal';
import { ajaxByUser } from '@helper/api';
import { getEssityApiClient } from '@services/EssityApi';
import { Button, Space } from 'antd';
import { CenteredRow } from 'layout/CenteredRow';
import React, { useEffect, useState } from 'react';

interface IProps {
	onClose: () => void;
	visible: boolean;
}

export const PortalInvitationModal = (props: IProps) => {
	const [isBusy, setIsBusy] = useState<boolean>(false);
	const [
		pharmaciesToInviteCount,
		setPharmaciesToInviteCount,
	] = useState<number>();

	useEffect(() => {
		if (props.visible) {
			(async () => {
				const api = await getEssityApiClient();
				const result = await api.account.getInviteAllPharmaciesCount();
				setPharmaciesToInviteCount(result.body);
			})();
		}
	}, [props.visible]);

	return (
		<IsolatedModal
			visible={props.visible}
			title="Portal invitations"
			width="20vw"
			onCancel={() => props.onClose()}
		>
			<Space direction="vertical">
				<CenteredRow>
					<p style={{ textAlign: 'center' }}>
						You are about to send <b>{pharmaciesToInviteCount}</b>{' '}
						portal invitations. <br /> Are you sure you want to do
						this?
					</p>

					<Button
						type="primary"
						size="large"
						shape="round"
						disabled={isBusy || pharmaciesToInviteCount === 0}
						onClick={() => {
							setIsBusy(true);
							ajaxByUser('Successfully added new contact.', () =>
								getEssityApiClient().then((api) =>
									api.account
										.inviteAllPharmacies()
										.then(() => {})
										.finally(() => {
											setIsBusy(false);
											props.onClose();
										})
								)
							);
						}}
					>
						Send invitations
					</Button>
				</CenteredRow>
			</Space>
		</IsolatedModal>
	);
};
