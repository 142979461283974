import { DollarCircleFilled } from '@ant-design/icons';
import { IsolatedModal } from '@components/shared/modals/IsolatedModal';
import { EssityColumnProps } from '@components/shared/paginatedEssityTable/GridHelper';
import { ajaxCatch, ajaxCatchSilently } from '@helper/api';
import { getEssityApiClient } from '@services/EssityApi';
import { PharmacyTaxNoteGroupManualOperationDto } from '@services/src/models';
import { Badge, Button } from 'antd';
import EssityTable from 'layout/EssityTable';
import React, { useEffect, useState } from 'react';

import styles from '../styles/ManualOperationsModal.module.less';
import { AddManualOperationModal } from './AddManualOperationModal';
import { Operations } from './ManualOperationsTableActions';
import { ManualOperationsTableColumns } from './ManualOperationsTableColumns';

interface IProps {
	noteGroupId: string;
}

export const ManualOperationsModal = (props: IProps) => {
	const [visible, setVisible] = useState<boolean>();
	const [isBusy, setIsBusy] = useState<boolean>();
	const [operations, setOperations] = useState<
		PharmacyTaxNoteGroupManualOperationDto[]
	>();
	const [hasAnyOperation, setHasAnyOperation] = useState<boolean>(false);

	const handleCancel = () => {
		setVisible(false);
	};

	const refreshOperations = async () => {
		setIsBusy(true);
		ajaxCatchSilently(() =>
			getEssityApiClient().then((api) =>
				api.pharmacyTaxNoteGroupManualOperation
					.all({
						pharmacyTaxNoteGroupId: props.noteGroupId,
					})
					.then(
						(result: PharmacyTaxNoteGroupManualOperationDto[]) => {
							setOperations(result);
						}
					)
					.finally(() => setIsBusy(false))
			)
		);
	};

	const refreshHasAnyOperation = async () => {
		ajaxCatch(
			() =>
				getEssityApiClient().then((api) =>
					api.pharmacyTaxNoteGroupManualOperation
						.hasAnyManualOperation({
							pharmacyTaxNoteGroupId: props.noteGroupId,
						})
						.then((result) => {
							setHasAnyOperation(result.body);
						})
				),
			() => {},
			() => {},
			false
		);
	};

	useEffect(() => {
		if (props.noteGroupId) {
			if (visible) {
				refreshOperations();
			} else {
				refreshHasAnyOperation();
			}
		}
	}, [props.noteGroupId, visible]);

	const columns: EssityColumnProps<any>[] = [
		...ManualOperationsTableColumns,
		Operations(refreshOperations),
	];

	return (
		<>
			{hasAnyOperation ? (
				<Badge
					count={
						<DollarCircleFilled
							style={{ fontSize: 24, color: '#F40785' }}
						/>
					}
				>
					<Button
						size="large"
						shape="round"
						type="primary"
						onClick={() => {
							setVisible(true);
						}}
					>
						Manual operation
					</Button>
				</Badge>
			) : (
				<Button
					size="large"
					shape="round"
					type="primary"
					onClick={() => {
						setVisible(true);
					}}
				>
					Manual operation
				</Button>
			)}

			{visible && (
				<IsolatedModal
					title="Manual operations"
					visible={visible}
					width="40vw"
					centered
					cancelButtonProps={{ hidden: true }}
					okButtonProps={{ hidden: true }}
					maskClosable={false}
					onCancel={handleCancel}
					closable={true}
					destroyOnClose
				>
					<>
						{props.noteGroupId && (
							<AddManualOperationModal
								refresh={refreshOperations}
								pharmacyTaxNoteGroupId={props.noteGroupId}
							/>
						)}

						<EssityTable
							className={styles.table}
							columns={columns}
							loading={isBusy}
							dataSource={operations}
							size="small"
							pagination={false}
						/>
					</>
				</IsolatedModal>
			)}
		</>
	);
};
