import { filterType } from '@components/shared/paginatedEssityTable/GridHelper';
import PaginatedEssityTable from '@components/shared/paginatedEssityTable/PaginatedEssityTable';
import { SieveModel } from '@components/shared/paginatedEssityTable/SieveModels';
import { useRootData } from '@hooks/hook';
import { DictionariesConstants } from '@services/DictionariesConstants';
import { getEssityApiClient } from '@services/EssityApi';
import {
	VoivodeshipPaymentGroupAllOptionalParams,
	VoivodeshipPaymentGroupDto,
} from '@services/src/models';
import React from 'react';
import { IDictionaryStore } from 'stores/DictionaryStore';
import { ITableColumnStore } from 'stores/TableColumnStore';

import styles from './styles/SendingPayments.module.less';

const gridName = 'sendingPayments';

export const SendingPaymentsTable = () => {
	const tableColumnStore: ITableColumnStore = useRootData(
		(store) => store.tableColumnStore
	);

	const dictionaryStore: IDictionaryStore = useRootData(
		(store) => store.dictionaryStore
	);

	const columns = [
		{
			sorter: true,
			dataIndex: 'voivodeshipId',
			key: 'voivodeshipId',
			title: 'Voivodeship',
			filter: 'dictionary' as filterType,
			filterDictionary: DictionariesConstants.Voivodeships,
			render: (value: number) => {
				return dictionaryStore.getValueById(
					DictionariesConstants.Voivodeships,
					value
				);
			},
			width: 150,
		},
		...tableColumnStore.getTable(gridName),
	];

	return (
		<PaginatedEssityTable<VoivodeshipPaymentGroupDto>
			columns={columns}
			gridName={gridName}
			hasRowSelection={true}
			hidePersonalizationSettings={true}
			getRowKey={(r: VoivodeshipPaymentGroupDto) => r.id!}
			className={styles.table}
			getPagedResult={async (sieve: SieveModel, abortSignal) => {
				const parameters: VoivodeshipPaymentGroupAllOptionalParams = {
					page: sieve.page,
					pageSize: sieve.pageSize,
					sorts: sieve.sorts,
					abortSignal: abortSignal,
					filters: sieve.filters,
				};

				const api = await getEssityApiClient();
				return await api.voivodeshipPaymentGroup.all(parameters);
			}}
		/>
	);
};
