import { MonitorOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useRootData } from 'hooks/hook';
import EssityTooltip from 'layout/EssityTooltip';
import React from 'react';

import { IGridStore } from '../../../../stores/GridStore';

interface IProps {
	handleSearch: () => void;
	gridName: string;
}

const GridClearFilters: React.FunctionComponent<IProps> = (props) => {
	const gridStore: IGridStore = useRootData((store) => store.gridStore);

	function clear() {
		gridStore.resetGrid(props.gridName);
		gridStore.clearSearch.set(Date.now());
		props.handleSearch();
	}

	return (
		<EssityTooltip title="Clear filters/sorting" placement="topRight">
			<Button
				aria-label="Clear filters/sorting"
				shape="circle"
				size="small"
				type="ghost"
				icon={<MonitorOutlined />}
				onClick={() => clear()}
			/>
		</EssityTooltip>
	);
};

export default GridClearFilters;
