import React from 'react';

import { NHFSettlementsMenu } from './NHFSettlementsMenu';
import { NHFSettlementsTable } from './NHFSettlementsTable';
import styles from './styles/NHFSettlements.module.less';

export const NHFSettlements = () => {
	return (
		<div>
			<NHFSettlementsMenu />

			<div className={styles.tableContainer}>
				<NHFSettlementsTable />
			</div>
		</div>
	);
};
