import React from 'react';

import { OrdersMenu } from './OrdersMenu';
import { OrdersTable } from './OrdersTable';
import styles from './styles/Orders.module.less';

interface IProps {
	groupId: string;
}

export const Orders = (props: IProps) => {
	return (
		<div className={styles.orders}>
			<OrdersMenu groupId={props.groupId} />
			<div className={styles.tableContainer}>
				<OrdersTable groupId={props.groupId} />
			</div>
		</div>
	);
};
