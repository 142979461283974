import { VoivodeshipContractDto } from '@services/src/models';
import { Col, Row } from 'antd';
import { Field } from 'formik';
import { FDatePicker, FFieldLabel, FInput } from 'forms/FormikFormItems';
import React from 'react';
import styles from './styles/Departments.module.less';

import { ContractsModal } from './contracts/ContractsModal';

interface IProps {
	readOnly: boolean;
	contracts: VoivodeshipContractDto[] | undefined;
}

export const EditableData = (props: IProps) => {
	return (
		<Col span={15}>
			<div className={styles.description}>
				<Row>
					<Col span={12}>
						<div className={styles.fieldbox}>
							<FFieldLabel label="Service provider code" />
							<Field
								component={FInput}
								placeholder="Service provider code"
								name="serviceProviderCode"
								readOnly={props.readOnly}
							/>
						</div>
					</Col>
				</Row>
				<Row>
					<Col span={12}>
						<div className={styles.fieldbox}>
							<FFieldLabel label="User" />
							<Field
								component={FInput}
								placeholder="User"
								name="user"
								readOnly={props.readOnly}
							/>
						</div>
					</Col>
				</Row>
				<Row>
					<Col span={12}>
						<div className={styles.fieldbox}>
							<FFieldLabel label="Password" />
							<Field
								component={FInput}
								placeholder="Password"
								name="password"
								readOnly={props.readOnly}
							/>
						</div>
					</Col>
				</Row>
				<Row>
					<Col span={8}>
						<div className={styles.fieldbox}>
							<FFieldLabel label="Main contract" />
							<Field
								component={FInput}
								placeholder="Main contract"
								name="currentMainContractNumber"
								readOnly={true}
							/>
						</div>
					</Col>
					<Col span={7} offset={1}>
						<div className={`${styles.fieldbox} ${styles.short}`}>
							<FFieldLabel label="Start date" />
							<Field
								label="Start date"
								component={FDatePicker}
								name="currentMainContractStartDate"
								mode="date"
								disabled={props.readOnly}
							/>
						</div>
					</Col>
					<Col span={7} offset={1}>
						<div className={`${styles.fieldbox} ${styles.short}`}>
							<FFieldLabel label="End date" />
							<Field
								label="End date"
								component={FDatePicker}
								name="currentMainContractEndDate"
								mode="date"
								disabled={props.readOnly}
							/>
						</div>
					</Col>
				</Row>
				<Row>
					<Col span={8}>
						<div className={styles.fieldbox}>
							<FFieldLabel label="Current contract" />
							<Field
								component={FInput}
								placeholder="Current contract"
								name="currentContractNumber"
								readOnly={true}
							/>
						</div>
					</Col>
					<Col span={7} offset={1}>
						<div className={`${styles.fieldbox} ${styles.short}`}>
							<FFieldLabel label="Start date" />
							<Field
								label="Start date"
								component={FDatePicker}
								name="currentContractStartDate"
								mode="date"
								disabled={true}
							/>
						</div>
					</Col>
					<Col span={7} offset={1}>
						<div className={`${styles.fieldbox} ${styles.short}`}>
							<FFieldLabel label="End date" />
							<Field
								label="End date"
								component={FDatePicker}
								name="currentContractEndDate"
								mode="date"
								disabled={true}
							/>
						</div>
					</Col>
				</Row>
				{props.contracts && (
					<ContractsModal contracts={props.contracts} />
				)}
			</div>
		</Col>
	);
};
