import { useRootData } from '@hooks/hook';
import { SignoutReason } from '@services/mappers/SignoutReason';
import * as SignoutReasonMapper from '@services/mappers/SignoutReason';
import { Button, Space } from 'antd';
import { observer } from 'mobx-react-lite';
import { User } from 'oidc-client';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { IAccountDetailsStore } from 'stores/AccountDetailsStore';

import { GlobalUserManager } from './userManager';

const Login = () => {
	const history = useHistory();

	const accountDetailsStore: IAccountDetailsStore = useRootData(
		(store) => store.accountDetailsStore
	);

	const [signoutReason, setSignoutReason] = useState<
		SignoutReason | undefined
	>();

	useEffect(() => {
		GlobalUserManager.UserManager()
			.getUser()
			.then((user: User | null) => {
				if (user) {
					accountDetailsStore.oidcUser.set(user);
					history.push('/orderGroups');
				}
			});

		setSignoutReason(
			localStorage.getItem('signOutReason') as SignoutReason
		);
		localStorage.removeItem('signOutReason');
	}, []);

	return (
		<div className="login">
			<Space direction="vertical">
				<img
					width={250}
					height={104}
					src={process.env.PUBLIC_URL + '/logo.png'}
				/>
				<Button
					type="primary"
					shape="round"
					style={{ width: '250px' }}
					size="large"
					onClick={() => {
						GlobalUserManager.UserManager().signinRedirect();
						localStorage.removeItem('signOutReason');
					}}
				>
					Log in
				</Button>
				{signoutReason && (
					<p style={{ textAlign: 'center', fontWeight: 'bold' }}>
						{SignoutReasonMapper.map(signoutReason)}
					</p>
				)}
			</Space>
		</div>
	);
};

export default observer(Login);
