import { Result } from 'antd';
import React from 'react';

interface IProps {
	title?: string;
	message?: string;
}

export const ExceptionView = (props: IProps) => {
	const title = props.title || 'Error';
	const message =
		props.message || 'Error, please contact with administrator.';

	return <Result status="500" title={title} subTitle={message} />;
};
