import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { EssityColumnProps } from '@components/shared/paginatedEssityTable/GridHelper';
import { ajaxByUser } from '@helper/api';
import { getEssityApiClient } from '@services/EssityApi';
import {
	InternalTaxNoteType,
	PaymentState,
	PharmacyTaxNoteDto,
	TaxNoteSource,
} from '@services/src/models';
import { Button, Popconfirm, Space, Tooltip } from 'antd';
import React from 'react';
import { useHistory } from 'react-router';
import { AssignBalancedPaymentConfirmationModal } from './paymentConfirmation/AssignBalancedPaymentConfirmationModal';

import { AssignPaymentConfirmationModal } from './paymentConfirmation/AssignPaymentConfirmationModal';
import { IsSummaryRecord } from './PharmacyNotesHelpers';

export const Operations = (
	refresh: () => void,
	groupId: string
): EssityColumnProps<PharmacyTaxNoteDto> => {
	const history = useHistory();

	return {
		title: '',
		key: 'operation',
		fixed: 'right',
		render: (item: PharmacyTaxNoteDto) => (
			<Space direction="horizontal">
				{OverpayAction(item, groupId, history)}
				{ConfirmNoteAction(item, refresh)}
				{ConfirmedNoteAction(item, refresh)}
				{RecountNoteAction(item, refresh)}
				{EditNoteAction(item, groupId, history)}
				{DeleteNoteAction(item, refresh)}
			</Space>
		),
	};
};

const OverpayAction = (
	item: PharmacyTaxNoteDto,
	groupId: string,
	history: any
) => {
	return (
		!item.isOverpayDisabled &&
		canOverpayNote(item) &&
		!IsSummaryRecord(item) && (
			<Tooltip title="Create Overpay">
				<Button
					type="primary"
					shape="round"
					size="small"
					onClick={() => {
						history.push(
							`/overpayNote/create/${groupId}/${item.id}`
						);
					}}
				>
					Overpay
				</Button>
			</Tooltip>
		)
	);
};

const ConfirmNoteAction = (item: PharmacyTaxNoteDto, refresh: () => void) => {
	return (
		!IsSummaryRecord(item) &&
		canConfirmNote(item) && (
			<AssignPaymentConfirmationModal refresh={refresh} taxNote={item} />
		)
	);
};

const ConfirmedNoteAction = (item: PharmacyTaxNoteDto, refresh: () => void) => {
	return (
		!IsSummaryRecord(item) &&
		canDeleteConfirmation(item) && (
			<AssignBalancedPaymentConfirmationModal refresh={refresh} taxNoteGroup={item} />
		)
	);
};

const RecountNoteAction = (item: PharmacyTaxNoteDto, refresh: () => void) => {
	return (
		!IsSummaryRecord(item) &&
		canRecountNote(item) && (
			<Tooltip title="Update note">
				<Button
					type="primary"
					shape="round"
					size="small"
					disabled={item.paymentState !== PaymentState.Created}
					onClick={async () => {
						ajaxByUser('Successfully updated note.', () =>
							getEssityApiClient().then((api) =>
								item.internalTaxNoteType ===
									InternalTaxNoteType.Overpay
									? api.pharmacyTaxNote
										.recountPharmacyTaxNoteOverpay({
											body: {
												pharmacyTaxNoteId: item.id,
											},
										})
										.then(() => refresh())
									: api.pharmacyTaxNote
										.recountPharmacyTaxNoteCorrection({
											body: {
												pharmacyTaxNoteId: item.id,
											},
										})
										.then(() => refresh())
							)
						);
					}}
				>
					Update
				</Button>
			</Tooltip>
		)
	);
};

const EditNoteAction = (
	item: PharmacyTaxNoteDto,
	groupId: string,
	history: any
) => {
	return (
		!IsSummaryRecord(item) && (
			<Tooltip title="Edit">
				<Button
					type="primary"
					shape="circle"
					size="small"
					onClick={() => {
						switch (item.internalTaxNoteType) {
							case InternalTaxNoteType.Correction:
							case InternalTaxNoteType.Debit:
							case InternalTaxNoteType.PayLoss:
							case InternalTaxNoteType.Return:
								history.push(
									`/correctionNote/edit/${groupId}/${item.id}`
								);
								break;
							case InternalTaxNoteType.Loss:
								history.push(
									`/lossNote/edit/${groupId}/${item.id}`
								);
								break;
							case InternalTaxNoteType.Overpay:
								history.push(
									`/overpayNote/edit/${groupId}/${item.id}/${item.mainPharmacyTaxNoteId}`
								);
								break;
							default:
								history.push(`/pharmacyNote/edit/${item.id}`);
								break;
						}
					}}
					icon={<EditOutlined />}
				/>
			</Tooltip>
		)
	);
};

const DeleteNoteAction = (item: PharmacyTaxNoteDto, refresh: () => void) => {
	return (
		!IsSummaryRecord(item) && (
			<Popconfirm
				title="Are you sure to delete note?"
				okText="Yes"
				cancelText="No"
				placement="bottom"
				disabled={!canDeleteTaxNote(item)}
				onConfirm={() => {
					ajaxByUser('Successfully deleted note.', () =>
						getEssityApiClient().then((api) =>
							api.pharmacyTaxNote
								.deleteMethod({ body: { id: item.id } })
								.then(() => refresh())
						)
					);
				}}
			>
				<Tooltip title="Delete">
					<Button
						type="primary"
						shape="circle"
						size="small"
						disabled={!canDeleteTaxNote(item)}
						icon={<DeleteOutlined />}
					/>
				</Tooltip>
			</Popconfirm>
		)
	);
};

const canDeleteTaxNote = (item: PharmacyTaxNoteDto): boolean => {
	return (
		item.taxNoteSource === TaxNoteSource.Phoenix &&
		item.paymentState === PaymentState.Created &&
		!item.hasCorrections
	);
};

const canConfirmNote = (item: PharmacyTaxNoteDto): boolean => {
	return (
		item.internalTaxNoteType === InternalTaxNoteType.Return &&
		item.paymentState === PaymentState.SentToSap &&
		item.confirmedBy === ''
	);
};

const canDeleteConfirmation = (item: PharmacyTaxNoteDto): boolean => {
	return (
		item.internalTaxNoteType === InternalTaxNoteType.Return &&
		item.confirmedBy !== ''
	);
};

const canRecountNote = (item: PharmacyTaxNoteDto): boolean => {
	return (
		item.internalTaxNoteType === InternalTaxNoteType.Correction ||
		item.internalTaxNoteType === InternalTaxNoteType.Return ||
		item.internalTaxNoteType === InternalTaxNoteType.Overpay
	);
};

const canOverpayNote = (item: PharmacyTaxNoteDto): boolean => {
	return (
		item.internalTaxNoteType === InternalTaxNoteType.Return &&
		item.isOverpay === true
	);
};
