import { EssityColumnProps } from '@components/shared/paginatedEssityTable/GridHelper';
import { AccountDto, AccountRoleDto } from '@services/src/models';
import * as RolesMapper from 'authorization/Roles';
import { AccountRoles } from 'authorization/Roles';

export const AccountsTableColumns: EssityColumnProps<AccountDto>[] = [
	{
		sorter: false,
		dataIndex: 'displayName',
		key: 'displayName',
		title: 'Display Name',
	},
	{
		sorter: false,
		dataIndex: 'mail',
		key: 'mail',
		title: 'E-mail',
	},
	{
		sorter: false,
		dataIndex: 'roles',
		key: 'roles',
		title: 'Role',
		render: (values: AccountRoleDto[]) => {
			return values
				.map((x) => RolesMapper.map(x.name as AccountRoles))
				.join(',');
		},
	},
];
