import QuickFilters from '@components/quickFilters/QuickFilters';
import { Divider, Space } from 'antd';
import React from 'react';

import ExportPaymentNoteModal from './ExportPaymentNoteModal';
import { ImportNHFTemplateModal } from './ImportNHFTemplateModal';
import { ImportZPOZRFileModal } from './ImportZPOZRFileModal';
import SendToSAPModal from './SendToSAPModal';
import styles from './styles/NHFSettlements.module.less';

export const NHFSettlementsMenu = () => {
	return (
		<>
			<Divider />
			<Space direction="horizontal" className={styles.menu}>
				<ImportNHFTemplateModal />
				<ImportZPOZRFileModal />
				<ExportPaymentNoteModal />
				<SendToSAPModal />
				<QuickFilters hide={false} />
			</Space>
			<Divider />
		</>
	);
};
