/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from "@azure/ms-rest-js";
import * as Models from "../models";
import * as Mappers from "../models/accountMappers";
import * as Parameters from "../models/parameters";
import { PolandPharmacyRxAPIContext } from "../polandPharmacyRxAPIContext";

/** Class representing a Account. */
export class Account {
  private readonly client: PolandPharmacyRxAPIContext;

  /**
   * Create a Account.
   * @param {PolandPharmacyRxAPIContext} client Reference to the service client.
   */
  constructor(client: PolandPharmacyRxAPIContext) {
    this.client = client;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.AccountRegisterUserResponse>
   */
  registerUser(options?: Models.AccountRegisterUserOptionalParams): Promise<Models.AccountRegisterUserResponse>;
  /**
   * @param callback The callback
   */
  registerUser(callback: msRest.ServiceCallback<string>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  registerUser(options: Models.AccountRegisterUserOptionalParams, callback: msRest.ServiceCallback<string>): void;
  registerUser(options?: Models.AccountRegisterUserOptionalParams | msRest.ServiceCallback<string>, callback?: msRest.ServiceCallback<string>): Promise<Models.AccountRegisterUserResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      registerUserOperationSpec,
      callback) as Promise<Models.AccountRegisterUserResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.AccountRegisterPharmacyResponse>
   */
  registerPharmacy(options?: Models.AccountRegisterPharmacyOptionalParams): Promise<Models.AccountRegisterPharmacyResponse>;
  /**
   * @param callback The callback
   */
  registerPharmacy(callback: msRest.ServiceCallback<string>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  registerPharmacy(options: Models.AccountRegisterPharmacyOptionalParams, callback: msRest.ServiceCallback<string>): void;
  registerPharmacy(options?: Models.AccountRegisterPharmacyOptionalParams | msRest.ServiceCallback<string>, callback?: msRest.ServiceCallback<string>): Promise<Models.AccountRegisterPharmacyResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      registerPharmacyOperationSpec,
      callback) as Promise<Models.AccountRegisterPharmacyResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.AccountInvitePharmacyResponse>
   */
  invitePharmacy(options?: Models.AccountInvitePharmacyOptionalParams): Promise<Models.AccountInvitePharmacyResponse>;
  /**
   * @param callback The callback
   */
  invitePharmacy(callback: msRest.ServiceCallback<string>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  invitePharmacy(options: Models.AccountInvitePharmacyOptionalParams, callback: msRest.ServiceCallback<string>): void;
  invitePharmacy(options?: Models.AccountInvitePharmacyOptionalParams | msRest.ServiceCallback<string>, callback?: msRest.ServiceCallback<string>): Promise<Models.AccountInvitePharmacyResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      invitePharmacyOperationSpec,
      callback) as Promise<Models.AccountInvitePharmacyResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.AccountInviteAllPharmaciesResponse>
   */
  inviteAllPharmacies(options?: msRest.RequestOptionsBase): Promise<Models.AccountInviteAllPharmaciesResponse>;
  /**
   * @param callback The callback
   */
  inviteAllPharmacies(callback: msRest.ServiceCallback<string>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  inviteAllPharmacies(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<string>): void;
  inviteAllPharmacies(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<string>, callback?: msRest.ServiceCallback<string>): Promise<Models.AccountInviteAllPharmaciesResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      inviteAllPharmaciesOperationSpec,
      callback) as Promise<Models.AccountInviteAllPharmaciesResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.AccountGetInviteAllPharmaciesCountResponse>
   */
  getInviteAllPharmaciesCount(options?: msRest.RequestOptionsBase): Promise<Models.AccountGetInviteAllPharmaciesCountResponse>;
  /**
   * @param callback The callback
   */
  getInviteAllPharmaciesCount(callback: msRest.ServiceCallback<number>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getInviteAllPharmaciesCount(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<number>): void;
  getInviteAllPharmaciesCount(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<number>, callback?: msRest.ServiceCallback<number>): Promise<Models.AccountGetInviteAllPharmaciesCountResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      getInviteAllPharmaciesCountOperationSpec,
      callback) as Promise<Models.AccountGetInviteAllPharmaciesCountResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  saveSettings(options?: Models.AccountSaveSettingsOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  saveSettings(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  saveSettings(options: Models.AccountSaveSettingsOptionalParams, callback: msRest.ServiceCallback<void>): void;
  saveSettings(options?: Models.AccountSaveSettingsOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      saveSettingsOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.AccountMeResponse>
   */
  me(options?: msRest.RequestOptionsBase): Promise<Models.AccountMeResponse>;
  /**
   * @param callback The callback
   */
  me(callback: msRest.ServiceCallback<Models.AccountDto>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  me(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.AccountDto>): void;
  me(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.AccountDto>, callback?: msRest.ServiceCallback<Models.AccountDto>): Promise<Models.AccountMeResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      meOperationSpec,
      callback) as Promise<Models.AccountMeResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.AccountGetUsersResponse>
   */
  getUsers(options?: Models.AccountGetUsersOptionalParams): Promise<Models.AccountGetUsersResponse>;
  /**
   * @param callback The callback
   */
  getUsers(callback: msRest.ServiceCallback<Models.AccountDtoArrayAzureListDto>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getUsers(options: Models.AccountGetUsersOptionalParams, callback: msRest.ServiceCallback<Models.AccountDtoArrayAzureListDto>): void;
  getUsers(options?: Models.AccountGetUsersOptionalParams | msRest.ServiceCallback<Models.AccountDtoArrayAzureListDto>, callback?: msRest.ServiceCallback<Models.AccountDtoArrayAzureListDto>): Promise<Models.AccountGetUsersResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      getUsersOperationSpec,
      callback) as Promise<Models.AccountGetUsersResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  deleteMethod(options?: Models.AccountDeleteMethodOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  deleteMethod(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteMethod(options: Models.AccountDeleteMethodOptionalParams, callback: msRest.ServiceCallback<void>): void;
  deleteMethod(options?: Models.AccountDeleteMethodOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      deleteMethodOperationSpec,
      callback);
  }
}

// Operation Specifications
const serializer = new msRest.Serializer(Mappers);
const registerUserOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "account/Account/registerUser",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.RegisterAccountCommand
  },
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Uuid"
        }
      }
    },
    default: {}
  },
  serializer
};

const registerPharmacyOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "account/Account/registerPharmacy",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.RegisterPharmacyCommand
  },
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Uuid"
        }
      }
    },
    default: {}
  },
  serializer
};

const invitePharmacyOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "account/Account/invite",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.InvitePharmacyCommand
  },
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Uuid"
        }
      }
    },
    default: {}
  },
  serializer
};

const inviteAllPharmaciesOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "account/Account/inviteAll",
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Uuid"
        }
      }
    },
    default: {}
  },
  serializer
};

const getInviteAllPharmaciesCountOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "account/Account/inviteAll/count",
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Number"
        }
      }
    },
    default: {}
  },
  serializer
};

const saveSettingsOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "account/Account/saveSettings",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.SaveAccountSettingsCommand
  },
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const meOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "account/Account/me",
  responses: {
    200: {
      bodyMapper: Mappers.AccountDto
    },
    default: {}
  },
  serializer
};

const getUsersOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "account/Account",
  queryParameters: [
    Parameters.searchText0,
    Parameters.skipToken
  ],
  responses: {
    200: {
      bodyMapper: Mappers.AccountDtoArrayAzureListDto
    },
    default: {}
  },
  serializer
};

const deleteMethodOperationSpec: msRest.OperationSpec = {
  httpMethod: "DELETE",
  path: "account/Account",
  queryParameters: [
    Parameters.userId
  ],
  responses: {
    200: {},
    default: {}
  },
  serializer
};
