import styles from './styles/Pharmacies.module.less';

import React from 'react';

import { PharmaciesMenu } from './PharmaciesMenu';
import { PharmaciesTable } from './PharmaciesTable';

export const Pharmacies = () => {
	return (
		<div className={styles.pharmacies}>
			<PharmaciesMenu />

			<PharmaciesTable />
		</div>
	);
};
