/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from "@azure/ms-rest-js";
import * as Models from "../models";
import * as Mappers from "../models/pharmacyTaxNoteGroupReturnDocumentMappers";
import * as Parameters from "../models/parameters";
import { PolandPharmacyRxAPIContext } from "../polandPharmacyRxAPIContext";

/** Class representing a PharmacyTaxNoteGroupReturnDocument. */
export class PharmacyTaxNoteGroupReturnDocument {
  private readonly client: PolandPharmacyRxAPIContext;

  /**
   * Create a PharmacyTaxNoteGroupReturnDocument.
   * @param {PolandPharmacyRxAPIContext} client Reference to the service client.
   */
  constructor(client: PolandPharmacyRxAPIContext) {
    this.client = client;
  }

  /**
   * @param pharmacyTaxNoteGroupId
   * @param [options] The optional parameters
   * @returns Promise<Models.PharmacyTaxNoteGroupReturnDocumentGetByPharmacyTaxNoteGroupIdResponse>
   */
  getByPharmacyTaxNoteGroupId(pharmacyTaxNoteGroupId: string, options?: msRest.RequestOptionsBase): Promise<Models.PharmacyTaxNoteGroupReturnDocumentGetByPharmacyTaxNoteGroupIdResponse>;
  /**
   * @param pharmacyTaxNoteGroupId
   * @param callback The callback
   */
  getByPharmacyTaxNoteGroupId(pharmacyTaxNoteGroupId: string, callback: msRest.ServiceCallback<Models.PharmacyTaxNoteGroupReturnDocumentDto[]>): void;
  /**
   * @param pharmacyTaxNoteGroupId
   * @param options The optional parameters
   * @param callback The callback
   */
  getByPharmacyTaxNoteGroupId(pharmacyTaxNoteGroupId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.PharmacyTaxNoteGroupReturnDocumentDto[]>): void;
  getByPharmacyTaxNoteGroupId(pharmacyTaxNoteGroupId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.PharmacyTaxNoteGroupReturnDocumentDto[]>, callback?: msRest.ServiceCallback<Models.PharmacyTaxNoteGroupReturnDocumentDto[]>): Promise<Models.PharmacyTaxNoteGroupReturnDocumentGetByPharmacyTaxNoteGroupIdResponse> {
    return this.client.sendOperationRequest(
      {
        pharmacyTaxNoteGroupId,
        options
      },
      getByPharmacyTaxNoteGroupIdOperationSpec,
      callback) as Promise<Models.PharmacyTaxNoteGroupReturnDocumentGetByPharmacyTaxNoteGroupIdResponse>;
  }

  /**
   * @param pharmacyTaxNoteGroupId
   * @param [options] The optional parameters
   * @returns Promise<Models.PharmacyTaxNoteGroupReturnDocumentHasAnyReturnDocumentsResponse>
   */
  hasAnyReturnDocuments(pharmacyTaxNoteGroupId: string, options?: msRest.RequestOptionsBase): Promise<Models.PharmacyTaxNoteGroupReturnDocumentHasAnyReturnDocumentsResponse>;
  /**
   * @param pharmacyTaxNoteGroupId
   * @param callback The callback
   */
  hasAnyReturnDocuments(pharmacyTaxNoteGroupId: string, callback: msRest.ServiceCallback<boolean>): void;
  /**
   * @param pharmacyTaxNoteGroupId
   * @param options The optional parameters
   * @param callback The callback
   */
  hasAnyReturnDocuments(pharmacyTaxNoteGroupId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<boolean>): void;
  hasAnyReturnDocuments(pharmacyTaxNoteGroupId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<boolean>, callback?: msRest.ServiceCallback<boolean>): Promise<Models.PharmacyTaxNoteGroupReturnDocumentHasAnyReturnDocumentsResponse> {
    return this.client.sendOperationRequest(
      {
        pharmacyTaxNoteGroupId,
        options
      },
      hasAnyReturnDocumentsOperationSpec,
      callback) as Promise<Models.PharmacyTaxNoteGroupReturnDocumentHasAnyReturnDocumentsResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.PharmacyTaxNoteGroupReturnDocumentSaveAndGenerateReturnDocumentResponse>
   */
  saveAndGenerateReturnDocument(options?: Models.PharmacyTaxNoteGroupReturnDocumentSaveAndGenerateReturnDocumentOptionalParams): Promise<Models.PharmacyTaxNoteGroupReturnDocumentSaveAndGenerateReturnDocumentResponse>;
  /**
   * @param callback The callback
   */
  saveAndGenerateReturnDocument(callback: msRest.ServiceCallback<any>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  saveAndGenerateReturnDocument(options: Models.PharmacyTaxNoteGroupReturnDocumentSaveAndGenerateReturnDocumentOptionalParams, callback: msRest.ServiceCallback<any>): void;
  saveAndGenerateReturnDocument(options?: Models.PharmacyTaxNoteGroupReturnDocumentSaveAndGenerateReturnDocumentOptionalParams | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.PharmacyTaxNoteGroupReturnDocumentSaveAndGenerateReturnDocumentResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      saveAndGenerateReturnDocumentOperationSpec,
      callback) as Promise<Models.PharmacyTaxNoteGroupReturnDocumentSaveAndGenerateReturnDocumentResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  deleteReturnDocument(options?: Models.PharmacyTaxNoteGroupReturnDocumentDeleteReturnDocumentOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  deleteReturnDocument(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteReturnDocument(options: Models.PharmacyTaxNoteGroupReturnDocumentDeleteReturnDocumentOptionalParams, callback: msRest.ServiceCallback<void>): void;
  deleteReturnDocument(options?: Models.PharmacyTaxNoteGroupReturnDocumentDeleteReturnDocumentOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      deleteReturnDocumentOperationSpec,
      callback);
  }
}

// Operation Specifications
const serializer = new msRest.Serializer(Mappers);
const getByPharmacyTaxNoteGroupIdOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "pharmacyTaxNoteGroupReturnDocument/PharmacyTaxNoteGroupReturnDocument/{pharmacyTaxNoteGroupId}",
  urlParameters: [
    Parameters.pharmacyTaxNoteGroupId2
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "PharmacyTaxNoteGroupReturnDocumentDto"
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const hasAnyReturnDocumentsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "pharmacyTaxNoteGroupReturnDocument/PharmacyTaxNoteGroupReturnDocument/hasAny/{pharmacyTaxNoteGroupId}",
  urlParameters: [
    Parameters.pharmacyTaxNoteGroupId2
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Boolean"
        }
      }
    },
    default: {}
  },
  serializer
};

const saveAndGenerateReturnDocumentOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "pharmacyTaxNoteGroupReturnDocument/PharmacyTaxNoteGroupReturnDocument/saveAndGenerateReturnDocument",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.SaveAndGenerateReturnDocumentCommand
  },
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Stream"
        }
      }
    },
    400: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "String"
        }
      }
    },
    default: {}
  },
  serializer
};

const deleteReturnDocumentOperationSpec: msRest.OperationSpec = {
  httpMethod: "DELETE",
  path: "pharmacyTaxNoteGroupReturnDocument/PharmacyTaxNoteGroupReturnDocument",
  queryParameters: [
    Parameters.returnDocumentId
  ],
  responses: {
    200: {},
    default: {}
  },
  serializer
};
