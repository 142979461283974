import { CloudDownloadOutlined } from '@ant-design/icons';
import { DownloadByCreateElement } from '@components/shared/file/FileDownloadFunctions';
import { EssityColumnProps } from '@components/shared/paginatedEssityTable/GridHelper';
import { ajaxByUser } from '@helper/api';
import { getEssityApiClient } from '@services/EssityApi';
import { TemplateNoteDto } from '@services/src/models';
import { Button, Tooltip } from 'antd';
import React from 'react';

export const DownloadAction = (): EssityColumnProps<TemplateNoteDto> => {
	return {
		title: '',
		key: 'operation',
		fixed: 'right',
		width: 50,
		render: (item: TemplateNoteDto) => {
			return (
				item.noteCreated && (
					<Tooltip title="Download note">
						<Button
							type="primary"
							shape="circle"
							size="small"
							icon={<CloudDownloadOutlined />}
							onClick={() => {
								ajaxByUser('Successfully downloaded file', () =>
									getEssityApiClient().then((api) =>
										api.document
											.getZippedById({
												id: item.noteFileId!,
												zipExtension: 'ENX',
											})
											.then((result) => {
												DownloadByCreateElement({
													response: result,
												});
											})
									)
								);
							}}
						/>
					</Tooltip>
				)
			);
		},
	};
};
