/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from "@azure/ms-rest-js";
import * as Models from "../models";
import * as Mappers from "../models/dictionaryValueMappers";
import * as Parameters from "../models/parameters";
import { PolandPharmacyRxAPIContext } from "../polandPharmacyRxAPIContext";

/** Class representing a DictionaryValue. */
export class DictionaryValue {
  private readonly client: PolandPharmacyRxAPIContext;

  /**
   * Create a DictionaryValue.
   * @param {PolandPharmacyRxAPIContext} client Reference to the service client.
   */
  constructor(client: PolandPharmacyRxAPIContext) {
    this.client = client;
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<Models.DictionaryValueGetByIdResponse>
   */
  getById(id: number, options?: msRest.RequestOptionsBase): Promise<Models.DictionaryValueGetByIdResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  getById(id: number, callback: msRest.ServiceCallback<Models.DictionaryValueDto>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  getById(id: number, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.DictionaryValueDto>): void;
  getById(id: number, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.DictionaryValueDto>, callback?: msRest.ServiceCallback<Models.DictionaryValueDto>): Promise<Models.DictionaryValueGetByIdResponse> {
    return this.client.sendOperationRequest(
      {
        id,
        options
      },
      getByIdOperationSpec,
      callback) as Promise<Models.DictionaryValueGetByIdResponse>;
  }
}

// Operation Specifications
const serializer = new msRest.Serializer(Mappers);
const getByIdOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "dictionaries/DictionaryValue/{id}",
  urlParameters: [
    Parameters.id0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.DictionaryValueDto
    },
    default: {}
  },
  serializer
};
