/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from "@azure/ms-rest-js";
import * as Models from "../models";
import * as Mappers from "../models/templateNoteMappers";
import * as Parameters from "../models/parameters";
import { PolandPharmacyRxAPIContext } from "../polandPharmacyRxAPIContext";

/** Class representing a TemplateNote. */
export class TemplateNote {
  private readonly client: PolandPharmacyRxAPIContext;

  /**
   * Create a TemplateNote.
   * @param {PolandPharmacyRxAPIContext} client Reference to the service client.
   */
  constructor(client: PolandPharmacyRxAPIContext) {
    this.client = client;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.TemplateNoteAllResponse>
   */
  all(options?: Models.TemplateNoteAllOptionalParams): Promise<Models.TemplateNoteAllResponse>;
  /**
   * @param callback The callback
   */
  all(callback: msRest.ServiceCallback<Models.TemplateNoteDto>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  all(options: Models.TemplateNoteAllOptionalParams, callback: msRest.ServiceCallback<Models.TemplateNoteDto>): void;
  all(options?: Models.TemplateNoteAllOptionalParams | msRest.ServiceCallback<Models.TemplateNoteDto>, callback?: msRest.ServiceCallback<Models.TemplateNoteDto>): Promise<Models.TemplateNoteAllResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      allOperationSpec,
      callback) as Promise<Models.TemplateNoteAllResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.TemplateNoteOrdersResponse>
   */
  orders(options?: Models.TemplateNoteOrdersOptionalParams): Promise<Models.TemplateNoteOrdersResponse>;
  /**
   * @param callback The callback
   */
  orders(callback: msRest.ServiceCallback<Models.OrderDto>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  orders(options: Models.TemplateNoteOrdersOptionalParams, callback: msRest.ServiceCallback<Models.OrderDto>): void;
  orders(options?: Models.TemplateNoteOrdersOptionalParams | msRest.ServiceCallback<Models.OrderDto>, callback?: msRest.ServiceCallback<Models.OrderDto>): Promise<Models.TemplateNoteOrdersResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      ordersOperationSpec,
      callback) as Promise<Models.TemplateNoteOrdersResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  sendToSAP(options?: Models.TemplateNoteSendToSAPOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  sendToSAP(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  sendToSAP(options: Models.TemplateNoteSendToSAPOptionalParams, callback: msRest.ServiceCallback<void>): void;
  sendToSAP(options?: Models.TemplateNoteSendToSAPOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      sendToSAPOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.TemplateNoteGetTemplateNoteInfoResponse>
   */
  getTemplateNoteInfo(options?: Models.TemplateNoteGetTemplateNoteInfoOptionalParams): Promise<Models.TemplateNoteGetTemplateNoteInfoResponse>;
  /**
   * @param callback The callback
   */
  getTemplateNoteInfo(callback: msRest.ServiceCallback<Models.TemplateNoteInfoDto>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getTemplateNoteInfo(options: Models.TemplateNoteGetTemplateNoteInfoOptionalParams, callback: msRest.ServiceCallback<Models.TemplateNoteInfoDto>): void;
  getTemplateNoteInfo(options?: Models.TemplateNoteGetTemplateNoteInfoOptionalParams | msRest.ServiceCallback<Models.TemplateNoteInfoDto>, callback?: msRest.ServiceCallback<Models.TemplateNoteInfoDto>): Promise<Models.TemplateNoteGetTemplateNoteInfoResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      getTemplateNoteInfoOperationSpec,
      callback) as Promise<Models.TemplateNoteGetTemplateNoteInfoResponse>;
  }
}

// Operation Specifications
const serializer = new msRest.Serializer(Mappers);
const allOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "templateNote/TemplateNote",
  queryParameters: [
    Parameters.filters,
    Parameters.sorts,
    Parameters.page,
    Parameters.pageSize,
    Parameters.settlementPeriodFilterCodeName
  ],
  responses: {
    200: {
      bodyMapper: Mappers.TemplateNoteDto
    },
    default: {}
  },
  serializer
};

const ordersOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "templateNote/TemplateNote/orders",
  queryParameters: [
    Parameters.filters,
    Parameters.sorts,
    Parameters.page,
    Parameters.pageSize,
    Parameters.templateId
  ],
  responses: {
    200: {
      bodyMapper: Mappers.OrderDto
    },
    default: {}
  },
  serializer
};

const sendToSAPOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "templateNote/TemplateNote/sendToSAP",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.SendToSAPCommand
  },
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const getTemplateNoteInfoOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "templateNote/TemplateNote/templateNoteInfo",
  queryParameters: [
    Parameters.id2
  ],
  responses: {
    200: {
      bodyMapper: Mappers.TemplateNoteInfoDto
    },
    default: {}
  },
  serializer
};
