import { BackButton } from '@components/shared/buttons/BackButton';
import { ajaxByUser } from '@helper/api';
import { DateFormat } from '@helper/formatHelpers';
import { getEssityApiClient } from '@services/EssityApi';
import {
	CreateNoteResponseDto,
	InternalTaxNoteType,
	PharmacyNotePredictedToCorrectionDto,
	PharmacyTaxNoteCreateLossResponse,
	PharmacyTaxNoteDetailsDto,
	PharmacyTaxNoteGroupDto,
	PharmacyTaxNoteUpdateResponse,
} from '@services/src/models';
import { Button, Col, Modal, Row, Select, Space } from 'antd';
import { Field, Formik, FormikActions, FormikProps } from 'formik';
import {
	FDatePicker,
	FFieldLabel,
	FInput,
	FInputNumber,
	FSelect,
	FTextArea,
} from 'forms/FormikFormItems';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import styles from '../styles/PharmacyNote.module.less';
import {
	LossNoteEditValidationSchema,
	LossNoteValidationSchema,
} from '../validation/LossNoteValidationSchema';

interface IProps {
	editMode: boolean;
	readOnly: boolean;
	groupId: string;
	internalTaxNoteType: InternalTaxNoteType;
	lossNoteId?: string;
	setAttachment: (attachment: Blob | undefined) => void;
}

interface IState {
	notes: PharmacyNotePredictedToCorrectionDto[];
	group?: PharmacyTaxNoteGroupDto;
}

const getValidationSchema = (editMode: boolean) => {
	if (editMode) {
		return LossNoteEditValidationSchema;
	} else {
		return LossNoteValidationSchema;
	}
};

export const LossNoteDetails = (props: IProps) => {
	const [state, setState] = useState<IState>();
	const [note, setNote] = useState<PharmacyTaxNoteDetailsDto>();
	const [limitedEditMode, setLimitedEditMode] = useState<boolean>(false);
	const [isBusy, setIsBusy] = useState<boolean>();
	const history = useHistory();

	useEffect(() => {
		if (props.lossNoteId) {
			(async () => {
				const api = await getEssityApiClient();
				const result = await api.pharmacyTaxNote.getById(
					props.lossNoteId!
				);
				setLimitedEditMode(result.limitedEditMode!);
				setNote(result);
			})();
		}
	}, [props.lossNoteId]);

	useEffect(() => {
		(async () => {
			if (props.groupId && props.internalTaxNoteType) {
				const api = await getEssityApiClient();
				const result = await api.pharmacyTaxNote.getPharmacyTaxNotesPredictedToCorrection(
					{
						pharmacyTaxNoteGroupId: props.groupId!,
						internalTaxNoteType: props.internalTaxNoteType!,
					}
				);

				const group: PharmacyTaxNoteGroupDto = await api.pharmacyTaxNoteGroup.getById(
					props.groupId
				);

				setState({
					...state,
					notes: result,
					group: group,
				});
			}
		})();
	}, [props.groupId, props.internalTaxNoteType]);

	const getInitialValues = () => {
		if (!props.editMode && !props.readOnly) {
			return {};
		}

		const month = moment(
			new Date(moment().year(), note?.month!, moment().day())
		).add(-1, 'month');

		const year = moment(
			new Date(note?.year!, moment().month(), moment().day())
		);

		return {
			pharmacyName: note?.pharmacy?.name,
			pharmacyId: note?.pharmacy?.id,
			placeId: note?.pharmacy?.placeId,
			noteDate: moment(note?.noteDate),
			month: month,
			year: year,
			internalNumber: note?.internalNumber,
			noteValue: note?.noteValue,
			mainPharmacyTaxNoteInternalNumber:
				note?.mainPharmacyTaxNoteInternalNumber,
			note: note?.note,
		};
	};

	const RenderNoteDetails = (formikProps: FormikProps<any>) => {
		return (
			<>
				<Row>
					<Col span={10}>
						{(props.readOnly || props.editMode) && (
							<>
								<FFieldLabel label="Pharmacy note" />
								<Field
									component={FInput}
									placeholder="Pharmacy note number"
									name="mainPharmacyTaxNoteInternalNumber"
									readOnly={true}
								/>
							</>
						)}
					</Col>
				</Row>
				<Row>
					{!props.readOnly && !props.editMode && (
						<Col span={10}>
							<FFieldLabel label="Choose pharmacy note" />
							<Field
								component={FSelect}
								placeholder="Pharmacy note"
								name="pharmacyNote"
								readOnly={
									props.readOnly ||
									props.editMode ||
									limitedEditMode
								}
								children={Array.from(state!.notes).map(
									(x, i) => (
										<Select.Option
											key={i}
											value={x.pharmacyTaxNoteId!}
										>
											{x.noteNumber}
										</Select.Option>
									)
								)}
								changeData={(noteId: string) => {
									const note = state?.notes.find(
										(x) => x.pharmacyTaxNoteId === noteId
									);

									if (note) {
										formikProps.setFieldValue(
											'pharmacyTaxNoteId',
											note.pharmacyTaxNoteId
										);
										formikProps.setFieldValue(
											'placeId',
											note.placeId
										);
										formikProps.setFieldValue(
											'internalNumber',
											note.predictedCorrectionInternalNumber
										);
										formikProps.setFieldValue(
											'pharmacyName',
											note.pharmacyName
										);

										const year = moment(
											new Date(
												note!.year!,
												moment().month(),
												moment().day()
											)
										);

										formikProps.setFieldValue('year', year);

										const month = moment(
											new Date(
												moment().year(),
												note.month!,
												moment().day()
											)
										).add(-1, 'month');

										formikProps.setFieldValue(
											'month',
											month
										);

										formikProps.setFieldValue(
											'noteDate',
											moment()
										);
										formikProps.setFieldValue(
											'correctionNoteValue',
											note.predictedCorrectionNoteValue
										);
									} else {
										formikProps.resetForm();
									}
								}}
							/>
						</Col>
					)}
				</Row>
				<Row>
					<Col span={10}>
						<FFieldLabel label="Pharmacy name" />
						<Field
							component={FInput}
							placeholder="Pharmacy name"
							name="pharmacyName"
							readOnly={true}
						/>
					</Col>
				</Row>
				<Row>
					<Col span={10}>
						<FFieldLabel label="SAP ID" />
						<Field
							component={FInput}
							placeholder="SAP ID"
							name="placeId"
							readOnly={true}
						/>
					</Col>
				</Row>
				<Row>
					<Col span={10}>
						<FFieldLabel label="Loss date" />
						<Field
							label="Loss date"
							component={FDatePicker}
							name="noteDate"
							mode="date"
							style={{ width: '100%' }}
							disabled={props.readOnly || limitedEditMode}
							changeData={(value: any) => {}}
						/>
					</Col>
				</Row>
				{(props.readOnly || props.editMode) && (
					<Row>
						<Col span={10}>
							<FFieldLabel label="Internal number" />
							<Field
								component={FInput}
								changeData={(value: any) => {}}
								placeholder="Internal number"
								name="internalNumber"
								readOnly={true}
							/>
						</Col>
					</Row>
				)}
				<Row>
					<Col span={5} style={{ paddingRight: 5 }}>
						<FFieldLabel label="Month" />
						<Field
							component={FDatePicker}
							placeholder="Month"
							name="month"
							inputReadOnly={true}
							picker="month"
							format={DateFormat.Month}
							style={{ width: '100%' }}
						/>
					</Col>
					<Col span={5} style={{ paddingLeft: 5 }}>
						<FFieldLabel label="Year" />
						<Field
							component={FDatePicker}
							placeholder="Year"
							name="year"
							inputReadOnly={true}
							picker="year"
							format={DateFormat.Year}
							style={{ width: '100%' }}
						/>
					</Col>
				</Row>
				<Row>
					<Col span={10}>
						<FFieldLabel label="Loss value" />
						<Field
							component={FInputNumber}
							min={Number.MIN_SAFE_INTEGER}
							placeholder="Loss value"
							name="noteValue"
							readOnly={props.readOnly || limitedEditMode}
						/>
					</Col>
				</Row>
				<Row>
					<Col span={16}>
						<FFieldLabel label="Note" />
						<Field
							component={FTextArea}
							placeholder="Note"
							style={{ resize: 'none' }}
							maxLength={5000}
							name="note"
							rows={5}
							maxRows={5}
							readOnly={props.readOnly && !props.editMode}
						/>
					</Col>
				</Row>
			</>
		);
	};

	const createOrUpdateNote = (values: any) => {
		setIsBusy(true);
		if (props.editMode) {
			ajaxByUser('Successfully updated loss.', () =>
				getEssityApiClient().then((api) =>
					api.pharmacyTaxNote
						.updateLoss({
							body: {
								pharmacyTaxNoteId: props.lossNoteId,
								noteDate: moment(values.noteDate)
									.utc(true)
									.toDate(),
								noteValue: values.noteValue,
								note: values.note,
							},
						})
						.then((response: PharmacyTaxNoteUpdateResponse) => {
							history.push(
								`/pharmacyPayments/${response.pharmacyTaxNoteGroupId}/${response.pharmacyId}`
							);
						})
						.finally(() => setIsBusy(false))
				)
			);
		} else {
			ajaxByUser('Successfully added loss.', () =>
				getEssityApiClient().then((api) =>
					api.pharmacyTaxNote
						.createLoss({
							body: {
								pharmacyTaxNoteId: values.pharmacyTaxNoteId,
								noteDate:
									values.noteDate?.utc(true).toDate() ||
									undefined,
								noteValue: values.noteValue,
								note: values.note,
							},
						})
						.then((result: PharmacyTaxNoteCreateLossResponse) => {
							if (result) {
								const dto = result as CreateNoteResponseDto;
								const path = `/pharmacyPayments/${dto.pharmacyTaxNoteGroupId}/${dto.pharmacyId}`;

								Modal.success({
									centered: true,
									maskClosable: true,
									title: `Created loss with number: ${dto.pharmacyTaxNoteIdInternalNumber}`,
									onOk: () => {
										history.push(path);
									},
									onCancel: () => {
										history.push(path);
									},
								});
							}
						})
						.finally(() => setIsBusy(false))
				)
			);
		}
	};

	return (
		<Space direction="vertical" className={styles.details}>
			<h2>Loss Details</h2>
			<Space direction="vertical" className={styles.form}>
				<Formik
					validateOnChange={false}
					validateOnBlur={true}
					initialValues={getInitialValues()}
					enableReinitialize
					validationSchema={getValidationSchema(props.editMode)}
					onSubmit={(values, actions: FormikActions<any>) =>
						createOrUpdateNote(values)
					}
					render={(formikProps: FormikProps<any>) => (
						<Space direction="vertical" style={{ width: '100%' }}>
							{state?.notes && RenderNoteDetails(formikProps)}
							<Space
								direction="horizontal"
								className={styles.actions}
							>
								<BackButton />
								<Button
									type="primary"
									shape="round"
									size="large"
									className={styles.save}
									disabled={props.readOnly || isBusy}
									onClick={() => formikProps.submitForm()}
								>
									Save
								</Button>
							</Space>
						</Space>
					)}
				></Formik>
			</Space>
		</Space>
	);
};
