import { Col, Row } from 'antd';
import { Field } from 'formik';
import { FTextArea } from 'forms/FormikFormItems';
import React from 'react';
import styles from '../styles/PharmacyEdit.module.less';

interface IProps {
	readOnly: boolean;
}

export const Notes = (props: IProps) => (
	<Col span={12}>
		<h2 className="header-text">Description</h2>

		<div className={styles.description}>
			<Row>
				<Col span={24}>
					<Field
						component={FTextArea}
						placeholder="Description"
						name="description"
						readOnly={props.readOnly}
					/>
				</Col>
			</Row>
		</div>
	</Col>
);
