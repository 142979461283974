import * as Yup from 'yup';

export const PharmacyNoteValidationSchema = () =>
	Yup.object().shape({
		pharmacyName: Yup.string().required('Pharmacy name is required.'),
		placeId: Yup.string().required('SAP ID is required.'),
		noteDate: Yup.date().required('Note date is required.'),
		noteNumber: Yup.string()
			.max(50, 'Note number can have no more than 50 characters.')
			.required('Note number is required.'),
		month: Yup.date().required('Month is required.'),
		year: Yup.date().required('Year is required.'),
		noteValue: Yup.number().nullable().required('Note value is required.'),
	});
