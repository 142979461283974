import { ArrowDownOutlined, CloudUploadOutlined } from '@ant-design/icons';
import { LoadingView } from '@components/shared/import/LoadingView';
import { IsolatedContainer } from '@components/shared/IsolatedContainer';
import { IsolatedModal } from '@components/shared/modals/IsolatedModal';
import { ImportStatus } from '@components/shared/types/importStatus';
import { ajaxCatch } from '@helper/api';
import formatHelpers from '@helper/formatHelpers';
import { getEssityApiClient } from '@services/EssityApi';
import {
	ImportModelImportPriceListOptionalParams,
	PriceListGroup,
} from '@services/src/models';
import { Button, Col, Modal, Result } from 'antd';
import Dragger from 'antd/lib/upload/Dragger';
import {
	RcCustomRequestOptions,
	UploadChangeParam,
	UploadFile,
} from 'antd/lib/upload/interface';
import { Field, Formik, FormikActions, FormikProps } from 'formik';
import { FDatePicker, FFieldLabel, FRadioGroup } from 'forms/FormikFormItems';
import { CenteredRow } from 'layout/CenteredRow';
import moment from 'moment';
import React, { useState } from 'react';

interface IProps {
	onSubmit: () => void;
}

export const ImportPriceListFileModal = (props: IProps) => {
	const [visible, setVisible] = useState<boolean>(false);
	const [file, setFile] = useState<File | undefined>();
	const [fileList, setFileList] = useState<UploadFile[] | undefined>();

	const [progressState, setProgressState] = useState<ImportStatus>(
		ImportStatus.Init
	);

	const handleCreate = (
		values: ImportModelImportPriceListOptionalParams,
		actions: FormikActions<ImportModelImportPriceListOptionalParams>
	) => {
		setProgressState(ImportStatus.Loading);

		ajaxCatch(
			() =>
				getEssityApiClient().then((api) =>
					api.importModel.importPriceList({
						validFrom: moment(values.validFrom).utc(true).toDate(),
						file: file,
						priceListGroup: values.priceListGroup,
					})
				),
			() => {
				setProgressState(ImportStatus.Done);
				props.onSubmit();
				actions.resetForm();
				actions.setSubmitting(false);
			},
			(error) => {
				console.error(error);
				setProgressState(ImportStatus.Exception);
				actions.resetForm();
				actions.setSubmitting(false);
			}
		);
	};

	const handleCancel = () => {
		setVisible(false);
		setFile(undefined);
		setFileList(undefined);
		setProgressState(ImportStatus.Init);
	};

	const uploadProps = {
		name: 'file',
		multiple: false,
		customRequest: async (options: RcCustomRequestOptions) => {
			const { onSuccess } = options;

			setFile(options.file);
			onSuccess({}, options.file);
		},
		onChange: (info: UploadChangeParam) => {
			if (info.file.status !== 'removed') {
				setFileList([info.file]);
			}
		},
		onRemove: () => {
			setFile(undefined);
			setFileList(undefined);
		},
		progress: {
			strokeColor: {
				'0%': '#108ee9',
				'100%': '#87d068',
			},
			strokeWidth: 1,
			format: (percent: any) =>
				formatHelpers.formatPercent(percent, true),
		},
		accept: '.zphx, .xml',
		showUploadList: {
			showRemoveIcon: true,
		},
	};

	const getViewByStatus = (
		props: FormikProps<ImportModelImportPriceListOptionalParams>
	) => {
		switch (progressState) {
			case ImportStatus.Done:
				return (
					<Result
						status="success"
						title="Successfully finished import."
					/>
				);
			case ImportStatus.Exception:
				return <Result status="500" title="Import error" />;
			case ImportStatus.Loading:
				return <LoadingView />;
			case ImportStatus.Init:
				return (
					<>
						<Col span={24}>
							<Dragger {...uploadProps} fileList={fileList}>
								<p className="ant-upload-drag-icon">
									<CloudUploadOutlined />
								</p>
								<p className="ant-upload-text">
									Click or drag file to this area to import
								</p>
								<p>(.zphx or .xml)</p>
							</Dragger>
						</Col>
						<Col
							span={24}
							style={{ paddingLeft: '0px', marginTop: '30px' }}
						>
							<FFieldLabel label="Valid from" />
							<Field
								label="Valid from"
								component={FDatePicker}
								name="validFrom"
								mode="date"
								style={{ width: '50%' }}
							></Field>
						</Col>
						<Col
							span={24}
							style={{ paddingLeft: '0px', marginTop: '15px' }}
						>
							<FFieldLabel
								label="Price list group"
								for="priceListGroup"
							/>
							<Field
								readOnly={false}
								style={{ width: '100%' }}
								defaultValue={PriceListGroup.Auxiliaries}
								values={[
									PriceListGroup.Auxiliaries,
									PriceListGroup.OrthopedicProducts,
								]}
								labels={['Auxiliaries', 'Orthopedic Products']}
								component={FRadioGroup}
								name="priceListGroup"
							/>
						</Col>
						<Col>
							<Button
								type="primary"
								size="large"
								shape="round"
								style={{ marginTop: '15px' }}
								disabled={
									!props.values.validFrom ||
									file === undefined
								}
								onClick={() => {
									if (!props.isSubmitting) {
										props.submitForm();
									}
								}}
							>
								Import price list
							</Button>
						</Col>
					</>
				);
		}
	};

	const handleRender = (
		props: FormikProps<ImportModelImportPriceListOptionalParams>
	) => {
		return (
			<IsolatedModal
				title="Import price list from eZWM file"
				visible={visible}
				onCancel={handleCancel}
			>
				<CenteredRow>{getViewByStatus(props)}</CenteredRow>
			</IsolatedModal>
		);
	};

	return (
		<>
			<Button
				type="primary"
				shape="round"
				size="large"
				onClick={() => setVisible(true)}
			>
				Price list file
				<ArrowDownOutlined />
			</Button>
			<Formik
				validateOnChange
				validateOnBlur
				enableReinitialize
				isInitialValid={false}
				initialValues={{}}
				onSubmit={(values, actions: FormikActions<any>) =>
					handleCreate(values, actions)
				}
				render={handleRender}
			/>
		</>
	);
};
