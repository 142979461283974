import * as PaymentStateMapper from '@services/mappers/PaymentState';
import {
	PaymentState,
	PharmacyTaxNotePaymentWithNotesDto,
} from '@services/src/models';
import { Button, Space, Tag, Tooltip } from 'antd';
import React from 'react';

import { FreezePayment } from './FreezePayment';
import { WrongAccountModal } from './WrongAccountModal';

const canRegisterWrongAccount = (status: PaymentState): boolean => {
	return status === PaymentState.Paid || status === PaymentState.SentToSap;
};

export const GetPaymentTableColumns = (refresh: () => void) => [
	{
		sorter: false,
		dataIndex: 'paymentName',
		key: 'paymentName',
		title: 'Payment list',
		width: 250,
	},
	{
		sorter: false,
		dataIndex: 'paymentState',
		key: 'paymentState',
		title: '',
		render: (
			status: PaymentState,
			payment: PharmacyTaxNotePaymentWithNotesDto
		) => {
			return (
				<Space direction="horizontal">
					<Tag color="#404d57">{PaymentStateMapper.map(status)}</Tag>
					<FreezePayment
						status={status}
						paymentId={payment.paymentId!}
						refresh={refresh}
					/>
					{canRegisterWrongAccount(status) && (
						<WrongAccountModal
							payment={payment}
							refresh={refresh}
						/>
					)}
				</Space>
			);
		},
		width: 80,
	},
	{
		dataIndex: '',
		key: '',
		title: '',
	},
];
