/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from "@azure/ms-rest-js";

export const businessObjectsState: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "businessObjectsState"
  ],
  mapper: {
    serializedName: "businessObjectsState",
    type: {
      name: "Enum",
      allowedValues: [
        "CorrectOnly",
        "NotCorrectOnly",
        "All"
      ]
    }
  }
};
export const checked: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "checked"
  ],
  mapper: {
    serializedName: "Checked",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "Uuid"
        }
      }
    }
  }
};
export const codeName: msRest.OperationURLParameter = {
  parameterPath: "codeName",
  mapper: {
    required: true,
    serializedName: "codeName",
    type: {
      name: "String"
    }
  }
};
export const codeNames: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "codeNames"
  ],
  mapper: {
    serializedName: "CodeNames",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "String"
        }
      }
    }
  }
};
export const contactId: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "contactId"
  ],
  mapper: {
    serializedName: "contactId",
    type: {
      name: "Uuid"
    }
  }
};
export const correctReport: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "correctReport"
  ],
  mapper: {
    serializedName: "CorrectReport",
    type: {
      name: "Boolean"
    }
  }
};
export const dateFrom: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "dateFrom"
  ],
  mapper: {
    serializedName: "DateFrom",
    type: {
      name: "DateTime"
    }
  }
};
export const dateTo: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "dateTo"
  ],
  mapper: {
    serializedName: "DateTo",
    type: {
      name: "DateTime"
    }
  }
};
export const detailedReport: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "detailedReport"
  ],
  mapper: {
    serializedName: "DetailedReport",
    type: {
      name: "Boolean"
    }
  }
};
export const documentCategory: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "documentCategory"
  ],
  mapper: {
    serializedName: "documentCategory",
    type: {
      name: "Enum",
      allowedValues: [
        "IMPORT",
        "EXPORT"
      ]
    }
  }
};
export const file: msRest.OperationParameter = {
  parameterPath: [
    "options",
    "file"
  ],
  mapper: {
    serializedName: "File",
    type: {
      name: "Stream"
    }
  }
};
export const fileType: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "fileType"
  ],
  mapper: {
    serializedName: "FileType",
    type: {
      name: "Enum",
      allowedValues: [
        "XLSX",
        "PDF",
        "CSV",
        "JSON",
        "XML"
      ]
    }
  }
};
export const filters: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "filters"
  ],
  mapper: {
    serializedName: "Filters",
    type: {
      name: "String"
    }
  }
};
export const fromDate: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "fromDate"
  ],
  mapper: {
    serializedName: "FromDate",
    type: {
      name: "DateTime"
    }
  }
};
export const id0: msRest.OperationURLParameter = {
  parameterPath: "id",
  mapper: {
    required: true,
    serializedName: "id",
    type: {
      name: "Number"
    }
  }
};
export const id1: msRest.OperationURLParameter = {
  parameterPath: "id",
  mapper: {
    required: true,
    serializedName: "id",
    type: {
      name: "Uuid"
    }
  }
};
export const id2: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "id"
  ],
  mapper: {
    serializedName: "id",
    type: {
      name: "Uuid"
    }
  }
};
export const includeOrdersAssignedToPharmacyTaxNoteId: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "includeOrdersAssignedToPharmacyTaxNoteId"
  ],
  mapper: {
    serializedName: "includeOrdersAssignedToPharmacyTaxNoteId",
    type: {
      name: "Uuid"
    }
  }
};
export const internalTaxNoteType: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "internalTaxNoteType"
  ],
  mapper: {
    serializedName: "InternalTaxNoteType",
    type: {
      name: "Enum",
      allowedValues: [
        "Main",
        "Correction",
        "Debit",
        "Loss",
        "Return",
        "PayLoss",
        "Overpay"
      ]
    }
  }
};
export const isCheckedAll: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "isCheckedAll"
  ],
  mapper: {
    serializedName: "IsCheckedAll",
    type: {
      name: "Boolean"
    }
  }
};
export const kodKom: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "kodKom"
  ],
  mapper: {
    serializedName: "kodKom",
    type: {
      name: "Number"
    }
  }
};
export const month0: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "month"
  ],
  mapper: {
    serializedName: "month",
    type: {
      name: "Number"
    }
  }
};
export const month1: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "month"
  ],
  mapper: {
    serializedName: "Month",
    type: {
      name: "Number"
    }
  }
};
export const monthFrom: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "monthFrom"
  ],
  mapper: {
    serializedName: "MonthFrom",
    type: {
      name: "Number"
    }
  }
};
export const monthTo: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "monthTo"
  ],
  mapper: {
    serializedName: "MonthTo",
    type: {
      name: "Number"
    }
  }
};
export const nhfRealizationId: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "nhfRealizationId"
  ],
  mapper: {
    serializedName: "NhfRealizationId",
    type: {
      name: "String"
    }
  }
};
export const orderGroupId0: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "orderGroupId"
  ],
  mapper: {
    serializedName: "orderGroupId",
    type: {
      name: "Uuid"
    }
  }
};
export const orderGroupId1: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "orderGroupId"
  ],
  mapper: {
    serializedName: "OrderGroupId",
    type: {
      name: "Uuid"
    }
  }
};
export const orderId: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "orderId"
  ],
  mapper: {
    serializedName: "orderId",
    type: {
      name: "Uuid"
    }
  }
};
export const orderStates: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "orderStates"
  ],
  mapper: {
    serializedName: "OrderStates",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "Enum",
          allowedValues: [
            "Imported",
            "Reported",
            "ApprovedByNhf",
            "MarkedToCancel",
            "SentToCancel",
            "Canceled",
            "Restored",
            "SettledNhf"
          ]
        }
      }
    }
  }
};
export const page: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "page"
  ],
  mapper: {
    serializedName: "Page",
    constraints: {
      InclusiveMaximum: 2147483647,
      InclusiveMinimum: 1
    },
    type: {
      name: "Number"
    }
  }
};
export const pageSize: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "pageSize"
  ],
  mapper: {
    serializedName: "PageSize",
    constraints: {
      InclusiveMaximum: 2147483647,
      InclusiveMinimum: 1
    },
    type: {
      name: "Number"
    }
  }
};
export const paymentDateFrom: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "paymentDateFrom"
  ],
  mapper: {
    serializedName: "PaymentDateFrom",
    type: {
      name: "DateTime"
    }
  }
};
export const paymentDateTo: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "paymentDateTo"
  ],
  mapper: {
    serializedName: "PaymentDateTo",
    type: {
      name: "DateTime"
    }
  }
};
export const pharmaciesIds: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "pharmaciesIds"
  ],
  mapper: {
    serializedName: "PharmaciesIds",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "Uuid"
        }
      }
    }
  }
};
export const pharmacyId: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "pharmacyId"
  ],
  mapper: {
    serializedName: "pharmacyId",
    type: {
      name: "Uuid"
    }
  }
};
export const pharmacyTaxNoteGroupId0: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "pharmacyTaxNoteGroupId"
  ],
  mapper: {
    serializedName: "PharmacyTaxNoteGroupId",
    type: {
      name: "Uuid"
    }
  }
};
export const pharmacyTaxNoteGroupId1: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "pharmacyTaxNoteGroupId"
  ],
  mapper: {
    serializedName: "pharmacyTaxNoteGroupId",
    type: {
      name: "Uuid"
    }
  }
};
export const pharmacyTaxNoteGroupId2: msRest.OperationURLParameter = {
  parameterPath: "pharmacyTaxNoteGroupId",
  mapper: {
    required: true,
    serializedName: "pharmacyTaxNoteGroupId",
    type: {
      name: "Uuid"
    }
  }
};
export const pharmacyTaxNoteId: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "pharmacyTaxNoteId"
  ],
  mapper: {
    serializedName: "PharmacyTaxNoteId",
    type: {
      name: "Uuid"
    }
  }
};
export const priceListGroup0: msRest.OperationParameter = {
  parameterPath: [
    "options",
    "priceListGroup"
  ],
  mapper: {
    serializedName: "PriceListGroup",
    type: {
      name: "Object"
    }
  }
};
export const priceListGroup1: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "priceListGroup"
  ],
  mapper: {
    serializedName: "priceListGroup",
    type: {
      name: "Enum",
      allowedValues: [
        "Auxiliaries",
        "OrthopedicProducts"
      ]
    }
  }
};
export const priceListId0: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "priceListId"
  ],
  mapper: {
    serializedName: "priceListId",
    type: {
      name: "Uuid"
    }
  }
};
export const priceListId1: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "priceListId"
  ],
  mapper: {
    serializedName: "PriceListId",
    type: {
      name: "Uuid"
    }
  }
};
export const returnDocumentId: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "returnDocumentId"
  ],
  mapper: {
    serializedName: "returnDocumentId",
    type: {
      name: "Uuid"
    }
  }
};
export const searchText0: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "searchText"
  ],
  mapper: {
    serializedName: "searchText",
    type: {
      name: "String"
    }
  }
};
export const searchText1: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "searchText"
  ],
  mapper: {
    serializedName: "SearchText",
    type: {
      name: "String"
    }
  }
};
export const searchType: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "searchType"
  ],
  mapper: {
    serializedName: "SearchType",
    type: {
      name: "Enum",
      allowedValues: [
        "PESEL",
        "OrderNumber",
        "Status"
      ]
    }
  }
};
export const selectedIds: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "selectedIds"
  ],
  mapper: {
    serializedName: "selectedIds",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "Uuid"
        }
      }
    }
  }
};
export const settlementPeriodFilterCodeName: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "settlementPeriodFilterCodeName"
  ],
  mapper: {
    serializedName: "settlementPeriodFilterCodeName",
    type: {
      name: "String"
    }
  }
};
export const sieveFilters: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "sieveFilters"
  ],
  mapper: {
    serializedName: "Sieve.Filters",
    type: {
      name: "String"
    }
  }
};
export const sievePage: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "sievePage"
  ],
  mapper: {
    serializedName: "Sieve.Page",
    constraints: {
      InclusiveMaximum: 2147483647,
      InclusiveMinimum: 1
    },
    type: {
      name: "Number"
    }
  }
};
export const sievePageSize: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "sievePageSize"
  ],
  mapper: {
    serializedName: "Sieve.PageSize",
    constraints: {
      InclusiveMaximum: 2147483647,
      InclusiveMinimum: 1
    },
    type: {
      name: "Number"
    }
  }
};
export const sieveSorts: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "sieveSorts"
  ],
  mapper: {
    serializedName: "Sieve.Sorts",
    type: {
      name: "String"
    }
  }
};
export const skipToken: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "skipToken"
  ],
  mapper: {
    serializedName: "skipToken",
    type: {
      name: "String"
    }
  }
};
export const sorts: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "sorts"
  ],
  mapper: {
    serializedName: "Sorts",
    type: {
      name: "String"
    }
  }
};
export const templateId: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "templateId"
  ],
  mapper: {
    serializedName: "templateId",
    type: {
      name: "Uuid"
    }
  }
};
export const toDate: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "toDate"
  ],
  mapper: {
    serializedName: "ToDate",
    type: {
      name: "DateTime"
    }
  }
};
export const unchecked: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "unchecked"
  ],
  mapper: {
    serializedName: "Unchecked",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "Uuid"
        }
      }
    }
  }
};
export const userId: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "userId"
  ],
  mapper: {
    serializedName: "userId",
    type: {
      name: "Uuid"
    }
  }
};
export const validFrom: msRest.OperationParameter = {
  parameterPath: [
    "options",
    "validFrom"
  ],
  mapper: {
    serializedName: "ValidFrom",
    type: {
      name: "DateTime"
    }
  }
};
export const voivodeshipId0: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "voivodeshipId"
  ],
  mapper: {
    serializedName: "voivodeshipId",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "Number"
        }
      }
    }
  }
};
export const voivodeshipId1: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "voivodeshipId"
  ],
  mapper: {
    serializedName: "voivodeshipId",
    type: {
      name: "Number"
    }
  }
};
export const voivodeshipId2: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "voivodeshipId"
  ],
  mapper: {
    serializedName: "voivodeshipId",
    type: {
      name: "Uuid"
    }
  }
};
export const voivodeshipIds: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "voivodeshipIds"
  ],
  mapper: {
    serializedName: "VoivodeshipIds",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "Number"
        }
      }
    }
  }
};
export const year0: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "year"
  ],
  mapper: {
    serializedName: "year",
    type: {
      name: "Number"
    }
  }
};
export const year1: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "year"
  ],
  mapper: {
    serializedName: "Year",
    type: {
      name: "Number"
    }
  }
};
export const zeroBalance: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "zeroBalance"
  ],
  mapper: {
    serializedName: "zeroBalance",
    type: {
      name: "Boolean"
    }
  }
};
export const zipExtension: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "zipExtension"
  ],
  mapper: {
    serializedName: "zipExtension",
    type: {
      name: "String"
    }
  }
};
