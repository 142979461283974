import { BackButton } from '@components/shared/buttons/BackButton';
import { Divider } from 'antd';
import React from 'react';

import { OrdersSearch } from './OrdersSearch';
import styles from './styles/Orders.module.less';

interface IProps {
	groupId: string;
}

export const OrdersMenu = (props: IProps) => {
	return (
		<>
			<Divider />
			<div className={styles.menu}>
				<div></div>
				<BackButton />
				<OrdersSearch selectedGroupId={props.groupId}/>
			</div>
			<Divider />
		</>
	);
};
