import {
	BusinessObjectsState,
	OrderState,
	SearchType,
} from '@services/src/models';
import { observable } from 'mobx';
import moment from 'moment';

export interface IFilterData {
	searchMethod: SearchType;
	searchText: string | undefined;
	searchVoivodeships: number[] | undefined;
	searchOrderStates: OrderState[] | undefined;
	dateType: number;
	dateFrom: moment.Moment | null;
	dateTo: moment.Moment | null;
	selectedOrderId?: string;
}

export interface PharmacyPaymentsFilter {
	businessObjectsState: BusinessObjectsState;
	zeroBalance: boolean;
}

export const globalSearchStore = {
	visible: observable.box<boolean>(false),
	pharmacyPaymentsFilter: observable.box<PharmacyPaymentsFilter>({
		businessObjectsState: BusinessObjectsState.NotCorrectOnly,
		zeroBalance: true,
	}),
	filterData: observable.box<IFilterData>({
		searchMethod: SearchType.PESEL,
		searchText: undefined,
		dateType: 1,
		searchVoivodeships: undefined,
		searchOrderStates: undefined,
		dateFrom: null,
		dateTo: null,
		selectedOrderId: undefined,
	}),
};

export const createGlobalSearchStore = () => {
	return globalSearchStore;
};

export type IGlobalSearchStore = ReturnType<typeof createGlobalSearchStore>;
