import { ReloadOutlined } from '@ant-design/icons';
import { EssityColumnProps } from '@components/shared/paginatedEssityTable/GridHelper';
import { ajaxByUser } from '@helper/api';
import { useRootData } from '@hooks/hook';
import { getEssityApiClient } from '@services/EssityApi';
import { OrderDto, OrderState } from '@services/src/models';
import { Button, Popconfirm, Tooltip } from 'antd';
import React from 'react';
import { IGridStore } from 'stores/GridStore';

import { OrdersCancelModal } from './OrdersCancelModal';
import { OrdersPairedConfirmationModal } from './OrdersPairedConfirmationModal';

interface IProps {
	order: OrderDto;
}

const CancelationAction = (props: IProps) => {
	return (
		<Tooltip title="Order cancelation">
			<OrdersCancelModal order={props.order} />
		</Tooltip>
	);
};

const RestoreAction = (props: IProps) => {
	const gridStore: IGridStore = useRootData((store) => store.gridStore);

	return (
		<Tooltip title="Restore order">
			<Button
				type="primary"
				shape="circle"
				size="small"
				icon={<ReloadOutlined />}
				onClick={() => {
					OrdersPairedConfirmationModal({
						onAccept: () => {
							ajaxByUser(
								'Successfully restored order.',
								() =>
									getEssityApiClient().then((api) =>
										api.order.restore({
											body: { orderId: props.order.id },
										})
									),
								() => {
									gridStore.searching.set(true);
								}
							);
						},
						mode: 'restore',
						nhfRealizationId: props.order.nhfRealizationId!,
					});
				}}
			/>
		</Tooltip>
	);
};

export const OrderAction = (): EssityColumnProps<OrderDto> => {
	return {
		title: '',
		key: 'operation',
		fixed: 'right',
		width: 50,
		render: (item: OrderDto) => {
			if (item.year != undefined && item.year < 2024) {
				return item.orderState === OrderState.Canceled ? (
					<RestoreAction order={item} />
				) : item.orderState === OrderState.Imported ? (
					<></>
				) : (
					<CancelationAction order={item} />
				);
			} else {
				return <></>;
			}
		},
	};
};
