import { SelectOutlined } from '@ant-design/icons';
import { GetPharmaciesTableColumns } from '@components/pharmacies/PharmaciesTableColumns';
import { EssityColumnProps } from '@components/shared/paginatedEssityTable/GridHelper';
import PaginatedEssityTable from '@components/shared/paginatedEssityTable/PaginatedEssityTable';
import { SieveModel } from '@components/shared/paginatedEssityTable/SieveModels';
import { useRootData } from '@hooks/hook';
import { getEssityApiClient } from '@services/EssityApi';
import { PharmacyAllOptionalParams, PharmacyDto } from '@services/src/models';
import { Button, Space, Tooltip } from 'antd';
import React, { useState } from 'react';
import { IGridStore } from 'stores/GridStore';

const gridName = 'pharmaciesFinder';

interface IProps {
	onSelect: (items: PharmacyDto[], selectedAll?: boolean) => void;
	allowExtendedSelection?: boolean;
}

interface IState {
	allPagesCount: number | undefined;
	currentPageRecords: PharmacyDto[] | undefined;
}

export const PharmacyTable = (props: IProps) => {
	const gridStore: IGridStore = useRootData((store) => store.gridStore);
	const [state, setState] = useState<IState>();

	const columns: EssityColumnProps<PharmacyDto>[] = [
		...GetPharmaciesTableColumns({ allowFilterOnVoivodeship: false }),
		{
			title: '',
			key: 'operation',
			fixed: 'right',
			width: 50,
			render: (item: PharmacyDto) => (
				<Tooltip title="Select">
					<Button
						type="primary"
						shape="circle"
						size="small"
						onClick={() => {
							props.onSelect([item]);
							gridStore.resetGrid(gridName);
						}}
						icon={<SelectOutlined />}
					/>
				</Tooltip>
			),
		},
	];

	return (
		<>
			<PaginatedEssityTable<PharmacyDto>
				columns={columns}
				gridName={gridName}
				hasRowSelection={false}
				hidePersonalizationSettings={true}
				getRowKey={(r: PharmacyDto) => r.id!}
				getPagedResult={async (sieve: SieveModel, abortSignal) => {
					const parameters: PharmacyAllOptionalParams = {
						page: sieve.page,
						pageSize: sieve.pageSize,
						sorts: sieve.sorts,
						abortSignal: abortSignal,
						filters: sieve.filters,
					};

					const api = await getEssityApiClient();
					const response: any = await api.pharmacy.all(parameters);

					setState({
						currentPageRecords: response.results,
						allPagesCount: response.rowCount,
					});

					return response;
				}}
			/>
			{props.allowExtendedSelection && (
				<Space direction="horizontal">
					<Button
						type="primary"
						onClick={() => {
							if (state?.currentPageRecords) {
								props.onSelect(state.currentPageRecords);
							}
						}}
					>
						Choose records from this page (
						{state?.currentPageRecords?.length})
					</Button>
					<Button
						type="primary"
						onClick={() => {
							props.onSelect([], true);
						}}
					>
						Choose all records ({state?.allPagesCount})
					</Button>
				</Space>
			)}
		</>
	);
};
