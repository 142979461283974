import DictionaryFilter from '@components/shared/DictionaryFilter';
import EnumFilter from '@components/shared/EnumFilter';
import { EssityRangePicker } from '@components/shared/EssityDateTime';
import { useRootData } from '@hooks/hook';
import { DictionariesConstants } from '@services/DictionariesConstants';
import * as OrderStateMapper from '@services/mappers/OrderState';
import { OrderState, SearchType } from '@services/src/models';
import { Button, DatePicker, Input, Radio, Space } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import { observer } from 'mobx-react';
import { Moment } from 'moment';
import React from 'react';
import { IDictionaryStore } from 'stores/DictionaryStore';
import { IGlobalSearchStore } from 'stores/GlobalSearchStore';
import { IGridStore } from 'stores/GridStore';

import styles from './styles/GlobalSearch.module.less';

const GlobalSearchFilters = () => {
	const { RangePicker } = DatePicker;

	const gridStore: IGridStore = useRootData((store) => store.gridStore);

	const globalSearchStore: IGlobalSearchStore = useRootData(
		(store) => store.globalSearchStore
	);

	const dictionaryStore: IDictionaryStore = useRootData(
		(store) => store.dictionaryStore
	);

	const getFiltersByStatus = () => {
		const searchType = globalSearchStore.filterData.get().searchMethod;

		switch (searchType) {
			case SearchType.OrderNumber:
				return (
					<>
						<p className={styles.textInputLabel}>Order number</p>
						<Input
							value={
								globalSearchStore.filterData.get().searchText
							}
							onChange={(event) => {
								globalSearchStore.filterData.set({
									...globalSearchStore.filterData.get(),
									searchText: event.target.value,
								});
							}}
						/>
					</>
				);
			case SearchType.PESEL:
				return (
					<>
						<p className={styles.textInputLabel}>PESEL</p>
						<Input
							value={
								globalSearchStore.filterData.get().searchText
							}
							onChange={(event) => {
								globalSearchStore.filterData.set({
									...globalSearchStore.filterData.get(),
									searchText: event.target.value,
								});
							}}
						/>
					</>
				);
			case SearchType.Status:
				return (
					<Space direction="horizontal">
						<EnumFilter<OrderState>
							className={`${styles.item} ${styles.status}`}
							mode="multiple"
							name="Status"
							onChange={(items: OrderState[]) => {
								globalSearchStore.filterData.set({
									...globalSearchStore.filterData.get(),
									searchOrderStates: items,
								});
							}}
							enum={[...Object.values(OrderState)]}
							enumMapper={OrderStateMapper}
						/>
						<DictionaryFilter
							className={`${styles.item} ${styles.voivodeship}`}
							mode="multiple"
							name="Voivodeship"
							onChange={(items: number[]) => {
								globalSearchStore.filterData.set({
									...globalSearchStore.filterData.get(),
									searchVoivodeships: items,
								});
							}}
							dictionary={dictionaryStore.getDictionary(
								DictionariesConstants.Voivodeships
							)}
						/>
					</Space>
				);
				break;
		}
	};

	return (
		<Space direction="horizontal" className={styles.filters}>
			{getFiltersByStatus()}
			<Radio.Group
				onChange={(e: RadioChangeEvent) => {
					globalSearchStore.filterData.set({
						...globalSearchStore.filterData.get(),
						dateType: e.target.value,
					});
				}}
				value={globalSearchStore.filterData.get().dateType}
			>
				<Radio value={1}>last year</Radio>
				<Radio value={2}>all history</Radio>
				<Radio value={3}>date range</Radio>
			</Radio.Group>
			<EssityRangePicker
				disabled={globalSearchStore.filterData.get().dateType !== 3}
				value={[
					globalSearchStore.filterData.get().dateFrom,
					globalSearchStore.filterData.get().dateTo,
				]}
				onChange={(v: [Moment | null, Moment | null] | null) => {
					if (v) {
						globalSearchStore.filterData.set({
							...globalSearchStore.filterData.get(),
							dateFrom: v[0],
							dateTo: v[1],
						});
					}
				}}
			/>
			<Button
				size="middle"
				shape="round"
				type="primary"
				className={styles.searchButton}
				onClick={() => {
					gridStore.searching.set(true);
				}}
			>
				Search
			</Button>
		</Space>
	);
};

export default observer(GlobalSearchFilters);
