import { PaymentState } from '@services/src/models';

export const valueMap = new Map<PaymentState, string>([
	[PaymentState.Created, 'Created'],
    [PaymentState.Paid, 'Paid'],
    [PaymentState.ReadyToSend, 'Ready to send'],
    [PaymentState.SentToSap, 'Sent to SAP'],
    [PaymentState.Compensated, 'Compensated']
]);

export const map = (value: PaymentState): string => valueMap.get(value) ?? '';