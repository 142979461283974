import { OrderReferenceType } from '@services/src/models';

export const valueMap = new Map<OrderReferenceType, string>([
	[OrderReferenceType.REPORTFILE, 'NHF Report'],
	[OrderReferenceType.REPORTCORRECTIONFILE, 'NHF Correction Report'],
	[OrderReferenceType.NOTE, 'NHF Note'],
	[OrderReferenceType.CORRECTIONNOTE, 'NHF Correction Note'],
	[OrderReferenceType.RETURNDOCUMENT, 'Return document'],
]);

export const map = (value: OrderReferenceType): string =>
	valueMap.get(value) ?? '';
