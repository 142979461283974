import { ajaxByUser } from '@helper/api';
import { getEssityApiClient } from '@services/EssityApi';
import { PaymentState } from '@services/src/models';
import { Button, Popconfirm, Tooltip } from 'antd';
import React from 'react';

interface IProps {
	refresh: () => void;
	status: PaymentState;
	paymentId: string;
}

export const FreezePayment = (props: IProps) => {
	if (props.status === PaymentState.ReadyToSend) {
		return (
			<Popconfirm
				title="Are you sure to unfreeze payment?"
				okText="Yes"
				cancelText="No"
				placement="bottom"
				onConfirm={() => {
					ajaxByUser('Successfully unfreezed payment', () =>
						getEssityApiClient().then((api) =>
							api.pharmacyTaxNotePayment
								.changeState({
									body: {
										paymentId: props.paymentId,
										paymentState: PaymentState.Created,
									},
								})
								.finally(() => {
									props.refresh();
								})
						)
					);
				}}
			>
				<Tooltip title="Unfreeze payment">
					<Button type="primary" shape="round" size="small">
						Unfreeze
					</Button>
				</Tooltip>
			</Popconfirm>
		);
	} else if (props.status === PaymentState.Created) {
		return (
			<Popconfirm
				title="Are you sure to freeze payment?"
				okText="Yes"
				cancelText="No"
				placement="bottom"
				onConfirm={() => {
					ajaxByUser('Successfully freezed payment', () =>
						getEssityApiClient().then((api) =>
							api.pharmacyTaxNotePayment
								.changeState({
									body: {
										paymentId: props.paymentId,
										paymentState: PaymentState.ReadyToSend,
									},
								})
								.finally(() => {
									props.refresh();
								})
						)
					);
				}}
			>
				<Tooltip title="Freeze payment">
					<Button type="primary" shape="round" size="small">
						Freeze
					</Button>
				</Tooltip>
			</Popconfirm>
		);
	}

	return null;
};
