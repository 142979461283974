import { ManualOperationType } from '@services/src/models';

export const valueMap = new Map<ManualOperationType, string>([
	[ManualOperationType.Payment, 'Payment'],
	[ManualOperationType.Return, 'Return'],
]);

export const map = (value: ManualOperationType): string =>
	valueMap.get(value) ?? '';

export const getManualOperationTypes = () => Array.from(valueMap);