import React from 'react';

import { OrderGroupsMenu } from './OrderGroupsMenu';
import OrderGroupsTable from './OrderGroupsTable';
import styles from './styles/OrderGroups.module.less';

export const OrderGroups = () => {
	return (
		<div className={styles.orderGroups}>
			<OrderGroupsMenu />

			<div className={styles.tableContainer}>
				<OrderGroupsTable />
			</div>
		</div>
	);
};
