import { EssityColumnProps, filterType } from '@components/shared/paginatedEssityTable/GridHelper';
import { FarmapromPharmacyDto } from '@services/src/models';

export const FarmapromPharmaciesTableColumns: EssityColumnProps<FarmapromPharmacyDto>[] = [
	{
		sorter: true,
		dataIndex: 'farmapromPharmacyId',
		key: 'farmapromPharmacyId',
		title: 'FP ID',
		filter: 'equals' as filterType,
		width: 120
	},
	{
		sorter: true,
		dataIndex: 'name',
		key: 'name',
		title: 'Pharmacy name',
		filter: 'contains' as filterType,
		width: 190
	},
	{
		sorter: true,
		dataIndex: 'city',
		key: 'city',
		title: 'City',
		filter: 'contains' as filterType,
		width: 140
	},
	{
		sorter: true,
		dataIndex: 'street',
		key: 'street',
		title: 'Street',
		filter: 'contains' as filterType,
		width: 140
	},
	{
		sorter: true,
		dataIndex: 'postalCode',
		key: 'postalCode',
		title: 'Postal code',
		filter: 'contains' as filterType,
		width: 170
	},
	{
		sorter: true,
		dataIndex: 'district',
		key: 'district',
		title: 'District',
		filter: 'equals' as filterType,
		width: 130
	},
	{
		sorter: true,
		dataIndex: 'pharmacyChain',
		key: 'pharmacyChain',
		title: 'Pharmacy chain',
		filter: 'contains' as filterType,
		width: 200
	},
	{
		sorter: true,
		dataIndex: 'kam',
		key: 'kam',
		title: 'KAM',
		filter: 'contains' as filterType,
		width: 120
	},
	{
		sorter: true,
		dataIndex: 'salesRepresentative',
		key: 'salesRepresentative',
		title: 'Sales Representative',
		filter: 'contains' as filterType,
		width: 220
	},
];
