import { ArrowDownOutlined, CloudUploadOutlined } from '@ant-design/icons';
import {
	IsFileExtensionValid,
	MimeTypes,
} from '@components/shared/file/IsFileExtensionValid';
import { DoneView } from '@components/shared/import/DoneView';
import { ExceptionView } from '@components/shared/import/ExceptionView';
import { LoadingView } from '@components/shared/import/LoadingView';
import { IsolatedModal } from '@components/shared/modals/IsolatedModal';
import { ImportStatus } from '@components/shared/types/importStatus';
import { ajaxCatch } from '@helper/api';
import formatHelpers from '@helper/formatHelpers';
import { useRootData } from '@hooks/hook';
import { getEssityApiClient } from '@services/EssityApi';
import { ImportModelImportNhfNoteTemplateOptionalParams } from '@services/src/models';
import { Button } from 'antd';
import Dragger from 'antd/lib/upload/Dragger';
import {
	RcCustomRequestOptions,
	UploadChangeParam,
	UploadFile,
} from 'antd/lib/upload/interface';
import { Formik, FormikActions, FormikProps } from 'formik';
import { FFieldLabel } from 'forms/FormikFormItems';
import { CenteredRow } from 'layout/CenteredRow';
import React, { useState } from 'react';
import { IGridStore } from 'stores/GridStore';

export const ImportNHFTemplateModal = () => {
	const [visible, setVisible] = useState<boolean>(false);

	const [file, setFile] = useState<File | undefined>();
	const [fileList, setFileList] = useState<UploadFile[] | undefined>();

	const [progressState, setProgressState] = useState<ImportStatus>(
		ImportStatus.Init
	);

	const gridStore: IGridStore = useRootData((store) => store.gridStore);

	const uploadProps = {
		name: 'file',
		multiple: false,
		customRequest: async (options: RcCustomRequestOptions) => {
			const { onSuccess, onError } = options;

			if (!IsFileExtensionValid(options, [MimeTypes.XML])) {
				onError(
					{ name: 'Error', message: 'Invalid file extension' },
					'Import failed',
					options.file
				);
				return;
			}

			setFile(options.file);
			onSuccess({}, options.file);
		},
		onChange: (info: UploadChangeParam) => {
			if (info.file.status !== 'removed') {
				setFileList([info.file]);
			}
		},
		onRemove: () => {
			setFile(undefined);
			setFileList(undefined);
		},
		progress: {
			strokeColor: {
				'0%': '#108ee9',
				'100%': '#87d068',
			},
			strokeWidth: 1,
			format: (percent: any) =>
				formatHelpers.formatPercent(percent, true),
		},
		accept: '.xml',
		showUploadList: {
			showRemoveIcon: true,
		},
	};

	const handleCancel = () => {
		setVisible(false);
		setFile(undefined);
		setFileList(undefined);
		setProgressState(ImportStatus.Init);
	};

	const handleRender = (
		props: FormikProps<ImportModelImportNhfNoteTemplateOptionalParams>
	) => {
		return (
			<IsolatedModal
				title="Import NHF Template"
				visible={visible}
				onCancel={handleCancel}
			>
				{getViewByImportStatus(props)}
			</IsolatedModal>
		);
	};

	const getViewByImportStatus = (
		props: FormikProps<ImportModelImportNhfNoteTemplateOptionalParams>
	) => {
		switch (progressState) {
			case ImportStatus.Init:
				return <AddFileView {...props} />;
			case ImportStatus.Done:
				return <DoneView />;
			case ImportStatus.Exception:
				return <ExceptionView />;
			case ImportStatus.Loading:
				return <LoadingView />;
		}
	};

	const handleCreate = (
		values: any,
		actions: FormikActions<ImportModelImportNhfNoteTemplateOptionalParams>
	) => {
		setProgressState(ImportStatus.Loading);

		ajaxCatch(
			() =>
				getEssityApiClient().then((api) =>
					api.importModel.importNhfNoteTemplate({
						file: file,
					})
				),
			() => {
				setProgressState(ImportStatus.Done);
				gridStore.searching.set(true);
				actions.setSubmitting(false);
			},
			() => {
				setProgressState(ImportStatus.Exception);
				actions.setSubmitting(false);
			}
		);
	};

	const AddFileView = (
		props: FormikProps<ImportModelImportNhfNoteTemplateOptionalParams>
	) => {
		return (
			<>
				<FFieldLabel label="XML" />

				<Dragger {...uploadProps} fileList={fileList}>
					<p className="ant-upload-drag-icon">
						<CloudUploadOutlined />
					</p>
					<p className="ant-upload-text">
						Click or drag file to this area to import
					</p>
					<p>(.xml)</p>
				</Dragger>
				<CenteredRow style={{ marginTop: '15px' }}>
					<Button
						type="primary"
						size="large"
						shape="round"
						disabled={props.isSubmitting || file === undefined}
						onClick={() => {
							if (!props.isSubmitting) {
								props.submitForm();
							}
						}}
					>
						Import template
					</Button>
				</CenteredRow>
			</>
		);
	};

	return (
		<>
			<Button
				type="primary"
				shape="round"
				size="large"
				onClick={() => setVisible(true)}
			>
				NHF Template
				<ArrowDownOutlined />
			</Button>
			<Formik
				validateOnChange
				validateOnBlur
				enableReinitialize
				isInitialValid={false}
				initialValues={{}}
				onSubmit={(
					values,
					actions: FormikActions<ImportModelImportNhfNoteTemplateOptionalParams>
				) => handleCreate(values, actions)}
				render={handleRender}
			/>
		</>
	);
};
