import { ajaxCatch } from '@helper/api';
import { PharmacyEditDto } from '@services/commandMappers/Pharmacy';
import { getEssityApiClient } from '@services/EssityApi';
import { PharmacyDto, UpdatePharmacyCommand } from '@services/src/models';
import { Col } from 'antd';
import { Formik, FormikProps } from 'formik';
import { CenteredRow } from 'layout/CenteredRow';
import React, { useEffect, useState } from 'react';

import { Contact } from '../shared/Contact';
import { GeneralData } from '../shared/GeneralData';
import { InternalData } from '../shared/InternalData';
import { Notes } from '../shared/Notes';
import { PharmacyMenu } from '../shared/PharmacyMenu';
import styles from '../styles/PharmacyEdit.module.less';

interface IProps {
	pharmacyId?: string;
}

interface IState {
	pharmacy: PharmacyDto | undefined;
}

export const PharmacyView = (props: IProps) => {
	const [state, setState] = useState<IState>({ pharmacy: undefined });
	useEffect(() => {
		if (props.pharmacyId) {
			ajaxCatch(() =>
				getEssityApiClient().then((api) =>
					api.pharmacy.getById(props.pharmacyId!).then((result) => {
						if (result) {
							setState({ pharmacy: result });
						}
					})
				)
			);
		}
	}, [props.pharmacyId]);

	return (
		<div className={styles.pharmacyEdit}>
			<PharmacyMenu pharmacy={state.pharmacy} />
			{state.pharmacy && (
				<Formik
					validateOnChange={false}
					validateOnBlur={true}
					initialValues={new PharmacyEditDto(state?.pharmacy!)}
					enableReinitialize
					validationSchema={{}}
					onSubmit={() => {}}
					render={(
						props: FormikProps<
							UpdatePharmacyCommand | PharmacyEditDto
						>
					) => (
						<CenteredRow className={styles.details}>
							<Col span={24}>
								<div className="centered-view">
									<GeneralData readOnly={true} />
								</div>
							</Col>
							<Col span={24}>
								<div className="centered-view">
									<InternalData
										readOnly={true}
										updateView={false}
										formikProps={props}
									/>
								</div>
							</Col>
							<Col span={24}>
								<div className="centered-view">
									<Contact
										readOnly={true}
										updateView={false}
										formikProps={props}
									/>
								</div>
							</Col>
							<Col span={24}>
								<div className="centered-view">
									<Notes readOnly={true} />
								</div>
							</Col>
						</CenteredRow>
					)}
				/>
			)}
		</div>
	);
};
