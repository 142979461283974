import { IsolatedContainer } from '@components/shared/IsolatedContainer';
import { IsolatedModal } from '@components/shared/modals/IsolatedModal';
import { ajaxByUser } from '@helper/api';
import { getEssityApiClient } from '@services/EssityApi';
import { VoivodeshipContactDto } from '@services/src/models';
import { Button, Modal } from 'antd';
import { Field, Formik, FormikActions } from 'formik';
import { FFieldLabel, FInput } from 'forms/FormikFormItems';
import { CenteredRow } from 'layout/CenteredRow';
import React, { useState } from 'react';

import { ContactModalValidationSchema } from './ContactModalValidationSchema';

interface IProps {
	voivodeshipId: string;
	onCreate: () => void;
}

export const ContactModal = (props: IProps) => {
	const [visible, setVisible] = useState<boolean>(false);

	const handleCreate = (
		values: VoivodeshipContactDto,
		actions: FormikActions<VoivodeshipContactDto>
	) => {
		ajaxByUser('Successfully added new contact.', () =>
			getEssityApiClient().then((api) =>
				api.voivodeshipContact
					.add({
						body: {
							voivodeshipId: props.voivodeshipId,
							phone: values.phone,
							email: values.email,
							description: values.description,
						},
					})
					.then(() => {
						props.onCreate();
						setVisible(false);
						actions.resetForm();
					})
			)
		);
	};

	const handleCancel = (actions: FormikActions<VoivodeshipContactDto>) => {
		actions.resetForm();
		setVisible(false);
	};

	const handleRender = (actions: FormikActions<VoivodeshipContactDto>) => {
		return (
			<IsolatedModal
				title="Add new contact"
				onCancel={() => handleCancel(actions)}
				visible={visible}
			>
				<FFieldLabel label="Phone" />
				<Field
					component={FInput}
					placeholder="Phone"
					name="phone"
					readOnly={false}
				/>

				<FFieldLabel label="E-mail" />
				<Field
					component={FInput}
					placeholder="E-mail"
					name="email"
					readOnly={false}
				/>

				<FFieldLabel label="Description" />
				<Field
					component={FInput}
					placeholder="Description"
					name="description"
					readOnly={false}
				/>
				<CenteredRow style={{ marginTop: '15px' }}>
					<Button
						type="primary"
						size="large"
						shape="round"
						onClick={() => actions.submitForm()}
					>
						Add contact
					</Button>
				</CenteredRow>
			</IsolatedModal>
		);
	};

	return (
		<>
			<Button
				size="small"
				type="primary"
				shape="round"
				onClick={() => {
					setVisible(true);
				}}
			>
				Add contact
			</Button>
			<Formik
				validateOnChange
				validateOnBlur
				enableReinitialize
				isInitialValid={false}
				initialValues={{}}
				validationSchema={ContactModalValidationSchema}
				onSubmit={(
					values,
					actions: FormikActions<VoivodeshipContactDto>
				) => handleCreate(values, actions)}
				render={handleRender}
			/>
		</>
	);
};
