import { IsolatedModal } from '@components/shared/modals/IsolatedModal';
import { Col, Input } from 'antd';
import { Formik } from 'formik';
import { FFieldLabel } from 'forms/FormikFormItems';
import { CenteredRow } from 'layout/CenteredRow';
import { User } from 'oidc-client';
import React from 'react';

interface IProps {
	user: User;
	visible: boolean;
	setVisible: (state: boolean) => void;
}

export const MyProfile = (componentProps: IProps) => {
	const handleCancel = () => {
		componentProps.setVisible(false);
	};

	return (
		<IsolatedModal
			title="My profile"
			onCancel={handleCancel}
			visible={componentProps.visible}
		>
			<Formik
				validateOnChange={false}
				validateOnBlur={true}
				initialValues={{}}
				enableReinitialize
				validationSchema={{}}
				onSubmit={() => {}}
				render={() => (
					<CenteredRow>
						<Col span={24}>
							<FFieldLabel label="Full name" />
							<Input
								value={`${componentProps.user.profile.given_name} ${componentProps.user.profile.family_name}`}
								readOnly
							/>
						</Col>
						<Col span={24}>
							<FFieldLabel label="Email" />
							<Input
								value={componentProps.user.profile.emails[0]}
								readOnly
							/>
						</Col>
					</CenteredRow>
				)}
			></Formik>
		</IsolatedModal>
	);
};
