import { IsolatedContainer } from '@components/shared/IsolatedContainer';
import { IsolatedModal } from '@components/shared/modals/IsolatedModal';
import { MissingPharmacyDto } from '@services/src/models';
import { Modal } from 'antd';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';

import { ImportFarmapromValidationTable } from './ImportFarmapromValidationTable';

interface IProps {
	validation: MissingPharmacyDto[] | undefined;
}

export const ImportFarmapromValidationModal = (props: IProps) => {
	const [visible, setVisible] = useState<boolean>(false);

	useEffect(() => {
		if (props.validation) {
			setVisible(true);
		}
	}, [props.validation]);

	const handleCancel = () => {
		setVisible(false);
	};

	return (
		<>
			{visible && (
				<IsolatedModal
					title="FP file validation"
					visible={visible}
					onCancel={handleCancel}
				>
					<>
						<h2>Missing FP ID:</h2>
						<ImportFarmapromValidationTable
							validation={props.validation}
						/>
					</>
				</IsolatedModal>
			)}
		</>
	);
};
