import { EssityColumnProps } from '@components/shared/paginatedEssityTable/GridHelper';
import formatHelpers from '@helper/formatHelpers';
import { ManualOperationType } from '@services/src/models';
import * as ManualOperationTypeMapper from '@services/mappers/ManualOperationType';


export const ManualOperationsTableColumns: EssityColumnProps<any>[] = [
	{
		dataIndex: 'manualOperationType',
		key: 'manualOperationType',
		title: 'Type',
		width: 150,
		render: (value: ManualOperationType) => ManualOperationTypeMapper.map(value),
	},
	{
		dataIndex: 'value',
		key: 'value',
		title: 'Value',
		width: 150,
	},
	{
		dataIndex: 'date',
		key: 'date',
		title: 'Date',
		width: 150,
		render: (value?: Date) =>
			formatHelpers.formatDate(value, 'DD.MM.YYYY, HH:mm:ss'),
	},
];
