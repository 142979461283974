/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from "@azure/ms-rest-js";
import * as Models from "../models";
import * as Mappers from "../models/voivodeshipContactMappers";
import * as Parameters from "../models/parameters";
import { PolandPharmacyRxAPIContext } from "../polandPharmacyRxAPIContext";

/** Class representing a VoivodeshipContact. */
export class VoivodeshipContact {
  private readonly client: PolandPharmacyRxAPIContext;

  /**
   * Create a VoivodeshipContact.
   * @param {PolandPharmacyRxAPIContext} client Reference to the service client.
   */
  constructor(client: PolandPharmacyRxAPIContext) {
    this.client = client;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.VoivodeshipContactGetContactsResponse>
   */
  getContacts(options?: Models.VoivodeshipContactGetContactsOptionalParams): Promise<Models.VoivodeshipContactGetContactsResponse>;
  /**
   * @param callback The callback
   */
  getContacts(callback: msRest.ServiceCallback<Models.VoivodeshipContactDto[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getContacts(options: Models.VoivodeshipContactGetContactsOptionalParams, callback: msRest.ServiceCallback<Models.VoivodeshipContactDto[]>): void;
  getContacts(options?: Models.VoivodeshipContactGetContactsOptionalParams | msRest.ServiceCallback<Models.VoivodeshipContactDto[]>, callback?: msRest.ServiceCallback<Models.VoivodeshipContactDto[]>): Promise<Models.VoivodeshipContactGetContactsResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      getContactsOperationSpec,
      callback) as Promise<Models.VoivodeshipContactGetContactsResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  add(options?: Models.VoivodeshipContactAddOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  add(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  add(options: Models.VoivodeshipContactAddOptionalParams, callback: msRest.ServiceCallback<void>): void;
  add(options?: Models.VoivodeshipContactAddOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      addOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  deleteMethod(options?: Models.VoivodeshipContactDeleteMethodOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  deleteMethod(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteMethod(options: Models.VoivodeshipContactDeleteMethodOptionalParams, callback: msRest.ServiceCallback<void>): void;
  deleteMethod(options?: Models.VoivodeshipContactDeleteMethodOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      deleteMethodOperationSpec,
      callback);
  }
}

// Operation Specifications
const serializer = new msRest.Serializer(Mappers);
const getContactsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "contacts/VoivodeshipContact",
  queryParameters: [
    Parameters.voivodeshipId2
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "VoivodeshipContactDto"
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const addOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "contacts/VoivodeshipContact/add",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.AddVoivodeshipContactCommand
  },
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const deleteMethodOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "contacts/VoivodeshipContact/delete",
  queryParameters: [
    Parameters.contactId
  ],
  responses: {
    200: {},
    default: {}
  },
  serializer
};
