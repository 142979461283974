import { EssityColumnProps, filterType } from '@components/shared/paginatedEssityTable/GridHelper';
import { ProductPriceDto } from '@services/src/models';

export const ProductPricesTableColumns: EssityColumnProps<ProductPriceDto>[] = [
	{
		dataIndex: 'id',
		key: 'id',
		title: 'ID',
		width: 150,
		filter: 'equals' as filterType,
		hidden: true
	},
	{
		dataIndex: 'productNHFCode',
		key: 'productNHFCode',
		title: 'NHF Code',
		width: 150,
		filter: 'equals' as filterType,
	},
	{
		dataIndex: 'productVersionNFZCode',
		key: 'productVersionNFZCode',
		title: 'Product code',
		width: 150,
		filter: 'equals' as filterType,
	},
	{
		dataIndex: 'productName',
		key: 'productName',
		title: 'Name',
		width: 150
	},
	{
		dataIndex: 'model',
		key: 'model',
		title: 'Model',
		width: 150,
		filter: 'contains' as filterType,
	},
	{
		dataIndex: 'price',
		key: 'price',
		title: 'Price',
		width: 150,
	},
	{
		dataIndex: 'producent',
		key: 'producent',
		title: 'Producer',
		width: 150,
		filter: 'contains' as filterType,
	},
];
