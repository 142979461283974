import styles from './styles/Departments.module.less';

import { ajaxByUser, ajaxCatch } from '@helper/api';
import { DepartmentEditDto } from '@services/commandMappers/Department';
import { getEssityApiClient } from '@services/EssityApi';
import {
	UpdateVoivodeshipCommand,
	VoivodeshipDetailsDto,
} from '@services/src/models';
import { Button, Col, Divider, Select, Space, Spin } from 'antd';
import { Formik, FormikActions, FormikErrors, FormikProps } from 'formik';
import React, { useEffect, useState } from 'react';

import { ContactsTable } from './contacts/ContactsTable';
import { EditableData } from './EditableData';
import { InformationData } from './InformationData';

export const Departments = () => {
	const [
		selectedVoivodeshipId,
		setSelectedVoivodeshipId,
	] = useState<string>();

	const [department, setDepartment] = useState<VoivodeshipDetailsDto>();
	const [inEditMode, setInEditMode] = useState<boolean>(false);
	const [allDepartments, setAllDepartments] = useState<
		VoivodeshipDetailsDto[]
	>();
	const [isBusy, setIsBusy] = useState<boolean>(false);

	const refreshDepartment = (selectedVoivodeshipId: string | undefined) => {
		if (selectedVoivodeshipId) {
			setIsBusy(true);
			ajaxCatch(() =>
				getEssityApiClient().then((api) =>
					api.voivodeship
						.getById(selectedVoivodeshipId)
						.then((result) => {
							if (result) {
								setDepartment(result);
							}
						})
						.finally(() => setIsBusy(false))
				)
			);
		}
	};

	useEffect(() => {
		refreshDepartment(selectedVoivodeshipId);
	}, [selectedVoivodeshipId]);

	useEffect(() => {
		setIsBusy(true);
		ajaxCatch(() =>
			getEssityApiClient().then((api) =>
				api.voivodeship
					.all()
					.then((result) => {
						if (result) {
							setAllDepartments(result);
						}
					})
					.finally(() => setIsBusy(false))
			)
		);
	}, []);

	const UpdateDepartment = (
		values: DepartmentEditDto,
		actions: FormikActions<UpdateVoivodeshipCommand | DepartmentEditDto>
	) => {
		const command = values.toUpdateCommand();

		ajaxByUser(
			'Updated NHF department',
			() =>
				getEssityApiClient().then((api) =>
					api.voivodeship.update({ body: command })
				),
			() => {
				actions.resetForm();
				refreshDepartment(selectedVoivodeshipId);
			},
			(errors: FormikErrors<UpdateVoivodeshipCommand>) => {
				actions.setErrors(errors);
			}
		);
	};

	return (
		<Spin tip="Loading..." spinning={isBusy}>
			<Divider />
			<div className={styles.departments}>
				<Space direction="vertical">
					<h2>NHF Departments</h2>
					<div className={styles.filters}>
						<div id="Voivodeship" className={styles.item}>
							Voivodeships
							<Select
								mode={undefined}
								size="small"
								placeholder="Click here to filter"
								onChange={(item: string) => {
									setSelectedVoivodeshipId(item);
									setInEditMode(false);
								}}
							>
								{allDepartments?.map(
									(item: VoivodeshipDetailsDto) => {
										return (
											<Select.Option
												key={item.id}
												value={item.id!}
											>
												{item.voivodeshipName}
											</Select.Option>
										);
									}
								)}
							</Select>
						</div>
					</div>

					{department && (
						<>
							<InformationData department={department} />

							{department?.id && (
								<ContactsTable voivodeshipId={department.id} />
							)}

							<h2>General data</h2>
							<Formik
								validateOnChange={false}
								validateOnBlur={true}
								initialValues={
									new DepartmentEditDto(department)
								}
								enableReinitialize
								validationSchema={{}}
								onSubmit={(
									values: DepartmentEditDto,
									actions: FormikActions<
										| UpdateVoivodeshipCommand
										| DepartmentEditDto
									>
								) => {
									if (selectedVoivodeshipId) {
										UpdateDepartment(values, actions);
									}
								}}
								render={(props: FormikProps<any>) => (
									<>
										<EditableData
											readOnly={!inEditMode}
											contracts={
												department.voivodeshipContracts
											}
										/>
										<Col span={15}>
											<Button
												className={styles.createButton}
												type="primary"
												shape="round"
												size="large"
												onClick={() => {
													if (inEditMode) {
														props.submitForm();
														setInEditMode(false);
													} else {
														setInEditMode(true);
													}
												}}
											>
												{inEditMode ? 'Save' : 'Edit'}
											</Button>
										</Col>
									</>
								)}
							/>
						</>
					)}
				</Space>
			</div>
		</Spin>
	);
};
