import { LockTwoTone, UnlockTwoTone } from '@ant-design/icons';
import { ajaxByUser } from '@helper/api';
import { useRootData } from '@hooks/hook';
import { getEssityApiClient } from '@services/EssityApi';
import { DictionaryDto, DictionaryValueDto } from '@services/src/models';
import { Button, Select, Tooltip } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { IAccountDetailsStore } from 'stores/AccountDetailsStore';
import { IGridStore } from 'stores/GridStore';

import styles from './QuickFilters.module.less';

interface IProps {
	dictionary: DictionaryDto | undefined;
	grids: string[];
}

const SettlementPeriod = (props: IProps) => {
	const gridStore: IGridStore = useRootData((store) => store.gridStore);

	const accountDetailsStore: IAccountDetailsStore = useRootData(
		(store) => store.accountDetailsStore
	);

	const loadValueFromMemory = () => {
		const memoryValue = accountDetailsStore.account.get()?.settings
			?.settlementPeriodFilterDictionaryValueCodeName;

		if (memoryValue) {
			accountDetailsStore.settings.set({
				...accountDetailsStore.settings.get(),
				settlementPeriodFilterDictionaryValueCodeName: memoryValue,
			});
			handleFilterChange(memoryValue);
		}
	};

	useEffect(() => {
		loadValueFromMemory();
	}, [accountDetailsStore.account.get()]);

	const handleFilterChange = (value: string) => {
		accountDetailsStore.settings.set({
			...accountDetailsStore.settings.get(),
			settlementPeriodFilterDictionaryValueCodeName: value,
		});
		gridStore.searching.set(true);
	};

	const hangeLockStatus = () => {
		const settings = accountDetailsStore.settings.get();

		var locked = settings.filtersLocked;

		accountDetailsStore.settings.set({
			...settings,
			filtersLocked: !locked,
		});

		if (!locked) {
			ajaxByUser('Successfully saved filters', () =>
				getEssityApiClient().then((api) =>
					api.account
						.saveSettings({
							body: {
								voivodeshipFilterDictionaryIds:
									settings.voivodeshipFilterDictionaryIds,
								settlementPeriodFilterDictionaryValueCodeName:
									settings.settlementPeriodFilterDictionaryValueCodeName,
							},
						})
						.then(() => {
							accountDetailsStore.initialize();
						})
				)
			);
		}
	};

	const getLockButton = () => {
		const value = accountDetailsStore.settings.get().filtersLocked;

		return value ? (
			<Tooltip title="Click to unlock">
				<Button
					shape="circle"
					type="text"
					size="small"
					icon={<LockTwoTone twoToneColor="#FF0000" />}
					onClick={hangeLockStatus}
				/>
			</Tooltip>
		) : (
			<Tooltip title="Click to lock">
				<Button
					shape="circle"
					type="text"
					size="small"
					icon={<UnlockTwoTone twoToneColor="#52c41a" />}
					onClick={hangeLockStatus}
				/>
			</Tooltip>
		);
	};

	return (
		<div className={`${styles.item} ${styles.settlementPeriod}`}>
			Settlement period
			<Select
				size="small"
				onChange={handleFilterChange}
				value={
					accountDetailsStore.settings.get()
						.settlementPeriodFilterDictionaryValueCodeName
				}
				placeholder="Click here to filter"
				disabled={accountDetailsStore.settings.get().filtersLocked}
			>
				{props.dictionary?.values?.map((item: DictionaryValueDto) => {
					return (
						<Select.Option key={item.id} value={item.codeName!}>
							{item.value}
						</Select.Option>
					);
				})}
			</Select>
			{getLockButton()}
		</div>
	);
};

export default observer(SettlementPeriod);
