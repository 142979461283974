import React from 'react';

interface IProps {}

export const IsolatedContainer: React.FC<IProps> = ({ children }) => {
	return (
		<div
			onDoubleClick={(event: React.MouseEvent) => {
				event.stopPropagation();
			}}
		>
			{children}
		</div>
	);
};
