import * as Yup from 'yup';

export const LossNoteValidationSchema = () =>
	Yup.object().shape({
		pharmacyNote: Yup.string()
			.nullable()
			.required('Pharmacy note is required.'),
		noteDate: Yup.date().required('Note date is required.'),
		noteValue: Yup.string().nullable().required('Loss value is required.'),
	});

export const LossNoteEditValidationSchema = () =>
	Yup.object().shape({
		noteDate: Yup.date().required('Note date is required.'),
		noteValue: Yup.string().nullable().required('Loss value is required.'),
	});
