import styles from '../styles/PharmacyEdit.module.less';

import { ajaxByUser, ajaxCatch } from '@helper/api';
import { PharmacyEditDto } from '@services/commandMappers/Pharmacy';
import { getEssityApiClient } from '@services/EssityApi';
import {
	CreatePharmacyCommand,
	PharmacyCreateResponse,
	PharmacyDto,
	UpdatePharmacyCommand,
} from '@services/src/models';
import { Button, Col, Space } from 'antd';
import { Formik, FormikActions, FormikErrors, FormikProps } from 'formik';
import { CenteredRow } from 'layout/CenteredRow';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { Contact } from '../shared/Contact';
import { GeneralData } from '../shared/GeneralData';
import { InternalData } from '../shared/InternalData';
import { Notes } from '../shared/Notes';
import { PharmacyMenu } from '../shared/PharmacyMenu';
import { PharmacyValidationSchema } from '../validation/PharmacyValidationSchema';

interface IProps {
	pharmacyId?: string;
}

interface IState {
	pharmacy: PharmacyDto | undefined;
}

export const PharmacyEdit = (props: IProps) => {
	const [state, setState] = useState<IState>({ pharmacy: undefined });
	const history = useHistory();

	const refreshPharmacy = (pharmacyId: string | undefined) => {
		if (pharmacyId) {
			ajaxCatch(() =>
				getEssityApiClient().then((api) =>
					api.pharmacy.getById(pharmacyId).then((result) => {
						if (result) {
							setState({ pharmacy: result });
						}
					})
				)
			);
		}
	};

	useEffect(() => {
		refreshPharmacy(props.pharmacyId);
	}, [props.pharmacyId]);

	const CreatePharmacy = (
		values: PharmacyEditDto,
		actions: FormikActions<CreatePharmacyCommand | PharmacyEditDto>
	): void => {
		const command = values.toCreateCommand();

		ajaxByUser(
			'Created new pharmacy',
			() =>
				getEssityApiClient()
					.then((api) => api.pharmacy.create({ body: command }))
					.then((result: PharmacyCreateResponse) => {
						history.push(`/pharmacies/edit/${result.id}`);
					}),
			() => {
				actions.resetForm();
			},
			(errors: FormikErrors<CreatePharmacyCommand>) => {
				actions.setErrors(errors);
			}
		);
	};

	const UpdatePharmacy = (
		values: PharmacyEditDto,
		actions: FormikActions<UpdatePharmacyCommand | PharmacyEditDto>
	): void => {
		const command = values.toUpdateCommand();

		ajaxByUser(
			'Updated pharmacy',
			() =>
				getEssityApiClient().then((api) =>
					api.pharmacy.update({ body: command })
				),
			() => {
				actions.resetForm();
				refreshPharmacy(props.pharmacyId);
			},
			(errors: FormikErrors<UpdatePharmacyCommand>) => {
				actions.setErrors(errors);
			}
		);
	};

	const CreateView = () => {
		return (
			<Formik
				validateOnChange={false}
				validateOnBlur={true}
				initialValues={new PharmacyEditDto(undefined)}
				enableReinitialize
				validationSchema={PharmacyValidationSchema}
				onSubmit={(
					values: PharmacyEditDto,
					actions: FormikActions<
						| CreatePharmacyCommand
						| UpdatePharmacyCommand
						| PharmacyEditDto
					>
				) => {
					if (props.pharmacyId) {
						UpdatePharmacy(values, actions);
					} else {
						CreatePharmacy(values, actions);
					}
				}}
				render={(
					props: FormikProps<
						| CreatePharmacyCommand
						| UpdatePharmacyCommand
						| PharmacyEditDto
					>
				) => (
					<CenteredRow className={styles.details}>
						<Col span={24}>
							<div className="centered-view">
								<GeneralData readOnly={false} />
							</div>
						</Col>
						<Col span={24}>
							<div className="centered-view">
								<InternalData
									updateView={false}
									readOnly={false}
									formikProps={props}
								/>
							</div>
						</Col>
						<Col span={24}>
							<div className="centered-view">
								<Contact
									readOnly={false}
									updateView={false}
									formikProps={props}
								/>
							</div>
						</Col>
						<Col span={24}>
							<div className="centered-view">
								<Space direction="vertical">
									<Notes readOnly={false} />
									<Button
										className={styles.createButton}
										type="primary"
										shape="round"
										size="large"
										onClick={() => {
											props.submitForm();
										}}
									>
										Save
									</Button>
								</Space>
							</div>
						</Col>
					</CenteredRow>
				)}
			/>
		);
	};

	const UpdateView = () => {
		return (
			<>
				{state.pharmacy && (
					<Formik
						validateOnChange={false}
						validateOnBlur={true}
						initialValues={new PharmacyEditDto(state?.pharmacy)}
						enableReinitialize
						validationSchema={PharmacyValidationSchema}
						onSubmit={(
							values: PharmacyEditDto,
							actions: FormikActions<
								| CreatePharmacyCommand
								| UpdatePharmacyCommand
								| PharmacyEditDto
							>
						) => {
							if (props.pharmacyId) {
								UpdatePharmacy(values, actions);
							} else {
								CreatePharmacy(values, actions);
							}
						}}
						render={(
							props: FormikProps<
								| CreatePharmacyCommand
								| UpdatePharmacyCommand
								| PharmacyEditDto
							>
						) => (
							<CenteredRow className={styles.details}>
								<Col span={24}>
									<div className="centered-view">
										<GeneralData readOnly={false} />
									</div>
								</Col>
								<Col span={24}>
									<div className="centered-view">
										<InternalData
											updateView={true}
											readOnly={false}
											formikProps={props}
										/>
									</div>
								</Col>
								<Col span={24}>
									<div className="centered-view">
										<Contact
											readOnly={false}
											updateView={true}
											formikProps={props}
										/>
									</div>
								</Col>
								<Col span={24}>
									<div className="centered-view">
										<Space direction="vertical">
											<Notes readOnly={false} />
											<Button
												className={styles.createButton}
												type="primary"
												shape="round"
												size="large"
												onClick={() => {
													props.submitForm();
												}}
											>
												Save
											</Button>
										</Space>
									</div>
								</Col>
							</CenteredRow>
						)}
					/>
				)}
			</>
		);
	};

	return (
		<div className={styles.pharmacyEdit}>
			<PharmacyMenu pharmacy={state.pharmacy} />

			{props.pharmacyId ? <UpdateView /> : <CreateView />}
		</div>
	);
};
