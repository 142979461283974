import { useRootData } from '@hooks/hook';
import { BusinessObjectsState } from '@services/src/models';
import { Checkbox, Radio, Space, Switch } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { RadioChangeEvent } from 'antd/lib/radio';
import React from 'react';
import { IGlobalSearchStore } from 'stores/GlobalSearchStore';
import { IGridStore } from 'stores/GridStore';

import styles from './styles/PharmaciesNoteGroups.module.less';

interface IProps {}

export const PharmaciesNoteGroupsFilters = (props: IProps) => {
	const gridStore: IGridStore = useRootData((store) => store.gridStore);

	const globalSearchStore: IGlobalSearchStore = useRootData(
		(store) => store.globalSearchStore
	);

	const onFilter = (value: BusinessObjectsState) => {
		gridStore.searching.set(true);
		globalSearchStore.pharmacyPaymentsFilter.set({
			...globalSearchStore.pharmacyPaymentsFilter.get(),
			businessObjectsState: value,
		});
	};

	return (
		<Space>
			<Radio.Group
				onChange={(e: RadioChangeEvent) => onFilter(e.target.value)}
				className={styles.filters}
				defaultValue={
					globalSearchStore.pharmacyPaymentsFilter.get()
						.businessObjectsState
				}
			>
				<Radio value={BusinessObjectsState.CorrectOnly}>
					correct only
				</Radio>
				<Radio value={BusinessObjectsState.NotCorrectOnly}>
					incorrect only
				</Radio>
				<Radio value={BusinessObjectsState.All}>all</Radio>
			</Radio.Group>
			<Checkbox
				onChange={(event: CheckboxChangeEvent) => {
					gridStore.searching.set(true);

					globalSearchStore.pharmacyPaymentsFilter.set({
						...globalSearchStore.pharmacyPaymentsFilter.get(),
						zeroBalance: event.target.checked,
					});
				}}
				defaultChecked={
					globalSearchStore.pharmacyPaymentsFilter.get().zeroBalance
				}
			>
				Show balance 0
			</Checkbox>
		</Space>
	);
};
