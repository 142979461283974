import { getEssityApiClient } from '@services/EssityApi';
import { TemplateNoteInfoDto } from '@services/src/models';
import React, { useEffect, useState } from 'react';

import styles from '../styles/NHFSettlementsOrders.module.less';
import { NHFSettlementsOrdersMenu } from './NHFSettlementsOrdersMenu';
import { NHFSettlementsOrdersTable } from './NHFSettlementsOrdersTable';

interface IProps {
	templateId: string;
}

export const NHFSettlementsOrders = (props: IProps) => {
	const [template, setTemplate] = useState<TemplateNoteInfoDto>();

	useEffect(() => {
		if (props.templateId) {
			(async () => {
				const api = await getEssityApiClient();
				const result = await api.templateNote.getTemplateNoteInfo({
					id: props.templateId,
				});
				setTemplate(result);
			})();
		}
	}, [props.templateId]);

	return (
		<div>
			<NHFSettlementsOrdersMenu />
			<div className={styles.tableHeader}>
				<p>Template No,Note No: {template?.templateId},{template?.noteName} includes following orders:</p>
			</div>

			<div className={styles.tableContainer}>
				<NHFSettlementsOrdersTable templateId={props.templateId} />
			</div>
		</div>
	);
};
