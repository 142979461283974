/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from "@azure/ms-rest-js";
import * as Models from "../models";
import * as Mappers from "../models/orderGroupMappers";
import * as Parameters from "../models/parameters";
import { PolandPharmacyRxAPIContext } from "../polandPharmacyRxAPIContext";

/** Class representing a OrderGroup. */
export class OrderGroup {
  private readonly client: PolandPharmacyRxAPIContext;

  /**
   * Create a OrderGroup.
   * @param {PolandPharmacyRxAPIContext} client Reference to the service client.
   */
  constructor(client: PolandPharmacyRxAPIContext) {
    this.client = client;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.OrderGroupAllResponse>
   */
  all(options?: Models.OrderGroupAllOptionalParams): Promise<Models.OrderGroupAllResponse>;
  /**
   * @param callback The callback
   */
  all(callback: msRest.ServiceCallback<Models.OrderGroupDto>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  all(options: Models.OrderGroupAllOptionalParams, callback: msRest.ServiceCallback<Models.OrderGroupDto>): void;
  all(options?: Models.OrderGroupAllOptionalParams | msRest.ServiceCallback<Models.OrderGroupDto>, callback?: msRest.ServiceCallback<Models.OrderGroupDto>): Promise<Models.OrderGroupAllResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      allOperationSpec,
      callback) as Promise<Models.OrderGroupAllResponse>;
  }
}

// Operation Specifications
const serializer = new msRest.Serializer(Mappers);
const allOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "orderGroups/OrderGroup",
  queryParameters: [
    Parameters.filters,
    Parameters.sorts,
    Parameters.page,
    Parameters.pageSize,
    Parameters.settlementPeriodFilterCodeName,
    Parameters.selectedIds
  ],
  responses: {
    200: {
      bodyMapper: Mappers.OrderGroupDto
    },
    default: {}
  },
  serializer
};
