import { PartialRecord } from '@helper/typeScriptUtilities';
import { SorterResult } from 'antd/lib/table/interface';
import { filterType } from 'components/shared/paginatedEssityTable/GridHelper';
import { action, observable } from 'mobx';
import React, { Key } from 'react';

import { AntPagination } from '../components/shared/paginatedEssityTable/SieveModels';
import { GridNameType } from './TableColumnStore';

export interface IFilter {
	filterType?: filterType;
	value: any;
}

const filters = observable(
	new Map<string, Map<React.Key, IFilter | undefined>>()
);
const filterGC = observable(new Map<string, Map<React.Key, boolean>>());
const paginations = new Map<string, AntPagination>();
const sorters = new Map<string, any>();

export const gridStore = {
	filters: filters,
	filtersGC: filterGC,
	paginations: paginations,
	sorters: observable(sorters),
	searching: observable.box<boolean>(false),
	labels: observable.box<PartialRecord<GridNameType, string[]>>({}),
	selectedKeys: observable(new Array<string | number>()),
	selectedValues: observable(new Array<any>()),
	excludedKeys: observable(new Array<string | number>()),
	excludedValues: observable(new Array<any | number>()),
	allRowsSelected: observable.box<boolean>(false),
	refreshDictionaryVersions: observable.box<boolean>(false),
	backTab: observable.box<string | undefined>(),
	clearSearch: observable.box<number>(),
	clearDictionariesVersionsMeta: observable.box<number>(),
	clearFormsVersionsMeta: observable.box<number>(),

	saveColumnFilter: action(function (
		gridName: string,
		columnKey: React.Key,
		value: any,
		filter?: filterType
	) {
		let grid = gridStore.filters.get(gridName);
		if (!grid) {
			let gridFilters = new Map<React.Key, IFilter>();
			gridFilters.set(columnKey, { value: value, filterType: filter });
			filters.set(gridName, gridFilters);
		} else {
			grid.set(columnKey, { value: value, filterType: filter });
		}
	}),

	saveColumnFilterForMultipleGrids: action(
		(
			gridNames: string[],
			columnKey: React.Key,
			value: any,
			filter?: filterType
		) => {
			gridNames.forEach((gridName) => {
				const grid = gridStore.filters.get(gridName);
				if (!grid) {
					const gridFilters = new Map<React.Key, IFilter>();
					gridFilters.set(columnKey, {
						value: value,
						filterType: filter,
					});
					filters.set(gridName, gridFilters);
				} else {
					grid.set(columnKey, { value: value, filterType: filter });
				}
			});
		}
	),

	getColumnFilter: function (
		gridName: string,
		columnKey: React.Key
	): IFilter | undefined {
		let grid = gridStore.filters.get(gridName);

		if (!grid) {
			return undefined;
		}
		return grid.get(columnKey);
	},

	clearColumnFilter: action(function (gridName: string, columnKey: Key) {
		let grid = gridStore.filters.get(gridName);

		if (grid) {
			grid.set(columnKey, undefined);
		}
	}),

	clearColumnFilterForMultipleGrids: action(
		(gridNames: string[], columnKey: Key) => {
			gridNames.forEach((gridName) => {
				const grid = gridStore.filters.get(gridName);

				if (grid) {
					grid.set(columnKey, undefined);
				}
			});
		}
	),

	getGridFilters: function (
		gridName: string
	): Map<React.Key, IFilter | undefined> | undefined {
		return gridStore.filters.get(gridName);
	},

	resetGrid: function (gridName: string) {
		gridStore.filters.delete(gridName);
		gridStore.clearGridPagination(gridName);
		gridStore.clearGridSorter(gridName);
		gridStore.labels.set({});
	},

	saveGridPagination: function (gridName: string, pagination: AntPagination) {
		gridStore.paginations.set(gridName, pagination);
	},

	getGridPagination: function (gridName: string): AntPagination {
		let saved = gridStore.paginations.get(gridName);
		if (saved) return saved;

		return new AntPagination();
	},

	clearGridPagination: function (gridName: string): void {
		let pagination = gridStore.paginations.get(gridName);
		if (pagination) {
			pagination.current = 1;
			gridStore.paginations.set(gridName, pagination);
		}
	},

	saveGridSorter: function (gridName: string, antSorter: any) {
		gridStore.sorters.set(gridName, antSorter);
	},

	getGridSorter: function (gridName: string): SorterResult<any> {
		return gridStore.sorters.get(gridName);
	},

	clearGridSorter: function (gridName: string) {
		gridStore.sorters.delete(gridName);
	},

	clearGridFilter: function (gridName: string) {
		gridStore.filters.delete(gridName);
	},

	getFilterGCStatus: function (
		gridName: string,
		columnKey: React.Key
	): boolean {
		const tableFiltersGC = gridStore.filtersGC.get(gridName);
		return tableFiltersGC?.get(columnKey) ?? false;
	},
	markFilterVisibleChange: function (
		gridName: string,
		columnKey: React.Key,
		status: boolean
	) {
		const tableFiltersGCGrid = gridStore.filtersGC.get(gridName);

		if (!tableFiltersGCGrid) {
			let gridFiltersGC = new Map<React.Key, boolean>();
			gridFiltersGC.set(columnKey, status);
			gridStore.filtersGC.set(gridName, gridFiltersGC);
		} else {
			tableFiltersGCGrid?.set(columnKey, status);
		}
	},

	saveGridPageSize: function (gridName: string, pageSize: number) {
		const currentGrid = gridStore.getGridPagination(gridName);
		currentGrid.pageSize = pageSize;
		gridStore.saveGridPagination(gridName, currentGrid);
	},

	setSelectedKeys: (keys: Array<string | number>) => {
		gridStore.selectedKeys.replace(keys);
	},

	setExcludedKeys: (keys: Array<string | number>) => {
		gridStore.excludedKeys.replace(keys);
	},

	getSelectedKeys: (): Array<string | number> => [...gridStore.selectedKeys],

	getExcludedKeys: (): Array<string | number> => [...gridStore.excludedKeys],

	setSelectedValues: (values: Array<any>) => {
		gridStore.selectedValues.replace(values);
	},

	setExcludedValues: (values: Array<any>) => {
		gridStore.excludedValues.replace(values);
	},

	getSelectedValues: (): Array<any> => [...gridStore.selectedValues],

	getExcludedValues: (): Array<any> => [...gridStore.excludedValues],

	toggleAllRowsSelected: action(
		(callback: (allSelected: boolean) => void) => {
			const toggledSelection = !gridStore.allRowsSelected.get();
			gridStore.allRowsSelected.set(toggledSelection);

			callback(toggledSelection);
		}
	),

	clearSelection: () => {
		gridStore.allRowsSelected.set(false);
		gridStore.setSelectedKeys([]);
		gridStore.setExcludedKeys([]);
	},
	setRefreshDictionaryVersion: (value: boolean) =>
		gridStore.refreshDictionaryVersions.set(value),
	getRefreshdictionaryVersion: () =>
		gridStore.refreshDictionaryVersions.get(),

	setLabels: (table: GridNameType, labels: string[] | undefined) => {
		const draftLabels = gridStore.labels.get();
		draftLabels[table] = labels;
		gridStore.labels.set(draftLabels);
	},
	getLabels: (table: GridNameType) => {
		const observableLabels = gridStore.labels.get()[table];
		if (!observableLabels?.length) return undefined;
		return [...observableLabels];
	},
	clearLabels: () => {
		gridStore.labels.set({});
	},
	setBackTab: (tab: string | undefined) => {
		gridStore.backTab.set(tab);
	},
	getBackTab: () => gridStore.backTab.get(),
};

export const createGridStore = () => {
	return gridStore;
};

export type IGridStore = ReturnType<typeof createGridStore>;
