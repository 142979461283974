import { Button } from 'antd';
import React from 'react';
import { useHistory } from 'react-router';

interface IProps {
	pharmacyId?: string;
}

export const PharmaciesNoteGroupsAddNote = (props: IProps) => {
	const history = useHistory();

	return (
		<Button
			type="primary"
			shape="round"
			size="large"
			onClick={() => {
				if (props.pharmacyId) {
					history.push(`/pharmacyNote/create/${props.pharmacyId}`);
				} else {
					history.push(`/pharmacyNote/create/${props.pharmacyId}`);
				}
			}}
		>
			Add Pharmacy note
		</Button>
	);
};
