import { IsolatedModal } from '@components/shared/modals/IsolatedModal';
import { ajaxByUser } from '@helper/api';
import { getEssityApiClient } from '@services/EssityApi';
import { getManualOperationTypes } from '@services/mappers/ManualOperationType';
import { CreatePharmacyTaxNoteGroupManualOperationCommand } from '@services/src/models';
import { Button, Select } from 'antd';
import { Field, Formik, FormikActions, FormikProps } from 'formik';
import {
	FDatePicker,
	FFieldLabel,
	FInputNumber,
	FSelect,
} from 'forms/FormikFormItems';
import { CenteredRow } from 'layout/CenteredRow';
import moment from 'moment';
import React, { useState } from 'react';

import { ManualOperationValidationSchema } from './ManualOperationValidationSchema';

interface IProps {
	refresh: () => void;
	pharmacyTaxNoteGroupId: string;
}

export const AddManualOperationModal = (props: IProps) => {
	const [visible, setVisible] = useState<boolean>();
	const [isBusy, setIsBusy] = useState<boolean>();

	const handleCancel = () => {
		setVisible(false);
	};

	const handleCreate = (
		values: CreatePharmacyTaxNoteGroupManualOperationCommand,
		actions: FormikActions<CreatePharmacyTaxNoteGroupManualOperationCommand>
	) => {
		setIsBusy(true);
		ajaxByUser('Successfully registred new operation.', () =>
			getEssityApiClient().then((api) =>
				api.pharmacyTaxNoteGroupManualOperation
					.create({
						body: {
							date: moment(values.date).utc(true).toDate(),
							value: values.value,
							pharmacyTaxNoteGroupId:
								props.pharmacyTaxNoteGroupId,
							manualOperationType: values.manualOperationType,
						},
					})
					.then(() => {
						setVisible(false);
						props.refresh();
						actions.resetForm();
					})
					.finally(() => setIsBusy(false))
			)
		);
	};

	return (
		<>
			<Button
				type="primary"
				shape="round"
				size="small"
				style={{ marginBottom: 5 }}
				disabled={isBusy}
				onClick={() => {
					setVisible(true);
				}}
			>
				Add record
			</Button>

			{visible && (
				<IsolatedModal
					title="Add new manual operation"
					visible={visible}
					width="23vw"
					centered
					cancelButtonProps={{ hidden: true }}
					okButtonProps={{ hidden: true }}
					maskClosable={false}
					onCancel={handleCancel}
					closable={true}
					destroyOnClose
				>
					<Formik
						validateOnChange={false}
						validateOnBlur={true}
						initialValues={{}}
						enableReinitialize
						validationSchema={ManualOperationValidationSchema}
						onSubmit={(
							values: CreatePharmacyTaxNoteGroupManualOperationCommand,
							actions: FormikActions<CreatePharmacyTaxNoteGroupManualOperationCommand>
						) => handleCreate(values, actions)}
						render={(
							formikProps: FormikProps<CreatePharmacyTaxNoteGroupManualOperationCommand>
						) => (
							<>
								<FFieldLabel label="Type" />
								<Field
									component={FSelect}
									placeholder="Type"
									name="manualOperationType"
									readOnly={false}
									children={getManualOperationTypes().map(
										(x, i) => (
											<Select.Option key={i} value={x[0]}>
												{x[1]}
											</Select.Option>
										)
									)}
								/>

								<FFieldLabel label="Value" />
								<Field
									component={FInputNumber}
									min={0.01}
									placeholder="Value"
									name="value"
									readOnly={false}
								/>

								<FFieldLabel label="Date" />
								<Field
									component={FDatePicker}
									name="date"
									mode="date"
									placeholder="Date"
									readOnly={false}
									style={{ width: '100%' }}
								/>
								<CenteredRow style={{ marginTop: '15px' }}>
									<Button
										type="primary"
										size="large"
										shape="round"
										onClick={() => formikProps.submitForm()}
									>
										Save
									</Button>
								</CenteredRow>
							</>
						)}
					></Formik>
				</IsolatedModal>
			)}
		</>
	);
};
