import { FarmapromPharmaciesModal } from '@components/farmapromPharmacies/FarmapromPharmaciesModal';
import { UMXPharmaciesModal } from '@components/umx/UMXPharmaciesModal';
import { useRootData } from '@hooks/hook';
import { PharmacyEditDto } from '@services/commandMappers/Pharmacy';
import { DictionariesConstants } from '@services/DictionariesConstants';
import {
	CreatePharmacyCommand,
	FarmapromPharmacyDto,
	UmxPharmacyDto,
	UpdatePharmacyCommand,
} from '@services/src/models';
import { Col, Row, Space } from 'antd';
import { Field, Formik, FormikProps } from 'formik';
import { DictionarySelect } from 'forms/DictionarySelect';
import {
	FDatePicker,
	FFieldLabel,
	FInput,
	FInputNumber,
} from 'forms/FormikFormItems';
import React from 'react';
import { IDictionaryStore } from 'stores/DictionaryStore';

import { IdentifiersModal } from '../identifiers/IdentifiersModal';
import styles from '../styles/PharmacyEdit.module.less';

interface IProps {
	readOnly: boolean;
	updateView: boolean;
	formikProps: FormikProps<
		CreatePharmacyCommand | UpdatePharmacyCommand | PharmacyEditDto
	>;
}

export const InternalData = (props: IProps) => {
	const dictionaryStore: IDictionaryStore = useRootData(
		(store) => store.dictionaryStore
	);

	const pharmacyNhfContractId = dictionaryStore.getItemCodeNameById(
		DictionariesConstants.NHFContract,
		props.formikProps.values.nhfContractId!
	);

	const nhfContractIds = ['CONTRACTED', 'TO_EXTINCTION'];

	return (
		<Col span={12}>
			<h2 className="header-text">Internal data</h2>

			<div className={styles.description}>
				<Row>
					<Col span={12}>
						<div className="fieldbox">
							<FFieldLabel label="SAP ID" />
							<Field
								component={FInput}
								placeholder="SAP ID"
								name="placeId"
								readOnly={true}
							/>
						</div>
					</Col>
					<Col span={11} offset={1}>
						<Space direction="horizontal">
							<div className="fieldbox">
								<FFieldLabel label="Kod Kom" />
								<Field
									component={FInputNumber}
									placeholder="Kod Kom"
									name="kodKom"
									precision={0}
									readOnly={true}
								/>
							</div>
							<UMXPharmaciesModal
								disabled={props.readOnly || !props.updateView}
								voivodeshipId={
									props.formikProps.values.voivodeshipId!
								}
								onPharmacySelect={(
									pharmacy: UmxPharmacyDto
								) => {
									props.formikProps.setFieldValue(
										'identifier',
										pharmacy.identifier
									);

									props.formikProps.setFieldValue(
										'kodKom',
										pharmacy.kodKom
									);

									props.formikProps.setFieldValue(
										'login',
										pharmacy.technicalCode
									);

									props.formikProps.setFieldValue(
										'healthFundID',
										pharmacy.healthFundID
									);

									const contractedValueDto = dictionaryStore.getValueByCodeName(
										DictionariesConstants.NHFContract,
										'CONTRACTED'
									);

									if (contractedValueDto?.id) {
										props.formikProps.setFieldValue(
											'nhfContractId',
											contractedValueDto?.id
										);
									}
								}}
							/>
						</Space>
					</Col>
				</Row>

				<Row>
					<Col span={12}>
						<div className="fieldbox">
							<FFieldLabel label="Health Fund ID" />
							<Field
								component={FInput}
								placeholder="Health Fund ID"
								name="healthFundID"
								readOnly={true}
							/>
						</div>
					</Col>
					<Col span={11} offset={1}>
						<Space direction="horizontal">
							<div className="fieldbox">
								<FFieldLabel label="FP ID" />
								<Field
									component={FInput}
									placeholder="FP ID"
									name="farmapromPharmacyId"
									readOnly={true}
								/>
							</div>
							<FarmapromPharmaciesModal
								disabled={props.readOnly}
								onPharmacySelect={(
									pharmacy: FarmapromPharmacyDto | null
								) => {
									if (pharmacy) {
										props.formikProps.setFieldValue(
											'farmapromPharmacyId',
											pharmacy.farmapromPharmacyId
										);
										props.formikProps.setFieldValue(
											'name',
											pharmacy.name
										);
										props.formikProps.setFieldValue(
											'street',
											pharmacy.street
										);
										props.formikProps.setFieldValue(
											'city',
											pharmacy.city
										);
										props.formikProps.setFieldValue(
											'postalCode',
											pharmacy.postalCode
										);
										props.formikProps.setFieldValue(
											'voivodeshipId',
											pharmacy.voivodeshipId
										);
										props.formikProps.setFieldValue(
											'district',
											pharmacy.district
										);
										props.formikProps.setFieldValue(
											'salesRepresentative',
											pharmacy.salesRepresentative
										);
										props.formikProps.setFieldValue(
											'pharmacyChain',
											pharmacy.pharmacyChain
										);
										props.formikProps.setFieldValue(
											'kam',
											pharmacy.kam
										);
									} else {
										props.formikProps.setFieldValue(
											'farmapromPharmacyId',
											undefined
										);
									}
								}}
							/>
						</Space>
					</Col>
				</Row>

				<Row>
					<Col span={12}>
						<div className="fieldbox">
							<FFieldLabel label="NHF contract" />
							<DictionarySelect
								readOnly={props.readOnly}
								formikProps={props.formikProps}
								formikName="nhfContractId"
								dictionaryCodeName={
									DictionariesConstants.NHFContract
								}
								disabledCodes={
									props.formikProps.values instanceof
										PharmacyEditDto &&
									pharmacyNhfContractId &&
									!nhfContractIds.includes(
										pharmacyNhfContractId
									) &&
									props.formikProps.values
										.isPharmacyHasNhfContract
										? nhfContractIds
										: []
								}
							/>
						</div>
					</Col>
					<Col span={11} offset={1}>
						<Space direction="horizontal">
							<div className="fieldbox">
								<FFieldLabel label="Dist.number (wyroznik)" />
								<Field
									component={FInputNumber}
									placeholder="Dist.number (wyroznik)"
									name="identifier"
									precision={0}
									readOnly={true}
								/>
							</div>

							<IdentifiersModal
								disabled={props.readOnly || !props.updateView}
								voivodeshipId={
									props.formikProps.values.voivodeshipId
								}
								kodKom={props.formikProps.values.kodKom}
							/>
						</Space>
					</Col>
				</Row>

				<Row>
					<Col span={12}>
						<div className="fieldbox">
							<FFieldLabel label="Essity paper contract number" />
							<Field
								component={FInput}
								placeholder="Essity paper contract number"
								name="essityPaperContractNumber"
								readOnly={props.readOnly}
							/>
						</div>
					</Col>
					<Col span={11} offset={1}>
						<div className="fieldbox">
							<FFieldLabel label="Date of joining Essity net" />
							<Field
								label="Date of joining Essity net"
								component={FDatePicker}
								style={{ width: '100%' }}
								name="dateOfJoiningEssityNet"
								mode="date"
								disabled={props.readOnly}
							/>
						</div>
					</Col>
				</Row>

				<Row>
					<Col span={12}>
						<div className="fieldbox">
							<FFieldLabel label="End date of Essity contract" />
							<Field
								label="End date of Essity contract"
								component={FDatePicker}
								name="endDateOfEssityContract"
								mode="date"
								style={{ width: '100%' }}
								disabled={props.readOnly}
							/>
						</div>
					</Col>
					<Col span={11} offset={1}>
						<div className="fieldbox">
							<FFieldLabel label="End contract reason" />
							<DictionarySelect
								readOnly={props.readOnly}
								formikProps={props.formikProps}
								formikName="endContractReasonId"
								dictionaryCodeName={
									DictionariesConstants.EndContractReason
								}
							/>
						</div>
					</Col>
				</Row>
				<Row>
					<Col span={12}>
						<div className="fieldbox">
							<FFieldLabel label="Login" />
							<Field
								component={FInput}
								placeholder="Login"
								name="login"
								readOnly={true}
							/>
						</div>
					</Col>
					<Col span={11} offset={1}>
						<div className="fieldbox">
							<FFieldLabel label="Business continuity" />
							<Field
								component={FInput}
								placeholder="Business continuity"
								name="businessContinuity"
								readOnly={props.readOnly}
							/>
						</div>
					</Col>
				</Row>
			</div>
		</Col>
	);
};
