import PaginatedEssityTable from '@components/shared/paginatedEssityTable/PaginatedEssityTable';
import { SieveModel } from '@components/shared/paginatedEssityTable/SieveModels';
import { getEssityApiClient } from '@services/EssityApi';
import { ProductPriceDto } from '@services/src/models';
import React from 'react';
import styles from './styles/PriceLists.module.less';
import { ProductPricesSearch } from './ProductPricesSearch';
import { ProductPricesTableColumns } from './ProductPricesTableColumns';

interface IProps {
	selectedPriceListId?: string;
}

export const ProductPricesTable = (props: IProps) => {
	return (
		<div className={styles.productPrices}>
			<div></div>
			<div>
				<h2 className="header-text">Products</h2>

				{props.selectedPriceListId && (
					<ProductPricesSearch
						selectedPriceListId={props.selectedPriceListId}
					/>
				)}

				<div className={styles.table}>
					<PaginatedEssityTable<ProductPriceDto>
						columns={ProductPricesTableColumns}
						getRowKey={(r: ProductPriceDto) => r.id!}
						gridName="productPrices"
						hasRowSelection={false}
						hidePersonalizationSettings={true}
						getPagedResult={async (
							sieve: SieveModel,
							abortSignal
						) => {
							const api = await getEssityApiClient();
							return await api.price.all({
								priceListId: props.selectedPriceListId,
								page: sieve.page,
								pageSize: sieve.pageSize,
								sorts: sieve.sorts,
								abortSignal: abortSignal,
								filters: sieve.filters,
							});
						}}
					/>
				</div>
			</div>
		</div>
	);
};
