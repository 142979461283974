import { IsolatedContainer } from '@components/shared/IsolatedContainer';
import { IsolatedModal } from '@components/shared/modals/IsolatedModal';
import { VoivodeshipPaymentGroupInternalIdDto } from '@services/src/models';
import { Button, Modal } from 'antd';
import React, { useState } from 'react';

import { IdentifiersTable } from './IdentifiersTable';

interface IProps {
	previousInternalIds?: VoivodeshipPaymentGroupInternalIdDto[];
}

export const IdentifiersModal = (props: IProps) => {
	const [visible, setVisible] = useState<boolean>();

	const handleCancel = () => {
		setVisible(false);
	};

	return (
		<>
			<Button
				shape="round"
				size="small"
				onClick={() => {
					setVisible(true);
				}}
			>
				ID list
			</Button>
			{visible && (
				<IsolatedModal
					title="Identifiers"
					visible={visible}
					onCancel={handleCancel}
				>
					<IdentifiersTable
						previousInternalIds={props.previousInternalIds}
					/>
				</IsolatedModal>
			)}
		</>
	);
};
