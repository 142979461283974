import {
	PharmacyTaxNoteDto,
	PharmacyTaxNotePaymentWithNotesDto,
} from '@services/src/models';

export const IsSummaryRecord = (record: PharmacyTaxNoteDto): boolean => {
	return record.id === 'summary';
};

export const AppendSummaryRecord = (
	payment: PharmacyTaxNotePaymentWithNotesDto
) => {
	const notes = payment.pharmacyTaxNotes;

	if (!notes) {
		return;
	}

	return [
		...notes,
		{
			id: 'summary',
			noteValueSummary: notes
				?.map((x) => x.noteValue!)
				.reduce((a, b) => a + b, 0),
			ordersValueSummary: notes
				?.map((x) => x.ordersValue!)
				.reduce((a, b) => a + b, 0),
			settledOrdersValueSummary: notes
				?.map((x) => x.settledOrdersValue!)
				.reduce((a, b) => a + b, 0),
			lossValueSummary: notes
				?.map((x) => x.lossValue!)
				.reduce((a, b) => a + b, 0),
			returnedValueSummary: notes
				?.map((x) => x.returnedValue!)
				.reduce((a, b) => a + b, 0),
			overpayValueSummary: notes
				?.map((x) => x.overpayValue!)
				.reduce((a, b) => a + b, 0),
			paidValueSummary: notes
				?.map((x) => x.paidValue!)
				.reduce((a, b) => a + b, 0),
		},
	];
};
