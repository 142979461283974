import QuickFilters from '@components/quickFilters/QuickFilters';
import { Divider, Space } from 'antd';
import React from 'react';

import { PharmaciesNoteGroupsAddNote } from './PharmaciesNoteGroupsAddNote';
import PharmaciesNoteGroupsFreeze from './PharmaciesNoteGroupsFreeze';
import PharmaciesNoteGroupsUnfreeze from './PharmaciesNoteGroupsUnfreeze';
import styles from './styles/PharmaciesNoteGroups.module.less';

interface IProps {}

export const PharmaciesNoteGroupsMenu = (props: IProps) => {
	return (
		<>
			<Divider />
			<Space direction="horizontal" className={styles.menu}>
				<PharmaciesNoteGroupsAddNote />
				<PharmaciesNoteGroupsFreeze />
				<PharmaciesNoteGroupsUnfreeze />
				<QuickFilters hide={false}/>
			</Space>
			<Divider />
		</>
	);
};
