import { VoivodeshipContractDto } from '@services/src/models';
import EssityTable from 'layout/EssityTable';
import React from 'react';

import { ContractsTableColumns } from './ContractsTableColumns';

interface IProps {
	contracts: VoivodeshipContractDto[];
}

export const ContractsTable = (props: IProps) => {
	return (
		<EssityTable
			columns={ContractsTableColumns}
			dataSource={props.contracts}
			pagination={false}
		/>
	);
};
