export const getFullItemWidth = (element: HTMLDivElement | HTMLElement) => {
	const style = window.getComputedStyle(element),
		width = element.offsetWidth,
		margin = parseFloat(style.marginLeft) + parseFloat(style.marginRight),
		padding =
			parseFloat(style.paddingLeft) + parseFloat(style.paddingRight),
		border =
			parseFloat(style.borderLeftWidth) +
			parseFloat(style.borderRightWidth);
	const totalWidth = width + margin - padding + border;

	return totalWidth;
};
