/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from "@azure/ms-rest-js";
import * as Models from "../models";
import * as Mappers from "../models/voivodeshipPaymentGroupMappers";
import * as Parameters from "../models/parameters";
import { PolandPharmacyRxAPIContext } from "../polandPharmacyRxAPIContext";

/** Class representing a VoivodeshipPaymentGroup. */
export class VoivodeshipPaymentGroup {
  private readonly client: PolandPharmacyRxAPIContext;

  /**
   * Create a VoivodeshipPaymentGroup.
   * @param {PolandPharmacyRxAPIContext} client Reference to the service client.
   */
  constructor(client: PolandPharmacyRxAPIContext) {
    this.client = client;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.VoivodeshipPaymentGroupAllResponse>
   */
  all(options?: Models.VoivodeshipPaymentGroupAllOptionalParams): Promise<Models.VoivodeshipPaymentGroupAllResponse>;
  /**
   * @param callback The callback
   */
  all(callback: msRest.ServiceCallback<Models.VoivodeshipPaymentGroupDtoPagedResult>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  all(options: Models.VoivodeshipPaymentGroupAllOptionalParams, callback: msRest.ServiceCallback<Models.VoivodeshipPaymentGroupDtoPagedResult>): void;
  all(options?: Models.VoivodeshipPaymentGroupAllOptionalParams | msRest.ServiceCallback<Models.VoivodeshipPaymentGroupDtoPagedResult>, callback?: msRest.ServiceCallback<Models.VoivodeshipPaymentGroupDtoPagedResult>): Promise<Models.VoivodeshipPaymentGroupAllResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      allOperationSpec,
      callback) as Promise<Models.VoivodeshipPaymentGroupAllResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  sendToSap(options?: Models.VoivodeshipPaymentGroupSendToSapOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  sendToSap(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  sendToSap(options: Models.VoivodeshipPaymentGroupSendToSapOptionalParams, callback: msRest.ServiceCallback<void>): void;
  sendToSap(options?: Models.VoivodeshipPaymentGroupSendToSapOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      sendToSapOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  sendToSapAgain(options?: Models.VoivodeshipPaymentGroupSendToSapAgainOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  sendToSapAgain(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  sendToSapAgain(options: Models.VoivodeshipPaymentGroupSendToSapAgainOptionalParams, callback: msRest.ServiceCallback<void>): void;
  sendToSapAgain(options?: Models.VoivodeshipPaymentGroupSendToSapAgainOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      sendToSapAgainOperationSpec,
      callback);
  }
}

// Operation Specifications
const serializer = new msRest.Serializer(Mappers);
const allOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "voivodeshipPaymentGroup/VoivodeshipPaymentGroup",
  queryParameters: [
    Parameters.filters,
    Parameters.sorts,
    Parameters.page,
    Parameters.pageSize
  ],
  responses: {
    200: {
      bodyMapper: Mappers.VoivodeshipPaymentGroupDtoPagedResult
    },
    default: {}
  },
  serializer
};

const sendToSapOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "voivodeshipPaymentGroup/VoivodeshipPaymentGroup/sendToSap",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.SendToSapVoivodeshipPaymentGroupCommand
  },
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const sendToSapAgainOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "voivodeshipPaymentGroup/VoivodeshipPaymentGroup/sendToSapAgain",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.SendToSapAgainVoivodeshipPaymentGroupCommand
  },
  responses: {
    200: {},
    default: {}
  },
  serializer
};
