import { InternalTaxNoteType } from '@services/src/models';
import { Divider } from 'antd';
import React, { useState } from 'react';

import { PharmacyNotePreview } from '../PharmacyNotePreview';
import styles from '../styles/PharmacyNote.module.less';
import { LossNoteDetails } from './LossNoteDetails';

interface IProps {
	editMode: boolean;
	readOnly: boolean;
	groupId: string;
	internalTaxNoteType: InternalTaxNoteType;
	lossNoteId?: string;
}

export const LossNote = (props: IProps) => {
	const [noteAttachment, setNoteAttachment] = useState<Blob>();

	const setAttachment = (attachment: Blob | undefined) => {
		setNoteAttachment(attachment);
	};

	return (
		<div className={styles.note}>
			<LossNoteDetails
				editMode={props.editMode}
				readOnly={props.readOnly}
				groupId={props.groupId}
				internalTaxNoteType={props.internalTaxNoteType}
				lossNoteId={props.lossNoteId}
				setAttachment={setAttachment}
			/>
			<Divider className={styles.divider} type="vertical" />
			<PharmacyNotePreview blob={noteAttachment} />
		</div>
	);
};
