import { PaymentState } from '@services/src/models';
import { Divider, Space } from 'antd';
import React from 'react';

import { ImportReturnFileModal } from './ImportReturnFileModal';
import { ImportSAPConfirmationModal } from './ImportSAPConfirmationModal';
import SendToSAPModal from './sendToSAP/SendToSAPModal';
import styles from './styles/SendingPayments.module.less';

export const SendingPaymentsMenu = () => {
	return (
		<>
			<Divider />
			<Space direction="horizontal" className={styles.menu}>
				<SendToSAPModal
					label="Send to SAP"
					correctStatus={PaymentState.ReadyToSend}
					mode="first"
				/>
				<ImportSAPConfirmationModal />
				<ImportReturnFileModal />
				<SendToSAPModal
					label="Send Again"
					correctStatus={PaymentState.SentToSap}
					mode="again"
				/>
			</Space>
			<Divider />
		</>
	);
};
