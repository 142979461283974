import { CloudDownloadOutlined } from '@ant-design/icons';
import { EssityDatePicker, EssityRangePicker } from '@components/shared/EssityDateTime';
import { DownloadFile } from '@components/shared/file/FileDownloadFunctions';
import { LoadingView } from '@components/shared/import/LoadingView';
import { IsolatedModal } from '@components/shared/modals/IsolatedModal';
import { PharmacyFinder } from '@components/shared/modals/pharmacyFinder/PharmacyFinder';
import { mapEssityFiltersToSieveFilters } from '@components/shared/paginatedEssityTable/SieveModels';
import { ajaxByUser } from '@helper/api';
import { DateFormat } from '@helper/formatHelpers';
import { useRootData } from '@hooks/hook';
import { getEssityApiClient } from '@services/EssityApi';
import { FileType, PharmacyDto } from '@services/src/models';
import { Button, Checkbox, DatePicker, Dropdown, Menu, Space } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { CenteredRow } from 'layout/CenteredRow';
import { Moment } from 'moment';
import React, { useState } from 'react';
import { IGridStore } from 'stores/GridStore';

import styles from './styles/Reports.module.less';

interface IState {
	pharmacies: string[];
	selectedAllPharmacies?: boolean;
	month?: Moment;
	year?: Moment;
	paymentDateFrom?: Moment;
	paymentDateTo?: Moment;
	detailedReport: boolean;
	fileType?: FileType;
}

export const MonthlyPharmaciesBalanceReport = () => {
	const [visible, setVisible] = useState<boolean>(false);
	const [state, setState] = useState<IState>({
		detailedReport: false,
		pharmacies: [],
	});
	const [isBusy, setIsBusy] = useState<boolean>();

	const gridStore: IGridStore = useRootData((store) => store.gridStore);

	const { RangePicker } = DatePicker;

	const menu = (
		<Menu>
			<Menu.Item
				key="1"
				onClick={() => {
					setState({ ...state, fileType: FileType.CSV });
					setVisible(true);
				}}
			>
				Export data as CSV
			</Menu.Item>
			<Menu.Item
				key="2"
				onClick={() => {
					setState({ ...state, fileType: FileType.XLSX });
					setVisible(true);
				}}
			>
				Export data as XLSX
			</Menu.Item>
		</Menu>
	);

	const handleCancel = () => {
		setVisible(false);
		clearState();
	};

	const clearState = () => {
		setState({ detailedReport: false, pharmacies: [] });
		setIsBusy(false);
		gridStore.resetGrid('pharmaciesFinder');
	};

	const handleSubmit = async () => {
		setIsBusy(true);

		const filters = gridStore.getGridFilters('pharmaciesFinder')!;

		ajaxByUser('Successfully downloaded file', () =>
			getEssityApiClient().then((api) =>
				api.report
					.getMonthlyBalance({
						year: state?.year?.year(),
						month: state?.month!.month() + 1,
						paymentDateFrom:
							state?.paymentDateFrom?.toDate() || undefined,
						paymentDateTo:
							state?.paymentDateTo?.toDate() || undefined,
						detailedReport: state?.detailedReport,
						fileType: state?.fileType,
						pharmaciesIds: state?.pharmacies,
						isCheckedAll: state?.selectedAllPharmacies,
						sieveFilters: mapEssityFiltersToSieveFilters(filters),
					})
					.then((result) => {
						DownloadFile({
							response: result,
						});
					})
					.finally(() => {
						setVisible(false);
						clearState();
					})
			)
		);
	};

	const isExportDisabled = (): boolean => {
		return (
			!state.month ||
			!state.year ||
			(state.pharmacies?.length === 0 && !state.selectedAllPharmacies)
		);
	};

	return (
		<>
			<div className={styles.report}>
				<Space direction="vertical">
					<h3>Pharmacy balance report (monthly)</h3>

					<p>
						Export monthly pharmacy balance report to xlsx or csv
						file
					</p>
				</Space>

				<Dropdown overlay={menu} placement="bottomLeft" arrow>
					<Button type="primary" shape="round" size="middle">
						Run
					</Button>
				</Dropdown>
			</div>
			<IsolatedModal
				title="Pharmacy balance report (monthly)"
				visible={visible}
				onCancel={handleCancel}
			>
				<Space direction="vertical" className={styles.filters}>
					<PharmacyFinder
						readOnly={false}
						mode="multiple"
						validatePlaceId={false}
						style={{ width: 'auto' }}
						onPharmacySelect={(
							items: PharmacyDto[],
							selectedAll?: boolean
						) => {
							if (selectedAll) {
								setState({
									...state,
									selectedAllPharmacies: selectedAll,
								});
							} else {
								setState({
									...state,
									selectedAllPharmacies: false,
									pharmacies: items.map((x) => x.id!),
								});
							}
						}}
						customTitle={`Choose pharmacies (${
							state.selectedAllPharmacies
								? 'all'
								: state.pharmacies.length
						} selected)`}
					/>
					Settlement period
					<Space direction="horizontal">
						<EssityDatePicker
							picker="month"
							format={DateFormat.Month}
							onChange={(date) =>
								setState({ ...state, month: date || undefined })
							}
						/>
						<EssityDatePicker
							picker="year"
							format={DateFormat.Year}
							onChange={(date) =>
								setState({ ...state, year: date || undefined })
							}
						/>
					</Space>
					Payment date
					<EssityRangePicker
						placeholder={['From', 'To']}
						value={[state.paymentDateFrom!, state.paymentDateTo!]}
						onChange={(value: any) => {
							setState({
								...state,
								paymentDateFrom: value ? value[0] : undefined,
								paymentDateTo: value ? value[1] : undefined,
							});
						}}
					/>
					<Checkbox
						value={state.detailedReport}
						onChange={(e: CheckboxChangeEvent) =>
							setState({
								...state,
								detailedReport: e.target.checked,
							})
						}
					>
						generate detailed report
					</Checkbox>
				</Space>
				<CenteredRow className={styles.exportButton}>
					{isBusy ? (
						<LoadingView message="Creating report, please wait..." />
					) : (
						<Button
							type="primary"
							shape="round"
							size="large"
							onClick={handleSubmit}
							disabled={isExportDisabled()}
						>
							Generate report
							<CloudDownloadOutlined />
						</Button>
					)}
				</CenteredRow>
			</IsolatedModal>
		</>
	);
};
