/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from "@azure/ms-rest-js";
import * as Models from "../models";
import * as Mappers from "../models/reportMappers";
import * as Parameters from "../models/parameters";
import { PolandPharmacyRxAPIContext } from "../polandPharmacyRxAPIContext";

/** Class representing a Report. */
export class Report {
  private readonly client: PolandPharmacyRxAPIContext;

  /**
   * Create a Report.
   * @param {PolandPharmacyRxAPIContext} client Reference to the service client.
   */
  constructor(client: PolandPharmacyRxAPIContext) {
    this.client = client;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.ReportGetOrdersResponse>
   */
  getOrders(options?: Models.ReportGetOrdersOptionalParams): Promise<Models.ReportGetOrdersResponse>;
  /**
   * @param callback The callback
   */
  getOrders(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getOrders(options: Models.ReportGetOrdersOptionalParams, callback: msRest.ServiceCallback<void>): void;
  getOrders(options?: Models.ReportGetOrdersOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<Models.ReportGetOrdersResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      getOrdersOperationSpec,
      callback) as Promise<Models.ReportGetOrdersResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.ReportGetPharmaciesResponse>
   */
  getPharmacies(options?: Models.ReportGetPharmaciesOptionalParams): Promise<Models.ReportGetPharmaciesResponse>;
  /**
   * @param callback The callback
   */
  getPharmacies(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getPharmacies(options: Models.ReportGetPharmaciesOptionalParams, callback: msRest.ServiceCallback<void>): void;
  getPharmacies(options?: Models.ReportGetPharmaciesOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<Models.ReportGetPharmaciesResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      getPharmaciesOperationSpec,
      callback) as Promise<Models.ReportGetPharmaciesResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.ReportGetMonthlyBalanceResponse>
   */
  getMonthlyBalance(options?: Models.ReportGetMonthlyBalanceOptionalParams): Promise<Models.ReportGetMonthlyBalanceResponse>;
  /**
   * @param callback The callback
   */
  getMonthlyBalance(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getMonthlyBalance(options: Models.ReportGetMonthlyBalanceOptionalParams, callback: msRest.ServiceCallback<void>): void;
  getMonthlyBalance(options?: Models.ReportGetMonthlyBalanceOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<Models.ReportGetMonthlyBalanceResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      getMonthlyBalanceOperationSpec,
      callback) as Promise<Models.ReportGetMonthlyBalanceResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.ReportGetGeneralBalanceResponse>
   */
  getGeneralBalance(options?: Models.ReportGetGeneralBalanceOptionalParams): Promise<Models.ReportGetGeneralBalanceResponse>;
  /**
   * @param callback The callback
   */
  getGeneralBalance(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getGeneralBalance(options: Models.ReportGetGeneralBalanceOptionalParams, callback: msRest.ServiceCallback<void>): void;
  getGeneralBalance(options?: Models.ReportGetGeneralBalanceOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<Models.ReportGetGeneralBalanceResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      getGeneralBalanceOperationSpec,
      callback) as Promise<Models.ReportGetGeneralBalanceResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.ReportGetPharmacyTaxNoteGroupsResponse>
   */
  getPharmacyTaxNoteGroups(options?: Models.ReportGetPharmacyTaxNoteGroupsOptionalParams): Promise<Models.ReportGetPharmacyTaxNoteGroupsResponse>;
  /**
   * @param callback The callback
   */
  getPharmacyTaxNoteGroups(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getPharmacyTaxNoteGroups(options: Models.ReportGetPharmacyTaxNoteGroupsOptionalParams, callback: msRest.ServiceCallback<void>): void;
  getPharmacyTaxNoteGroups(options?: Models.ReportGetPharmacyTaxNoteGroupsOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<Models.ReportGetPharmacyTaxNoteGroupsResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      getPharmacyTaxNoteGroupsOperationSpec,
      callback) as Promise<Models.ReportGetPharmacyTaxNoteGroupsResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.ReportGetTemplateNotesResponse>
   */
  getTemplateNotes(options?: Models.ReportGetTemplateNotesOptionalParams): Promise<Models.ReportGetTemplateNotesResponse>;
  /**
   * @param callback The callback
   */
  getTemplateNotes(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getTemplateNotes(options: Models.ReportGetTemplateNotesOptionalParams, callback: msRest.ServiceCallback<void>): void;
  getTemplateNotes(options?: Models.ReportGetTemplateNotesOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<Models.ReportGetTemplateNotesResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      getTemplateNotesOperationSpec,
      callback) as Promise<Models.ReportGetTemplateNotesResponse>;
  }
}

// Operation Specifications
const serializer = new msRest.Serializer(Mappers);
const getOrdersOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "report/Report/orders",
  queryParameters: [
    Parameters.year1,
    Parameters.dateFrom,
    Parameters.dateTo,
    Parameters.sieveFilters,
    Parameters.sieveSorts,
    Parameters.sievePage,
    Parameters.sievePageSize,
    Parameters.isCheckedAll,
    Parameters.checked,
    Parameters.unchecked,
    Parameters.fileType
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Stream"
        }
      }
    },
    default: {}
  },
  serializer
};

const getPharmaciesOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "report/Report/pharmacies",
  queryParameters: [
    Parameters.sieveFilters,
    Parameters.sieveSorts,
    Parameters.sievePage,
    Parameters.sievePageSize,
    Parameters.isCheckedAll,
    Parameters.checked,
    Parameters.unchecked,
    Parameters.fileType
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Stream"
        }
      }
    },
    default: {}
  },
  serializer
};

const getMonthlyBalanceOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "report/Report/monthlyBalance",
  queryParameters: [
    Parameters.year1,
    Parameters.month1,
    Parameters.detailedReport,
    Parameters.paymentDateFrom,
    Parameters.paymentDateTo,
    Parameters.pharmaciesIds,
    Parameters.sieveFilters,
    Parameters.sieveSorts,
    Parameters.sievePage,
    Parameters.sievePageSize,
    Parameters.isCheckedAll,
    Parameters.checked,
    Parameters.unchecked,
    Parameters.fileType
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Stream"
        }
      }
    },
    default: {}
  },
  serializer
};

const getGeneralBalanceOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "report/Report/generalBalance",
  queryParameters: [
    Parameters.year1,
    Parameters.monthFrom,
    Parameters.monthTo,
    Parameters.paymentDateFrom,
    Parameters.paymentDateTo,
    Parameters.pharmaciesIds,
    Parameters.sieveFilters,
    Parameters.sieveSorts,
    Parameters.sievePage,
    Parameters.sievePageSize,
    Parameters.isCheckedAll,
    Parameters.checked,
    Parameters.unchecked,
    Parameters.fileType
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Stream"
        }
      }
    },
    default: {}
  },
  serializer
};

const getPharmacyTaxNoteGroupsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "report/Report/pharmacyTaxNoteGroups",
  queryParameters: [
    Parameters.year1,
    Parameters.dateFrom,
    Parameters.dateTo,
    Parameters.fileType,
    Parameters.sieveFilters,
    Parameters.sieveSorts,
    Parameters.sievePage,
    Parameters.sievePageSize,
    Parameters.isCheckedAll,
    Parameters.checked,
    Parameters.unchecked
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Stream"
        }
      }
    },
    default: {}
  },
  serializer
};

const getTemplateNotesOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "report/Report/templateNotes",
  queryParameters: [
    Parameters.year1,
    Parameters.dateFrom,
    Parameters.dateTo,
    Parameters.sieveFilters,
    Parameters.sieveSorts,
    Parameters.sievePage,
    Parameters.sievePageSize,
    Parameters.isCheckedAll,
    Parameters.checked,
    Parameters.unchecked,
    Parameters.fileType
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Stream"
        }
      }
    },
    default: {}
  },
  serializer
};
