import './AccountMenu.less';

import { MyProfile } from '@authentication/MyProfile';
import { GlobalUserManager } from '@authentication/userManager';
import { Button, Dropdown, Menu } from 'antd';
import EssityTooltip from 'layout/EssityTooltip';
import { User } from 'oidc-client';
import React, { useState } from 'react';

import { SignoutReason } from '../../services/mappers/SignoutReason';

interface IProps {
	user?: User | null;
}

const AccountMenu: React.FC<IProps> = (props) => {
	const [myProfileVisible, setMyProfileVisible] = useState<boolean>(false);

	const getInitials = (user: User | undefined): string => {
		return `${user?.profile.given_name?.charAt(
			0
		)}${user?.profile.family_name?.charAt(0)}`;
	};

	const menu = (
		<Menu>
			<Menu.Item
				key="1"
				onClick={() => {
					setMyProfileVisible(true);
				}}
			>
				My profile
			</Menu.Item>
			<Menu.Item
				key="2"
				onClick={() => {
					const url = `${window._env_.REACT_APP_DOMAIN}oauth2/v2.0/authorize?p=${window._env_.REACT_APP_RESET_PASS_FLOW}&client_id=${window._env_.REACT_APP_CLIENT_ID}&nonce=defaultNonce&redirect_uri=${window._env_.REACT_APP_RESET_PASS_CALLBACK}&scope=openid&response_type=id_token&prompt=login`;
					window.location.replace(url);
				}}
			>
				Change password
			</Menu.Item>
			<Menu.Item
				key="3"
				onClick={() => {
					GlobalUserManager.UserManager().signoutRedirect();
					localStorage.setItem(
						'signOutReason',
						SignoutReason.USER_SIGNOUT
					);
				}}
			>
				Log out
			</Menu.Item>
		</Menu>
	);

	return (
		<>
			<EssityTooltip placement="left" title={props.user?.profile.name}>
				<Dropdown overlay={menu} trigger={['click']}>
					<Button type="primary" shape="circle" size="large">
						{props.user && getInitials(props.user)}
					</Button>
				</Dropdown>
			</EssityTooltip>
			{props.user && (
				<MyProfile
					user={props.user}
					visible={myProfileVisible}
					setVisible={(state: boolean) => setMyProfileVisible(state)}
				/>
			)}
		</>
	);
};

export default AccountMenu;
