import { VoivodeshipPaymentGroupInternalIdDto } from '@services/src/models';
import EssityTable from 'layout/EssityTable';
import React from 'react';

import { IdentifiersTableColumns } from './IdentifiersTableColumns';

interface IProps {
	previousInternalIds?: VoivodeshipPaymentGroupInternalIdDto[];
}

export const IdentifiersTable = (props: IProps) => {
	return (
		<EssityTable
			columns={IdentifiersTableColumns}
			dataSource={props.previousInternalIds}
			pagination={false}
		/>
	);
};
