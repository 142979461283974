import { getEssityApiClient } from '@services/EssityApi';
import { DictionaryDto, DictionaryValueDto } from '@services/src/models';
import { observable } from 'mobx';

export const dictionaryStore = {
	initialized: observable.box<boolean>(false),
	dictionaries: observable(new Map<string, DictionaryDto>()),
	initializeStore: (codeNames: string[]) => {
		if (!dictionaryStore.initialized.get()) {
			getEssityApiClient().then((api) =>
				api.dictionary
					.getByCodeNames({ codeNames: codeNames })
					.then((dictionaries: DictionaryDto[]) => {
						dictionaries.forEach((dictionary: DictionaryDto) => {
							dictionaryStore.dictionaries.set(
								dictionary.codeName!,
								dictionary
							);
						});
					})
					.then(() => dictionaryStore.initialized.set(true))
			);
		}
	},
	getDictionary: (codeName: string): DictionaryDto | undefined => {
		return dictionaryStore.dictionaries.get(codeName);
	},
	getValueByCodeName: (
		dictionaryCodeName: string,
		valueCodeName: string
	): DictionaryValueDto | undefined => {
		const dictionary = dictionaryStore.dictionaries.get(dictionaryCodeName);

		if (dictionary) {
			const dictionaryValue = dictionary.values?.find(
				(x) => x.codeName === valueCodeName
			);

			return dictionaryValue;
		}
	},
	getValueById: (
		dictionaryCodeName: string,
		valueId: number
	): string | undefined => {
		const dictionary = dictionaryStore.dictionaries.get(dictionaryCodeName);

		if (dictionary) {
			const dictionaryValue = dictionary.values?.find(
				(x) => x.id == valueId
			);

			return dictionaryValue?.value;
		}
	},
	getItemCodeNameById: (
		dictionaryCodeName: string,
		valueId: number
	): string | undefined => {
		const dictionary = dictionaryStore.dictionaries.get(dictionaryCodeName);

		if (dictionary) {
			const dictionaryValue = dictionary.values?.find(
				(x) => x.id == valueId
			);

			return dictionaryValue?.codeName;
		}
	},
};

export const createDictionaryStore = () => {
	return dictionaryStore;
};

export type IDictionaryStore = ReturnType<typeof createDictionaryStore>;
