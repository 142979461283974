import { WarningTwoTone } from '@ant-design/icons';
import {
	EssityColumnProps,
	filterType,
} from '@components/shared/paginatedEssityTable/GridHelper';
import { useRootData } from '@hooks/hook';
import { DictionariesConstants } from '@services/DictionariesConstants';
import * as PortalStatusMapper from '@services/mappers/PortalStatus';
import { PharmacyDto, PortalStatus } from '@services/src/models';
import { Space, Tag, Tooltip } from 'antd';
import React from 'react';
import { IDictionaryStore } from 'stores/DictionaryStore';

import styles from './styles/Pharmacies.module.less';

interface IProps {
	allowFilterOnVoivodeship: boolean;
}

export const GetPharmaciesTableColumns = (
	props: IProps
): EssityColumnProps<PharmacyDto>[] => {
	const dictionaryStore: IDictionaryStore = useRootData(
		(store) => store.dictionaryStore
	);

	const GetUMXValidationIcon = (pharmacyDto: PharmacyDto) => {
		if (pharmacyDto.umxWarning) {
			const nhfContract = dictionaryStore.getItemCodeNameById(
				DictionariesConstants.NHFContract,
				pharmacyDto.nhfContractId!
			);

			if (
				nhfContract &&
				['TO_EXTINCTION', 'CLOSED'].includes(nhfContract)
			) {
				return (
					<Tooltip title="UMX validation error">
						<WarningTwoTone
							twoToneColor="#fff200"
							style={{ fontSize: '16px' }}
						/>
					</Tooltip>
				);
			}

			return (
				<Tooltip title="UMX validation error">
					<WarningTwoTone
						twoToneColor="#FF0000"
						style={{ fontSize: '16px' }}
					/>
				</Tooltip>
			);
		}
	};

	const getVoivodeshipColumn = () => {
		if (props.allowFilterOnVoivodeship) {
			return {
				dataIndex: 'voivodeshipId',
				key: 'voivodeshipId',
				title: 'Voivodeship',
				width: 160,
				render: (
					value: number,
					_record: PharmacyDto,
					_index: number
				) => {
					return dictionaryStore.getValueById(
						DictionariesConstants.Voivodeships,
						value
					);
				},
				sorter: true,
				filter: 'dictionary' as filterType,
				filterDictionary: DictionariesConstants.Voivodeships,
			};
		}

		return {
			sorter: true,
			dataIndex: 'voivodeshipId',
			width: 160,
			key: 'voivodeshipId',
			title: 'Voivodeship',
			filter: 'equals' as filterType,
			hideFilterIcon: true,
			render: (value: number) => {
				return dictionaryStore.getValueById(
					DictionariesConstants.Voivodeships,
					value
				);
			},
		};
	};

	let columns: EssityColumnProps<PharmacyDto>[] = [
		{
			title: '',
			dataIndex: 'farmapromWarning',
			key: 'farmapromWarning',
			width: 38,
			align: 'left',
			render: (_item: boolean, pharmacyDto: PharmacyDto) => (
				<Space direction="horizontal" className={styles.validation}>
					{GetUMXValidationIcon(pharmacyDto)}
					{pharmacyDto.farmapromWarning && (
						<Tooltip title="FP validation error">
							<WarningTwoTone
								twoToneColor="#32387e"
								style={{ fontSize: '16px' }}
							/>
						</Tooltip>
					)}
				</Space>
			),
		},
		{
			dataIndex: 'placeId',
			key: 'placeId',
			title: 'SAP ID',
			width: 100,
			sorter: true,
			filter: 'equals' as filterType,
		},
		{
			dataIndex: 'kodKom',
			key: 'kodKom',
			title: 'Kod Kom',
			width: 110,
			sorter: true,
			filter: 'equals' as filterType,
		},
		{
			dataIndex: 'nhfContractId',
			key: 'nhfContractId',
			title: 'NHF contract',
			width: 150,
			render: (value: number, _record: PharmacyDto, _index: number) => {
				return dictionaryStore.getValueById(
					DictionariesConstants.NHFContract,
					value
				);
			},
			sorter: true,
			filter: 'dictionary' as filterType,
			filterDictionary: DictionariesConstants.NHFContract,
		},
		{
			dataIndex: 'name',
			key: 'name',
			title: 'Name',
			width: 160,
			sorter: true,
			filter: 'contains' as filterType,
		},
		getVoivodeshipColumn(),
		{
			dataIndex: 'city',
			key: 'city',
			title: 'City',
			width: 160,
			sorter: true,
			filter: 'contains' as filterType,
		},
		{
			dataIndex: 'street',
			key: 'street',
			title: 'Street',
			width: 150,
			sorter: true,
			filter: 'contains' as filterType,
		},
		{
			dataIndex: 'taxNumber',
			key: 'taxNumber',
			title: 'NIP',
			width: 160,
			sorter: true,
			filter: 'contains' as filterType,
		},
		{
			dataIndex: 'pharmacyChain',
			key: 'pharmacyChain',
			title: 'Pharmacy Chain',
			width: 160,
			sorter: true,
			filter: 'contains' as filterType,
		},
		{
			dataIndex: 'companyName',
			key: 'companyName',
			title: 'Company Name',
			width: 200,
			sorter: true,
			filter: 'contains' as filterType,
		},
		{
			dataIndex: 'portalStatus',
			key: 'portalStatus',
			title: 'Portal status',
			width: 140,
			sorter: true,
			filter: 'enum' as filterType,
			enumMapper: PortalStatusMapper,
			filterDictionary: PortalStatus,
			render: (value: any) => {
				return (
					<Tag color="#32387e">{PortalStatusMapper.map(value)}</Tag>
				);
			},
		},
	];

	if (props.allowFilterOnVoivodeship) {
	} else {
	}

	return columns;
};
