import GlobalSearchMenu from '@components/globalSearch/GlobalSearchMenu';
import SettingsMenu from '@components/settings/SettingsMenu';
import { useRootData } from '@hooks/hook';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { IAccountDetailsStore } from 'stores/AccountDetailsStore';

import AccountMenu from './AccountMenu';

interface IAccountProps {}

const Account: React.FC<IAccountProps & any> = observer(() => {
	const accountDetailsStore: IAccountDetailsStore = useRootData(
		(store) => store.accountDetailsStore
	);

	return (
		<div className="account">
			<GlobalSearchMenu />
			<SettingsMenu />
			<AccountMenu user={accountDetailsStore.oidcUser.get()} />
		</div>
	);
});

export default Account;
