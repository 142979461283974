import { EssityColumnProps } from "@components/shared/paginatedEssityTable/GridHelper";
import { OrderLineDetailsDto } from "@services/src/models";

export const OrderLinesTableColumns: EssityColumnProps<OrderLineDetailsDto>[] = [
	{
		dataIndex: 'productId',
		key: 'productId',
		title: 'Product code',
		width: 150,
	},
	{
		dataIndex: 'productName',
		key: 'productName',
		title: 'Name',
		width: 400,
	},
	{
		dataIndex: 'productVersionNFZCode',
		key: 'productVersionNFZCode',
		title: 'Manufacture Code',
		width: 150,
	},
	{
		dataIndex: 'qty',
		key: 'qty',
		title: 'Quantity',
		width: 150,
	},
	{
		dataIndex: 'price',
		key: 'price',
		title: 'Price',
		width: 150,
	},
	{
		dataIndex: 'value',
		key: 'value',
		title: 'Value',
		width: 150,
	},
];
