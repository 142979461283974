import { SearchOutlined } from '@ant-design/icons';
import { SearchCommaHelper } from '@helper/stringHelper';
import { Button, Input, Mentions } from 'antd';
import { useRootData } from 'hooks/hook';
import React, { useEffect, useImperativeHandle, useState } from 'react';
import { IGridStore } from 'stores/GridStore';
import { GridNameType } from 'stores/TableColumnStore';

import { filterType } from './GridHelper';

const { Search } = Input;
interface SearchReducedType {
	column: string;
	filter: filterType;
}

export interface SearchBarSettings {
	placeholder?: string;
	column: string;
	filter: filterType;
	gridName: string;
	defaultValue?: string;
	allowTags?: boolean;
	adaptiveSearchReducer?: (
		value: string,
		clearColumn: (col: string) => void
	) => SearchReducedType;
}

export interface SearchBarElement {
	clear: () => void;
}

export const hashRegex = new RegExp(/#[\w\d\a-ząćęłńóśźż]+/gi);
export const SEARCH_BAR_FILTER_PREFIX = '!SearchBar!';

const SearchBar = React.forwardRef<SearchBarElement, SearchBarSettings>(
	(props, ref) => {
		const {
			placeholder,
			column,
			gridName,
			filter,
			adaptiveSearchReducer,
			defaultValue,
		} = props;
		const [value, setValue] = useState<string | undefined>(defaultValue);
		const gridStore: IGridStore = useRootData((store) => store.gridStore);
		const columnName = props.allowTags
			? SEARCH_BAR_FILTER_PREFIX + column
			: column;

		gridStore.clearSearch.observe(() => {
			setValue(undefined);
			gridStore.clearLabels();
		});

		useEffect(() => {
			const gridFilters = gridStore.getGridFilters(gridName);

			if (gridFilters) {
				const filterValue = gridFilters.get(`${props.allowTags ? SEARCH_BAR_FILTER_PREFIX : ''}${column}`);

				if (filterValue) {
					setValue(SearchCommaHelper.undoChanges(filterValue.value, column));
				}
			}
		}, []);

		useImperativeHandle(ref, () => ({
			clear: () => {
				setValue(undefined);
				gridStore.clearColumnFilter(gridName, column);
				gridStore.clearLabels();
			},
		}));

		const clearColumn = (col: string) => {
			gridStore.clearColumnFilter(gridName, col);
		};

		const onSearch = (value: string | undefined) => {
			if (value) {
				const matchedTags = value.match(hashRegex);
				const tags = matchedTags?.map((tag) => tag.replace('#', ''));

				gridStore.setLabels(gridName as GridNameType, tags);
			} else {
				gridStore.setLabels(gridName as GridNameType, []);
			}

			if (adaptiveSearchReducer) {
				const searchReduced = adaptiveSearchReducer(
					value!,
					clearColumn
				);

				value &&
					gridStore.saveColumnFilter(
						gridName,
						SEARCH_BAR_FILTER_PREFIX + searchReduced.column,
						value,
						searchReduced.filter
					);
			} else {
				clearColumn(column);
				gridStore.saveColumnFilter(
					gridName,
					columnName,
					SearchCommaHelper.applyChanges(value, columnName),
					filter
				);
			}
			gridStore.searching.set(true);
		};
		return props.allowTags ? (
			<>
				<span className="ant-input-group-wrapper ant-input-group-wrapper-lg ant-input-search ant-input-search-large searchBar">
					<span className="ant-input-wrapper ant-input-group">
						<Mentions
							className="ant-input ant-input-lg ant-input-borderless"
							value={value}
							onChange={(e) => setValue(e)}
							placeholder={`To search by tags, enter a # character at the beginning`}
							aria-label={`To search by tags, enter a # character at the beginning`}
							prefix={'#'}
							autoSize={{ maxRows: 1, minRows: 1 }}
							rows={1}
							onPressEnter={(event) => {
								event.preventDefault();
								onSearch(value);
							}}
						>

						</Mentions>
						<span className="ant-input-group-addon">
							<Button
								size="large"
								type="ghost"
								htmlType="submit"
								className="ant-input-search-button"
								aria-label="Search"
								icon={<SearchOutlined />}
								onClick={() => {
									onSearch(value);
								}}
							/>
						</span>
					</span>
				</span>
			</>
		) : (
			<Search
				value={value}
				onChange={(e) => setValue(e.target.value)}
				placeholder={placeholder}
				className="searchBar"
				size="large"
				aria-label={placeholder}
				onSearch={onSearch}
				bordered={false}
				enterButton={
					<Button
						size="large"
						type="ghost"
						htmlType="submit"
						className="ant-input-search-button"
						aria-label="Search"
						icon={<SearchOutlined />}
					></Button>
				}
			/>
		);
	}
);

SearchBar.defaultProps = {
	allowTags: false,
};

export default SearchBar;
