import { SelectOutlined } from '@ant-design/icons';
import {
	EssityColumnProps,
	filterType,
} from '@components/shared/paginatedEssityTable/GridHelper';
import PaginatedEssityTable from '@components/shared/paginatedEssityTable/PaginatedEssityTable';
import { SieveModel } from '@components/shared/paginatedEssityTable/SieveModels';
import { useRootData } from '@hooks/hook';
import { DictionariesConstants } from '@services/DictionariesConstants';
import { getEssityApiClient } from '@services/EssityApi';
import {
	FarmapromPharmacyAllOptionalParams,
	FarmapromPharmacyDto,
} from '@services/src/models';
import { Button, Tooltip } from 'antd';
import React from 'react';
import { IDictionaryStore } from 'stores/DictionaryStore';
import { IGridStore } from 'stores/GridStore';
import { ITableColumnStore } from 'stores/TableColumnStore';

const gridName = 'farmapromPharmacies';

interface IProps {
	onPharmacySelect: (pharmacy: FarmapromPharmacyDto) => void;
}

export const FarmapromPharmaciesTable = (props: IProps) => {
	const gridStore: IGridStore = useRootData((store) => store.gridStore);

	const tableColumnStore: ITableColumnStore = useRootData(
		(store) => store.tableColumnStore
	);

	const dictionaryStore: IDictionaryStore = useRootData(
		(store) => store.dictionaryStore
	);

	const columns: EssityColumnProps<FarmapromPharmacyDto>[] = [
		...tableColumnStore.getTable(gridName),
		{
			sorter: true,
			dataIndex: 'voivodeshipId',
			key: 'voivodeshipId',
			title: 'Voivodeship',
			filter: 'dictionary' as filterType,
			filterDictionary: DictionariesConstants.Voivodeships,
			render: (value: number) => {
				return dictionaryStore.getValueById(
					DictionariesConstants.Voivodeships,
					value
				);
			},
			width: 150,
		},
		{
			title: '',
			key: 'operation',
			fixed: 'right',
			width: 50,
			render: (item: FarmapromPharmacyDto) => (
				<Tooltip title="Select">
					<Button
						type="primary"
						shape="circle"
						size="small"
						onClick={() => {
							props.onPharmacySelect(item);
							gridStore.resetGrid(gridName);
						}}
						icon={<SelectOutlined />}
					/>
				</Tooltip>
			),
		},
	];

	return (
		<PaginatedEssityTable<FarmapromPharmacyDto>
			columns={columns}
			gridName={gridName}
			hasRowSelection={false}
			hidePersonalizationSettings={true}
			getRowKey={(r: FarmapromPharmacyDto) => r.id!}
			getPagedResult={async (sieve: SieveModel, abortSignal) => {
				const parameters: FarmapromPharmacyAllOptionalParams = {
					page: sieve.page,
					pageSize: sieve.pageSize,
					sorts: sieve.sorts,
					abortSignal: abortSignal,
					filters: sieve.filters,
				};

				const api = await getEssityApiClient();
				return await api.farmapromPharmacy.all(parameters);
			}}
		/>
	);
};
