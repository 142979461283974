import { VoivodeshipDetailsDto } from '@services/src/models';
import { Col, Descriptions } from 'antd';
import React from 'react';
import styles from './styles/Departments.module.less';

interface IProps {
	department: VoivodeshipDetailsDto;
}

export const InformationData = (props: IProps) => {
	const { department } = props;
	return (
		<>
			<h2>Address</h2>
			<Col span={15}>
				<Descriptions className={styles.description} size="small" column={2}>
					<Descriptions.Item label="Name" span={2}>
						<b>{department.name}</b>
					</Descriptions.Item>
					<Descriptions.Item label="Address" span={2}>
						<b>
							{`${department.street}, ${department.postalCode} ${department.city}`}
						</b>
					</Descriptions.Item>
					<Descriptions.Item label="SAP ID" span={2}>
						<b>{department.sapId}</b>
					</Descriptions.Item>
				</Descriptions>
			</Col>
		</>
	);
};
