import './TableWithTitle.less';

import { Space } from 'antd';
import React from 'react';

interface IProps {
	title: string;
	right?: React.ReactNode;
}

export const TableWithTitle: React.FC<IProps> = (props: any) => {
	return (
		<div className="table-with-title">
			<Space>
				<h2>{props.title}</h2>
				{props.right}
			</Space>

			<div className="table-component">{props.children}</div>
		</div>
	);
};
