import { SorterResult } from 'antd/lib/table/interface';
import { Key } from 'react';

import { IFilter } from '../../../stores/GridStore';
import SieveQueryBuilder from './filters/SieveQueryBuilder';
import { EssityColumnProps } from './GridHelper';
import { SEARCH_BAR_FILTER_PREFIX, hashRegex } from './SearchBar';

export class AntPagination {
	total?: number | undefined = undefined;
	pageSize?: number = 10;
	current?: number = 1;
	position?: 'top' | 'bottom' | 'both' = 'bottom';
	hideOnSinglePage?: boolean = true;
	showSizeChanger?: boolean = false;
	pageSizeOptions?: string[];
	onChange?: (page: number, pageSize: number) => void;
	onShowSizeChange?: (current: number, size: number) => void;
	showTotal?: (total: number, range: any) => React.ReactElement | string;

	constructor(showSizeChanger: boolean = false) {
		const MIN_ROWS_PER_PAGE = 5;
		if (showSizeChanger) {
			this.hideOnSinglePage = false;
			this.showSizeChanger = true;
			this.pageSizeOptions = new Array<number>(20)
				.fill(0)
				.map((n, i) => (MIN_ROWS_PER_PAGE * (i + 1)).toString());
		}
	}
}

export interface IPagination {}

export class SieveModel {
	filters?: string;
	sorts?: string;
	page?: number;
	pageSize?: number;

	labels?: string[];

	constructor(init?: Partial<SieveModel>) {
		Object.assign(this, init);
	}
}

export const mapEssityFiltersToSieveFilters = (
	essityFilters: Map<React.Key, IFilter | undefined>
): string => {
	let queryBuilder = new SieveQueryBuilder();

	essityFilters?.forEach(
		(filterValue: IFilter | undefined, filterKey: Key) => {
			if (
				filterValue === undefined ||
				filterValue.filterType === undefined
			)
				return;

			let key = filterKey.toString();
			let value = filterValue.value;
			if (key.startsWith(SEARCH_BAR_FILTER_PREFIX)) {
				key = key.replace(SEARCH_BAR_FILTER_PREFIX, '');
				value = value?.replace(hashRegex, '').trim();
			}

			if (value != '') {
				queryBuilder.default(key, value, filterValue.filterType);
			}
		}
	);

	return queryBuilder.build();
};

export const mapAntSorterToSieve = (antSorter: SorterResult<any>): string => {
	let sieveSorts = '';
	if (
		antSorter &&
		!(
			Object.keys(antSorter).length === 0 &&
			antSorter.constructor === Object
		)
	) {
		if (antSorter.column)
			sieveSorts =
				(antSorter.order == 'ascend' ? '' : '-') + antSorter.column.key;
		else sieveSorts = '';
	}

	return sieveSorts;
};
