import { DoneView } from '@components/shared/import/DoneView';
import { ExceptionView } from '@components/shared/import/ExceptionView';
import { LoadingView } from '@components/shared/import/LoadingView';
import { IsolatedContainer } from '@components/shared/IsolatedContainer';
import { IsolatedModal } from '@components/shared/modals/IsolatedModal';
import { ImportStatus } from '@components/shared/types/importStatus';
import { ajaxCatch } from '@helper/api';
import { useRootData } from '@hooks/hook';
import { getEssityApiClient } from '@services/EssityApi';
import {
	TemplateNoteDto,
	TemplateNoteSendToSAPOptionalParams,
} from '@services/src/models';
import { Button, Col, Modal } from 'antd';
import { Formik, FormikActions, FormikProps } from 'formik';
import { CenteredRow } from 'layout/CenteredRow';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useState } from 'react';
import { IGridStore } from 'stores/GridStore';

const SendToSAPModal = () => {
	const [visible, setVisible] = useState<boolean>(false);

	const [progressState, setProgressState] = useState<ImportStatus>(
		ImportStatus.Init
	);

	const gridStore: IGridStore = useRootData((store) => store.gridStore);

	const handleCancel = () => {
		setVisible(false);
		setProgressState(ImportStatus.Init);
	};

	const InitialView = (
		props: FormikProps<TemplateNoteSendToSAPOptionalParams>
	) => (
		<CenteredRow style={{ textAlign: 'center' }}>
			<Col span={24}>
				<h3>System will send following NHF payment notes:</h3>
			</Col>
			<Col span={24}>
				{gridStore
					.getSelectedValues()
					.filter((x: TemplateNoteDto) => x.noteCreated && !x.sent)
					.map((x: TemplateNoteDto) => {
						return (
							<p key={x.id}>
								<b>{x.noteName}</b>
								<b>
									{x.month}.{x.year}
								</b>{' '}
								(Settlement period)
							</p>
						);
					})}
			</Col>
			<Button
				type="primary"
				size="large"
				shape="round"
				onClick={() => {
					if (!props.isSubmitting) {
						props.submitForm();
					}
				}}
			>
				Send notes to SAP
			</Button>
		</CenteredRow>
	);

	const handleRender = (
		props: FormikProps<TemplateNoteSendToSAPOptionalParams>
	) => {
		return (
			<IsolatedModal
				title="Sending NHF payment notes to SAP"
				visible={visible}
				onCancel={handleCancel}
			>
				{getViewByExportStatus(props)}
			</IsolatedModal>
		);
	};

	const getViewByExportStatus = (
		props: FormikProps<TemplateNoteSendToSAPOptionalParams>
	) => {
		switch (progressState) {
			case ImportStatus.Init:
				return <InitialView {...props} />;
			case ImportStatus.Done:
				return <DoneView message="Successfully finished." />;
			case ImportStatus.Exception:
				return <ExceptionView />;
			case ImportStatus.Loading:
				return (
					<LoadingView message="Sending file in progress, please wait..." />
				);
		}
	};

	const handleExport = (
		values: any,
		actions: FormikActions<TemplateNoteSendToSAPOptionalParams>
	) => {
		setProgressState(ImportStatus.Loading);

		const selectedValues: TemplateNoteDto[] = gridStore
			.getSelectedValues()
			.filter((x: TemplateNoteDto) => x.noteCreated && !x.sent);

		const idsToExport = selectedValues.map((x) => x.id);

		ajaxCatch(
			() =>
				getEssityApiClient().then((api) =>
					api.templateNote.sendToSAP({
						body: {
							checked: idsToExport as string[],
						},
					})
				),
			() => {
				setProgressState(ImportStatus.Done);
				gridStore.searching.set(true);
			},
			() => {
				setProgressState(ImportStatus.Exception);
			}
		);

		actions.setSubmitting(false);
		gridStore.setSelectedValues([]);
		gridStore.setSelectedKeys([]);
		gridStore.allRowsSelected.set(false);
	};

	const isExportEnabled = () => {
		return (
			gridStore.selectedValues.length > 0 &&
			gridStore.selectedValues.filter(
				(x: TemplateNoteDto) => x.noteCreated && !x.sent
			).length > 0
		);
	};

	return (
		<>
			<Button
				type="primary"
				shape="round"
				size="large"
				onClick={() => setVisible(true)}
				disabled={!isExportEnabled()}
			>
				Send to SAP
			</Button>

			<Formik
				validateOnChange
				validateOnBlur
				enableReinitialize
				isInitialValid={false}
				initialValues={{}}
				onSubmit={(
					values,
					actions: FormikActions<TemplateNoteSendToSAPOptionalParams>
				) => handleExport(values, actions)}
				render={handleRender}
			/>
		</>
	);
};

export default observer(SendToSAPModal);
