import { BackToSearchButton } from '@components/globalSearch/BackToSearchButton';
import { BackButton } from '@components/shared/buttons/BackButton';
import * as OrderStateMapper from '@services/mappers/OrderState';
import { OrderDetailsDto } from '@services/src/models';
import { Divider, Space } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';

import styleModule from './styles/OrderLines.module.less';

interface IProps {
	orderDetails: OrderDetailsDto | undefined;
	isInSearch: boolean;
	theme?: { [key: string]: string };
}

const OrderLinesMenu = (props: IProps) => {
	const { orderDetails } = props;

	const styles = props.theme ? props.theme : styleModule;

	const getOthersRealizationIds = (): string => {
		return [
			orderDetails!.restoredRealizationId,
			orderDetails!.canceledRealizationId,
		]
			.filter((x) => x !== null)
			.join(',');
	};

	return (
		<>
			<Divider />
			<div className={styles.menu}>
				<div></div>

				<Space direction="horizontal">
					{props.isInSearch ? <BackToSearchButton /> : <BackButton />}
				</Space>

				{orderDetails && (
					<div className={styles.title}>
						<h2>
							Settlement period:{' '}
							{`${orderDetails.month}/${orderDetails.year}`}{' '}
						</h2>

						{orderDetails.orderState && (
							<h2>
								Status:{' '}
								{OrderStateMapper.map(orderDetails.orderState)}
							</h2>
						)}
						<div className={styles.realizationBox}>
							{orderDetails.realizationId && (
								<h2>
									Realization ID: {orderDetails.realizationId}
								</h2>
							)}

							{getOthersRealizationIds() && (
								<p>
									Previous realization ID:{' '}
									{getOthersRealizationIds()}
								</p>
							)}
						</div>
					</div>
				)}
			</div>
			<Divider />
		</>
	);
};

export default observer(OrderLinesMenu);
