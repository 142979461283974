
import { notification } from 'antd';
import NProgress from 'nprogress';

import { showNotExpectedErrorModal } from '../layout/Modals';

export const baseAppUrl = (
	document.getElementsByTagName('base')[0] || {}
).href.slice(0, -1);

export const baseUrl = document
	.getElementsByTagName('base')[0]
	.getAttribute('href');

export function ajaxByUser<T>(
	successMsg: string,
	request: () => Promise<T>,
	success?: () => void,
	validation?: (errors: any) => void,
	onNotExpectedModalConfirmation?: () => void
) {
	NProgress.start();
	return request()
		.then(() => {
			notification.success({
				placement: 'bottomRight',
				message: successMsg
				// description: 'This is the content of the notification. This is the content of the notification. This is the content of the notification.',
			});
		})
		.then(() => {
			if (success) success();
		})
		.catch((resp: any) => {
			const validationErrors = showNotExpectedErrorModal(
				resp,
				onNotExpectedModalConfirmation
			);
			if (validationErrors !== undefined && validation) {
				validation(validationErrors);
			}
			
		})
		.finally(() => {
			NProgress.done();
		});
}

export function ajaxCatch<T>(
	request: () => Promise<T>,
	success?: (arg0: T) => void,
	validation?: (errors: any) => void,
	showProgress: boolean = true
) {
	if (showProgress) {
		NProgress.start();
	}

	return request()
		.then((arg0: T) => {
			if (success) success(arg0);
			return arg0;
		})
		.catch((resp: any) => {
			const validationErrors = showNotExpectedErrorModal(resp);
			if (validationErrors !== undefined && validation) {
				validation(validationErrors);
			}
		})
		.finally(() => {
			if (showProgress) {
				NProgress.done();
			}
		});
}

export function ajaxCatchSilently<T>(
	request: () => Promise<T>,
	success?: (arg0: T) => void,
	error?: () => void
) {
	NProgress.start();
	return request()
		.then((arg0: T) => {
			if (success) success(arg0);
			return arg0;
		})
		.catch((resp: any) => {
			console.error(resp);
			if (error) error();
		})
		.finally(() => {
			NProgress.done();
		});
}

export function downloadFile(file: Blob, fileName: string) {
	const fileURL = URL.createObjectURL(file);

	// if (window.navigator.msSaveOrOpenBlob)  // IE hack; see http://msdn.microsoft.com/en-us/library/ie/hh779016.aspx
	window.navigator.msSaveBlob(file, fileName);
	// else {
	var a = document.createElement('a');
	a.href = fileURL;
	a.download = fileName;
	document.body.appendChild(a);
	a.click();
	document.body.removeChild(a);
	// }

	setTimeout(() => {
		//Na potrzeby IE11
		window.URL.revokeObjectURL(fileURL);
	}, 300);
}
