import { EssityColumnProps } from '@components/shared/paginatedEssityTable/GridHelper';
import { ajaxCatch } from '@helper/api';
import { useRootData } from '@hooks/hook';
import { getEssityApiClient } from '@services/EssityApi';
import {
	InternalTaxNoteType,
	PharmacyTaxNoteDto,
	PharmacyTaxNotePaymentWithNotesDto,
} from '@services/src/models';
import { Table } from 'antd';
import EssityTable from 'layout/EssityTable';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { ITableColumnStore } from 'stores/TableColumnStore';

import { GetPaymentTableColumns } from './PaymentTableColumns';
import { AppendSummaryRecord, IsSummaryRecord } from './PharmacyNotesHelpers';
import {
	Operations,
} from './PharmacyNotesTableActions';
import styles from './styles/PharmacyNotes.module.less';

interface IProps {
	groupId: string;
}

export const PharmacyNotesTable = (props: IProps) => {
	const tableColumnStore: ITableColumnStore = useRootData(
		(store) => store.tableColumnStore
	);

	const [notes, setNotes] = useState<PharmacyTaxNotePaymentWithNotesDto[]>();
	const [isBusy, setIsBusy] = useState<boolean>();
	const [expandedKeys, setExpandedKeys] = useState<string[]>([]);

	const history = useHistory();

	const gridName = 'pharmacyNotes';

	const refreshNotes = () => {
		setIsBusy(true);
		ajaxCatch(() =>
			getEssityApiClient().then((api) =>
				api.pharmacyTaxNotePayment
					.getPharmacyTaxNotePaymentWithNotes({
						pharmacyTaxNoteGroupId: props.groupId,
					})
					.then((result) => {
						if (result) {
							setNotes(result);
							setExpandedKeys(result.map((x) => x.paymentId!));
						}
					})
					.finally(() => setIsBusy(false))
			)
		);
	};

	const columns: EssityColumnProps<PharmacyTaxNoteDto>[] = [
		...tableColumnStore.getTable(gridName),
		Operations(refreshNotes, props.groupId)
	];

	useEffect(() => refreshNotes(), []);

	return (
		<EssityTable
			className={styles.table}
			columns={GetPaymentTableColumns(refreshNotes)}
			gridName={gridName}
			dataSource={notes}
			hasRowSelection={false}
			hidePersonalizationSettings={true}
			loading={isBusy}
			pagination={false}
			rowKey={(r: PharmacyTaxNotePaymentWithNotesDto) => r.paymentId}
			expandable={{
				expandedRowRender: (
					record: PharmacyTaxNotePaymentWithNotesDto
				) => (
					<Table
						columns={columns}
						dataSource={AppendSummaryRecord(record)}
						pagination={false}
						onRow={(record: PharmacyTaxNoteDto) => {
							return {
								onDoubleClick: () => {
									if (IsSummaryRecord(record)) {
										return;
									}

									switch (record.internalTaxNoteType) {
										case InternalTaxNoteType.Correction:
										case InternalTaxNoteType.Debit:
										case InternalTaxNoteType.PayLoss:
										case InternalTaxNoteType.Return:
											history.push(
												`/correctionNote/view/${props.groupId}/${record.id}`
											);
											break;
										case InternalTaxNoteType.Loss:
											history.push(
												`/lossNote/view/${props.groupId}/${record.id}`
											);
											break;
										case InternalTaxNoteType.Overpay:
											history.push(
												`/overpayNote/view/${props.groupId}/${record.id}/${record.mainPharmacyTaxNoteId}`
											);
											break;
										default:
											history.push(
												`/pharmacyNote/view/${record.id}`
											);
											break;
									}
								},
							};
						}}
					/>
				),
				expandedRowKeys: expandedKeys,
				onExpand: (
					expanded: boolean,
					record: PharmacyTaxNotePaymentWithNotesDto
				) => {
					if (expanded) {
						setExpandedKeys([...expandedKeys, record.paymentId!]);
					} else {
						const items = expandedKeys;
						const index = items.findIndex(
							(x) => x === record.paymentId
						);
						items.splice(index, 1);
						setExpandedKeys(items);
					}
				},
			}}
		/>
	);
};
