import { PriceListGroup } from "@services/src/models";


export const mapings = new Map<PriceListGroup, string>([
	[PriceListGroup.Auxiliaries, 'Auxiliaries'],
	[PriceListGroup.OrthopedicProducts, 'Orthopedic products'],
]);

export const map = (value: PriceListGroup): string =>
	mapings.get(value) ?? '';

