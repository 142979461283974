import { Divider } from 'antd';
import React, { useState } from 'react';

import { PharmacyNoteDetails } from './PharmacyNoteDetails';
import { PharmacyNotePreview } from './PharmacyNotePreview';
import styles from './styles/PharmacyNote.module.less';

interface IProps {
	noteId?: string;
	pharmacyId?: string;
	editMode?: boolean;
}

export const PharmacyNote = (props: IProps) => {
	const [noteAttachment, setNoteAttachment] = useState<Blob>();

	const setAttachment = (attachment: Blob | undefined) => {
		setNoteAttachment(attachment);
	};

	return (
		<div className={styles.note}>
			<PharmacyNoteDetails
				noteId={props.noteId}
				readOnly={props.noteId !== undefined}
				pharmacyId={props.pharmacyId}
				editMode={props.editMode}
				setAttachment={setAttachment}
			/>
			<Divider className={styles.divider} type="vertical" />
			<PharmacyNotePreview blob={noteAttachment} />
		</div>
	);
};
