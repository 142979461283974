import * as Yup from 'yup';

export const CorrectionNoteValidationSchema = () =>
	Yup.object().shape({
		pharmacyNote: Yup.string()
			.nullable()
			.required('Pharmacy note is required.'),
		noteDate: Yup.date().required('Note date is required.'),
	});

export const CorrectionNoteEditValidationSchema = () =>
	Yup.object().shape({
		noteDate: Yup.date().required('Note date is required.'),
	});

export const CorrectionDebitNoteValidationSchema = () =>
	Yup.object().shape({
		ordersValue: Yup.string().required('Orders value is required.'),
		noteDate: Yup.date().required('Note date is required.'),
		month: Yup.date().required('Month is required.'),
		year: Yup.date().required('Year is required.'),
	});
