import { IsolatedModal } from '@components/shared/modals/IsolatedModal';
import {
	AssignBalancedPaymentConfirmationCommand,
	PharmacyTaxNoteDto,
} from '@services/src/models';
import { Button, Space } from 'antd';
import { Formik, FormikProps } from 'formik';
import { CenteredRow } from 'layout/CenteredRow';
import React, { useState } from 'react';
import { AssignPaymentConfirmationValidationSchema } from './AssignPaymentConfirmationValidationSchema';

interface IProps {
	taxNoteGroup: PharmacyTaxNoteDto;
	refresh: () => void;
}

export const AssignBalancedPaymentConfirmationModal = (props: IProps) => {
	const [visible, setVisible] = useState<boolean>();
	const [isBusy, setIsBusy] = useState<boolean>();

	const handleCancel = () => {
		setVisible(false);
	};

	const deleteAllConfirmations = (): void => {
		setIsBusy(true);
	};

	const handleRender = (taxNote: PharmacyTaxNoteDto) => (
		props: FormikProps<AssignBalancedPaymentConfirmationCommand>
	) => {
		return (
			<IsolatedModal
				title="Note compensation"
				visible={visible}
				width="20vw"
				onCancel={handleCancel}
				cancelButtonProps={{ hidden: true }}
				okButtonProps={{ hidden: true }}
				footer={
					<CenteredRow>
						<Space direction="horizontal">
							<Button
								type="primary"
								size="large"
								shape="round"
								onClick={() => deleteAllConfirmations()}
								disabled={isBusy}
								danger
							>
								Delete
							</Button>
							<Button
								type="primary"
								size="large"
								shape="round"
								onClick={() => handleCancel()}
							>
								Keep compensated
							</Button>
						</Space>
					</CenteredRow>
				}
				destroyOnClose
			>
				<h3>Return compensated by notes: {taxNote.confirmedBy}</h3>
			</IsolatedModal>
		);
	};

	return (
		<>
			<Button
				type="primary"
				shape="round"
				size="small"
				onClick={() => {
					setVisible(true);
				}}
			>
				Compensated
			</Button>

			{visible && (
				<Formik
					validateOnChange={false}
					validateOnBlur={true}
					initialValues={{}}
					enableReinitialize
					validationSchema={AssignPaymentConfirmationValidationSchema()}
					onSubmit={deleteAllConfirmations}
					render={handleRender(props.taxNoteGroup)}
				></Formik>
			)}
		</>
	);
};
