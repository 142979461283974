import { DeleteOutlined, SearchOutlined } from '@ant-design/icons';
import { useRootData } from '@hooks/hook';
import { Button, Select } from 'antd';
import { RefSelectProps } from 'antd/lib/select';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import { CenteredRow } from 'layout/CenteredRow';
import { observer } from 'mobx-react-lite';
import { deepObserve } from 'mobx-utils';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { IDictionaryStore } from 'stores/DictionaryStore';
import { IGridStore } from 'stores/GridStore';

import { EssityColumnProps } from '../GridHelper';

interface IProps<T> {
	column: EssityColumnProps<T>;
	handleSearch: (value?: string[]) => void;
	gridName: string;
	clearInput?: boolean;
	setClearInput?: () => void;
	filterProps: FilterDropdownProps;
	defaultValue?: any;
}

export const GridFilterDictionarySelect = observer(
	<T extends any>(props: IProps<T> & { children?: ReactNode }) => {
		const { defaultValue } = props;

		const [value, setValue] = useState<string[] | undefined>();
		const gridStore: IGridStore = useRootData((store) => store.gridStore);

		const [visible, setVisible] = useState<boolean>(false);

		useEffect(() => {
			if (props.filterProps.visible) {
				setVisible(true);
			}
		}, [props.filterProps.visible]);

		const dictionaryStore: IDictionaryStore = useRootData(
			(store) => store.dictionaryStore
		);

		const dict = dictionaryStore.getDictionary(
			props.column.filterDictionary
		);

		const dictValues = dict && dict.values;

		let inputRef = useRef<RefSelectProps>(null);

		useEffect(() => {
			if (props.clearInput) {
				setValue(undefined);
				props.setClearInput?.();
			}
		}, [props.clearInput]);

		const clear = () => {
			setValue(undefined);
			gridStore.clearColumnFilter(props.gridName, props.column.key!);

			props.handleSearch();
		};

		const search = () => {
			gridStore.saveColumnFilter(
				props.gridName,
				props.column.key!,
				value
			);
			props.handleSearch(value);
		};

		useEffect(() => {
			setValue(defaultValue);
		}, [props?.gridName]);

		useEffect(() => {
			const x = window.scrollX,
				y = window.scrollY;
			window.scrollTo(x, y);
		});

		useEffect(() => {
			return () => {
				disposer();
			};
		}, []);

		const disposer = deepObserve(
			gridStore.filtersGC.get(props.gridName),
			(change, path) => {
				const status = gridStore.getFilterGCStatus(
					props.gridName,
					props.column.key!
				);
				if (status) {
					const columnFilters = gridStore.getColumnFilter(
						props.gridName,
						props.column.key!
					)?.value;
					setValue(columnFilters);
				}
			}
		);

		return visible ? (
			<div tabIndex={-1} className="filter-block" style={{ padding: 8 }}>
				<CenteredRow>
					<Button
						type="primary"
						icon={<SearchOutlined />}
						size="small"
						onClick={() => {
							search();
							setVisible(false);
						}}
						disabled={!value}
					>
						Search
					</Button>
					<Button
						size="small"
						style={{ marginLeft: 4 }}
						icon={<DeleteOutlined />}
						onClick={() => {
							clear();
							setVisible(false);
						}}
					>
						Clear
					</Button>
				</CenteredRow>
				<Select<string[]>
					ref={inputRef}
					className="searchSelect"
					mode="multiple"
					size="small"
					placeholder="Select"
					style={{
						width: 300,
						marginTop: 8,
						marginBottom: 8,
						display: 'block',
					}}
					showSearch
					value={value}
					onChange={(val: string[]) => setValue(val)}
					optionFilterProp="children"
				>
					{dictValues &&
						dictValues.map((x) => (
							<Select.Option key={x.codeName} value={x.id ?? ''}>
								{x.value}
							</Select.Option>
						))}
				</Select>
			</div>
		) : (
			<></>
		);
	}
);
