import { DeleteOutlined } from '@ant-design/icons';
import { EssityColumnProps } from '@components/shared/paginatedEssityTable/GridHelper';
import { ajaxByUser, ajaxCatchSilently } from '@helper/api';
import { getEssityApiClient } from '@services/EssityApi';
import { VoivodeshipContactDto } from '@services/src/models';
import { Button, Col, Popconfirm, Space, Tooltip } from 'antd';
import EssityTable from 'layout/EssityTable';
import React, { useEffect, useState } from 'react';

import styles from '../styles/Departments.module.less';
import { ContactModal } from './ContactModal';
import { ContactsTableColumns } from './ContactsTableColumns';

interface IProps {
	voivodeshipId: string;
}

export const ContactsTable = (props: IProps) => {
	const [contacts, setContacts] = useState<VoivodeshipContactDto[]>();
	const [isBusy, setIsBusy] = useState<boolean>(false);

	const columns: EssityColumnProps<VoivodeshipContactDto>[] = [
		...ContactsTableColumns,
		{
			title: '',
			key: 'operation',
			fixed: 'right',
			width: 50,
			render: (item: VoivodeshipContactDto) => (
				<Popconfirm
					title="Are you sure to delete contact?"
					okText="Yes"
					cancelText="No"
					placement="bottom"
					onConfirm={() => {
						ajaxByUser('Successfully deleted contact.', () =>
							getEssityApiClient().then((api) =>
								api.voivodeshipContact
									.deleteMethod({ contactId: item.id })
									.then(() => refreshContacts())
							)
						);
					}}
				>
					<Tooltip title="Delete">
						<Button
							type="primary"
							shape="circle"
							size="small"
							icon={<DeleteOutlined />}
						/>
					</Tooltip>
				</Popconfirm>
			),
		},
	];

	const refreshContacts = async () => {
		setIsBusy(true);
		ajaxCatchSilently(() =>
			getEssityApiClient().then((api) =>
				api.voivodeshipContact
					.getContacts({
						voivodeshipId: props.voivodeshipId,
					})
					.then((result) => {
						setContacts(result);
					})
					.finally(() => setIsBusy(false))
			)
		);
	};

	useEffect(() => {
		refreshContacts();
	}, [props.voivodeshipId]);

	return (
		<>
			<Space direction="horizontal" align="baseline">
				<h2>Contacts</h2>
				{props.voivodeshipId && (
					<ContactModal
						onCreate={() => refreshContacts()}
						voivodeshipId={props.voivodeshipId}
					/>
				)}
			</Space>
			<Col span={15} className={styles.description}>
				<EssityTable
					loading={isBusy}
					columns={columns}
					dataSource={contacts}
					pagination={false}
				/>
			</Col>
		</>
	);
};
