import { IsolatedModal } from '@components/shared/modals/IsolatedModal';
import { ajaxByUser } from '@helper/api';
import { useRootData } from '@hooks/hook';
import { getEssityApiClient } from '@services/EssityApi';
import { BusinessObjectsState, PaymentState } from '@services/src/models';
import { Button } from 'antd';
import { CenteredRow } from 'layout/CenteredRow';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { IGlobalSearchStore } from 'stores/GlobalSearchStore';
import { IGridStore } from 'stores/GridStore';

interface IProps {}

const PharmaciesNoteGroupsFreeze = (props: IProps) => {
	const gridStore: IGridStore = useRootData((store) => store.gridStore);
	const globalSearchStore: IGlobalSearchStore = useRootData(
		(store) => store.globalSearchStore
	);
	const [visible, setVisible] = useState<boolean>();
	const [freezeVisible, setFreezeVisible] = useState<boolean>();

	useEffect(() => {
		const value = globalSearchStore.pharmacyPaymentsFilter.get()
			.businessObjectsState;
		if (value === BusinessObjectsState.CorrectOnly) {
			setFreezeVisible(true);
		} else {
			setFreezeVisible(false);
		}
	}, [globalSearchStore.pharmacyPaymentsFilter.get().businessObjectsState]);

	return (
		<>
			{freezeVisible && (
				<Button
					type="primary"
					shape="round"
					size="large"
					onClick={() => setVisible(true)}
				>
					Freeze
				</Button>
			)}
			<IsolatedModal
				title="Freeze payment notes"
				visible={visible}
				onCancel={() => setVisible(false)}
			>
				<CenteredRow>
					<h3>
						You want to freeze {gridStore.selectedKeys.length}{' '}
						selected payment notes.
					</h3>
				</CenteredRow>
				<CenteredRow>
					<p>They will be ready to send to SAP.</p>
				</CenteredRow>

				<CenteredRow style={{ marginTop: '15px' }}>
					<Button
						type="primary"
						size="large"
						shape="round"
						onClick={() => {
							const values = gridStore.getSelectedKeys() as string[];

							ajaxByUser('Successfully freezed records', () =>
								getEssityApiClient().then((api) =>
									api.pharmacyTaxNoteGroup
										.changeState({
											body: {
												pharmacyTaxNoteGroupIds: values,
												paymentState:
													PaymentState.ReadyToSend,
											},
										})
										.finally(() => {
											gridStore.searching.set(true);
											setVisible(false);
										})
								)
							);
						}}
					>
						Yes, I agree.
					</Button>
				</CenteredRow>
			</IsolatedModal>
		</>
	);
};

export default observer(PharmaciesNoteGroupsFreeze);
