import { Popover } from 'antd';
import clsx from 'clsx';
import { FieldProps } from 'formik';
import React from 'react';

import {
	FErrorMessage,
	isNotValidAndTouched,
	shouldShowErrorMessage,
} from './FormikFormItems';

export interface ErrorPopoverProps extends FieldProps<any> {
	className?: string;
}

export const ErrorPopover: React.FC<React.PropsWithChildren<
	ErrorPopoverProps
>> = ({ form, field, className, children }) => {
	const { name: fieldName } = field;

	return (
		<div
			className={clsx(
				className && className,
				isNotValidAndTouched({ field, form }) && 'form-error'
			)}
		>
			<Popover
				overlayStyle={{
					visibility: shouldShowErrorMessage(form, fieldName)
						? 'visible'
						: 'hidden',
				}}
				content={<FErrorMessage {...form} name={fieldName} />}
				trigger={['hover', 'focus']}
			>
				{children}
			</Popover>
		</div>
	);
};
