import {
	AutocompleteSearch,
	ISearchResult,
} from '@components/shared/AutocompleteSearch';
import { useRootData } from '@hooks/hook';
import { getEssityApiClient } from '@services/EssityApi';
import { OrderSearchDto } from '@services/src/models';
import React from 'react';
import { IGridStore } from 'stores/GridStore';

import styles from './styles/Orders.module.less';

interface IProps {
	selectedGroupId: string;
}

export const OrdersSearch = (props: IProps) => {
	const gridStore: IGridStore = useRootData((store) => store.gridStore);

	const searchFunction = async (
		searchText: string
	): Promise<ISearchResult[]> => {
		if (searchText.length >= 3 && props.selectedGroupId) {
			const api = await getEssityApiClient();
			const results: OrderSearchDto[] = await api.order.search({
				searchText: searchText,
				orderGroupId: props.selectedGroupId,
			});

			if (results) {
				const mapped = results.map((x: OrderSearchDto) => {
					return {
						id: x.id!,
						value: `${x.orderNumber} ${
							x.realizationId ? `(${x.realizationId})` : ''
						}`,
					};
				});

				if (mapped) {
					return mapped;
				}
			}
		} else if (searchText.length === 0) {
			gridStore.clearColumnFilter('orders', 'id');
			gridStore.searching.set(true);
		}

		return [];
	};

	return (
		<AutocompleteSearch
			onSelect={(value: string, object: any) => {
				gridStore.saveColumnFilter('orders', 'id', object.id);
				gridStore.searching.set(true);
			}}
			onClear={() => {
				gridStore.clearColumnFilter('orders', 'id');
				gridStore.searching.set(true);
			}}
			timeout={600}
			searchFunction={searchFunction}
			className={styles.searchBar}
			placeholder="Enter RealizationId or PESEL to search..."
		/>
	);
};
