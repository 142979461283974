import { MissingPharmacyDto } from '@services/src/models';
import EssityTable from 'layout/EssityTable';
import React from 'react';

import { ImportUMXValidationTableColumns } from './ImportUMXValidationTableColumns';

interface IProps {
	validation: MissingPharmacyDto[] | undefined;
}

export const ImportUMXValidationTable = (props: IProps) => {
	return (
		<EssityTable
			columns={ImportUMXValidationTableColumns}
			dataSource={props.validation}
			size="small"
		/>
	);
};
