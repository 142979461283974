import { getEssityApiClient } from '@services/EssityApi';
import { PharmacyTaxNoteGroupDto } from '@services/src/models';
import React, { useEffect, useState } from 'react';

import { PharmacyNotesMenu } from './PharmacyNotesMenu';
import { PharmacyNotesTable } from './PharmacyNotesTable';
import styles from './styles/PharmacyNotes.module.less';

interface IProps {
	groupId: string;
	pharmacyId: string;
}

export const PharmacyNotes = (props: IProps) => {
	const [noteGroup, setNoteGroup] = useState<PharmacyTaxNoteGroupDto>();

	useEffect(() => {
		if (props.groupId) {
			(async () => {
				const api = await getEssityApiClient();
				const result = await api.pharmacyTaxNoteGroup.getById(
					props.groupId
				);
				setNoteGroup(result);
			})();
		}
	}, [props.groupId]);

	return (
		<div>
			{noteGroup && <PharmacyNotesMenu noteGroup={noteGroup} />}
			<div className={styles.tableContainer}>
				<PharmacyNotesTable groupId={props.groupId} />
			</div>
		</div>
	);
};
