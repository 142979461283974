/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is regenerated.
 */

import { ServiceClientOptions } from "@azure/ms-rest-js";
import * as msRest from "@azure/ms-rest-js";

/**
 * An interface representing DictionaryValueDto.
 */
export interface DictionaryValueDto {
  id?: number;
  codeName?: string;
  value?: string;
  dictionaryId?: number;
}

/**
 * An interface representing DictionaryDto.
 */
export interface DictionaryDto {
  codeName?: string;
  name?: string;
  values?: DictionaryValueDto[];
}

/**
 * An interface representing OrderDto.
 */
export interface OrderDto {
  id?: string;
  nhfRealizationId?: string;
  realizationId?: number;
  year?: number;
  month?: number;
  downloadId?: number;
  kodKom?: number;
  documentType?: string;
  orderNumber?: string;
  orderDate?: Date;
  realizationMonthsQty?: number;
  orderRealizationStartDate?: Date;
  pesel?: string;
  value?: number;
  refund?: number;
  reportDate?: Date;
  internalNumber?: number;
  pharmacyId?: string;
  pharmacyName?: string;
  placeId?: number;
  /**
   * Possible values include: 'Imported', 'Reported', 'ApprovedByNhf', 'MarkedToCancel',
   * 'SentToCancel', 'Canceled', 'Restored', 'SettledNhf'
   */
  orderState?: OrderState;
  /**
   * Possible values include: 'NotAssigned', 'Assigned', 'ReadyToSend', 'SentToSap', 'Paid',
   * 'NotPaid'
   */
  taxNoteOrderState?: TaxNoteOrderState;
  cancellationReasonId?: number;
  cancellationReasonText?: string;
}

/**
 * An interface representing OrderSearchDto.
 */
export interface OrderSearchDto {
  id?: string;
  orderNumber?: string;
  realizationId?: number;
}

/**
 * An interface representing OrderRealizationDto.
 */
export interface OrderRealizationDto {
  id?: string;
  nhfRealizationId?: string;
  realizationId?: number;
}

/**
 * An interface representing OrderReferencesDto.
 */
export interface OrderReferencesDto {
  orderId?: string;
  /**
   * Possible values include: 'NOTE', 'CORRECTION_NOTE', 'REPORT_FILE', 'REPORT_CORRECTION_FILE',
   * 'RETURN_DOCUMENT'
   */
  referenceType?: OrderReferenceType;
  identifierId?: string;
  createdDate?: Date;
  details?: string;
}

/**
 * An interface representing PharmacyNotePredictedToCorrectionDto.
 */
export interface PharmacyNotePredictedToCorrectionDto {
  pharmacyTaxNoteId?: string;
  noteNumber?: string;
  placeId?: number;
  year?: number;
  month?: number;
  predictedCorrectionNoteValue?: number;
  predictedCorrectionInternalNumber?: string;
  pharmacyName?: string;
  pharmacyId?: string;
  note?: string;
}

/**
 * An interface representing PharmacyTaxNoteGroupManualOperationDto.
 */
export interface PharmacyTaxNoteGroupManualOperationDto {
  id?: string;
  /**
   * Possible values include: 'Return', 'Payment'
   */
  manualOperationType?: ManualOperationType;
  value?: number;
  date?: Date;
}

/**
 * An interface representing PharmacyTaxNoteGroupReturnDocumentDto.
 */
export interface PharmacyTaxNoteGroupReturnDocumentDto {
  id?: string;
  pharmacyTaxNoteGroupId?: string;
  documentNumber?: number;
  documentName?: string;
  documentId?: string;
}

/**
 * An interface representing PharmacyTaxNoteDto.
 */
export interface PharmacyTaxNoteDto {
  id?: string;
  mainPharmacyTaxNoteId?: string;
  noteDate?: Date;
  noteNumber?: string;
  noteValue?: number;
  lossValue?: number;
  returnValue?: number;
  overpayValue?: number;
  ordersValue?: number;
  settledOrdersValue?: number;
  /**
   * Possible values include: 'Main', 'Correction', 'Loss'
   */
  taxNoteType?: TaxNoteType;
  /**
   * Possible values include: 'Main', 'Correction', 'Debit', 'Loss', 'Return', 'PayLoss', 'Overpay'
   */
  internalTaxNoteType?: InternalTaxNoteType;
  /**
   * Possible values include: 'Phoenix', 'Portal'
   */
  taxNoteSource?: TaxNoteSource;
  /**
   * Possible values include: 'Created', 'ReadyToSend', 'SentToSap', 'Paid', 'Compensated'
   */
  paymentState?: PaymentState;
  hasCorrections?: boolean;
  internalNumber?: string;
  year?: number;
  month?: number;
  returnedValue?: number;
  paidValue?: number;
  createDate?: Date;
  hasOverpay?: boolean;
  isOverpayDisabled?: boolean;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly isOverpay?: boolean;
  confirmedBy?: string;
  noteValueSummary?: number;
  overpayValueSummary?: number;
  ordersValueSummary?: number;
  settledOrdersValueSummary?: number;
  lossValueSummary?: number;
  returnedValueSummary?: number;
  paidValueSummary?: number;
}

/**
 * An interface representing PharmacyTaxNotePaymentWithNotesDto.
 */
export interface PharmacyTaxNotePaymentWithNotesDto {
  id?: string;
  paymentId?: string;
  paymentName?: string;
  noteValueSum?: number;
  ordersValueSum?: number;
  settledOrdersValueSum?: number;
  /**
   * Possible values include: 'Created', 'ReadyToSend', 'SentToSap', 'Paid', 'Compensated'
   */
  paymentState?: PaymentState;
  /**
   * Possible values include: 'CONFIRMED', 'REFUSED', 'RECOVERED'
   */
  confirmationState?: PaymentConfirmationState;
  refuseDate?: Date;
  recoverDate?: Date;
  pharmacyTaxNotes?: PharmacyTaxNoteDto[];
}

/**
 * An interface representing PharmacyTaxNotePaymentConfirmationDto.
 */
export interface PharmacyTaxNotePaymentConfirmationDto {
  id?: string;
  pharmacyTaxNoteId?: string;
  amount?: number;
  confirmationDate?: Date;
}

/**
 * An interface representing ProductPriceSearchDto.
 */
export interface ProductPriceSearchDto {
  id?: string;
  productName?: string;
  productId?: number;
}

/**
 * An interface representing PriceListDto.
 */
export interface PriceListDto {
  id?: string;
  priceListId?: number;
  name?: string;
  voivodeshipId?: string;
  validFrom?: Date;
  validTo?: Date;
  /**
   * Possible values include: 'Auxiliaries', 'OrthopedicProducts'
   */
  priceListGroup?: PriceListGroup;
}

/**
 * An interface representing VoivodeshipContractDto.
 */
export interface VoivodeshipContractDto {
  id?: string;
  contractStartDate?: Date;
  contractEndDate?: Date;
  mainContractNumber?: string;
  contractNumber?: string;
  mainContractStartDate?: Date;
  mainContractEndDate?: Date;
}

/**
 * An interface representing VoivodeshipIdentifierDto.
 */
export interface VoivodeshipIdentifierDto {
  id?: string;
  identifier?: number;
  identifierStartDate?: Date;
  identifierEndDate?: Date;
}

/**
 * An interface representing VoivodeshipDetailsDto.
 */
export interface VoivodeshipDetailsDto {
  id?: string;
  sapId?: number;
  name?: string;
  shortName?: string;
  city?: string;
  street?: string;
  postalCode?: string;
  voivodeshipId?: number;
  voivodeshipName?: string;
  taxNumber?: string;
  phone?: string;
  phone2?: string;
  departmentId?: string;
  departmentNumber?: string;
  /**
   * Possible values include: 'Portal', 'SZOI'
   */
  portalSzoi?: PortalSzoiEnum;
  installationId?: string;
  serviceProviderCode?: string;
  user?: string;
  password?: string;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly currentContractId?: string;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly currentContractNumber?: string;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly currentMainContractNumber?: string;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly currentContractStartDate?: Date;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly currentContractEndDate?: Date;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly currentMainContractStartDate?: Date;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly currentMainContractEndDate?: Date;
  voivodeshipContracts?: VoivodeshipContractDto[];
  voivodeshipIdentifiers?: VoivodeshipIdentifierDto[];
}

/**
 * An interface representing VoivodeshipContactDto.
 */
export interface VoivodeshipContactDto {
  id?: string;
  phone?: string;
  email?: string;
  description?: string;
}

/**
 * An interface representing AccountSettingsDto.
 */
export interface AccountSettingsDto {
  voivodeshipFilterDictionaryIds?: string;
  settlementPeriodFilterDictionaryValueCodeName?: string;
}

/**
 * An interface representing AccountRoleDto.
 */
export interface AccountRoleDto {
  id?: string;
  name?: string;
  isSuperAdmin?: boolean;
  pharmacyId?: string;
}

/**
 * An interface representing AddressDto.
 */
export interface AddressDto {
  city?: string;
  street?: string;
  postalCode?: string;
}

/**
 * An interface representing AccountDto.
 */
export interface AccountDto {
  id?: string;
  displayName?: string;
  userPrincipalName?: string;
  mail?: string;
  settings?: AccountSettingsDto;
  roles?: AccountRoleDto[];
  address?: AddressDto;
}

/**
 * An interface representing AccountDtoArrayAzureListDto.
 */
export interface AccountDtoArrayAzureListDto {
  items?: AccountDto[];
  skipToken?: string;
}

/**
 * An interface representing AddVoivodeshipContactCommand.
 */
export interface AddVoivodeshipContactCommand {
  voivodeshipId?: string;
  phone?: string;
  email?: string;
  description?: string;
}

/**
 * An interface representing AssignBalancedPaymentConfirmationCommand.
 */
export interface AssignBalancedPaymentConfirmationCommand {
  pharmacyTaxNoteId?: string;
}

/**
 * An interface representing AssignPaymentConfirmationCommand.
 */
export interface AssignPaymentConfirmationCommand {
  pharmacyTaxNoteId?: string;
  amount?: number;
  confirmationDate?: Date;
}

/**
 * An interface representing ChangeStatePharmacyTaxNoteGroupCommand.
 */
export interface ChangeStatePharmacyTaxNoteGroupCommand {
  pharmacyTaxNoteGroupIds?: string[];
  /**
   * Possible values include: 'Created', 'ReadyToSend', 'SentToSap', 'Paid', 'Compensated'
   */
  paymentState?: PaymentState;
}

/**
 * An interface representing ChangeStatePharmacyTaxNotePaymentCommand.
 */
export interface ChangeStatePharmacyTaxNotePaymentCommand {
  paymentId?: string;
  /**
   * Possible values include: 'Created', 'ReadyToSend', 'SentToSap', 'Paid', 'Compensated'
   */
  paymentState?: PaymentState;
}

/**
 * An interface representing CreateCorrectionPharmacyTaxNoteCommand.
 */
export interface CreateCorrectionPharmacyTaxNoteCommand {
  pharmacyTaxNoteId?: string;
  noteDate?: Date;
  note?: string;
}

/**
 * An interface representing CreateDebitCorrectionPharmacyTaxNoteCommand.
 */
export interface CreateDebitCorrectionPharmacyTaxNoteCommand {
  pharmacyId?: string;
  year?: number;
  month?: number;
  noteDate?: Date;
  note?: string;
  pharmacyTaxNoteOrders?: string[];
}

/**
 * An interface representing CreateLossPharmacyTaxNoteCommand.
 */
export interface CreateLossPharmacyTaxNoteCommand {
  pharmacyTaxNoteId?: string;
  noteDate?: Date;
  noteValue?: number;
  note?: string;
}

/**
 * An interface representing CreateNoteResponseDto.
 */
export interface CreateNoteResponseDto {
  pharmacyTaxNoteId?: string;
  pharmacyTaxNoteGroupId?: string;
  pharmacyId?: string;
  pharmacyTaxNoteIdInternalNumber?: string;
  canBeConfirmedBy?: string;
}

/**
 * An interface representing CreatePayLossPharmacyTaxNoteCommand.
 */
export interface CreatePayLossPharmacyTaxNoteCommand {
  year?: number;
  month?: number;
  noteDate?: Date;
  noteValue?: number;
  note?: string;
  pharmacyId?: string;
}

/**
 * An interface representing CreatePharmacyCommand.
 */
export interface CreatePharmacyCommand {
  name?: string;
  companyName?: string;
  city?: string;
  street?: string;
  voivodeshipId?: number;
  taxNumber?: string;
  placeId?: number;
  endDateOfEssityContract?: Date;
  dateOfJoiningEssityNet?: Date;
  /**
   * Possible values include: 'PHARMACY', 'MEDICAL_SHOP'
   */
  type?: PharmacyType;
  endContractReasonId?: number;
  nhfContractId?: number;
  essityPaperContractNumber?: string;
  healthFundID?: string;
  farmapromPharmacyId?: number;
  kodKom?: number;
  login?: string;
  kam?: string;
  mail?: string;
  phoneNumber?: string;
  salesRepresentative?: string;
  postalCode?: string;
  district?: number;
  pharmacyChain?: string;
  description?: string;
  iban?: string;
  identifier?: number;
  businessContinuity?: string;
}

/**
 * An interface representing CreatePharmacyTaxNoteCommand.
 */
export interface CreatePharmacyTaxNoteCommand {
  year?: number;
  month?: number;
  noteDate?: Date;
  noteValue?: number;
  note?: string;
  pharmacyId?: string;
  pharmacyTaxNoteOrders?: string[];
  noteNumber?: string;
  fileName?: string;
  fileExtension?: string;
  mimeType?: string;
  data?: Uint8Array;
  isDocumentationComplete?: boolean;
}

/**
 * An interface representing CreatePharmacyTaxNoteCommandForPharmacy.
 */
export interface CreatePharmacyTaxNoteCommandForPharmacy {
  year?: number;
  month?: number;
  noteDate?: Date;
  noteValue?: number;
  note?: string;
  noteNumber?: string;
  fileName?: string;
  fileExtension?: string;
  mimeType?: string;
  data?: Uint8Array;
}

/**
 * An interface representing CreatePharmacyTaxNoteGroupManualOperationCommand.
 */
export interface CreatePharmacyTaxNoteGroupManualOperationCommand {
  pharmacyTaxNoteGroupId?: string;
  /**
   * Possible values include: 'Return', 'Payment'
   */
  manualOperationType?: ManualOperationType;
  value?: number;
  date?: Date;
}

/**
 * An interface representing CreatePharmacyTaxOverpayNoteCommand.
 */
export interface CreatePharmacyTaxOverpayNoteCommand {
  pharmacyTaxNoteId?: string;
  noteDate?: Date;
  note?: string;
}

/**
 * An interface representing DeletePaymentConfirmationCommand.
 */
export interface DeletePaymentConfirmationCommand {
  pharmacyTaxNotePaymentConfirmationId?: string;
}

/**
 * An interface representing DeletePaymentConfirmationsCommand.
 */
export interface DeletePaymentConfirmationsCommand {
  pharmacyTaxNoteId?: string;
}

/**
 * An interface representing DeletePharmacyTaxNoteCommand.
 */
export interface DeletePharmacyTaxNoteCommand {
  id?: string;
}

/**
 * An interface representing DeletePharmacyTaxNoteCommandForPharmacy.
 */
export interface DeletePharmacyTaxNoteCommandForPharmacy {
  id?: string;
}

/**
 * An interface representing DeletePharmacyTaxNoteGroupManualOperationCommand.
 */
export interface DeletePharmacyTaxNoteGroupManualOperationCommand {
  id?: string;
}

/**
 * An interface representing FarmapromPharmacyDto.
 */
export interface FarmapromPharmacyDto {
  id?: string;
  farmapromPharmacyId?: number;
  name?: string;
  street?: string;
  city?: string;
  postalCode?: string;
  voivodeshipId?: number;
  district?: number;
  kam?: string;
  salesRepresentative?: string;
  pharmacyChain?: string;
  importId?: string;
}

/**
 * An interface representing FarmapromPharmacyDtoPagedResult.
 */
export interface FarmapromPharmacyDtoPagedResult {
  queryable?: FarmapromPharmacyDto[];
  currentPage?: number;
  pageCount?: number;
  pageSize?: number;
  rowCount?: number;
}

/**
 * An interface representing FileResultDto.
 */
export interface FileResultDto {
  fileName?: string;
  fileExtension?: string;
  mimeType?: string;
  data?: Uint8Array;
}

/**
 * An interface representing GuidIIdDto.
 */
export interface GuidIIdDto {
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly id?: string;
}

/**
 * An interface representing MissingPharmacyDto.
 */
export interface MissingPharmacyDto {
  id?: number;
  name?: string;
}

/**
 * An interface representing ImportFarmapromPharmaciesStatusDto.
 */
export interface ImportFarmapromPharmaciesStatusDto {
  missingFarmapromIds?: MissingPharmacyDto[];
}

/**
 * An interface representing ImportUmxPharmaciesStatusDto.
 */
export interface ImportUmxPharmaciesStatusDto {
  missingKodKomsInDatabase?: MissingPharmacyDto[];
  missingKodKomsInFile?: MissingPharmacyDto[];
  kodKomsInWrongStates?: MissingPharmacyDto[];
}

/**
 * An interface representing InvitePharmacyCommand.
 */
export interface InvitePharmacyCommand {
  pharmacyId?: string;
}

/**
 * An interface representing MarkToCancelCommand.
 */
export interface MarkToCancelCommand {
  orderId?: string;
  cancellationReasonId?: number;
  cancellationReasonText?: string;
}

/**
 * An interface representing OrderLineDetailsDto.
 */
export interface OrderLineDetailsDto {
  qty?: number;
  productId?: number;
  productVersionNFZCode?: string;
  price?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly value?: number;
  productName?: string;
}

/**
 * An interface representing OrderErrorDto.
 */
export interface OrderErrorDto {
  id?: string;
  code?: string;
  description?: string;
}

/**
 * An interface representing OrderDetailsDto.
 */
export interface OrderDetailsDto {
  patientId?: string;
  patientCardNumber?: string;
  patientFirstname?: string;
  patientSurname?: string;
  patientDateOfBirth?: Date;
  isEUPatient?: boolean;
  pharmacyId?: string;
  pharmacyName?: string;
  placeId?: number;
  releasePlace?: string;
  orderNumber?: string;
  releaseDate?: Date;
  orderReceiptDate?: Date;
  productReleaseDate?: Date;
  realizationPeriod?: string;
  additionalEntitlementCode?: string;
  additionalEntitlementFullName?: string;
  orderLines?: OrderLineDetailsDto[];
  summaryRefund?: number;
  summarySurcharge?: number;
  summaryTotalValue?: number;
  year?: number;
  month?: number;
  /**
   * Possible values include: 'Imported', 'Reported', 'ApprovedByNhf', 'MarkedToCancel',
   * 'SentToCancel', 'Canceled', 'Restored', 'SettledNhf'
   */
  orderState?: OrderState;
  /**
   * Possible values include: 'NotAssigned', 'Assigned', 'ReadyToSend', 'SentToSap', 'Paid',
   * 'NotPaid'
   */
  taxNoteOrderState?: TaxNoteOrderState;
  orderErrors?: OrderErrorDto[];
  realizationId?: number;
  canceledRealizationId?: number;
  restoredRealizationId?: number;
  cancellationReasonId?: number;
  cancellationReasonText?: string;
}

/**
 * An interface representing OrderDtoPagedResult.
 */
export interface OrderDtoPagedResult {
  queryable?: OrderDto[];
  currentPage?: number;
  pageCount?: number;
  pageSize?: number;
  rowCount?: number;
}

/**
 * An interface representing OrderGroupDto.
 */
export interface OrderGroupDto {
  id?: string;
  year?: number;
  month?: number;
  voivodeshipId?: string;
  importDate?: Date;
  ordersQty?: number;
  value?: number;
  refund?: number;
  surcharge?: number;
  reportedQty?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly toReportQty?: number;
  toCorrectReportQty?: number;
  waitingForNhfCorrectAnswerQty?: number;
}

/**
 * An interface representing PharmacyDto.
 */
export interface PharmacyDto {
  id?: string;
  name?: string;
  companyName?: string;
  city?: string;
  street?: string;
  voivodeshipId?: number;
  taxNumber?: string;
  placeId?: number;
  endDateOfEssityContract?: Date;
  dateOfJoiningEssityNet?: Date;
  /**
   * Possible values include: 'PHARMACY', 'MEDICAL_SHOP'
   */
  type?: PharmacyType;
  endContractReasonId?: number;
  nhfContractId?: number;
  essityPaperContractNumber?: string;
  healthFundID?: string;
  farmapromPharmacyId?: number;
  kodKom?: number;
  login?: string;
  kam?: string;
  mail?: string;
  phoneNumber?: string;
  salesRepresentative?: string;
  postalCode?: string;
  district?: number;
  pharmacyChain?: string;
  description?: string;
  iban?: string;
  identifier?: number;
  umxWarning?: boolean;
  farmapromWarning?: boolean;
  businessContinuity?: string;
  accountId?: string;
  /**
   * Possible values include: 'Sent', 'NotSent', 'Access'
   */
  portalStatus?: PortalStatus;
  invitationCode?: string;
  isPharmacyHasNhfContract?: boolean;
  createdDate?: Date;
}

/**
 * An interface representing PharmacyDtoPagedResult.
 */
export interface PharmacyDtoPagedResult {
  queryable?: PharmacyDto[];
  currentPage?: number;
  pageCount?: number;
  pageSize?: number;
  rowCount?: number;
}

/**
 * An interface representing PharmacyTaxNoteOrderDetailsDto.
 */
export interface PharmacyTaxNoteOrderDetailsDto {
  id?: string;
  realizationId?: number;
  downloadId?: number;
  kodKom?: number;
  documentType?: string;
  orderNumber?: string;
  orderDate?: Date;
  realizationMonthsQty?: number;
  orderRealizationStartDate?: Date;
  pesel?: string;
  value?: number;
  refund?: number;
  reported?: boolean;
  reportDate?: Date;
  internalNumber?: number;
  pharmacyId?: string;
  pharmacyName?: string;
  /**
   * Possible values include: 'Imported', 'Reported', 'ApprovedByNhf', 'MarkedToCancel',
   * 'SentToCancel', 'Canceled', 'Restored', 'SettledNhf'
   */
  orderState?: OrderStateEnum;
}

/**
 * An interface representing PharmacyTaxNoteDetailsDto.
 */
export interface PharmacyTaxNoteDetailsDto {
  id?: string;
  pharmacy?: PharmacyDto;
  internalNumber?: string;
  pharmacyTaxNoteGroupId?: string;
  noteDate?: Date;
  noteNumber?: string;
  noteValue?: number;
  settledOrdersValue?: number;
  assignedOrdersValue?: number;
  /**
   * Possible values include: 'Main', 'Correction', 'Loss'
   */
  taxNoteType?: TaxNoteType;
  /**
   * Possible values include: 'Phoenix', 'Portal'
   */
  taxNoteSource?: TaxNoteSource;
  /**
   * Possible values include: 'Main', 'Correction', 'Debit', 'Loss', 'Return', 'PayLoss', 'Overpay'
   */
  internalTaxNoteType?: InternalTaxNoteType;
  year?: number;
  month?: number;
  fileName?: string;
  fileExtension?: string;
  mimeType?: string;
  data?: Uint8Array;
  orders?: PharmacyTaxNoteOrderDetailsDto[];
  mainPharmacyTaxNoteId?: string;
  mainPharmacyTaxNoteInternalNumber?: string;
  isDocumentationComplete?: boolean;
  note?: string;
  limitedEditMode?: boolean;
}

/**
 * An interface representing PharmacyTaxNoteGroupDto.
 */
export interface PharmacyTaxNoteGroupDto {
  id?: string;
  pharmacyId?: string;
  pharmacyName?: string;
  placeId?: number;
  year?: number;
  month?: number;
  voivodeshipId?: number;
  noteValue?: number;
  settledOrdersValue?: number;
  assignedOrdersValue?: number;
  allOrdersValue?: number;
  correctionValue?: number;
  lossValue?: number;
  toPay?: number;
  toReturn?: number;
  sentToSap?: number;
  returnProperty?: number;
  paid?: number;
  balance?: number;
  /**
   * Possible values include: 'Created', 'ReadyToSend', 'SentToSap', 'Paid', 'Compensated'
   */
  paymentState?: PaymentState;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly freezed?: boolean;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly isGroupValidAccordingToFinancialLogic?: boolean;
}

/**
 * An interface representing PharmacyTaxNoteGroupDtoPagedResult.
 */
export interface PharmacyTaxNoteGroupDtoPagedResult {
  queryable?: PharmacyTaxNoteGroupDto[];
  currentPage?: number;
  pageCount?: number;
  pageSize?: number;
  rowCount?: number;
}

/**
 * An interface representing PharmacyTaxNotePaymentConfirmationChangeStateCommand.
 */
export interface PharmacyTaxNotePaymentConfirmationChangeStateCommand {
  pharmacyTaxNotePaymentId?: string;
  /**
   * Possible values include: 'CONFIRMED', 'REFUSED', 'RECOVERED'
   */
  confirmationState?: PaymentConfirmationState;
  refuseDate?: Date;
  recoverDate?: Date;
}

/**
 * An interface representing ProductDto.
 */
export interface ProductDto {
  id?: string;
  productId?: number;
  productVersionCode?: string;
  productVersionNFZCode?: string;
  productVersionPlace?: string;
  productName?: string;
  /**
   * Possible values include: 'Auxiliaries', 'OrthopedicProducts'
   */
  priceListGroup?: PriceListGroup;
}

/**
 * An interface representing ProductPriceDto.
 */
export interface ProductPriceDto {
  id?: string;
  productNHFCode?: number;
  productVersionNFZCode?: string;
  productName?: string;
  price?: number;
  unit?: boolean;
  qtyLimit?: number;
  producent?: string;
  model?: string;
  individualOrder?: boolean;
}

/**
 * An interface representing ProductPriceDtoPagedResult.
 */
export interface ProductPriceDtoPagedResult {
  queryable?: ProductPriceDto[];
  currentPage?: number;
  pageCount?: number;
  pageSize?: number;
  rowCount?: number;
}

/**
 * An interface representing RecountPharmacyTaxNoteCorrectionCommand.
 */
export interface RecountPharmacyTaxNoteCorrectionCommand {
  pharmacyTaxNoteId?: string;
}

/**
 * An interface representing RecountPharmacyTaxNoteOverpayCommand.
 */
export interface RecountPharmacyTaxNoteOverpayCommand {
  pharmacyTaxNoteId?: string;
}

/**
 * An interface representing RegisterAccountCommand.
 */
export interface RegisterAccountCommand {
  password?: string;
  accountRoles?: AccountRoleDto[];
  userPrincipalName?: string;
  displayName?: string;
  givenName?: string;
  surname?: string;
}

/**
 * An interface representing RegisterPharmacyCommand.
 */
export interface RegisterPharmacyCommand {
  password?: string;
  pharmacyId?: string;
  invitationCode?: string;
}

/**
 * An interface representing RestoreCommand.
 */
export interface RestoreCommand {
  orderId?: string;
}

/**
 * An interface representing ReturnDocument.
 */
export interface ReturnDocument {
  orderId?: string;
  orderNumber?: string;
  pesel?: string;
  errorMessage?: string;
  orderRefund?: number;
}

/**
 * An interface representing SaveAccountSettingsCommand.
 */
export interface SaveAccountSettingsCommand {
  voivodeshipFilterDictionaryIds?: string;
  settlementPeriodFilterDictionaryValueCodeName?: string;
}

/**
 * An interface representing SaveAndGenerateReturnDocumentCommand.
 */
export interface SaveAndGenerateReturnDocumentCommand {
  pharmacyTaxNoteGroupId?: string;
  returnDocuments?: ReturnDocument[];
}

/**
 * An interface representing SendToSAPCommand.
 */
export interface SendToSAPCommand {
  checked?: string[];
}

/**
 * An interface representing VoivodeshipPaymentGroupWithHashDto.
 */
export interface VoivodeshipPaymentGroupWithHashDto {
  id?: string;
  hashCode?: number;
}

/**
 * An interface representing SendToSapAgainVoivodeshipPaymentGroupCommand.
 */
export interface SendToSapAgainVoivodeshipPaymentGroupCommand {
  voivodeshipPaymentGroupsWithHash?: VoivodeshipPaymentGroupWithHashDto[];
  postingDate?: Date;
}

/**
 * An interface representing SendToSapVoivodeshipPaymentGroupCommand.
 */
export interface SendToSapVoivodeshipPaymentGroupCommand {
  voivodeshipPaymentGroupsWithHash?: VoivodeshipPaymentGroupWithHashDto[];
  postingDate?: Date;
}

/**
 * An interface representing SourceDocumentDto.
 */
export interface SourceDocumentDto {
  id?: string;
  /**
   * Possible values include: 'PHARMACIES_IMPORT', 'FARMAPROM_PHARMACIES_IMPORT',
   * 'UMX_PHARMACIES_IMPORT', 'ORDERS_IMPORT', 'PRICE_LIST_IMPORT', 'ZPOSP_EXPORT',
   * 'PHARMACIES_IMPORT_TEMPLATE', 'NHF_STATE_IMPORT', 'NOTE_TEMPLATE_IMPORT', 'NOTE_EXPORT',
   * 'PAYMENT_EXPORT', 'PAYMENT_CONFIRMATIONS_IMPORT', 'RETURN_DOCUMENT', 'ZPOZR_IMPORT'
   */
  source?: DocumentSource;
  /**
   * Possible values include: 'IMPORT', 'EXPORT'
   */
  category?: DocumentCategory;
  createdDate?: Date;
  name?: string;
  createdBy?: string;
  createdByUserId?: string;
}

/**
 * An interface representing SourceDocumentDtoPagedResult.
 */
export interface SourceDocumentDtoPagedResult {
  queryable?: SourceDocumentDto[];
  currentPage?: number;
  pageCount?: number;
  pageSize?: number;
  rowCount?: number;
}

/**
 * An interface representing TemplateNoteDto.
 */
export interface TemplateNoteDto {
  id?: string;
  voivodeshipId?: number;
  templateId?: number;
  templateCorrectId?: number;
  year?: number;
  month?: number;
  /**
   * Possible values include: 'Main', 'Correction'
   */
  noteType?: NoteType;
  noteName?: string;
  noteDate?: Date;
  totalValue?: number;
  refund?: number;
  surcharge?: number;
  noteCreated?: boolean;
  noteFileId?: string;
  sent?: boolean;
}

/**
 * An interface representing TemplateNoteInfoDto.
 */
export interface TemplateNoteInfoDto {
  id?: string;
  templateId?: number;
  noteName?: string;
}

/**
 * An interface representing UmxPharmacyDto.
 */
export interface UmxPharmacyDto {
  id?: string;
  technicalCode?: string;
  dateFrom?: Date;
  dateTo?: Date;
  kodKom?: number;
  name?: string;
  city?: string;
  postalCode?: string;
  voivodeshipId?: number;
  importId?: string;
  identifier?: number;
  street?: string;
  healthFundID?: string;
}

/**
 * An interface representing UmxPharmacyDtoPagedResult.
 */
export interface UmxPharmacyDtoPagedResult {
  results?: UmxPharmacyDto[];
  rowCount?: number;
  pageSize?: number;
  currentPage?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly totalPagesNumber?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly hasPreviousPage?: boolean;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly hasNextPage?: boolean;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly firstRowOnPage?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly lastRowOnPage?: number;
}

/**
 * An interface representing UpdateLossPharmacyTaxNoteCommand.
 */
export interface UpdateLossPharmacyTaxNoteCommand {
  pharmacyTaxNoteId?: string;
  noteDate?: Date;
  noteValue?: number;
  note?: string;
}

/**
 * An interface representing UpdateNoteResponseDto.
 */
export interface UpdateNoteResponseDto {
  pharmacyId?: string;
  pharmacyTaxNoteGroupId?: string;
}

/**
 * An interface representing UpdatePayLossPharmacyTaxNoteCommand.
 */
export interface UpdatePayLossPharmacyTaxNoteCommand {
  pharmacyTaxNoteId?: string;
  noteDate?: Date;
  noteValue?: number;
  note?: string;
}

/**
 * An interface representing UpdatePharmacyCommand.
 */
export interface UpdatePharmacyCommand {
  id?: string;
  name?: string;
  companyName?: string;
  city?: string;
  street?: string;
  voivodeshipId?: number;
  taxNumber?: string;
  /**
   * Possible values include: 'PHARMACY', 'MEDICAL_SHOP'
   */
  type?: PharmacyType;
  endDateOfEssityContract?: Date;
  dateOfJoiningEssityNet?: Date;
  endContractReasonId?: number;
  nhfContractId?: number;
  essityPaperContractNumber?: string;
  healthFundID?: string;
  farmapromPharmacyId?: number;
  kodKom?: number;
  login?: string;
  kam?: string;
  mail?: string;
  phoneNumber?: string;
  salesRepresentative?: string;
  postalCode?: string;
  district?: number;
  pharmacyChain?: string;
  description?: string;
  iban?: string;
  identifier?: number;
  businessContinuity?: string;
}

/**
 * An interface representing UpdatePharmacyTaxNoteCommand.
 */
export interface UpdatePharmacyTaxNoteCommand {
  pharmacyTaxNoteId?: string;
  noteDate?: Date;
  pharmacyTaxNoteOrders?: string[];
  note?: string;
  noteNumber?: string;
  noteValue?: number;
  fileName?: string;
  fileExtension?: string;
  mimeType?: string;
  data?: Uint8Array;
  isDocumentationComplete?: boolean;
}

/**
 * An interface representing UpdatePharmacyTaxNoteCommandForPharmacy.
 */
export interface UpdatePharmacyTaxNoteCommandForPharmacy {
  pharmacyTaxNoteId?: string;
  noteDate?: Date;
  noteNumber?: string;
  noteValue?: number;
  fileName?: string;
  fileExtension?: string;
  mimeType?: string;
  data?: Uint8Array;
}

/**
 * An interface representing UpdatePharmacyTaxNoteCorrectionCommand.
 */
export interface UpdatePharmacyTaxNoteCorrectionCommand {
  pharmacyTaxNoteId?: string;
  noteDate?: Date;
  pharmacyTaxNoteOrders?: string[];
  note?: string;
}

/**
 * An interface representing UpdatePharmacyTaxNoteGroupManualOperationCommand.
 */
export interface UpdatePharmacyTaxNoteGroupManualOperationCommand {
  id?: string;
  /**
   * Possible values include: 'Return', 'Payment'
   */
  manualOperationType?: ManualOperationType;
  value?: number;
  date?: Date;
}

/**
 * An interface representing UpdatePharmacyTaxNotePaymentConfirmationCommand.
 */
export interface UpdatePharmacyTaxNotePaymentConfirmationCommand {
  pharmacyTaxNotePaymentConfirmationId?: string;
  amount?: number;
  confirmationDate?: Date;
}

/**
 * An interface representing UpdatePharmacyTaxOverpayNoteCommand.
 */
export interface UpdatePharmacyTaxOverpayNoteCommand {
  pharmacyTaxNoteId?: string;
  noteDate?: Date;
  note?: string;
}

/**
 * An interface representing UpdateSAPIdentifierCommand.
 */
export interface UpdateSAPIdentifierCommand {
  pharmacyId?: string;
  sapId?: number;
}

/**
 * An interface representing UpdateVoivodeshipCommand.
 */
export interface UpdateVoivodeshipCommand {
  id?: string;
  serviceProviderCode?: string;
  user?: string;
  password?: string;
  currentContractId?: string;
  currentMainContractStartDate?: Date;
  currentMainContractEndDate?: Date;
}

/**
 * An interface representing VoivodeshipPaymentGroupInternalIdDto.
 */
export interface VoivodeshipPaymentGroupInternalIdDto {
  id?: string;
  internalId?: number;
  postingDate?: Date;
}

/**
 * An interface representing VoivodeshipPaymentGroupDto.
 */
export interface VoivodeshipPaymentGroupDto {
  id?: string;
  internalId?: number;
  voivodeshipId?: number;
  year?: number;
  month?: number;
  amount?: number;
  /**
   * Possible values include: 'Created', 'ReadyToSend', 'SentToSap', 'Paid', 'Compensated'
   */
  voivodeshipPaymentState?: PaymentState;
  sentDate?: Date;
  postingDate?: Date;
  paymentDate?: Date;
  previousInternalIds?: VoivodeshipPaymentGroupInternalIdDto[];
  hashCode?: number;
}

/**
 * An interface representing VoivodeshipPaymentGroupDtoPagedResult.
 */
export interface VoivodeshipPaymentGroupDtoPagedResult {
  results?: VoivodeshipPaymentGroupDto[];
  rowCount?: number;
  pageSize?: number;
  currentPage?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly totalPagesNumber?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly hasPreviousPage?: boolean;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly hasNextPage?: boolean;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly firstRowOnPage?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly lastRowOnPage?: number;
}

/**
 * An interface representing PolandPharmacyRxAPIOptions.
 */
export interface PolandPharmacyRxAPIOptions extends ServiceClientOptions {
  baseUri?: string;
}

/**
 * Optional Parameters.
 */
export interface AccountRegisterUserOptionalParams extends msRest.RequestOptionsBase {
  body?: RegisterAccountCommand;
}

/**
 * Optional Parameters.
 */
export interface AccountRegisterPharmacyOptionalParams extends msRest.RequestOptionsBase {
  body?: RegisterPharmacyCommand;
}

/**
 * Optional Parameters.
 */
export interface AccountInvitePharmacyOptionalParams extends msRest.RequestOptionsBase {
  body?: InvitePharmacyCommand;
}

/**
 * Optional Parameters.
 */
export interface AccountSaveSettingsOptionalParams extends msRest.RequestOptionsBase {
  body?: SaveAccountSettingsCommand;
}

/**
 * Optional Parameters.
 */
export interface AccountGetUsersOptionalParams extends msRest.RequestOptionsBase {
  searchText?: string;
  skipToken?: string;
}

/**
 * Optional Parameters.
 */
export interface AccountDeleteMethodOptionalParams extends msRest.RequestOptionsBase {
  userId?: string;
}

/**
 * Optional Parameters.
 */
export interface DictionaryGetByCodeNamesOptionalParams extends msRest.RequestOptionsBase {
  codeNames?: string[];
}

/**
 * Optional Parameters.
 */
export interface DocumentAllForPharmacyOptionalParams extends msRest.RequestOptionsBase {
  filters?: string;
  sorts?: string;
  page?: number;
  pageSize?: number;
  /**
   * Possible values include: 'IMPORT', 'EXPORT'
   */
  documentCategory?: DocumentCategory;
}

/**
 * Optional Parameters.
 */
export interface DocumentAllOptionalParams extends msRest.RequestOptionsBase {
  filters?: string;
  sorts?: string;
  page?: number;
  pageSize?: number;
  /**
   * Possible values include: 'IMPORT', 'EXPORT'
   */
  documentCategory?: DocumentCategory;
}

/**
 * Optional Parameters.
 */
export interface DocumentGetZippedByIdOptionalParams extends msRest.RequestOptionsBase {
  id?: string;
  zipExtension?: string;
}

/**
 * Optional Parameters.
 */
export interface ExportModelGetZPOSPListOptionalParams extends msRest.RequestOptionsBase {
  checked?: string[];
  /**
   * Possible values include: 'XLSX', 'PDF', 'CSV', 'JSON', 'XML'
   */
  fileType?: FileType;
  correctReport?: boolean;
}

/**
 * Optional Parameters.
 */
export interface ExportModelGetTemplateNoteOptionalParams extends msRest.RequestOptionsBase {
  checked?: string[];
  /**
   * Possible values include: 'XLSX', 'PDF', 'CSV', 'JSON', 'XML'
   */
  fileType?: FileType;
}

/**
 * Optional Parameters.
 */
export interface FarmapromPharmacyAllOptionalParams extends msRest.RequestOptionsBase {
  filters?: string;
  sorts?: string;
  page?: number;
  pageSize?: number;
}

/**
 * Optional Parameters.
 */
export interface ImportModelImportOrdersOptionalParams extends msRest.RequestOptionsBase {
  file?: msRest.HttpRequestBody;
}

/**
 * Optional Parameters.
 */
export interface ImportModelImportZPOZWOrdersOptionalParams extends msRest.RequestOptionsBase {
  file?: msRest.HttpRequestBody;
}

/**
 * Optional Parameters.
 */
export interface ImportModelImportZPOZROrdersOptionalParams extends msRest.RequestOptionsBase {
  file?: msRest.HttpRequestBody;
}

/**
 * Optional Parameters.
 */
export interface ImportModelImportNhfStateOptionalParams extends msRest.RequestOptionsBase {
  file?: msRest.HttpRequestBody;
}

/**
 * Optional Parameters.
 */
export interface ImportModelImportPriceListOptionalParams extends msRest.RequestOptionsBase {
  file?: msRest.HttpRequestBody;
  validFrom?: Date;
  priceListGroup?: any;
}

/**
 * Optional Parameters.
 */
export interface ImportModelImportPharmaciesOptionalParams extends msRest.RequestOptionsBase {
  file?: msRest.HttpRequestBody;
}

/**
 * Optional Parameters.
 */
export interface ImportModelImportFarmapromPharmaciesOptionalParams extends msRest.RequestOptionsBase {
  file?: msRest.HttpRequestBody;
}

/**
 * Optional Parameters.
 */
export interface ImportModelImportUmxPharmaciesOptionalParams extends msRest.RequestOptionsBase {
  file?: msRest.HttpRequestBody;
}

/**
 * Optional Parameters.
 */
export interface ImportModelImportNhfNoteTemplateOptionalParams extends msRest.RequestOptionsBase {
  file?: msRest.HttpRequestBody;
}

/**
 * Optional Parameters.
 */
export interface ImportModelImportPaymentSAPConfirmationOptionalParams extends msRest.RequestOptionsBase {
  file?: msRest.HttpRequestBody;
}

/**
 * Optional Parameters.
 */
export interface OrderAllForPharmacyOptionalParams extends msRest.RequestOptionsBase {
  filters?: string;
  sorts?: string;
  page?: number;
  pageSize?: number;
  selectedIds?: string[];
}

/**
 * Optional Parameters.
 */
export interface OrderSearchPagedForPharmacyOptionalParams extends msRest.RequestOptionsBase {
  sieveFilters?: string;
  sieveSorts?: string;
  sievePage?: number;
  sievePageSize?: number;
  searchText?: string;
  /**
   * Possible values include: 'PESEL', 'OrderNumber', 'Status'
   */
  searchType?: SearchType;
  fromDate?: Date;
  toDate?: Date;
  orderStates?: OrderState[];
  voivodeshipIds?: number[];
}

/**
 * Optional Parameters.
 */
export interface OrderAllOptionalParams extends msRest.RequestOptionsBase {
  filters?: string;
  sorts?: string;
  page?: number;
  pageSize?: number;
  orderGroupId?: string;
  selectedIds?: string[];
}

/**
 * Optional Parameters.
 */
export interface OrderGetAllPaginatedOrdersForPharmacyInDateScopeOptionalParams extends msRest.RequestOptionsBase {
  filters?: string;
  sorts?: string;
  page?: number;
  pageSize?: number;
  pharmacyId?: string;
  year?: number;
  month?: number;
  includeOrdersAssignedToPharmacyTaxNoteId?: string;
}

/**
 * Optional Parameters.
 */
export interface OrderGetAllNotAssignedOrdersForPharmacyInDateScopeOptionalParams extends msRest.RequestOptionsBase {
  pharmacyId?: string;
  year?: number;
  month?: number;
  includeOrdersAssignedToPharmacyTaxNoteId?: string;
}

/**
 * Optional Parameters.
 */
export interface OrderSearchOptionalParams extends msRest.RequestOptionsBase {
  orderGroupId?: string;
  searchText?: string;
}

/**
 * Optional Parameters.
 */
export interface OrderSearchPagedOptionalParams extends msRest.RequestOptionsBase {
  sieveFilters?: string;
  sieveSorts?: string;
  sievePage?: number;
  sievePageSize?: number;
  searchText?: string;
  /**
   * Possible values include: 'PESEL', 'OrderNumber', 'Status'
   */
  searchType?: SearchType;
  fromDate?: Date;
  toDate?: Date;
  orderStates?: OrderState[];
  voivodeshipIds?: number[];
}

/**
 * Optional Parameters.
 */
export interface OrderMarkToCancelOptionalParams extends msRest.RequestOptionsBase {
  body?: MarkToCancelCommand;
}

/**
 * Optional Parameters.
 */
export interface OrderRestoreOptionalParams extends msRest.RequestOptionsBase {
  body?: RestoreCommand;
}

/**
 * Optional Parameters.
 */
export interface OrderGetOrdersRealizationByNhfRealizationOptionalParams extends msRest.RequestOptionsBase {
  nhfRealizationId?: string;
}

/**
 * Optional Parameters.
 */
export interface OrderGetOrderReferencesByOrderIdOptionalParams extends msRest.RequestOptionsBase {
  orderId?: string;
}

/**
 * Optional Parameters.
 */
export interface OrderGetAllPaginatedCancelledOrdersByPharmacyIdQueryOptionalParams extends msRest.RequestOptionsBase {
  filters?: string;
  sorts?: string;
  page?: number;
  pageSize?: number;
  pharmacyId?: string;
  year?: number;
  month?: number;
}

/**
 * Optional Parameters.
 */
export interface OrderHasAnyCancelledOrdersByPharmacyIdQueryOptionalParams extends msRest.RequestOptionsBase {
  pharmacyId?: string;
  year?: number;
  month?: number;
}

/**
 * Optional Parameters.
 */
export interface OrderGroupAllOptionalParams extends msRest.RequestOptionsBase {
  filters?: string;
  sorts?: string;
  page?: number;
  pageSize?: number;
  settlementPeriodFilterCodeName?: string;
  selectedIds?: string[];
}

/**
 * Optional Parameters.
 */
export interface PharmaciesUpdateSAPIdentifierOptionalParams extends msRest.RequestOptionsBase {
  body?: UpdateSAPIdentifierCommand;
}

/**
 * Optional Parameters.
 */
export interface PharmacyAllOptionalParams extends msRest.RequestOptionsBase {
  filters?: string;
  sorts?: string;
  page?: number;
  pageSize?: number;
  selectedIds?: string[];
}

/**
 * Optional Parameters.
 */
export interface PharmacyCreateOptionalParams extends msRest.RequestOptionsBase {
  body?: CreatePharmacyCommand;
}

/**
 * Optional Parameters.
 */
export interface PharmacyUpdateOptionalParams extends msRest.RequestOptionsBase {
  body?: UpdatePharmacyCommand;
}

/**
 * Optional Parameters.
 */
export interface PharmacyPharmaciesWithUnasignedOrdersOptionalParams extends msRest.RequestOptionsBase {
  filters?: string;
  sorts?: string;
  page?: number;
  pageSize?: number;
}

/**
 * Optional Parameters.
 */
export interface PharmacyTaxNoteCreateForPharmacyOptionalParams extends msRest.RequestOptionsBase {
  body?: CreatePharmacyTaxNoteCommandForPharmacy;
}

/**
 * Optional Parameters.
 */
export interface PharmacyTaxNoteUpdateForPharmacyOptionalParams extends msRest.RequestOptionsBase {
  body?: UpdatePharmacyTaxNoteCommandForPharmacy;
}

/**
 * Optional Parameters.
 */
export interface PharmacyTaxNoteDeleteForPharmacyOptionalParams extends msRest.RequestOptionsBase {
  body?: DeletePharmacyTaxNoteCommandForPharmacy;
}

/**
 * Optional Parameters.
 */
export interface PharmacyTaxNoteCreateOptionalParams extends msRest.RequestOptionsBase {
  body?: CreatePharmacyTaxNoteCommand;
}

/**
 * Optional Parameters.
 */
export interface PharmacyTaxNoteDeleteMethodOptionalParams extends msRest.RequestOptionsBase {
  body?: DeletePharmacyTaxNoteCommand;
}

/**
 * Optional Parameters.
 */
export interface PharmacyTaxNoteUpdateOptionalParams extends msRest.RequestOptionsBase {
  body?: UpdatePharmacyTaxNoteCommand;
}

/**
 * Optional Parameters.
 */
export interface PharmacyTaxNoteCreateCorrectionOptionalParams extends msRest.RequestOptionsBase {
  body?: CreateCorrectionPharmacyTaxNoteCommand;
}

/**
 * Optional Parameters.
 */
export interface PharmacyTaxNoteCreateDebitCorrectionOptionalParams extends msRest.RequestOptionsBase {
  body?: CreateDebitCorrectionPharmacyTaxNoteCommand;
}

/**
 * Optional Parameters.
 */
export interface PharmacyTaxNoteCreateLossOptionalParams extends msRest.RequestOptionsBase {
  body?: CreateLossPharmacyTaxNoteCommand;
}

/**
 * Optional Parameters.
 */
export interface PharmacyTaxNoteCreatePayLossOptionalParams extends msRest.RequestOptionsBase {
  body?: CreatePayLossPharmacyTaxNoteCommand;
}

/**
 * Optional Parameters.
 */
export interface PharmacyTaxNoteUpdatePayLossOptionalParams extends msRest.RequestOptionsBase {
  body?: UpdatePayLossPharmacyTaxNoteCommand;
}

/**
 * Optional Parameters.
 */
export interface PharmacyTaxNoteUpdateLossOptionalParams extends msRest.RequestOptionsBase {
  body?: UpdateLossPharmacyTaxNoteCommand;
}

/**
 * Optional Parameters.
 */
export interface PharmacyTaxNoteUpdateCorrectionOptionalParams extends msRest.RequestOptionsBase {
  body?: UpdatePharmacyTaxNoteCorrectionCommand;
}

/**
 * Optional Parameters.
 */
export interface PharmacyTaxNoteUpdateOverpayOptionalParams extends msRest.RequestOptionsBase {
  body?: UpdatePharmacyTaxOverpayNoteCommand;
}

/**
 * Optional Parameters.
 */
export interface PharmacyTaxNoteGetPharmacyTaxNotesPredictedToCorrectionOptionalParams extends msRest.RequestOptionsBase {
  pharmacyTaxNoteGroupId?: string;
  /**
   * Possible values include: 'Main', 'Correction', 'Debit', 'Loss', 'Return', 'PayLoss', 'Overpay'
   */
  internalTaxNoteType?: InternalTaxNoteType;
}

/**
 * Optional Parameters.
 */
export interface PharmacyTaxNoteGetPredictedReturnOverpayOptionalParams extends msRest.RequestOptionsBase {
  pharmacyTaxNoteId?: string;
}

/**
 * Optional Parameters.
 */
export interface PharmacyTaxNoteRecountPharmacyTaxNoteCorrectionOptionalParams extends msRest.RequestOptionsBase {
  body?: RecountPharmacyTaxNoteCorrectionCommand;
}

/**
 * Optional Parameters.
 */
export interface PharmacyTaxNoteRecountPharmacyTaxNoteOverpayOptionalParams extends msRest.RequestOptionsBase {
  body?: RecountPharmacyTaxNoteOverpayCommand;
}

/**
 * Optional Parameters.
 */
export interface PharmacyTaxNoteAssignBalancedPaymentConfirmationOptionalParams extends msRest.RequestOptionsBase {
  body?: AssignBalancedPaymentConfirmationCommand;
}

/**
 * Optional Parameters.
 */
export interface PharmacyTaxNoteCreateOverpayOptionalParams extends msRest.RequestOptionsBase {
  body?: CreatePharmacyTaxOverpayNoteCommand;
}

/**
 * Optional Parameters.
 */
export interface PharmacyTaxNoteGroupAllForPharmacyOptionalParams extends msRest.RequestOptionsBase {
  filters?: string;
  sorts?: string;
  page?: number;
  pageSize?: number;
}

/**
 * Optional Parameters.
 */
export interface PharmacyTaxNoteGroupAllOptionalParams extends msRest.RequestOptionsBase {
  filters?: string;
  sorts?: string;
  page?: number;
  pageSize?: number;
  /**
   * Possible values include: 'CorrectOnly', 'NotCorrectOnly', 'All'
   */
  businessObjectsState?: BusinessObjectsState;
  settlementPeriodFilterCodeName?: string;
  zeroBalance?: boolean;
}

/**
 * Optional Parameters.
 */
export interface PharmacyTaxNoteGroupChangeStateOptionalParams extends msRest.RequestOptionsBase {
  body?: ChangeStatePharmacyTaxNoteGroupCommand;
}

/**
 * Optional Parameters.
 */
export interface PharmacyTaxNoteGroupManualOperationAllOptionalParams extends msRest.RequestOptionsBase {
  pharmacyTaxNoteGroupId?: string;
}

/**
 * Optional Parameters.
 */
export interface PharmacyTaxNoteGroupManualOperationCreateOptionalParams extends msRest.RequestOptionsBase {
  body?: CreatePharmacyTaxNoteGroupManualOperationCommand;
}

/**
 * Optional Parameters.
 */
export interface PharmacyTaxNoteGroupManualOperationUpdateOptionalParams extends msRest.RequestOptionsBase {
  body?: UpdatePharmacyTaxNoteGroupManualOperationCommand;
}

/**
 * Optional Parameters.
 */
export interface PharmacyTaxNoteGroupManualOperationDeleteMethodOptionalParams extends msRest.RequestOptionsBase {
  body?: DeletePharmacyTaxNoteGroupManualOperationCommand;
}

/**
 * Optional Parameters.
 */
export interface PharmacyTaxNoteGroupManualOperationHasAnyManualOperationOptionalParams extends msRest.RequestOptionsBase {
  pharmacyTaxNoteGroupId?: string;
}

/**
 * Optional Parameters.
 */
export interface PharmacyTaxNoteGroupReturnDocumentSaveAndGenerateReturnDocumentOptionalParams extends msRest.RequestOptionsBase {
  body?: SaveAndGenerateReturnDocumentCommand;
}

/**
 * Optional Parameters.
 */
export interface PharmacyTaxNoteGroupReturnDocumentDeleteReturnDocumentOptionalParams extends msRest.RequestOptionsBase {
  returnDocumentId?: string;
}

/**
 * Optional Parameters.
 */
export interface PharmacyTaxNotePaymentGetPharmacyTaxNotePaymentWithNotesForPharmacyOptionalParams extends msRest.RequestOptionsBase {
  pharmacyTaxNoteGroupId?: string;
}

/**
 * Optional Parameters.
 */
export interface PharmacyTaxNotePaymentGetPharmacyTaxNotePaymentWithNotesOptionalParams extends msRest.RequestOptionsBase {
  pharmacyTaxNoteGroupId?: string;
}

/**
 * Optional Parameters.
 */
export interface PharmacyTaxNotePaymentChangeStateOptionalParams extends msRest.RequestOptionsBase {
  body?: ChangeStatePharmacyTaxNotePaymentCommand;
}

/**
 * Optional Parameters.
 */
export interface PharmacyTaxNotePaymentConfirmationAssignPaymentConfirmationOptionalParams extends msRest.RequestOptionsBase {
  body?: AssignPaymentConfirmationCommand;
}

/**
 * Optional Parameters.
 */
export interface PharmacyTaxNotePaymentConfirmationChangeStateOptionalParams extends msRest.RequestOptionsBase {
  body?: PharmacyTaxNotePaymentConfirmationChangeStateCommand;
}

/**
 * Optional Parameters.
 */
export interface PharmacyTaxNotePaymentConfirmationUpdatePaymentConfirmationOptionalParams extends msRest.RequestOptionsBase {
  body?: UpdatePharmacyTaxNotePaymentConfirmationCommand;
}

/**
 * Optional Parameters.
 */
export interface PharmacyTaxNotePaymentConfirmationDeletePaymentConfirmationOptionalParams extends msRest.RequestOptionsBase {
  body?: DeletePaymentConfirmationCommand;
}

/**
 * Optional Parameters.
 */
export interface PharmacyTaxNotePaymentConfirmationDeletePaymentConfirmationsOptionalParams extends msRest.RequestOptionsBase {
  body?: DeletePaymentConfirmationsCommand;
}

/**
 * Optional Parameters.
 */
export interface PriceAllOptionalParams extends msRest.RequestOptionsBase {
  filters?: string;
  sorts?: string;
  page?: number;
  pageSize?: number;
  priceListId?: string;
}

/**
 * Optional Parameters.
 */
export interface PriceSearchOptionalParams extends msRest.RequestOptionsBase {
  priceListId?: string;
  searchText?: string;
}

/**
 * Optional Parameters.
 */
export interface PriceListAllOptionalParams extends msRest.RequestOptionsBase {
  /**
   * Possible values include: 'Auxiliaries', 'OrthopedicProducts'
   */
  priceListGroup?: PriceListGroup;
  voivodeshipId?: number[];
}

/**
 * Optional Parameters.
 */
export interface PriceListDeleteMethodOptionalParams extends msRest.RequestOptionsBase {
  priceListId?: string;
}

/**
 * Optional Parameters.
 */
export interface ProductAllOptionalParams extends msRest.RequestOptionsBase {
  filters?: string;
  sorts?: string;
  page?: number;
  pageSize?: number;
  selectedIds?: string[];
}

/**
 * Optional Parameters.
 */
export interface ReportGetOrdersOptionalParams extends msRest.RequestOptionsBase {
  year?: number;
  dateFrom?: Date;
  dateTo?: Date;
  sieveFilters?: string;
  sieveSorts?: string;
  sievePage?: number;
  sievePageSize?: number;
  isCheckedAll?: boolean;
  checked?: string[];
  unchecked?: string[];
  /**
   * Possible values include: 'XLSX', 'PDF', 'CSV', 'JSON', 'XML'
   */
  fileType?: FileType;
}

/**
 * Optional Parameters.
 */
export interface ReportGetPharmaciesOptionalParams extends msRest.RequestOptionsBase {
  sieveFilters?: string;
  sieveSorts?: string;
  sievePage?: number;
  sievePageSize?: number;
  isCheckedAll?: boolean;
  checked?: string[];
  unchecked?: string[];
  /**
   * Possible values include: 'XLSX', 'PDF', 'CSV', 'JSON', 'XML'
   */
  fileType?: FileType;
}

/**
 * Optional Parameters.
 */
export interface ReportGetMonthlyBalanceOptionalParams extends msRest.RequestOptionsBase {
  year?: number;
  month?: number;
  detailedReport?: boolean;
  paymentDateFrom?: Date;
  paymentDateTo?: Date;
  pharmaciesIds?: string[];
  sieveFilters?: string;
  sieveSorts?: string;
  sievePage?: number;
  sievePageSize?: number;
  isCheckedAll?: boolean;
  checked?: string[];
  unchecked?: string[];
  /**
   * Possible values include: 'XLSX', 'PDF', 'CSV', 'JSON', 'XML'
   */
  fileType?: FileType;
}

/**
 * Optional Parameters.
 */
export interface ReportGetGeneralBalanceOptionalParams extends msRest.RequestOptionsBase {
  year?: number;
  monthFrom?: number;
  monthTo?: number;
  paymentDateFrom?: Date;
  paymentDateTo?: Date;
  pharmaciesIds?: string[];
  sieveFilters?: string;
  sieveSorts?: string;
  sievePage?: number;
  sievePageSize?: number;
  isCheckedAll?: boolean;
  checked?: string[];
  unchecked?: string[];
  /**
   * Possible values include: 'XLSX', 'PDF', 'CSV', 'JSON', 'XML'
   */
  fileType?: FileType;
}

/**
 * Optional Parameters.
 */
export interface ReportGetPharmacyTaxNoteGroupsOptionalParams extends msRest.RequestOptionsBase {
  year?: number;
  dateFrom?: Date;
  dateTo?: Date;
  /**
   * Possible values include: 'XLSX', 'PDF', 'CSV', 'JSON', 'XML'
   */
  fileType?: FileType;
  sieveFilters?: string;
  sieveSorts?: string;
  sievePage?: number;
  sievePageSize?: number;
  isCheckedAll?: boolean;
  checked?: string[];
  unchecked?: string[];
}

/**
 * Optional Parameters.
 */
export interface ReportGetTemplateNotesOptionalParams extends msRest.RequestOptionsBase {
  year?: number;
  dateFrom?: Date;
  dateTo?: Date;
  sieveFilters?: string;
  sieveSorts?: string;
  sievePage?: number;
  sievePageSize?: number;
  isCheckedAll?: boolean;
  checked?: string[];
  unchecked?: string[];
  /**
   * Possible values include: 'XLSX', 'PDF', 'CSV', 'JSON', 'XML'
   */
  fileType?: FileType;
}

/**
 * Optional Parameters.
 */
export interface TemplateNoteAllOptionalParams extends msRest.RequestOptionsBase {
  filters?: string;
  sorts?: string;
  page?: number;
  pageSize?: number;
  settlementPeriodFilterCodeName?: string;
}

/**
 * Optional Parameters.
 */
export interface TemplateNoteOrdersOptionalParams extends msRest.RequestOptionsBase {
  filters?: string;
  sorts?: string;
  page?: number;
  pageSize?: number;
  templateId?: string;
}

/**
 * Optional Parameters.
 */
export interface TemplateNoteSendToSAPOptionalParams extends msRest.RequestOptionsBase {
  body?: SendToSAPCommand;
}

/**
 * Optional Parameters.
 */
export interface TemplateNoteGetTemplateNoteInfoOptionalParams extends msRest.RequestOptionsBase {
  id?: string;
}

/**
 * Optional Parameters.
 */
export interface UmxPharmacyAllOptionalParams extends msRest.RequestOptionsBase {
  filters?: string;
  sorts?: string;
  page?: number;
  pageSize?: number;
  voivodeshipId?: number;
}

/**
 * Optional Parameters.
 */
export interface VoivodeshipUpdateOptionalParams extends msRest.RequestOptionsBase {
  body?: UpdateVoivodeshipCommand;
}

/**
 * Optional Parameters.
 */
export interface VoivodeshipGetVoivodeshipIdentifiersOptionalParams extends msRest.RequestOptionsBase {
  voivodeshipId?: number;
  kodKom?: number;
}

/**
 * Optional Parameters.
 */
export interface VoivodeshipContactGetContactsOptionalParams extends msRest.RequestOptionsBase {
  voivodeshipId?: string;
}

/**
 * Optional Parameters.
 */
export interface VoivodeshipContactAddOptionalParams extends msRest.RequestOptionsBase {
  body?: AddVoivodeshipContactCommand;
}

/**
 * Optional Parameters.
 */
export interface VoivodeshipContactDeleteMethodOptionalParams extends msRest.RequestOptionsBase {
  contactId?: string;
}

/**
 * Optional Parameters.
 */
export interface VoivodeshipPaymentGroupAllOptionalParams extends msRest.RequestOptionsBase {
  filters?: string;
  sorts?: string;
  page?: number;
  pageSize?: number;
}

/**
 * Optional Parameters.
 */
export interface VoivodeshipPaymentGroupSendToSapOptionalParams extends msRest.RequestOptionsBase {
  body?: SendToSapVoivodeshipPaymentGroupCommand;
}

/**
 * Optional Parameters.
 */
export interface VoivodeshipPaymentGroupSendToSapAgainOptionalParams extends msRest.RequestOptionsBase {
  body?: SendToSapAgainVoivodeshipPaymentGroupCommand;
}

/**
 * Defines values for OrderState.
 * Possible values include: 'Imported', 'Reported', 'ApprovedByNhf', 'MarkedToCancel',
 * 'SentToCancel', 'Canceled', 'Restored', 'SettledNhf'
 * @readonly
 * @enum {string}
 */
export enum OrderState {
  Imported = 'Imported',
  Reported = 'Reported',
  ApprovedByNhf = 'ApprovedByNhf',
  MarkedToCancel = 'MarkedToCancel',
  SentToCancel = 'SentToCancel',
  Canceled = 'Canceled',
  Restored = 'Restored',
  SettledNhf = 'SettledNhf',
}

/**
 * Defines values for TaxNoteOrderState.
 * Possible values include: 'NotAssigned', 'Assigned', 'ReadyToSend', 'SentToSap', 'Paid',
 * 'NotPaid'
 * @readonly
 * @enum {string}
 */
export enum TaxNoteOrderState {
  NotAssigned = 'NotAssigned',
  Assigned = 'Assigned',
  ReadyToSend = 'ReadyToSend',
  SentToSap = 'SentToSap',
  Paid = 'Paid',
  NotPaid = 'NotPaid',
}

/**
 * Defines values for OrderReferenceType.
 * Possible values include: 'NOTE', 'CORRECTION_NOTE', 'REPORT_FILE', 'REPORT_CORRECTION_FILE',
 * 'RETURN_DOCUMENT'
 * @readonly
 * @enum {string}
 */
export enum OrderReferenceType {
  NOTE = 'NOTE',
  CORRECTIONNOTE = 'CORRECTION_NOTE',
  REPORTFILE = 'REPORT_FILE',
  REPORTCORRECTIONFILE = 'REPORT_CORRECTION_FILE',
  RETURNDOCUMENT = 'RETURN_DOCUMENT',
}

/**
 * Defines values for ManualOperationType.
 * Possible values include: 'Return', 'Payment'
 * @readonly
 * @enum {string}
 */
export enum ManualOperationType {
  Return = 'Return',
  Payment = 'Payment',
}

/**
 * Defines values for PaymentState.
 * Possible values include: 'Created', 'ReadyToSend', 'SentToSap', 'Paid', 'Compensated'
 * @readonly
 * @enum {string}
 */
export enum PaymentState {
  Created = 'Created',
  ReadyToSend = 'ReadyToSend',
  SentToSap = 'SentToSap',
  Paid = 'Paid',
  Compensated = 'Compensated',
}

/**
 * Defines values for PaymentConfirmationState.
 * Possible values include: 'CONFIRMED', 'REFUSED', 'RECOVERED'
 * @readonly
 * @enum {string}
 */
export enum PaymentConfirmationState {
  CONFIRMED = 'CONFIRMED',
  REFUSED = 'REFUSED',
  RECOVERED = 'RECOVERED',
}

/**
 * Defines values for TaxNoteType.
 * Possible values include: 'Main', 'Correction', 'Loss'
 * @readonly
 * @enum {string}
 */
export enum TaxNoteType {
  Main = 'Main',
  Correction = 'Correction',
  Loss = 'Loss',
}

/**
 * Defines values for InternalTaxNoteType.
 * Possible values include: 'Main', 'Correction', 'Debit', 'Loss', 'Return', 'PayLoss', 'Overpay'
 * @readonly
 * @enum {string}
 */
export enum InternalTaxNoteType {
  Main = 'Main',
  Correction = 'Correction',
  Debit = 'Debit',
  Loss = 'Loss',
  Return = 'Return',
  PayLoss = 'PayLoss',
  Overpay = 'Overpay',
}

/**
 * Defines values for TaxNoteSource.
 * Possible values include: 'Phoenix', 'Portal'
 * @readonly
 * @enum {string}
 */
export enum TaxNoteSource {
  Phoenix = 'Phoenix',
  Portal = 'Portal',
}

/**
 * Defines values for PriceListGroup.
 * Possible values include: 'Auxiliaries', 'OrthopedicProducts'
 * @readonly
 * @enum {string}
 */
export enum PriceListGroup {
  Auxiliaries = 'Auxiliaries',
  OrthopedicProducts = 'OrthopedicProducts',
}

/**
 * Defines values for PortalSzoiEnum.
 * Possible values include: 'Portal', 'SZOI'
 * @readonly
 * @enum {string}
 */
export enum PortalSzoiEnum {
  Portal = 'Portal',
  SZOI = 'SZOI',
}

/**
 * Defines values for BusinessObjectsState.
 * Possible values include: 'CorrectOnly', 'NotCorrectOnly', 'All'
 * @readonly
 * @enum {string}
 */
export enum BusinessObjectsState {
  CorrectOnly = 'CorrectOnly',
  NotCorrectOnly = 'NotCorrectOnly',
  All = 'All',
}

/**
 * Defines values for PharmacyType.
 * Possible values include: 'PHARMACY', 'MEDICAL_SHOP'
 * @readonly
 * @enum {string}
 */
export enum PharmacyType {
  PHARMACY = 'PHARMACY',
  MEDICALSHOP = 'MEDICAL_SHOP',
}

/**
 * Defines values for DocumentCategory.
 * Possible values include: 'IMPORT', 'EXPORT'
 * @readonly
 * @enum {string}
 */
export enum DocumentCategory {
  IMPORT = 'IMPORT',
  EXPORT = 'EXPORT',
}

/**
 * Defines values for DocumentSource.
 * Possible values include: 'PHARMACIES_IMPORT', 'FARMAPROM_PHARMACIES_IMPORT',
 * 'UMX_PHARMACIES_IMPORT', 'ORDERS_IMPORT', 'PRICE_LIST_IMPORT', 'ZPOSP_EXPORT',
 * 'PHARMACIES_IMPORT_TEMPLATE', 'NHF_STATE_IMPORT', 'NOTE_TEMPLATE_IMPORT', 'NOTE_EXPORT',
 * 'PAYMENT_EXPORT', 'PAYMENT_CONFIRMATIONS_IMPORT', 'RETURN_DOCUMENT', 'ZPOZR_IMPORT'
 * @readonly
 * @enum {string}
 */
export enum DocumentSource {
  PHARMACIESIMPORT = 'PHARMACIES_IMPORT',
  FARMAPROMPHARMACIESIMPORT = 'FARMAPROM_PHARMACIES_IMPORT',
  UMXPHARMACIESIMPORT = 'UMX_PHARMACIES_IMPORT',
  ORDERSIMPORT = 'ORDERS_IMPORT',
  PRICELISTIMPORT = 'PRICE_LIST_IMPORT',
  ZPOSPEXPORT = 'ZPOSP_EXPORT',
  PHARMACIESIMPORTTEMPLATE = 'PHARMACIES_IMPORT_TEMPLATE',
  NHFSTATEIMPORT = 'NHF_STATE_IMPORT',
  NOTETEMPLATEIMPORT = 'NOTE_TEMPLATE_IMPORT',
  NOTEEXPORT = 'NOTE_EXPORT',
  PAYMENTEXPORT = 'PAYMENT_EXPORT',
  PAYMENTCONFIRMATIONSIMPORT = 'PAYMENT_CONFIRMATIONS_IMPORT',
  RETURNDOCUMENT = 'RETURN_DOCUMENT',
  ZPOZRIMPORT = 'ZPOZR_IMPORT',
}

/**
 * Defines values for FileType.
 * Possible values include: 'XLSX', 'PDF', 'CSV', 'JSON', 'XML'
 * @readonly
 * @enum {string}
 */
export enum FileType {
  XLSX = 'XLSX',
  PDF = 'PDF',
  CSV = 'CSV',
  JSON = 'JSON',
  XML = 'XML',
}

/**
 * Defines values for NoteType.
 * Possible values include: 'Main', 'Correction'
 * @readonly
 * @enum {string}
 */
export enum NoteType {
  Main = 'Main',
  Correction = 'Correction',
}

/**
 * Defines values for OrderStateEnum.
 * Possible values include: 'Imported', 'Reported', 'ApprovedByNhf', 'MarkedToCancel',
 * 'SentToCancel', 'Canceled', 'Restored', 'SettledNhf'
 * @readonly
 * @enum {string}
 */
export enum OrderStateEnum {
  Imported = 'Imported',
  Reported = 'Reported',
  ApprovedByNhf = 'ApprovedByNhf',
  MarkedToCancel = 'MarkedToCancel',
  SentToCancel = 'SentToCancel',
  Canceled = 'Canceled',
  Restored = 'Restored',
  SettledNhf = 'SettledNhf',
}

/**
 * Defines values for PortalStatus.
 * Possible values include: 'Sent', 'NotSent', 'Access'
 * @readonly
 * @enum {string}
 */
export enum PortalStatus {
  Sent = 'Sent',
  NotSent = 'NotSent',
  Access = 'Access',
}

/**
 * Defines values for SearchType.
 * Possible values include: 'PESEL', 'OrderNumber', 'Status'
 * @readonly
 * @enum {string}
 */
export enum SearchType {
  PESEL = 'PESEL',
  OrderNumber = 'OrderNumber',
  Status = 'Status',
}

/**
 * Contains response data for the registerUser operation.
 */
export type AccountRegisterUserResponse = {
  /**
   * The parsed response body.
   */
  body: string;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string;
    };
};

/**
 * Contains response data for the registerPharmacy operation.
 */
export type AccountRegisterPharmacyResponse = {
  /**
   * The parsed response body.
   */
  body: string;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string;
    };
};

/**
 * Contains response data for the invitePharmacy operation.
 */
export type AccountInvitePharmacyResponse = {
  /**
   * The parsed response body.
   */
  body: string;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string;
    };
};

/**
 * Contains response data for the inviteAllPharmacies operation.
 */
export type AccountInviteAllPharmaciesResponse = {
  /**
   * The parsed response body.
   */
  body: string;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string;
    };
};

/**
 * Contains response data for the getInviteAllPharmaciesCount operation.
 */
export type AccountGetInviteAllPharmaciesCountResponse = {
  /**
   * The parsed response body.
   */
  body: number;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: number;
    };
};

/**
 * Contains response data for the me operation.
 */
export type AccountMeResponse = AccountDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: AccountDto;
    };
};

/**
 * Contains response data for the getUsers operation.
 */
export type AccountGetUsersResponse = AccountDtoArrayAzureListDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: AccountDtoArrayAzureListDto;
    };
};

/**
 * Contains response data for the getById operation.
 */
export type DictionaryGetByIdResponse = DictionaryDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: DictionaryDto;
    };
};

/**
 * Contains response data for the getByCodeName operation.
 */
export type DictionaryGetByCodeNameResponse = DictionaryDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: DictionaryDto;
    };
};

/**
 * Contains response data for the getByCodeNames operation.
 */
export type DictionaryGetByCodeNamesResponse = Array<DictionaryDto> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: DictionaryDto[];
    };
};

/**
 * Contains response data for the getById operation.
 */
export type DictionaryValueGetByIdResponse = DictionaryValueDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: DictionaryValueDto;
    };
};

/**
 * Contains response data for the allForPharmacy operation.
 */
export type DocumentAllForPharmacyResponse = SourceDocumentDtoPagedResult & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: SourceDocumentDtoPagedResult;
    };
};

/**
 * Contains response data for the all operation.
 */
export type DocumentAllResponse = SourceDocumentDtoPagedResult & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: SourceDocumentDtoPagedResult;
    };
};

/**
 * Contains response data for the getById operation.
 */
export type DocumentGetByIdResponse = {
  /**
   * BROWSER ONLY
   *
   * The response body as a browser Blob.
   * Always undefined in node.js.
   */
  blobBody?: Promise<Blob>;

  /**
   * NODEJS ONLY
   *
   * The response body as a node.js Readable stream.
   * Always undefined in the browser.
   */
  readableStreamBody?: NodeJS.ReadableStream;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse;
};

/**
 * Contains response data for the getZippedById operation.
 */
export type DocumentGetZippedByIdResponse = {
  /**
   * BROWSER ONLY
   *
   * The response body as a browser Blob.
   * Always undefined in node.js.
   */
  blobBody?: Promise<Blob>;

  /**
   * NODEJS ONLY
   *
   * The response body as a node.js Readable stream.
   * Always undefined in the browser.
   */
  readableStreamBody?: NodeJS.ReadableStream;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse;
};

/**
 * Contains response data for the getZPOSPList operation.
 */
export type ExportModelGetZPOSPListResponse = FileResultDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: FileResultDto;
    };
};

/**
 * Contains response data for the getTemplateNote operation.
 */
export type ExportModelGetTemplateNoteResponse = FileResultDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: FileResultDto;
    };
};

/**
 * Contains response data for the all operation.
 */
export type FarmapromPharmacyAllResponse = FarmapromPharmacyDtoPagedResult & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: FarmapromPharmacyDtoPagedResult;
    };
};

/**
 * Contains response data for the getById operation.
 */
export type FarmapromPharmacyGetByIdResponse = FarmapromPharmacyDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: FarmapromPharmacyDto;
    };
};

/**
 * Contains response data for the importFarmapromPharmacies operation.
 */
export type ImportModelImportFarmapromPharmaciesResponse = ImportFarmapromPharmaciesStatusDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: ImportFarmapromPharmaciesStatusDto;
    };
};

/**
 * Contains response data for the importUmxPharmacies operation.
 */
export type ImportModelImportUmxPharmaciesResponse = ImportUmxPharmaciesStatusDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: ImportUmxPharmaciesStatusDto;
    };
};

/**
 * Contains response data for the environment operation.
 */
export type MaintenanceEnvironmentResponse = {
  /**
   * The parsed response body.
   */
  body: string;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string;
    };
};

/**
 * Contains response data for the keyVaultName operation.
 */
export type MaintenanceKeyVaultNameResponse = {
  /**
   * The parsed response body.
   */
  body: string;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string;
    };
};

/**
 * Contains response data for the allForPharmacy operation.
 */
export type OrderAllForPharmacyResponse = OrderDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: OrderDto;
    };
};

/**
 * Contains response data for the getByIdForPharmacy operation.
 */
export type OrderGetByIdForPharmacyResponse = OrderDetailsDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: OrderDetailsDto;
    };
};

/**
 * Contains response data for the searchPagedForPharmacy operation.
 */
export type OrderSearchPagedForPharmacyResponse = OrderDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: OrderDto;
    };
};

/**
 * Contains response data for the all operation.
 */
export type OrderAllResponse = OrderDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: OrderDto;
    };
};

/**
 * Contains response data for the getAllPaginatedOrdersForPharmacyInDateScope operation.
 */
export type OrderGetAllPaginatedOrdersForPharmacyInDateScopeResponse = OrderDtoPagedResult & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: OrderDtoPagedResult;
    };
};

/**
 * Contains response data for the getAllNotAssignedOrdersForPharmacyInDateScope operation.
 */
export type OrderGetAllNotAssignedOrdersForPharmacyInDateScopeResponse = Array<OrderDto> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: OrderDto[];
    };
};

/**
 * Contains response data for the search operation.
 */
export type OrderSearchResponse = Array<OrderSearchDto> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: OrderSearchDto[];
    };
};

/**
 * Contains response data for the searchPaged operation.
 */
export type OrderSearchPagedResponse = OrderDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: OrderDto;
    };
};

/**
 * Contains response data for the getById operation.
 */
export type OrderGetByIdResponse = OrderDetailsDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: OrderDetailsDto;
    };
};

/**
 * Contains response data for the getOrdersRealizationByNhfRealization operation.
 */
export type OrderGetOrdersRealizationByNhfRealizationResponse = Array<OrderRealizationDto> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: OrderRealizationDto[];
    };
};

/**
 * Contains response data for the getOrderReferencesByOrderId operation.
 */
export type OrderGetOrderReferencesByOrderIdResponse = Array<OrderReferencesDto> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: OrderReferencesDto[];
    };
};

/**
 * Contains response data for the getAllPaginatedCancelledOrdersByPharmacyIdQuery operation.
 */
export type OrderGetAllPaginatedCancelledOrdersByPharmacyIdQueryResponse = OrderDtoPagedResult & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: OrderDtoPagedResult;
    };
};

/**
 * Contains response data for the hasAnyCancelledOrdersByPharmacyIdQuery operation.
 */
export type OrderHasAnyCancelledOrdersByPharmacyIdQueryResponse = {
  /**
   * The parsed response body.
   */
  body: boolean;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: boolean;
    };
};

/**
 * Contains response data for the all operation.
 */
export type OrderGroupAllResponse = OrderGroupDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: OrderGroupDto;
    };
};

/**
 * Contains response data for the all operation.
 */
export type PharmacyAllResponse = PharmacyDtoPagedResult & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: PharmacyDtoPagedResult;
    };
};

/**
 * Contains response data for the create operation.
 */
export type PharmacyCreateResponse = GuidIIdDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: GuidIIdDto;
    };
};

/**
 * Contains response data for the pharmaciesWithUnasignedOrders operation.
 */
export type PharmacyPharmaciesWithUnasignedOrdersResponse = PharmacyDtoPagedResult & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: PharmacyDtoPagedResult;
    };
};

/**
 * Contains response data for the getById operation.
 */
export type PharmacyGetByIdResponse = PharmacyDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: PharmacyDto;
    };
};

/**
 * Contains response data for the getImportTemplate operation.
 */
export type PharmacyGetImportTemplateResponse = {
  /**
   * BROWSER ONLY
   *
   * The response body as a browser Blob.
   * Always undefined in node.js.
   */
  blobBody?: Promise<Blob>;

  /**
   * NODEJS ONLY
   *
   * The response body as a node.js Readable stream.
   * Always undefined in the browser.
   */
  readableStreamBody?: NodeJS.ReadableStream;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse;
};

/**
 * Contains response data for the getByIdForPharmacy operation.
 */
export type PharmacyTaxNoteGetByIdForPharmacyResponse = PharmacyTaxNoteDetailsDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: PharmacyTaxNoteDetailsDto;
    };
};

/**
 * Contains response data for the createForPharmacy operation.
 */
export type PharmacyTaxNoteCreateForPharmacyResponse = CreateNoteResponseDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: CreateNoteResponseDto;
    };
};

/**
 * Contains response data for the updateForPharmacy operation.
 */
export type PharmacyTaxNoteUpdateForPharmacyResponse = UpdateNoteResponseDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: UpdateNoteResponseDto;
    };
};

/**
 * Contains response data for the getById operation.
 */
export type PharmacyTaxNoteGetByIdResponse = PharmacyTaxNoteDetailsDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: PharmacyTaxNoteDetailsDto;
    };
};

/**
 * Contains response data for the create operation.
 */
export type PharmacyTaxNoteCreateResponse = CreateNoteResponseDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: CreateNoteResponseDto;
    };
};

/**
 * Contains response data for the update operation.
 */
export type PharmacyTaxNoteUpdateResponse = UpdateNoteResponseDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: UpdateNoteResponseDto;
    };
};

/**
 * Contains response data for the createCorrection operation.
 */
export type PharmacyTaxNoteCreateCorrectionResponse = CreateNoteResponseDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: CreateNoteResponseDto;
    };
};

/**
 * Contains response data for the createDebitCorrection operation.
 */
export type PharmacyTaxNoteCreateDebitCorrectionResponse = CreateNoteResponseDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: CreateNoteResponseDto;
    };
};

/**
 * Contains response data for the createLoss operation.
 */
export type PharmacyTaxNoteCreateLossResponse = CreateNoteResponseDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: CreateNoteResponseDto;
    };
};

/**
 * Contains response data for the createPayLoss operation.
 */
export type PharmacyTaxNoteCreatePayLossResponse = CreateNoteResponseDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: CreateNoteResponseDto;
    };
};

/**
 * Contains response data for the updatePayLoss operation.
 */
export type PharmacyTaxNoteUpdatePayLossResponse = UpdateNoteResponseDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: UpdateNoteResponseDto;
    };
};

/**
 * Contains response data for the updateLoss operation.
 */
export type PharmacyTaxNoteUpdateLossResponse = UpdateNoteResponseDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: UpdateNoteResponseDto;
    };
};

/**
 * Contains response data for the updateCorrection operation.
 */
export type PharmacyTaxNoteUpdateCorrectionResponse = UpdateNoteResponseDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: UpdateNoteResponseDto;
    };
};

/**
 * Contains response data for the updateOverpay operation.
 */
export type PharmacyTaxNoteUpdateOverpayResponse = UpdateNoteResponseDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: UpdateNoteResponseDto;
    };
};

/**
 * Contains response data for the getPharmacyTaxNotesPredictedToCorrection operation.
 */
export type PharmacyTaxNoteGetPharmacyTaxNotesPredictedToCorrectionResponse = Array<PharmacyNotePredictedToCorrectionDto> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: PharmacyNotePredictedToCorrectionDto[];
    };
};

/**
 * Contains response data for the getPredictedReturnOverpay operation.
 */
export type PharmacyTaxNoteGetPredictedReturnOverpayResponse = PharmacyNotePredictedToCorrectionDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: PharmacyNotePredictedToCorrectionDto;
    };
};

/**
 * Contains response data for the recountPharmacyTaxNoteCorrection operation.
 */
export type PharmacyTaxNoteRecountPharmacyTaxNoteCorrectionResponse = {
  /**
   * The parsed response body.
   */
  body: string;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string;
    };
};

/**
 * Contains response data for the recountPharmacyTaxNoteOverpay operation.
 */
export type PharmacyTaxNoteRecountPharmacyTaxNoteOverpayResponse = {
  /**
   * The parsed response body.
   */
  body: string;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string;
    };
};

/**
 * Contains response data for the assignBalancedPaymentConfirmation operation.
 */
export type PharmacyTaxNoteAssignBalancedPaymentConfirmationResponse = {
  /**
   * The parsed response body.
   */
  body: string;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string;
    };
};

/**
 * Contains response data for the createOverpay operation.
 */
export type PharmacyTaxNoteCreateOverpayResponse = CreateNoteResponseDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: CreateNoteResponseDto;
    };
};

/**
 * Contains response data for the allForPharmacy operation.
 */
export type PharmacyTaxNoteGroupAllForPharmacyResponse = PharmacyTaxNoteGroupDtoPagedResult & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: PharmacyTaxNoteGroupDtoPagedResult;
    };
};

/**
 * Contains response data for the getByIdForPharmacy operation.
 */
export type PharmacyTaxNoteGroupGetByIdForPharmacyResponse = PharmacyTaxNoteGroupDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: PharmacyTaxNoteGroupDto;
    };
};

/**
 * Contains response data for the all operation.
 */
export type PharmacyTaxNoteGroupAllResponse = PharmacyTaxNoteGroupDtoPagedResult & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: PharmacyTaxNoteGroupDtoPagedResult;
    };
};

/**
 * Contains response data for the getById operation.
 */
export type PharmacyTaxNoteGroupGetByIdResponse = PharmacyTaxNoteGroupDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: PharmacyTaxNoteGroupDto;
    };
};

/**
 * Contains response data for the getById operation.
 */
export type PharmacyTaxNoteGroupManualOperationGetByIdResponse = PharmacyTaxNoteGroupManualOperationDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: PharmacyTaxNoteGroupManualOperationDto;
    };
};

/**
 * Contains response data for the all operation.
 */
export type PharmacyTaxNoteGroupManualOperationAllResponse = Array<PharmacyTaxNoteGroupManualOperationDto> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: PharmacyTaxNoteGroupManualOperationDto[];
    };
};

/**
 * Contains response data for the hasAnyManualOperation operation.
 */
export type PharmacyTaxNoteGroupManualOperationHasAnyManualOperationResponse = {
  /**
   * The parsed response body.
   */
  body: boolean;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: boolean;
    };
};

/**
 * Contains response data for the getByPharmacyTaxNoteGroupId operation.
 */
export type PharmacyTaxNoteGroupReturnDocumentGetByPharmacyTaxNoteGroupIdResponse = Array<PharmacyTaxNoteGroupReturnDocumentDto> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: PharmacyTaxNoteGroupReturnDocumentDto[];
    };
};

/**
 * Contains response data for the hasAnyReturnDocuments operation.
 */
export type PharmacyTaxNoteGroupReturnDocumentHasAnyReturnDocumentsResponse = {
  /**
   * The parsed response body.
   */
  body: boolean;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: boolean;
    };
};

/**
 * Contains response data for the saveAndGenerateReturnDocument operation.
 */
export type PharmacyTaxNoteGroupReturnDocumentSaveAndGenerateReturnDocumentResponse = {
  /**
   * BROWSER ONLY
   *
   * The response body as a browser Blob.
   * Always undefined in node.js.
   */
  blobBody?: Promise<Blob>;

  /**
   * NODEJS ONLY
   *
   * The response body as a node.js Readable stream.
   * Always undefined in the browser.
   */
  readableStreamBody?: NodeJS.ReadableStream;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse;
};

/**
 * Contains response data for the getPharmacyTaxNotePaymentWithNotesForPharmacy operation.
 */
export type PharmacyTaxNotePaymentGetPharmacyTaxNotePaymentWithNotesForPharmacyResponse = Array<PharmacyTaxNotePaymentWithNotesDto> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: PharmacyTaxNotePaymentWithNotesDto[];
    };
};

/**
 * Contains response data for the getPharmacyTaxNotePaymentWithNotes operation.
 */
export type PharmacyTaxNotePaymentGetPharmacyTaxNotePaymentWithNotesResponse = Array<PharmacyTaxNotePaymentWithNotesDto> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: PharmacyTaxNotePaymentWithNotesDto[];
    };
};

/**
 * Contains response data for the getByPharmacyTaxNoteId operation.
 */
export type PharmacyTaxNotePaymentConfirmationGetByPharmacyTaxNoteIdResponse = Array<PharmacyTaxNotePaymentConfirmationDto> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: PharmacyTaxNotePaymentConfirmationDto[];
    };
};

/**
 * Contains response data for the assignPaymentConfirmation operation.
 */
export type PharmacyTaxNotePaymentConfirmationAssignPaymentConfirmationResponse = {
  /**
   * The parsed response body.
   */
  body: string;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string;
    };
};

/**
 * Contains response data for the changeState operation.
 */
export type PharmacyTaxNotePaymentConfirmationChangeStateResponse = {
  /**
   * The parsed response body.
   */
  body: string;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string;
    };
};

/**
 * Contains response data for the updatePaymentConfirmation operation.
 */
export type PharmacyTaxNotePaymentConfirmationUpdatePaymentConfirmationResponse = {
  /**
   * The parsed response body.
   */
  body: string;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string;
    };
};

/**
 * Contains response data for the deletePaymentConfirmation operation.
 */
export type PharmacyTaxNotePaymentConfirmationDeletePaymentConfirmationResponse = {
  /**
   * The parsed response body.
   */
  body: string;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string;
    };
};

/**
 * Contains response data for the deletePaymentConfirmations operation.
 */
export type PharmacyTaxNotePaymentConfirmationDeletePaymentConfirmationsResponse = {
  /**
   * The parsed response body.
   */
  body: string;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string;
    };
};

/**
 * Contains response data for the all operation.
 */
export type PriceAllResponse = ProductPriceDtoPagedResult & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: ProductPriceDtoPagedResult;
    };
};

/**
 * Contains response data for the search operation.
 */
export type PriceSearchResponse = Array<ProductPriceSearchDto> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: ProductPriceSearchDto[];
    };
};

/**
 * Contains response data for the all operation.
 */
export type PriceListAllResponse = Array<PriceListDto> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: PriceListDto[];
    };
};

/**
 * Contains response data for the all operation.
 */
export type ProductAllResponse = ProductDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: ProductDto;
    };
};

/**
 * Contains response data for the getOrders operation.
 */
export type ReportGetOrdersResponse = {
  /**
   * BROWSER ONLY
   *
   * The response body as a browser Blob.
   * Always undefined in node.js.
   */
  blobBody?: Promise<Blob>;

  /**
   * NODEJS ONLY
   *
   * The response body as a node.js Readable stream.
   * Always undefined in the browser.
   */
  readableStreamBody?: NodeJS.ReadableStream;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse;
};

/**
 * Contains response data for the getPharmacies operation.
 */
export type ReportGetPharmaciesResponse = {
  /**
   * BROWSER ONLY
   *
   * The response body as a browser Blob.
   * Always undefined in node.js.
   */
  blobBody?: Promise<Blob>;

  /**
   * NODEJS ONLY
   *
   * The response body as a node.js Readable stream.
   * Always undefined in the browser.
   */
  readableStreamBody?: NodeJS.ReadableStream;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse;
};

/**
 * Contains response data for the getMonthlyBalance operation.
 */
export type ReportGetMonthlyBalanceResponse = {
  /**
   * BROWSER ONLY
   *
   * The response body as a browser Blob.
   * Always undefined in node.js.
   */
  blobBody?: Promise<Blob>;

  /**
   * NODEJS ONLY
   *
   * The response body as a node.js Readable stream.
   * Always undefined in the browser.
   */
  readableStreamBody?: NodeJS.ReadableStream;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse;
};

/**
 * Contains response data for the getGeneralBalance operation.
 */
export type ReportGetGeneralBalanceResponse = {
  /**
   * BROWSER ONLY
   *
   * The response body as a browser Blob.
   * Always undefined in node.js.
   */
  blobBody?: Promise<Blob>;

  /**
   * NODEJS ONLY
   *
   * The response body as a node.js Readable stream.
   * Always undefined in the browser.
   */
  readableStreamBody?: NodeJS.ReadableStream;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse;
};

/**
 * Contains response data for the getPharmacyTaxNoteGroups operation.
 */
export type ReportGetPharmacyTaxNoteGroupsResponse = {
  /**
   * BROWSER ONLY
   *
   * The response body as a browser Blob.
   * Always undefined in node.js.
   */
  blobBody?: Promise<Blob>;

  /**
   * NODEJS ONLY
   *
   * The response body as a node.js Readable stream.
   * Always undefined in the browser.
   */
  readableStreamBody?: NodeJS.ReadableStream;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse;
};

/**
 * Contains response data for the getTemplateNotes operation.
 */
export type ReportGetTemplateNotesResponse = {
  /**
   * BROWSER ONLY
   *
   * The response body as a browser Blob.
   * Always undefined in node.js.
   */
  blobBody?: Promise<Blob>;

  /**
   * NODEJS ONLY
   *
   * The response body as a node.js Readable stream.
   * Always undefined in the browser.
   */
  readableStreamBody?: NodeJS.ReadableStream;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse;
};

/**
 * Contains response data for the all operation.
 */
export type TemplateNoteAllResponse = TemplateNoteDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: TemplateNoteDto;
    };
};

/**
 * Contains response data for the orders operation.
 */
export type TemplateNoteOrdersResponse = OrderDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: OrderDto;
    };
};

/**
 * Contains response data for the getTemplateNoteInfo operation.
 */
export type TemplateNoteGetTemplateNoteInfoResponse = TemplateNoteInfoDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: TemplateNoteInfoDto;
    };
};

/**
 * Contains response data for the all operation.
 */
export type UmxPharmacyAllResponse = UmxPharmacyDtoPagedResult & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: UmxPharmacyDtoPagedResult;
    };
};

/**
 * Contains response data for the getById operation.
 */
export type UmxPharmacyGetByIdResponse = UmxPharmacyDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: UmxPharmacyDto;
    };
};

/**
 * Contains response data for the getById operation.
 */
export type VoivodeshipGetByIdResponse = VoivodeshipDetailsDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: VoivodeshipDetailsDto;
    };
};

/**
 * Contains response data for the all operation.
 */
export type VoivodeshipAllResponse = Array<VoivodeshipDetailsDto> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: VoivodeshipDetailsDto[];
    };
};

/**
 * Contains response data for the getVoivodeshipIdentifiers operation.
 */
export type VoivodeshipGetVoivodeshipIdentifiersResponse = Array<VoivodeshipIdentifierDto> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: VoivodeshipIdentifierDto[];
    };
};

/**
 * Contains response data for the getContacts operation.
 */
export type VoivodeshipContactGetContactsResponse = Array<VoivodeshipContactDto> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: VoivodeshipContactDto[];
    };
};

/**
 * Contains response data for the all operation.
 */
export type VoivodeshipPaymentGroupAllResponse = VoivodeshipPaymentGroupDtoPagedResult & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: VoivodeshipPaymentGroupDtoPagedResult;
    };
};
