export enum AccountRoles {
	BUSINESS_ADMIN = 'Business.Admin',
	BUSINESS_USER = 'Business.User',
	PHARMACY_USER = 'Pharmacy.User',
}

export const mappings = new Map<AccountRoles, string>([
	[AccountRoles.BUSINESS_ADMIN, 'Business admin'],
	[AccountRoles.BUSINESS_USER, 'Business user'],
	[AccountRoles.PHARMACY_USER, 'Pharmacy user'],
]);

export const getBusinessRoles = () => {
	return Array.from(mappings).filter(
		(x) => x[0] !== AccountRoles.PHARMACY_USER
	);
};

export const map = (value: AccountRoles): string => mappings.get(value) ?? '';
