import { DeleteOutlined } from '@ant-design/icons';
import { EssityColumnProps } from '@components/shared/paginatedEssityTable/GridHelper';
import { ajaxByUser } from '@helper/api';
import { getEssityApiClient } from '@services/EssityApi';
import { AccountDto } from '@services/src/models';
import { Button, Popconfirm, Tooltip } from 'antd';
import React from 'react';

export const DeleteAccountAction = (
	onDeleteAction: (accountId: string) => void
): EssityColumnProps<AccountDto> => {
	return {
		title: '',
		key: 'operation',
		fixed: 'right',
		width: 50,
		render: (item: AccountDto) => {
			return (
				<Popconfirm
					title="Are you sure to delete account?"
					okText="Yes"
					cancelText="No"
					placement="bottom"
					onConfirm={() => {
						ajaxByUser('Successfully deleted account.', () =>
							getEssityApiClient().then((api) =>
								api.account
									.deleteMethod({ userId: item.id })
									.then(() => onDeleteAction(item.id!))
							)
						);
					}}
				>
					<Tooltip title="Delete">
						<Button
							type="primary"
							shape="circle"
							size="small"
							icon={<DeleteOutlined />}
						/>
					</Tooltip>
				</Popconfirm>
			);
		},
	};
};
