import { filterType } from '@components/shared/paginatedEssityTable/GridHelper';
import PaginatedEssityTable from '@components/shared/paginatedEssityTable/PaginatedEssityTable';
import { SieveModel } from '@components/shared/paginatedEssityTable/SieveModels';
import { useRootData } from '@hooks/hook';
import { DictionariesConstants } from '@services/DictionariesConstants';
import { getEssityApiClient } from '@services/EssityApi';
import {
	TemplateNoteAllOptionalParams,
	TemplateNoteDto,
} from '@services/src/models';
import React, { useEffect, useState } from 'react';
import { IAccountDetailsStore } from 'stores/AccountDetailsStore';
import { IDictionaryStore } from 'stores/DictionaryStore';
import { ITableColumnStore } from 'stores/TableColumnStore';

import { DownloadAction } from './NHFSettlementsTableActions';
import styles from './styles/NHFSettlements.module.less';
import { useLocation, useHistory } from "react-router-dom";
import { IGridStore } from 'stores/GridStore';

export const NHFSettlementsTable = () => {
	const tableColumnStore: ITableColumnStore = useRootData(
		(store) => store.tableColumnStore
	);

	const dictionaryStore: IDictionaryStore = useRootData(
		(store) => store.dictionaryStore
	);

	const accountDetailsStore: IAccountDetailsStore = useRootData(
		(store) => store.accountDetailsStore
	);
	const gridStore: IGridStore = useRootData((store) => store.gridStore);

	const [isLoaded, setIsLoaded] = useState<boolean>(false);


	const location = useLocation()
	const history = useHistory()

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search)
		if (queryParams.has("notename")) {
			gridStore.saveColumnFilter(gridName, 'noteName', queryParams.get("notename"), 'contains' as filterType);
			history.replace({
				search: '',
			})
		}
		setIsLoaded(true)
	}, [])




	const gridName = 'nhfSettlements';
	const columns = [
		{
			sorter: true,
			dataIndex: 'voivodeshipId',
			key: 'voivodeshipId',
			title: 'Voivodeship',
			width: 150,
			filter: 'equals' as filterType,
			hideFilterIcon: true,
			filterDictionary: DictionariesConstants.Voivodeships,
			render: (value: number) => {
				return dictionaryStore.getValueById(
					DictionariesConstants.Voivodeships,
					value
				);
			},
		},
		...tableColumnStore.getTable(gridName),
		DownloadAction(),
	];

	return (
		<>
			{isLoaded && <PaginatedEssityTable<TemplateNoteDto>
				className={styles.table}
				columns={columns}
				gridName={gridName}
				hasRowSelection={true}

				hidePersonalizationSettings={true}
				getRowKey={(r: TemplateNoteDto) => r.id!}
				detailsUrl="nhfSettlements"
				getPagedResult={async (sieve: SieveModel, abortSignal) => {
					const parameters: TemplateNoteAllOptionalParams = {
						page: sieve.page,
						pageSize: sieve.pageSize,
						sorts: sieve.sorts,
						abortSignal: abortSignal,
						filters: sieve.filters,
						settlementPeriodFilterCodeName: accountDetailsStore.settings.get()
							.settlementPeriodFilterDictionaryValueCodeName,
					};

					const api = await getEssityApiClient();
					return await api.templateNote.all(parameters);
				}}
			/>}
		</>
	);
};
