import { Col, Skeleton } from 'antd'
import { CenteredRow } from 'layout/CenteredRow'
import React from 'react'

export const OrderLinesDetailsSkeleton = () => {
    return (
		<CenteredRow>
			<Col span={12}>
				<Skeleton />
			</Col>
		</CenteredRow>
    )
}
