import { EssityColumnProps } from '@components/shared/paginatedEssityTable/GridHelper';
import formatHelpers from '@helper/formatHelpers';
import { useRootData } from '@hooks/hook';
import { DictionariesConstants } from '@services/DictionariesConstants';
import * as PriceListGroupMapper from '@services/mappers/PriceListGroup';
import { PriceListDto, PriceListGroup } from '@services/src/models';
import { IDictionaryStore } from 'stores/DictionaryStore';

export const GetPriceListsTableColumns = (): EssityColumnProps<PriceListDto>[] => {
	const dictionaryStore: IDictionaryStore = useRootData(
		(store) => store.dictionaryStore
	);

	return [
		{
			dataIndex: 'priceListId',
			key: 'priceListId',
			title: 'ID',
			width: 150,
		},
		{
			dataIndex: 'name',
			key: 'name',
			title: 'Name',
			width: 150,
		},
		{
			dataIndex: 'voivodeshipId',
			key: 'voivodeshipId',
			title: 'Voivodeship',
			width: 150,
			render: (value: number) => {
				return dictionaryStore.getValueById(
					DictionariesConstants.Voivodeships,
					value
				);
			},
		},
		{
			dataIndex: 'validFrom',
			key: 'validFrom',
			title: 'Valid from',
			width: 150,
			render: (value?: Date) => formatHelpers.formatDate(value),
		},
		{
			dataIndex: 'validTo',
			key: 'validTo',
			title: 'Valid to',
			width: 150,
			render: (value?: Date) => formatHelpers.formatDate(value),
		},
		{
			dataIndex: 'priceListGroup',
			key: 'priceListGroup',
			title: 'Group',
			width: 150,
			render: (value: PriceListGroup) => PriceListGroupMapper.map(value),
		},
	];
};
