import { InternalTaxNoteType } from '@services/src/models';
import { Divider } from 'antd';
import React, { useState } from 'react';
import { PharmacyNotePreview } from '../PharmacyNotePreview';

import styles from '../styles/PharmacyNote.module.less';
import { CorrectionNoteDetails } from './CorrectionNoteDetails';

interface IProps {
	editMode: boolean;
	readOnly: boolean;
	groupId: string;
	internalTaxNoteType: InternalTaxNoteType;
	correctionNoteId?: string;
}

export const CorrectionNote = (props: IProps) => {

	const [noteAttachment, setNoteAttachment] = useState<Blob>();

	const setAttachment = (attachment: Blob | undefined) => {
		setNoteAttachment(attachment);
	};

	return (
		<div className={styles.note}>
			<CorrectionNoteDetails
				editMode={props.editMode}
				readOnly={props.readOnly}
				groupId={props.groupId}
				internalTaxNoteType={props.internalTaxNoteType}
				correctionNoteId={props.correctionNoteId}
				setAttachment={setAttachment}
			/>
			<Divider className={styles.divider} type="vertical" />
			<PharmacyNotePreview blob={noteAttachment} />
		</div>
	);
};
