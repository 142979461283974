import { CenteredRow } from 'layout/CenteredRow';
import React, { useEffect } from 'react';

import { GlobalUserManager } from './userManager';

export const LogoutPage = () => {
	useEffect(() => {
		GlobalUserManager.UserManager().signoutRedirect();
	}, []);

	return <CenteredRow>Password changed, please sign in again.</CenteredRow>;
};
