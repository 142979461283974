import { OrderState } from '@services/src/models';

export const valueMap = new Map<OrderState, string>([
	[OrderState.Imported, 'Imported'],
	[OrderState.Reported, 'Reported'],
	[OrderState.ApprovedByNhf, 'Approved by NHF'],
	[OrderState.MarkedToCancel, 'Marked to cancel'],
	[OrderState.SentToCancel, 'Sent to cancel'],
	[OrderState.Canceled, 'Canceled'],
	[OrderState.SettledNhf, 'Settled'],
	[OrderState.Restored, 'Restored'],
]);

export const map = (value: OrderState): string => valueMap.get(value) ?? '';