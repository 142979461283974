import { useRootData } from '@hooks/hook';
import { DictionariesConstants } from '@services/DictionariesConstants';
import { observer } from 'mobx-react';
import { User } from 'oidc-client';
import React, { useEffect } from 'react';
import { Redirect } from 'react-router';
import { IAccountDetailsStore } from 'stores/AccountDetailsStore';
import { IDictionaryStore } from 'stores/DictionaryStore';

import { GlobalUserManager } from './userManager';
const CallbackPage = () => {
	const accountDetailsStore: IAccountDetailsStore = useRootData(
		(store) => store.accountDetailsStore
	);

	const dictionaryStore: IDictionaryStore = useRootData(
		(store) => store.dictionaryStore
	);
	const returnUrl = 'returnUrl';

	useEffect(() => {
		GlobalUserManager.UserManager()
			.signinRedirectCallback()
			.then((user: User) => {
				accountDetailsStore.oidcUser.set(user);


				dictionaryStore.initializeStore([
					DictionariesConstants.Voivodeships,
					DictionariesConstants.SettlementPeriod,
					DictionariesConstants.NHFContract,
					DictionariesConstants.EndContractReason,
					DictionariesConstants.OrderCancellationReason
				]);
			});
	}, []);
	let returnAddres = sessionStorage.getItem(returnUrl);
	if (returnAddres == '/login') { returnAddres = null }
	return accountDetailsStore.oidcUser?.get() ? (

		returnAddres ? (<Redirect to={returnAddres} />) : <Redirect to="/orderGroups" />
	) : (
		<></>
	);
};

export default observer(CallbackPage);
