import * as Yup from 'yup';

export const AccountRegisterValidationSchema = () =>
	Yup.object().shape({
		userPrincipalName: Yup.string().required('E-mail is required.'),
		password: Yup.string().required('Password is required'),
		passwordConfirmation: Yup.string().test(
			'passwords-match',
			'Passwords must match',
			function (value) {
				return this.parent.password === value;
			}
		),
		accountRoles: Yup.string().required('Account role is required.'),
	});
