import {
	ShoppingCartOutlined,
	SnippetsOutlined,
	UserOutlined,
} from '@ant-design/icons';
import formatHelpers from '@helper/formatHelpers';
import { useRootData } from '@hooks/hook';
import { DictionariesConstants } from '@services/DictionariesConstants';
import { OrderDetailsDto, OrderErrorDto, SearchType } from '@services/src/models';
import { Button, Descriptions, Space } from 'antd';
import EssityTable from 'layout/EssityTable';
import EssityTooltip from 'layout/EssityTooltip';
import React from 'react';
import { useHistory } from 'react-router';
import { IDictionaryStore } from 'stores/DictionaryStore';
import { IGlobalSearchStore } from 'stores/GlobalSearchStore';

import { OrderLinesDetailsSkeleton } from './OrderLinesDetailsSkeleton';
import { OrderLinesTableColumns } from './OrderLinesTableColumns';
import styleModule from './styles/OrderLines.module.less';

interface IProps {
	orderDetails: OrderDetailsDto | undefined;
	theme?: { [key: string]: string };
	isInSearch: boolean;
}

export const OrderLinesDetails = (props: IProps) => {
	const styles = props.theme ? props.theme : styleModule;

	const history = useHistory();

	const dictionaryStore: IDictionaryStore = useRootData(
		(store) => store.dictionaryStore
	);

	const globalSearchStore: IGlobalSearchStore = useRootData(
		(store) => store.globalSearchStore
	);

	const getCancelationReason = (
		dictionaryItemId: number | undefined,
		text: string | undefined
	) => {
		if (text) {
			return (
				<Descriptions className={styles.info} column={1}>
					<Descriptions.Item span={1}>
						<p>{`Cancelation reason: ${text}`}</p>
					</Descriptions.Item>
				</Descriptions>
			);
		} else if (dictionaryItemId) {
			return (
				<Descriptions className={styles.info} column={1}>
					<Descriptions.Item span={1}>
						<p>
							{`Cancelation reason: ${dictionaryStore.getValueById(
								DictionariesConstants.OrderCancellationReason,
								dictionaryItemId
							)}`}
						</p>
					</Descriptions.Item>
				</Descriptions>
			);
		}

		return <></>;
	};

	const { orderDetails } = props;

	return orderDetails ? (
		<div className={styles.details}>
			{orderDetails.orderErrors && orderDetails.orderErrors.length > 0 && (
				<Descriptions className={styles.warning} column={1}>
					{orderDetails.orderErrors?.map((error: OrderErrorDto) => (
						<Descriptions.Item span={1}>
							<p>{error.description}</p>
						</Descriptions.Item>
					))}
				</Descriptions>
			)}

			{getCancelationReason(
				orderDetails.cancellationReasonId,
				orderDetails.cancellationReasonText
			)}

			<div className={styles.section}>
				<Space className="header-text">
					<UserOutlined />
					Patient
				</Space>

				<Descriptions className={styles.description}>
					{!orderDetails.isEUPatient && orderDetails?.patientId && (
						<Descriptions.Item label="PESEL">
							<b>{orderDetails.patientId}</b>
						</Descriptions.Item>
					)}

					{orderDetails?.orderNumber && (
						<Descriptions.Item label="Card number">
							<b>{orderDetails.orderNumber}</b>
						</Descriptions.Item>
					)}

					{orderDetails.isEUPatient && (
						<>
							{orderDetails.patientFirstname && (
								<Descriptions.Item label="Name">
									<b>{orderDetails.patientFirstname}</b>
								</Descriptions.Item>
							)}

							{orderDetails.patientSurname && (
								<Descriptions.Item label="Surname">
									<b>{orderDetails.patientSurname}</b>
								</Descriptions.Item>
							)}

							{orderDetails.patientDateOfBirth && (
								<Descriptions.Item label="Birth date">
									<b>
										{formatHelpers.formatDate(
											orderDetails.patientDateOfBirth
										)}
									</b>
								</Descriptions.Item>
							)}
						</>
					)}

					<Descriptions.Item>
						<Button
							type="primary"
							shape="round"
							size="small"
							onClick={() => {
								globalSearchStore.filterData.set({
									...globalSearchStore.filterData.get(),
									searchText: orderDetails.patientId,
									dateType: 2,
									searchMethod: SearchType.PESEL
								});
								globalSearchStore.visible.set(true);
							}}
						>
							All orders
						</Button>
					</Descriptions.Item>
				</Descriptions>
			</div>

			<div className={styles.section}>
				<Space className="header-text">
					<SnippetsOutlined />
					Order
				</Space>
				<Descriptions
					className={styles.description}
					column={2}
					size="small"
				>
					{orderDetails?.pharmacyName && (
						<Descriptions.Item label="Pharmacy">
							<Space>
								<b>{orderDetails.pharmacyName}</b>
								{orderDetails?.pharmacyId && (
									<Button
										type="primary"
										shape="round"
										size="small"
										disabled={props.isInSearch}
										onClick={() => {
											history.push(
												`/pharmacies/details/${orderDetails.pharmacyId}`
											);
										}}
									>
										details
									</Button>
								)}
							</Space>
						</Descriptions.Item>
					)}

					{orderDetails?.orderReceiptDate && (
						<Descriptions.Item label="Order receipt date">
							<b>
								{formatHelpers.formatDate(
									orderDetails.orderReceiptDate
								)}
							</b>
						</Descriptions.Item>
					)}

					{orderDetails?.releaseDate && (
						<Descriptions.Item label="Release date">
							<b>
								{formatHelpers.formatDate(
									orderDetails.releaseDate
								)}
							</b>
						</Descriptions.Item>
					)}

					{orderDetails?.productReleaseDate && (
						<Descriptions.Item label="Product release date">
							<b>
								{formatHelpers.formatDate(
									orderDetails.productReleaseDate
								)}
							</b>
						</Descriptions.Item>
					)}

					{orderDetails?.realizationPeriod && (
						<Descriptions.Item label="Realization period">
							<b>{orderDetails.realizationPeriod}</b>
						</Descriptions.Item>
					)}

					{orderDetails?.releasePlace && (
						<Descriptions.Item label="Release place">
							<b>{orderDetails.releasePlace}</b>
						</Descriptions.Item>
					)}

					{orderDetails?.additionalEntitlementCode && (
						<Descriptions.Item label="Additional entitlement code">
							<EssityTooltip
								placement="top"
								title={
									orderDetails.additionalEntitlementFullName
								}
							>
								<b>{orderDetails.additionalEntitlementCode}</b>
							</EssityTooltip>
						</Descriptions.Item>
					)}
				</Descriptions>
			</div>
			<div className={styles.section}>
				<Space className="header-text">
					<ShoppingCartOutlined />
					Products
				</Space>
				<Descriptions
					className={`${styles.description} ${styles.products}`}
				>
					<Descriptions.Item>
						<EssityTable
							columns={OrderLinesTableColumns}
							dataSource={props.orderDetails?.orderLines}
							pagination={false}
						/>
					</Descriptions.Item>
				</Descriptions>
			</div>

			<div className={styles.section}>
				<div className={`${styles.description} ${styles.summary}`}>
					<p className={styles.prefix}>Summary</p>
					<div>
						<p>Refund</p>
						<p className={styles.value}>
							{props.orderDetails?.summaryRefund}
						</p>
					</div>
					<div>
						<p>Surcharge</p>
						<p className={styles.value}>
							{props.orderDetails?.summarySurcharge}
						</p>
					</div>
					<div>
						<p>Total value</p>
						<p className={styles.value}>
							{props.orderDetails?.summaryTotalValue}
						</p>
					</div>
				</div>
			</div>
		</div>
	) : (
		<OrderLinesDetailsSkeleton />
	);
};
