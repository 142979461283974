import { GlobalUserManager } from '@authentication/userManager';
import { SignoutReason } from '@services/mappers/SignoutReason';
import { useIdleTimer } from 'react-idle-timer';
import { IAccountDetailsStore } from 'stores/AccountDetailsStore';

import { useRootData } from './hook';

const handleOnIdle = (event: Event, accountStore: IAccountDetailsStore) => {
	if (accountStore.oidcUser.get()) {
		GlobalUserManager.UserManager().signoutRedirect();
		localStorage.setItem('signOutReason', SignoutReason.USER_INACTIVE);
	}
};

export const useIdleHook = () => {
	const accountDetailsStore: IAccountDetailsStore = useRootData(
		(store) => store.accountDetailsStore
	);

	GlobalUserManager.UserManager().events.addUserUnloaded(() => {
		accountDetailsStore.oidcUser.set(undefined);
	});

	useIdleTimer({
		timeout: 1000 * 30 * 15,
		onIdle: (e: Event) => handleOnIdle(e, accountDetailsStore),
		debounce: 500,
	});
};
