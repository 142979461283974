import { IsolatedModal } from '@components/shared/modals/IsolatedModal';
import { useRootData } from '@hooks/hook';
import { OrderDto } from '@services/src/models';
import { Button, Row } from 'antd';
import React, { useState } from 'react';
import { IGridStore } from 'stores/GridStore';
import OrdersReturnDocumentTable from './OrdersReturnDocumentTable';


interface IProps {
	pharmacyId: string;
	year: number;
	month: number;
	onOrdersSelect: (orders: OrderDto[]) => void;
	selectedOrders: OrderDto[] | undefined;
}

export const OrdersToReturnDocumentModal = (props: IProps) => {
	const [visible, setVisible] = useState<boolean>();
	const gridStore: IGridStore = useRootData((store) => store.gridStore);

	const handleCancel = () => {
		setVisible(false);
		gridStore.setSelectedKeys([]);
		gridStore.setSelectedValues([]);
	};

	return (
		<>
			<Row>
				<Button
					type="primary"
					size="middle"
					shape="round"
					style={{ marginBottom: '10px!important;' }}
					onClick={() => setVisible(true)}
				>
					Choose orders
				</Button>
			</Row>

			{visible && (
				<IsolatedModal
					title='Orders'
					visible={visible}
					width="85vw"
					onCancel={handleCancel}
					footer={
						<Button
							type="primary"
							size="large"
							shape="round"
							onClick={() => {
								props.onOrdersSelect(
									gridStore.getSelectedValues()
								);
								gridStore.setSelectedKeys([]);
								gridStore.setSelectedValues([]);
								setVisible(false);
							}}
						>
							Save
						</Button>
					}
				>
					<OrdersReturnDocumentTable
						pharmacyId={props.pharmacyId}
						year={props.year}
						month={props.month}
						onOrdersSelect={(orders: OrderDto[]) => {
							props.onOrdersSelect(orders);
							setVisible(false);
						}}
						selectedOrders={props.selectedOrders}
					/>
				</IsolatedModal>
			)}
		</>
	);
};
