import { FileResultDto } from '@services/src/models';
import FileSaver from 'file-saver';
import _ from 'lodash';

interface IProps {
	response: any;
	action?: () => void;
}

export const DownloadFile = (props: IProps) => {
	var fileName = props.response._response.headers
		.get('content-disposition')
		?.split('filename=')[1]
		.split(';')[0];

	if (
		props.response._response.headers.get('content-type') ==
		'application/pdf'
	)
		if (fileName?.includes('"')) fileName = fileName?.split('"')[1];

	if (props.response.blobBody) {
		props.response.blobBody.then((x: any) => {
			FileSaver.saveAs(x, _.replace(fileName!, /"/g, ''));
			props.action && props.action();
		});
	}
};

export const DownloadByCreateElement = (props: IProps) => {
	var fileName = props.response._response.headers
		.get('content-disposition')
		?.split('filename=')[1]
		.split(';')[0];

	if (
		props.response._response.headers.get('content-type') ==
		'application/pdf'
	)
		if (fileName?.includes('"')) fileName = fileName?.split('"')[1];

	if (props.response.blobBody) {
		props.response.blobBody.then((x: any) => {
			var data = new Blob([x], { type: 'enx' });
			var csvURL = window.URL.createObjectURL(data);
			let tempLink = document.createElement('a');
			tempLink.href = csvURL;
			tempLink.setAttribute('download', fileName);
			tempLink.click();
			props.action && props.action();
		});
	}
};

export const DownloadFileFromArray = (fileResult: FileResultDto) => {
	const blob = new Blob(
		[
			new Uint8Array(
				fileResult.data!.buffer,
				fileResult.data!.byteOffset,
				fileResult.data!.length
			),
		],
		{ type: fileResult.mimeType }
	);
	const link = document.createElement('a');
	link.href = window.URL.createObjectURL(blob);
	link.download = fileResult.fileName!;
	link.click();
};
