import { EssityColumnProps } from '@components/shared/paginatedEssityTable/GridHelper';
import { ajaxByUser } from '@helper/api';
import { getEssityApiClient } from '@services/EssityApi';
import { PharmacyTaxNoteGroupManualOperationDto } from '@services/src/models';
import { Button, Popconfirm, Space, Tooltip } from 'antd';
import React from 'react';

import { EditManualOperationModal } from './EditManualOperationModal';

export const Operations = (
	refresh: () => void
): EssityColumnProps<PharmacyTaxNoteGroupManualOperationDto> => {
	return {
		title: '',
		key: 'operation',
		fixed: 'right',
		render: (item: PharmacyTaxNoteGroupManualOperationDto) => (
			<Space direction="horizontal">
				<EditManualOperationModal operationId={item.id!} refresh={refresh} />
				{DeleteAction(item, refresh)}
			</Space>
		),
	};
};

const EditAction = (
	item: PharmacyTaxNoteGroupManualOperationDto,
	refresh: () => void
) => <EditManualOperationModal operationId={item.id!} refresh={refresh} />;

const DeleteAction = (
	item: PharmacyTaxNoteGroupManualOperationDto,
	refresh: () => void
) => {
	return (
		<Popconfirm
			title="Are you sure to delete manual operation?"
			okText="Yes"
			cancelText="No"
			placement="bottom"
			onConfirm={() => {
				ajaxByUser('Successfully deleted manual operation.', () =>
					getEssityApiClient().then((api) =>
						api.pharmacyTaxNoteGroupManualOperation
							.deleteMethod({
								body: {
									id: item.id,
								},
							})
							.then(() => refresh())
					)
				);
			}}
		>
			<Tooltip title="Delete">
				<Button shape="round" size="small" danger ghost>
					Delete
				</Button>
			</Tooltip>
		</Popconfirm>
	);
};
