import { IsolatedModal } from '@components/shared/modals/IsolatedModal';
import { useRootData } from '@hooks/hook';
import { OrderDto } from '@services/src/models';
import { Button } from 'antd';
import React, { useState } from 'react';
import { IGridStore } from 'stores/GridStore';

import OrdersTable from './OrdersTable';

interface IProps {
	readOnly: boolean;
	pharmacyId: string;
	year: number;
	month: number;
	onOrdersSelect: (orders: OrderDto[]) => void;
	selectedOrders: OrderDto[] | undefined;
	suggestedOrders?: OrderDto[] | undefined;
	noteValue?: number;
	pharmacyTaxNoteId: string | undefined;
}

export const PharmacyNoteAddOrdersModal = (props: IProps) => {
	const [visible, setVisible] = useState<boolean>();
	const gridStore: IGridStore = useRootData((store) => store.gridStore);

	const handleCancel = () => {
		setVisible(false);
		gridStore.setSelectedKeys([]);
		gridStore.setSelectedValues([]);
	};

	return (
		<>
			<Button
				type="primary"
				size="middle"
				shape="round"
				style={{ width: '100%' }}
				onClick={() => {
					if (
						props.noteValue !== undefined &&
						props.suggestedOrders &&
						props.noteValue.toFixed(2) ===
							props.suggestedOrders
								.map((x) => x.refund!)
								.reduce((a, b) => a + b, 0)
								.toFixed(2)
					) {
						props.onOrdersSelect(props.suggestedOrders);
					} else {
						setVisible(true);
					}
				}}
			>
				{props.readOnly ? 'Show orders' : 'Assign orders'}
			</Button>
			{visible && (
				<IsolatedModal
					title='Orders'
					visible={visible}
					width="85vw"
					onCancel={handleCancel}
					footer={
						<Button
							type="primary"
							size="large"
							shape="round"
							disabled={props.readOnly}
							onClick={() => {
								props.onOrdersSelect(
									gridStore.getSelectedValues()
								);
								gridStore.setSelectedKeys([]);
								gridStore.setSelectedValues([]);
								setVisible(false);
							}}
						>
							Save
						</Button>
					}
				>
					<OrdersTable
						pharmacyId={props.pharmacyId}
						readOnly={props.readOnly}
						year={props.year}
						month={props.month}
						selectedOrders={props.selectedOrders}
						onOrdersSelect={(orders: OrderDto[]) => {
							props.onOrdersSelect(orders);
							setVisible(false);
						}}
						pharmacyTaxNoteId={props.pharmacyTaxNoteId}
					/>
				</IsolatedModal>
			)}
		</>
	);
};
