import { AutoComplete } from 'antd';
import React, { useRef, useState } from 'react';

export interface ISearchResult {
    id: string;
    value: string;
}
interface IProps {
	className: string;
    searchFunction: (searchText: string) => Promise<ISearchResult[]>;
    onSelect: (value: string, object: any) => void;
    onClear: () => void;
    placeholder: string;
    timeout: number;
}

export const AutocompleteSearch = (props: IProps) => {
	const [options, setOptions] = useState<ISearchResult[]>([]);
	const timerRef = useRef<NodeJS.Timeout | null>(null);

    const search = async (searchText: string) => {
        const result = await props.searchFunction(searchText);

        setOptions(result);
	};

	const onSearch = async (searchText: string) => {
		if (timerRef.current) {
			clearTimeout(timerRef.current);
		}

		timerRef.current = setTimeout(() => search(searchText), props.timeout);
	};

	return (
		<AutoComplete
			className={props.className}
			size="small"
			options={options}
			onSelect={props.onSelect}
			onSearch={onSearch}
            placeholder={props.placeholder}
			notFoundContent="No results"
			allowClear
            onClear={props.onClear}
		/>
	);
};
