import { ajaxCatch } from '@helper/api';
import { getEssityApiClient } from '@services/EssityApi';
import { AccountDto } from '@services/src/models';
import { Button } from 'antd';
import { CenteredRow } from 'layout/CenteredRow';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';

import { AccountsManagementMenu } from './AccountsManagementMenu';
import { AccountsTable } from './AccountsTable';
import styles from './styles/AccountsManagement.module.less';

export const AccountsManagement = () => {
	const [isBusy, setIsBusy] = useState<boolean>(false);
	const [accounts, setAccounts] = useState<AccountDto[]>();
	const [skipToken, setSkipToken] = useState<string>();

	useEffect(() => {
		if (skipToken === undefined) {
			refreshAccounts();
		}
	}, [skipToken]);

	const refreshAccounts = (searchText?: string, isSearching?: boolean) => {
		setIsBusy(true);
		ajaxCatch(() =>
			getEssityApiClient().then((api) =>
				api.account
					.getUsers({ skipToken: skipToken, searchText: searchText })
					.then((result) => {
						if (result) {
							let acc = _.uniqBy(
								[...(accounts ?? []), ...result.items!],
								'id'
							);

							if (isSearching) {
								acc = result.items!;
							}

							setAccounts(acc);
							setSkipToken(result.skipToken);
						}
					})
					.finally(() => setIsBusy(false))
			)
		);
	};

	return (
		<div>
			<AccountsManagementMenu
				onCreate={() => {
					setAccounts([]);
					setSkipToken(undefined);
				}}
				onSearch={(value) => {
					refreshAccounts(value, true);
				}}
			/>

			<div className={styles.tableContainer}>
				<AccountsTable
					dataSource={accounts}
					isBusy={isBusy}
					onDeleteAction={(accountId: string) => {
						const accountsWithoutDeleted = accounts?.filter(
							(x) => x.id !== accountId
						);
						setAccounts(accountsWithoutDeleted);
					}}
				/>
			</div>
			{skipToken && (
				<CenteredRow>
					<Button
						disabled={isBusy}
						type="primary"
						shape="round"
						size="large"
						onClick={() => {
							refreshAccounts();
						}}
					>
						Load more
					</Button>
				</CenteredRow>
			)}
		</div>
	);
};
