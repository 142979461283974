export const voivodeship = new Map<string, string>([
	['Dolnośląskie', 'DŚ'],
	['Kujawsko-Pomorskie', 'KP'],
	['Lubelskie', 'LB'],
	['Lubuskie', 'LS'],
	['Łódzkie', 'ŁD'],
	['Małopolskie', 'MP'],
	['Mazowieckie', 'MZ'],
	['Opolskie', 'OP'],
	['Podkarpackie', 'PK'],
	['Podlaskie', 'PL'],
	['Pomorskie', 'PM'],
	['Śląskie', 'ŚL'],
	['Świętokrzyskie', 'ŚK'],
	['Warmińsko-Mazurskie', 'WM'],
	['Wielkopolskie', 'WP'],
	['Zachodniopomorskie', 'ZP'],
]);

export const map = (value: string): string => voivodeship.get(value) ?? '';
