import { ajaxCatch } from '@helper/api';
import { getEssityApiClient } from '@services/EssityApi';
import { OrderDetailsDto } from '@services/src/models';
import React, { useEffect, useState } from 'react';

import { OrderLinesDetails } from './OrderLinesDetails';
import OrderLinesMenu from './OrderLinesMenu';
import { OrderReferences } from './OrderReferences';
import styleModule from './styles/OrderLines.module.less';

interface IProps {
	orderId: string;
	isInSearch: boolean;
	theme?: { [key: string]: string };
}

export const OrderLines = (props: IProps) => {
	const [orderDetails, setOrderDetails] = useState<OrderDetailsDto>();

	const styles = props.theme ? props.theme : styleModule;

	useEffect(() => {
		if (props.orderId) {
			ajaxCatch(() =>
				getEssityApiClient().then((api) =>
					api.order.getById(props.orderId).then((result) => {
						if (result) {
							setOrderDetails(result);
						}
					})
				)
			);
		}
	}, [props.orderId]);

	return (
		<div
			className={styles.orderLines}
		>
			<OrderLinesMenu
				orderDetails={orderDetails}
				isInSearch={props.isInSearch}
				theme={props.theme}
			/>
			<OrderLinesDetails
				orderDetails={orderDetails}
				theme={props.theme}
				isInSearch={props.isInSearch}
			/>
			<OrderReferences
				orderId={props.orderId}
				theme={props.theme}
			/>

		</div>
	);
};
