import { EssityColumnProps } from '@components/shared/paginatedEssityTable/GridHelper';
import formatHelpers from '@helper/formatHelpers';
import { InternalTaxNoteType, PharmacyTaxNoteDto } from '@services/src/models';
import React from 'react';
import * as InternalTaxNoteTypeMapper from '@services/mappers/InternalTaxNoteType';
import { IsSummaryRecord } from './PharmacyNotesHelpers';
import styles from './styles/PharmacyNotes.module.less';

const SummaryRecord = (value?: number) => {
	if (value === null || value === undefined) {
		return undefined;
	}

	return <p className={styles.summaryRecord}>{value.toFixed(2)}</p>;
};

export const PharmacyNotesTableColumns: EssityColumnProps<PharmacyTaxNoteDto>[] = [
	{
		sorter: false,
		dataIndex: 'internalTaxNoteType',
		key: 'internalTaxNoteType',
		title: 'Note type',
		width: 120,
		render: (value: InternalTaxNoteType) => {
			return InternalTaxNoteTypeMapper.map(value);
		},
	},
	{
		sorter: false,
		dataIndex: 'noteNumber',
		key: 'noteNumber',
		title: 'Note number',
		width: 150,
	},
	{
		sorter: false,
		dataIndex: 'internalNumber',
		key: 'internalNumber',
		title: 'Internal number',
		width: 250,
	},
	{
		sorter: false,
		dataIndex: 'noteDate',
		key: 'noteDate',
		title: 'Note date',
		render: (value?: Date) => formatHelpers.formatDate(value),
		width: 120,
	},
	{
		sorter: false,
		dataIndex: 'month',
		key: 'month',
		title: 'Month',
		width: 100,
	},
	{
		sorter: false,
		key: 'year',
		title: 'Year',
		width: 100,
		render: (item: PharmacyTaxNoteDto) => {
			return IsSummaryRecord(item) ? (
				<p className={styles.summary}>Summary</p>
			) : (
				item.year
			);
		},
	},
	{
		sorter: false,
		key: 'noteValue',
		title: 'Note value',
		width: 100,
		render: (item: PharmacyTaxNoteDto) => {
			return SummaryRecord(item?.noteValueSummary) || item?.noteValue;
		},
	},
	{
		sorter: false,
		key: 'ordersValue',
		title: 'Orders value',
		width: 100,
		render: (item: PharmacyTaxNoteDto) => {
			return SummaryRecord(item?.ordersValueSummary) || item?.ordersValue;
		},
	},
	{
		sorter: false,
		key: 'settledOrdersValue',
		title: 'Settled NHF',
		width: 100,
		render: (item: PharmacyTaxNoteDto) => {
			return (
				SummaryRecord(item?.settledOrdersValueSummary) ||
				item?.settledOrdersValue
			);
		},
	},
	{
		sorter: false,
		key: 'lossValue',
		title: 'Loss',
		width: 100,
		render: (item: PharmacyTaxNoteDto) => {
			return SummaryRecord(item?.lossValueSummary) || item?.lossValue;
		},
	},
	{
		sorter: false,
		key: 'overpayValue',
		title: 'Overpay',
		width: 100,
		render: (item: PharmacyTaxNoteDto) => {
			return SummaryRecord(item?.overpayValueSummary) || item?.overpayValue;
		},
	},
	{
		sorter: false,
		key: 'returnedValue',
		title: 'Returned',
		width: 100,
		render: (item: PharmacyTaxNoteDto) => {
			return SummaryRecord(item?.returnedValueSummary) || item?.returnedValue;
		},
	},
	{
		sorter: false,
		key: 'paidValue',
		title: 'Paid',
		width: 100,
		render: (item: PharmacyTaxNoteDto) => {
			return SummaryRecord(item?.paidValueSummary) || item?.paidValue;
		},
	},
];
