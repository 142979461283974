import { SearchOutlined } from '@ant-design/icons';
import { useRootData } from '@hooks/hook';
import { SearchType } from '@services/src/models';
import { Button, Dropdown, Menu } from 'antd';
import EssityTooltip from 'layout/EssityTooltip';
import React from 'react';
import { IGlobalSearchStore } from 'stores/GlobalSearchStore';

import GlobalSearchModal from './GlobalSearchModal';

interface IProps {}

const AccountMenu: React.FC<IProps> = (props: IProps) => {
	const globalSearchStore: IGlobalSearchStore = useRootData(
		(store) => store.globalSearchStore
	);

	const resetFilters = () => {
		globalSearchStore.filterData.set({
			searchText: undefined,
			searchMethod: SearchType.PESEL,
			searchOrderStates: undefined,
			searchVoivodeships: undefined,
			dateType: 1,
			dateFrom: null,
			dateTo: null,
		});
	};

	const menu = (
		<Menu>
			<Menu.Item
				key="1"
				onClick={() => {
					resetFilters();
					globalSearchStore.filterData.set({
						...globalSearchStore.filterData.get(),
						searchMethod: SearchType.PESEL,
					});
					globalSearchStore.visible.set(true);
				}}
			>
				Search order by PESEL
			</Menu.Item>
			<Menu.Item
				key="2"
				onClick={() => {
					resetFilters();
					globalSearchStore.filterData.set({
						...globalSearchStore.filterData.get(),
						searchMethod: SearchType.OrderNumber,
					});
					globalSearchStore.visible.set(true);
				}}
			>
				Search order by Order number
			</Menu.Item>
			<Menu.Item
				key="3"
				onClick={() => {
					resetFilters();
					globalSearchStore.filterData.set({
						...globalSearchStore.filterData.get(),
						searchMethod: SearchType.Status,
					});
					globalSearchStore.visible.set(true);
				}}
			>
				Search order by Status
			</Menu.Item>
		</Menu>
	);

	return (
		<>
			<EssityTooltip placement="left" title="Global search">
				<Dropdown overlay={menu} trigger={['click']}>
					<Button
						aria-label="global search"
						size="large"
						shape="circle"
						icon={<SearchOutlined />}
					/>
				</Dropdown>
			</EssityTooltip>
			<GlobalSearchModal />
		</>
	);
};

export default AccountMenu;
