import DictionaryFilter from '@components/shared/DictionaryFilter';
import { useRootData } from '@hooks/hook';
import { DictionariesConstants } from '@services/DictionariesConstants';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { IAccountDetailsStore } from 'stores/AccountDetailsStore';
import { IDictionaryStore } from 'stores/DictionaryStore';
import { IGridStore } from 'stores/GridStore';

import styles from './QuickFilters.module.less';
import SettlementPeriod from './SettlementPeriod';

const grids: string[] = [
	'orderGroups',
	'nhfSettlements',
	'pharmaciesNoteGroups',
	'pharmaciesFinder',
];

interface IProps {
	hide: boolean;
}

const QuickFilters = (props: IProps) => {
	const dictionaryStore: IDictionaryStore = useRootData(
		(store) => store.dictionaryStore
	);

	const gridStore: IGridStore = useRootData((store) => store.gridStore);

	const accountDetailsStore: IAccountDetailsStore = useRootData(
		(store) => store.accountDetailsStore
	);

	useEffect(() => {
		loadValueFromMemory();
	}, [accountDetailsStore.account.get()]);

	const parseValuesFromMemory = (value?: string): number[] => {
		if (value) {
			const parsedValues: number[] = value
				.split('|')
				.map((x: string) => +x);

			return parsedValues;
		}

		return [];
	};

	const loadValueFromMemory = () => {
		const memoryValue = accountDetailsStore.account.get()?.settings
			?.voivodeshipFilterDictionaryIds;

		if (memoryValue) {
			const parsedValues = parseValuesFromMemory(memoryValue);
			handleFilterChange(parsedValues, grids);
		}
	};

	const handleFilterChange = (items: number[], grids: string[]) => {
		const filterValue = items.join('|');

		accountDetailsStore.settings.set({
			...accountDetailsStore.settings.get(),
			voivodeshipFilterDictionaryIds: filterValue,
		});

		gridStore.saveColumnFilterForMultipleGrids(
			grids,
			'voivodeshipId',
			filterValue,
			'equals'
		);

		gridStore.searching.set(true);
	};

	return (
		<div className={styles.filters}>
			<div />
			{!props.hide && (
				<>
					<DictionaryFilter
						disabled={
							accountDetailsStore.settings.get().filtersLocked
						}
						className={`${styles.item} ${styles.voivodeship}`}
						mode="multiple"
						name="Voivodeship"
						selectedValue={parseValuesFromMemory(
							accountDetailsStore.settings.get()
								.voivodeshipFilterDictionaryIds
						)}
						onChange={(items: number[]) =>
							handleFilterChange(items, grids)
						}
						dictionary={dictionaryStore.getDictionary(
							DictionariesConstants.Voivodeships
						)}
					/>
					<SettlementPeriod
						dictionary={dictionaryStore.getDictionary(
							DictionariesConstants.SettlementPeriod
						)}
						grids={grids}
					/>
				</>
			)}
		</div>
	);
};

export default observer(QuickFilters);
