import { IsolatedModal } from '@components/shared/modals/IsolatedModal';
import { ImportUmxPharmaciesStatusDto } from '@services/src/models';
import { Button, Space, notification } from 'antd';
import React, { useEffect, useState } from 'react';

import { ImportUMXValidationTable } from './ImportUMXValidationTable';

interface IProps {
	validation: ImportUmxPharmaciesStatusDto;
}

export const ImportUMXValidationModal = (props: IProps) => {
	const [visible, setVisible] = useState<boolean>(false);

	const {
		missingKodKomsInFile,
		missingKodKomsInDatabase,
		kodKomsInWrongStates,
	} = props.validation;

	useEffect(() => {
		if (props.validation) {
			setVisible(true);
		}
	}, [props.validation]);

	const handleCancel = () => {
		setVisible(false);
	};

	return (
		<>
			{visible && (
				<IsolatedModal
					title="UMX file validation"
					visible={visible}
					onCancel={handleCancel}
				>
					{missingKodKomsInFile && missingKodKomsInFile.length > 0 && (
						<>
							<Space direction="horizontal">
								<h2>Missing KodKoms in file</h2>
								<Button
									shape="round"
									size="middle"
									type="primary"
									style={{ marginBottom: 5 }}
									onClick={() => {
										navigator.clipboard
											.writeText(`Missing KodKoms in file:
											${missingKodKomsInFile.map((x) => x.id).toString()}`);
										notification.success({
											placement: 'bottomRight',
											message:
												'Successfully copied data to clipboard.',
										});
									}}
								>
									Copy data to clipboard
								</Button>
							</Space>
							<ImportUMXValidationTable
								validation={missingKodKomsInFile}
							/>
						</>
					)}

					{missingKodKomsInDatabase &&
						missingKodKomsInDatabase.length > 0 && (
							<>
								<Space direction="horizontal">
									<h2>Missing KodKoms in database</h2>
									<Button
										shape="round"
										size="middle"
										type="primary"
										style={{ marginBottom: 5 }}
										onClick={() => {
											navigator.clipboard
												.writeText(`Missing KodKoms in database:
											${missingKodKomsInDatabase.map((x) => x.id).toString()}`);
											notification.success({
												placement: 'bottomRight',
												message:
													'Successfully copied data to clipboard.',
											});
										}}
									>
										Copy data to clipboard
									</Button>
								</Space>

								<ImportUMXValidationTable
									validation={missingKodKomsInDatabase}
								/>
							</>
						)}

					{kodKomsInWrongStates && kodKomsInWrongStates.length > 0 && (
						<>
							<Space direction="horizontal">
								<h2>Status closed</h2>
								<Button
									shape="round"
									size="middle"
									type="primary"
									style={{ marginBottom: 5 }}
									onClick={() => {
										navigator.clipboard
											.writeText(`Status closed:
										${kodKomsInWrongStates.map((x) => x.id).toString()}`);
										notification.success({
											placement: 'bottomRight',
											message:
												'Successfully copied data to clipboard.',
										});
									}}
								>
									Copy data to clipboard
								</Button>
							</Space>
							<ImportUMXValidationTable
								validation={kodKomsInWrongStates}
							/>
						</>
					)}
				</IsolatedModal>
			)}
		</>
	);
};
