import { PharmaciesNoteGroupsAddNote } from '@components/pharmaciesNoteGroups/PharmaciesNoteGroupsAddNote';
import { AddCorrectionNote } from '@components/pharmacyNote/correctionNote/AddCorrectionNote';
import { AddLossNote } from '@components/pharmacyNote/lossNote/AddLossNote';
import { BackButton } from '@components/shared/buttons/BackButton';
import { useRootData } from '@hooks/hook';
import { DictionariesConstants } from '@services/DictionariesConstants';
import { PharmacyTaxNoteGroupDto } from '@services/src/models';
import { Button, Divider, Space } from 'antd';
import React from 'react';
import { useHistory } from 'react-router';
import { IDictionaryStore } from 'stores/DictionaryStore';

import { ManualOperationsModal } from './manualOperation/ManualOperationsModal';
import { AddReturnDocumentModal } from './pharmaciesReturnDocuments/AddReturnDocument';
import styles from './styles/PharmacyNotes.module.less';

interface IProps {
	noteGroup: PharmacyTaxNoteGroupDto;
}

export const PharmacyNotesMenu = (props: IProps) => {
	const group = props.noteGroup;

	const dictionaryStore: IDictionaryStore = useRootData(
		(store) => store.dictionaryStore
	);

	const history = useHistory();

	const getHeader = () => {
		return `${group.pharmacyName} (${dictionaryStore.getValueById(
			DictionariesConstants.Voivodeships,
			group.voivodeshipId!
		)}, ${group.placeId}) ${group.month}/${group.year}`;
	};

	return (
		<>
			<Divider />
			<Space direction="vertical" className={styles.menu}>
				<h2>{getHeader()}</h2>

				<Space direction="horizontal">
					<BackButton
						customAction={() => history.push('/pharmacyPayments')}
					/>
					<PharmaciesNoteGroupsAddNote
						pharmacyId={props.noteGroup.pharmacyId}
					/>
					<AddCorrectionNote noteGroupId={props.noteGroup.id!} />
					<AddLossNote noteGroupId={props.noteGroup.id!} />
					<ManualOperationsModal noteGroupId={props.noteGroup.id!} />
					<AddReturnDocumentModal
						pharmacyId={props.noteGroup.pharmacyId!}
						year={props.noteGroup.year!}
						month={props.noteGroup.month!}
						pharmacyTaxNoteGroupId={props.noteGroup.id!}
					/>

				</Space>
			</Space>

			<Divider />
		</>
	);
};
