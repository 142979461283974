import PaginatedEssityTable from '@components/shared/paginatedEssityTable/PaginatedEssityTable';
import { SieveModel } from '@components/shared/paginatedEssityTable/SieveModels';
import { useRootData } from '@hooks/hook';
import { getEssityApiClient } from '@services/EssityApi';
import {
	BusinessObjectsState,
	PharmacyTaxNoteGroupAllOptionalParams,
	PharmacyTaxNoteGroupDto,
} from '@services/src/models';
import React from 'react';
import { useHistory } from 'react-router';
import { IAccountDetailsStore } from 'stores/AccountDetailsStore';
import { IGlobalSearchStore } from 'stores/GlobalSearchStore';

import { GetPharmaciesNoteGroupsTableColumns } from './PharmaciesNoteGroupsTableColumns';
import styles from './styles/PharmaciesNoteGroups.module.less';

interface IProps {}

export const PharmaciesNoteGroupsTable = (props: IProps) => {
	const gridName = 'pharmaciesNoteGroups';

	const history = useHistory();

	const accountDetailsStore: IAccountDetailsStore = useRootData(
		(store) => store.accountDetailsStore
	);

	const globalSearchStore: IGlobalSearchStore = useRootData(
		(store) => store.globalSearchStore
	);

	return (
		<PaginatedEssityTable<PharmacyTaxNoteGroupDto>
			className={styles.table}
			columns={GetPharmaciesNoteGroupsTableColumns()}
			gridName={gridName}
			hasRowSelection={true}
			hidePersonalizationSettings={true}
			scroll={{ x: 'max-content' }}
			getRowKey={(r: PharmacyTaxNoteGroupDto) => r.id!}
			onRow={(record: PharmacyTaxNoteGroupDto) => {
				return {
					onDoubleClick: () => {
						history.push(
							`/pharmacyPayments/${record.id}/${record.pharmacyId}`
						);
					},
				};
			}}
			getPagedResult={async (sieve: SieveModel, abortSignal) => {
				const filters = globalSearchStore.pharmacyPaymentsFilter.get();

				const parameters: PharmacyTaxNoteGroupAllOptionalParams = {
					page: sieve.page,
					pageSize: sieve.pageSize,
					sorts: sieve.sorts,
					abortSignal: abortSignal,
					filters: sieve.filters,
					businessObjectsState: filters.businessObjectsState,
					settlementPeriodFilterCodeName: accountDetailsStore.settings.get()
						.settlementPeriodFilterDictionaryValueCodeName,
					zeroBalance: filters.zeroBalance,
				};

				const api = await getEssityApiClient();
				return await api.pharmacyTaxNoteGroup.all(parameters);
			}}
		/>
	);
};
