import { CloudDownloadOutlined } from '@ant-design/icons';
import { EssityDatePicker } from '@components/shared/EssityDateTime';
import { DownloadFile } from '@components/shared/file/FileDownloadFunctions';
import { LoadingView } from '@components/shared/import/LoadingView';
import { IsolatedModal } from '@components/shared/modals/IsolatedModal';
import { ajaxByUser } from '@helper/api';
import { getEssityApiClient } from '@services/EssityApi';
import { FileType } from '@services/src/models';
import { Button, DatePicker, Dropdown, Menu, Radio, Space } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import { ReportDateType } from 'enums/ReportDateType';
import { CenteredRow } from 'layout/CenteredRow';
import { Moment } from 'moment';
import React, { useState } from 'react';

import styles from './styles/Reports.module.less';

interface IState {
	year?: Moment;
	dateFrom?: Moment;
	dateTo?: Moment;
	fileType?: FileType;
	reportDateType?: ReportDateType;
}

export const TemplateNotesReport = () => {
	const [visible, setVisible] = useState<boolean>(false);
	const [state, setState] = useState<IState>();
	const [isBusy, setIsBusy] = useState<boolean>();

	const { RangePicker } = DatePicker;

	const menu = (
		<Menu>
			<Menu.Item
				key="1"
				onClick={() => {
					setState({ ...state, fileType: FileType.CSV });
					setVisible(true);
				}}
			>
				Export data as CSV
			</Menu.Item>
			<Menu.Item
				key="2"
				onClick={() => {
					setState({ ...state, fileType: FileType.XLSX });
					setVisible(true);
				}}
			>
				Export data as XLSX
			</Menu.Item>
		</Menu>
	);

	const handleCancel = () => {
		setVisible(false);
		setState(undefined);
	};

	const onChange = (event: RadioChangeEvent) => {
		setState({ ...state, reportDateType: event.target.value });
	};

	const handleSubmit = async () => {
		setIsBusy(true);
		ajaxByUser('Successfully downloaded file', () =>
			getEssityApiClient().then((api) =>
				api.report
					.getTemplateNotes({
						year: state?.year?.year(),
						dateFrom: state?.dateFrom?.toDate() || undefined,
						dateTo: state?.dateTo?.toDate() || undefined,
						fileType: state?.fileType,
					})
					.then((result) => {
						DownloadFile({
							response: result,
						});
					})
					.finally(() => {
						setVisible(false);
						setState(undefined);
						setIsBusy(false);
					})
			)
		);
	};

	const isExportDisabled = () => {
		if (state?.reportDateType === ReportDateType.DateScope) {
			return !state.dateFrom || !state.dateTo;
		}

		return !state?.year;
	};

	return (
		<>
			<div className={styles.report}>
				<Space direction="vertical">
					<h3>NHF notes report</h3>

					<p>Export NHF notes to xlsx or csv file</p>
				</Space>

				<Dropdown overlay={menu} placement="bottomLeft" arrow>
					<Button type="primary" shape="round" size="middle">
						Run
					</Button>
				</Dropdown>
			</div>
			<IsolatedModal
				title="Exporting orders"
				visible={visible}
				onCancel={handleCancel}
			>
				<Space direction="vertical">
					<Space direction="horizontal" align="center">
						<h4
							style={{
								marginTop: 'auto',
								marginBottom: 'auto',
							}}
						>
							Pick report type:
						</h4>
						<Radio.Group
							onChange={onChange}
							value={state?.reportDateType}
						>
							<Radio value={ReportDateType.Year}>Year</Radio>
							<Radio value={ReportDateType.DateScope}>
								Date scope
							</Radio>
						</Radio.Group>
					</Space>

					{state?.reportDateType === ReportDateType.Year && (
						<EssityDatePicker
							picker="year"
							value={state.year}
							onChange={(value: any) => {
								setState({ ...state, year: value });
							}}
						/>
					)}
					{state?.reportDateType === ReportDateType.DateScope && (
						<RangePicker
							value={[state.dateFrom!, state.dateTo!]}
							onChange={(value: any) => {
								setState({
									...state,
									dateFrom: value ? value[0] : undefined,
									dateTo: value ? value[1] : undefined,
								});
							}}
						/>
					)}
				</Space>
				<CenteredRow className={styles.exportButton}>
					{isBusy ? (
						<LoadingView message="Creating report, please wait..." />
					) : (
						<Button
							type="primary"
							shape="round"
							size="large"
							onClick={handleSubmit}
							disabled={isExportDisabled()}
						>
							Generate report
							<CloudDownloadOutlined />
						</Button>
					)}
				</CenteredRow>
			</IsolatedModal>
		</>
	);
};
