export enum SignoutReason {
	USER_SIGNOUT = 'USER_SIGNOUT',
	USER_INACTIVE = 'USER_INACTIVE',
}

export const signoutReasonMessage = new Map<SignoutReason, string>([
	[SignoutReason.USER_SIGNOUT, 'You were successfully log out.'],
	[SignoutReason.USER_INACTIVE, 'Your session has expired.'],
]);

export const map = (value: SignoutReason): string =>
	signoutReasonMessage.get(value) ?? '';
