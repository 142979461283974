import { CloudDownloadOutlined, DollarCircleFilled, DeleteOutlined } from '@ant-design/icons';
import { DownloadFile } from '@components/shared/file/FileDownloadFunctions';
import { IsolatedModal } from '@components/shared/modals/IsolatedModal';
import { EssityColumnProps } from '@components/shared/paginatedEssityTable/GridHelper';
import { ajaxByUser, ajaxCatch } from '@helper/api';
import { useRootData } from '@hooks/hook';
import { DictionariesConstants } from '@services/DictionariesConstants';
import { getEssityApiClient } from '@services/EssityApi';
import { OrderDto, PharmacyTaxNoteGroupReturnDocumentDto } from '@services/src/models';
import { Badge, Button, Col, Input, Popconfirm, Row, Skeleton, Space, Tooltip } from 'antd';
import EssityTable from 'layout/EssityTable';
import React, { useEffect, useState } from 'react';
import { IDictionaryStore } from 'stores/DictionaryStore';

import styles from '../styles/ManualOperationsModal.module.less';
import { OrdersToReturnDocumentModal } from './AddOrdersToReturnDocumentModal';
import { AddReturnDocumentTableColumns } from './AddReturnDocumentTableColumns';

class OrderCancelledModel {
	id?: string;
	orderNumber?: string;
	pesel?: string;
	refund?: number;
	cancellationReasonText?: string;
}
interface IProps {
	pharmacyId: string;
	year: number;
	month: number;
	pharmacyTaxNoteGroupId: string;
}

export const AddReturnDocumentModal = (props: IProps) => {
	const [visible, setVisible] = useState<boolean>();
	const [isBusy, setIsBusy] = useState<boolean>();
	const [orders, setOrders] = useState<OrderDto[]>([]);
	const [ordersSelected, setOrdersSelected] = useState<OrderCancelledModel[]>([]);
	const [savedDocuments, setSavedDocuments] = useState<PharmacyTaxNoteGroupReturnDocumentDto[]>([]);
	const [hasAnyReturnDocuments, setHasAnyReturnDocuments] = useState<boolean>(false);
	const [hasAnyCancelledOrders, sethasAnyCancelledOrders] = useState<boolean>(false);

	const dictionaryStore: IDictionaryStore = useRootData(
		(store) => store.dictionaryStore
	);
	const handleCancel = () => {
		setVisible(false);
		setOrders([]);
	};
	const handleChangeReason = (index: string, value: string) => {
		let newState = ordersSelected.map((item) =>
			item.id === index ? { ...item, cancellationReasonText: value } : item
		);
		setOrdersSelected(newState)
	};
	const refreshHasAnyReturnDocuments = async () => {
		ajaxCatch(
			() =>
				getEssityApiClient().then((api) =>
					api.pharmacyTaxNoteGroupReturnDocument.hasAnyReturnDocuments(
						props.pharmacyTaxNoteGroupId,
					)
						.then((result) => {
							setHasAnyReturnDocuments(result.body);
						})
				),
			() => { },
			() => { },
			false
		);
	};
	const refreshHasAnyCancelledOrder = async () => {
		ajaxCatch(
			() =>
				getEssityApiClient().then((api) =>
					api.order.hasAnyCancelledOrdersByPharmacyIdQuery(
						{
							pharmacyId: props.pharmacyId,
							year: props.year,
							month: props.month
						}
					)
						.then((result) => {
							sethasAnyCancelledOrders(result.body);
						})
				),
			() => { },
			() => { },
			false
		);
	};
	const getReturnDocuments = () => {
		if (props.pharmacyTaxNoteGroupId) {
			(async () => {
				const api = await getEssityApiClient();
				const result = await api.pharmacyTaxNoteGroupReturnDocument.getByPharmacyTaxNoteGroupId(
					props.pharmacyTaxNoteGroupId
				);
				setSavedDocuments(result);
				setIsBusy(false);
			})();
		}
	}
	const handleSave = () => {
		if (ordersSelected) {
			ajaxByUser('Successfully added and downloaded return document.', () =>
				getEssityApiClient().then((api) =>
					api.pharmacyTaxNoteGroupReturnDocument
						.saveAndGenerateReturnDocument({
							body: {
								pharmacyTaxNoteGroupId: props.pharmacyTaxNoteGroupId!,
								returnDocuments: ordersSelected.map((item: OrderCancelledModel) => {
									return {
										orderId: item.id,
										orderNumber: item.orderNumber,
										errorMessage: item.cancellationReasonText,
										orderRefund: item.refund,
										pesel: item.pesel
									}
								})
							}
						})
						.then((result) => {
							DownloadFile({
								response: result,
							});
						})
						.finally(() => {
							handleCancel();
							setIsBusy(false)
						}
						)
				)
			);
		}
	}
	// useEffect(() => {
	// 	setIsBusy(true);
	// }, []);

	useEffect(() => {
		if (props.pharmacyTaxNoteGroupId) {
			refreshHasAnyReturnDocuments();
			refreshHasAnyCancelledOrder();

		}
	}, [props.pharmacyTaxNoteGroupId, visible]);
	useEffect(() => {
		orders && setOrdersSelected(orders.map((x: OrderDto) => {
			return {
				id: x.id!,
				orderNumber: x.orderNumber,
				pesel: x.pesel,
				refund: x.refund,
				cancellationReasonText: x.cancellationReasonText ? (x.cancellationReasonText) :
					(dictionaryStore.getValueById(
						DictionariesConstants.OrderCancellationReason,
						x.cancellationReasonId!
					))

			} as OrderCancelledModel
		}))
	}, [orders.length, visible]);

	const columns: EssityColumnProps<OrderCancelledModel>[] = [
		...AddReturnDocumentTableColumns,

		{
			key: 'cancellationReasonText',
			title: 'Error message',
			width: 450,
			render: (item: OrderCancelledModel) => {
				return <Input
					id={item.id}
					value={item.cancellationReasonText}
					onChange={(event) => {
						handleChangeReason(event.target.id, event.target.value)
					}
					}
				/>
			}
		},
	];

	return (
		<>
			{hasAnyReturnDocuments ? (
				<Badge
					count={
						<DollarCircleFilled
							style={{ fontSize: 24, color: '#F40785' }}
						/>
					}
				>
					<Button
						size="large"
						shape="round"
						type="primary"
						disabled={!hasAnyCancelledOrders}
						onClick={() => {
							setVisible(true);
							getReturnDocuments();
						}}
					>
						Create return doc
					</Button>
				</Badge>
			) : (
				<Button
					size="large"
					shape="round"
					disabled={!hasAnyCancelledOrders}
					type="primary"
					onClick={() => {
						setVisible(true);
						getReturnDocuments();
					}}
				>
					Create return doc
				</Button>
			)}


			{visible && (
				<IsolatedModal
					title="Creating return document"
					visible={visible}
					width="50vw"

					centered
					cancelButtonProps={{ hidden: true }}
					okButtonProps={{ hidden: true }}
					maskClosable={false}
					onCancel={handleCancel}
					closable={true}
					destroyOnClose>
					<>

						{isBusy ? <Skeleton></Skeleton> : (
							<>
								<b>Recently created documents:</b>
								{savedDocuments.map((item: PharmacyTaxNoteGroupReturnDocumentDto) => {
									return (
										<>
											<Row>
												<Col span={6}>
													{item.documentName}
												</Col>
												<Col span={1}>
													<Tooltip title="Download return document">
														<Button
															type="ghost"
															shape="circle"
															size="small"
															icon={<CloudDownloadOutlined />}
															onClick={() => {
																ajaxByUser('Successfully downloaded file', () =>
																	getEssityApiClient().then((api) =>
																		api.document
																			.getById(item.documentId!)
																			.then((result) => {
																				DownloadFile({
																					response: result,
																				});
																			})
																	)
																);
															}}
														/>
													</Tooltip>
												</Col>
												<Col span={1}>
													<Popconfirm
														title="Are you sure to delete return document?"
														okText="Yes"
														cancelText="No"
														placement="bottom"
														onConfirm={() => {
															ajaxByUser('Successfully deleted return document', () =>
																getEssityApiClient().then((api) =>
																	api.pharmacyTaxNoteGroupReturnDocument
																		.deleteReturnDocument({ returnDocumentId: item.id })
																		.then(() => getReturnDocuments())
																)
															);
														}}
													>
														<Tooltip title="Delete">
															<Button
																type="ghost"
																shape="circle"
																size="small"
																icon={<DeleteOutlined />}
															/>
														</Tooltip>
													</Popconfirm>


													<Tooltip title="Delete return document">

													</Tooltip>
												</Col>
											</Row>
											<p></p>

										</>
									);
								})
								}

							</>)
						}

						<OrdersToReturnDocumentModal
							year={props.year}
							month={props.month}
							pharmacyId={props.pharmacyId}
							onOrdersSelect={(orders: OrderDto[]) => setOrders(orders)}
							selectedOrders={orders}
						/>
						{ordersSelected && ordersSelected.length > 0 && (
							<>
								<Row className={styles.table}>
									<Col span={24}>
										<Space size={'large'}>
											<EssityTable
												className={styles.table}
												columns={columns}
												loading={isBusy}
												dataSource={ordersSelected}
												size="small"
												pagination={false}
											/>
										</Space>
									</Col>
								</Row>
								<Row>
									<Col offset={21}>
										<Button type="primary"
											size="large"
											shape="round"

											onClick={() => handleSave()}>Save</Button>
									</Col>
								</Row>
							</>
						)}
					</>
				</IsolatedModal>
			)}
		</>
	);
};
