import { Divider } from 'antd';
import { CenteredRow } from 'layout/CenteredRow';
import React from 'react';

import { GeneralPharmaciesBalanceReport } from './GeneralPharmaciesBalanceReport';
import { MonthlyPharmaciesBalanceReport } from './MonthlyPharmaciesBalanceReport';
import { OrdersReport } from './OrdersReport';
import { PharmaciesReport } from './PharmaciesReport';
import { PharmacyTaxNoteGroupsReport } from './PharmacyTaxNoteGroupsReport';
import { TemplateNotesReport } from './TemplateNotesReport';

export const Reports = () => {
	return (
		<>
			<Divider />
			<CenteredRow>
				<OrdersReport />
				<PharmaciesReport />
			</CenteredRow>
			<CenteredRow>
				<MonthlyPharmaciesBalanceReport />
				<GeneralPharmaciesBalanceReport />
			</CenteredRow>
			<CenteredRow>
				<PharmacyTaxNoteGroupsReport />
				<TemplateNotesReport />
			</CenteredRow>
		</>
	);
};
