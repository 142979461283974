import { EssityColumnProps } from "@components/shared/paginatedEssityTable/GridHelper";
import { VoivodeshipContactDto } from "@services/src/models";

export const ContactsTableColumns: EssityColumnProps<VoivodeshipContactDto>[] = [
	{
		dataIndex: 'phone',
		key: 'phone',
		title: 'Phone',
	},
	{
		dataIndex: 'email',
		key: 'email',
		title: 'E-mail',
	},
	{
		dataIndex: 'description',
		key: 'description',
		title: 'Description',
	},
];