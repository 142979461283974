import { Col, Spin } from 'antd';
import { CenteredRow } from 'layout/CenteredRow';
import React from 'react';

interface IProps {
	message?: string;
}

export const LoadingView = (props: IProps) => {
	const message = props.message || 'Import file in progress, please wait...';

	return (
		<CenteredRow>
			<Col span={24}>
				<CenteredRow>
					<Spin size="large" />
				</CenteredRow>
			</Col>
			<Col span={24}>
				<CenteredRow>
					<p>{message}</p>
				</CenteredRow>
			</Col>
		</CenteredRow>
	);
};
