import { CheckCircleTwoTone } from '@ant-design/icons';
import {
	EssityColumnProps,
	filterType,
} from '@components/shared/paginatedEssityTable/GridHelper';
import formatHelpers from '@helper/formatHelpers';
import { TemplateNoteDto } from '@services/src/models';
import React from 'react';

export const GetNHFSettlementsTableColumns = (): EssityColumnProps<TemplateNoteDto>[] => {
	return [
		{
			sorter: true,
			dataIndex: 'year',
			key: 'year',
			title: 'Year',
			width: 100,
			filter: 'equals' as filterType
		},
		{
			sorter: true,
			dataIndex: 'month',
			key: 'month',
			title: 'Month',
			width: 110,
			filter: 'equals' as filterType
		},
		{
			sorter: true,
			dataIndex: 'templateId',
			key: 'templateId',
			title: 'Template No.',
			width: 150,
			filter: 'equals' as filterType,
		},
		{
			sorter: true,
			dataIndex: 'noteType',
			key: 'noteType',
			title: 'Note Type',
			width: 140,
		},
		{
			sorter: true,
			dataIndex: 'noteName',
			key: 'noteName',
			title: 'Note Name',
			width: 145,
			filter: 'contains' as filterType,
		},
		{
			sorter: true,
			dataIndex: 'noteDate',
			key: 'noteDate',
			title: 'Note Date',
			render: (value?: Date) => formatHelpers.formatDate(value),
			width: 140,
			filter: 'dateRange' as filterType,
		},
		{
			sorter: true,
			dataIndex: 'totalValue',
			key: 'totalValue',
			title: 'Total Value',
			width: 140,
			filter: 'equals' as filterType,
		},
		{
			sorter: true,
			dataIndex: 'refund',
			key: 'refund',
			title: 'Refund',
			width: 130,
			filter: 'equals' as filterType,
		},
		{
			sorter: true,
			dataIndex: 'surcharge',
			key: 'surcharge',
			title: 'Surcharge',
			width: 140,
			filter: 'equals' as filterType,
		},
		{
			sorter: true,
			dataIndex: 'sent',
			key: 'sent',
			title: 'Sent',
			width: 90,
			render: (sent: string) => {
				return (
					sent && (
						<CheckCircleTwoTone
							twoToneColor="#52c41a"
							className="status-icon"
						/>
					)
				);
			},
		},
	];
};