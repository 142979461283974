/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from "@azure/ms-rest-js";
import * as Models from "../models";
import * as Mappers from "../models/pharmacyTaxNoteGroupManualOperationMappers";
import * as Parameters from "../models/parameters";
import { PolandPharmacyRxAPIContext } from "../polandPharmacyRxAPIContext";

/** Class representing a PharmacyTaxNoteGroupManualOperation. */
export class PharmacyTaxNoteGroupManualOperation {
  private readonly client: PolandPharmacyRxAPIContext;

  /**
   * Create a PharmacyTaxNoteGroupManualOperation.
   * @param {PolandPharmacyRxAPIContext} client Reference to the service client.
   */
  constructor(client: PolandPharmacyRxAPIContext) {
    this.client = client;
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<Models.PharmacyTaxNoteGroupManualOperationGetByIdResponse>
   */
  getById(id: string, options?: msRest.RequestOptionsBase): Promise<Models.PharmacyTaxNoteGroupManualOperationGetByIdResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  getById(id: string, callback: msRest.ServiceCallback<Models.PharmacyTaxNoteGroupManualOperationDto>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  getById(id: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.PharmacyTaxNoteGroupManualOperationDto>): void;
  getById(id: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.PharmacyTaxNoteGroupManualOperationDto>, callback?: msRest.ServiceCallback<Models.PharmacyTaxNoteGroupManualOperationDto>): Promise<Models.PharmacyTaxNoteGroupManualOperationGetByIdResponse> {
    return this.client.sendOperationRequest(
      {
        id,
        options
      },
      getByIdOperationSpec,
      callback) as Promise<Models.PharmacyTaxNoteGroupManualOperationGetByIdResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.PharmacyTaxNoteGroupManualOperationAllResponse>
   */
  all(options?: Models.PharmacyTaxNoteGroupManualOperationAllOptionalParams): Promise<Models.PharmacyTaxNoteGroupManualOperationAllResponse>;
  /**
   * @param callback The callback
   */
  all(callback: msRest.ServiceCallback<Models.PharmacyTaxNoteGroupManualOperationDto[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  all(options: Models.PharmacyTaxNoteGroupManualOperationAllOptionalParams, callback: msRest.ServiceCallback<Models.PharmacyTaxNoteGroupManualOperationDto[]>): void;
  all(options?: Models.PharmacyTaxNoteGroupManualOperationAllOptionalParams | msRest.ServiceCallback<Models.PharmacyTaxNoteGroupManualOperationDto[]>, callback?: msRest.ServiceCallback<Models.PharmacyTaxNoteGroupManualOperationDto[]>): Promise<Models.PharmacyTaxNoteGroupManualOperationAllResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      allOperationSpec,
      callback) as Promise<Models.PharmacyTaxNoteGroupManualOperationAllResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  create(options?: Models.PharmacyTaxNoteGroupManualOperationCreateOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  create(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  create(options: Models.PharmacyTaxNoteGroupManualOperationCreateOptionalParams, callback: msRest.ServiceCallback<void>): void;
  create(options?: Models.PharmacyTaxNoteGroupManualOperationCreateOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      createOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  update(options?: Models.PharmacyTaxNoteGroupManualOperationUpdateOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  update(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  update(options: Models.PharmacyTaxNoteGroupManualOperationUpdateOptionalParams, callback: msRest.ServiceCallback<void>): void;
  update(options?: Models.PharmacyTaxNoteGroupManualOperationUpdateOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      updateOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  deleteMethod(options?: Models.PharmacyTaxNoteGroupManualOperationDeleteMethodOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  deleteMethod(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteMethod(options: Models.PharmacyTaxNoteGroupManualOperationDeleteMethodOptionalParams, callback: msRest.ServiceCallback<void>): void;
  deleteMethod(options?: Models.PharmacyTaxNoteGroupManualOperationDeleteMethodOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      deleteMethodOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.PharmacyTaxNoteGroupManualOperationHasAnyManualOperationResponse>
   */
  hasAnyManualOperation(options?: Models.PharmacyTaxNoteGroupManualOperationHasAnyManualOperationOptionalParams): Promise<Models.PharmacyTaxNoteGroupManualOperationHasAnyManualOperationResponse>;
  /**
   * @param callback The callback
   */
  hasAnyManualOperation(callback: msRest.ServiceCallback<boolean>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  hasAnyManualOperation(options: Models.PharmacyTaxNoteGroupManualOperationHasAnyManualOperationOptionalParams, callback: msRest.ServiceCallback<boolean>): void;
  hasAnyManualOperation(options?: Models.PharmacyTaxNoteGroupManualOperationHasAnyManualOperationOptionalParams | msRest.ServiceCallback<boolean>, callback?: msRest.ServiceCallback<boolean>): Promise<Models.PharmacyTaxNoteGroupManualOperationHasAnyManualOperationResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      hasAnyManualOperationOperationSpec,
      callback) as Promise<Models.PharmacyTaxNoteGroupManualOperationHasAnyManualOperationResponse>;
  }
}

// Operation Specifications
const serializer = new msRest.Serializer(Mappers);
const getByIdOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "pharmacyTaxNoteGroup/PharmacyTaxNoteGroupManualOperation/{id}",
  urlParameters: [
    Parameters.id1
  ],
  responses: {
    200: {
      bodyMapper: Mappers.PharmacyTaxNoteGroupManualOperationDto
    },
    default: {}
  },
  serializer
};

const allOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "pharmacyTaxNoteGroup/PharmacyTaxNoteGroupManualOperation",
  queryParameters: [
    Parameters.pharmacyTaxNoteGroupId1
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "PharmacyTaxNoteGroupManualOperationDto"
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const createOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "pharmacyTaxNoteGroup/PharmacyTaxNoteGroupManualOperation",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.CreatePharmacyTaxNoteGroupManualOperationCommand
  },
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const updateOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "pharmacyTaxNoteGroup/PharmacyTaxNoteGroupManualOperation",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.UpdatePharmacyTaxNoteGroupManualOperationCommand
  },
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const deleteMethodOperationSpec: msRest.OperationSpec = {
  httpMethod: "DELETE",
  path: "pharmacyTaxNoteGroup/PharmacyTaxNoteGroupManualOperation",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.DeletePharmacyTaxNoteGroupManualOperationCommand
  },
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const hasAnyManualOperationOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "pharmacyTaxNoteGroup/PharmacyTaxNoteGroupManualOperation/hasAny",
  queryParameters: [
    Parameters.pharmacyTaxNoteGroupId1
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Boolean"
        }
      }
    },
    default: {}
  },
  serializer
};
