/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from "@azure/ms-rest-js";
import * as Models from "../models";
import * as Mappers from "../models/dictionaryMappers";
import * as Parameters from "../models/parameters";
import { PolandPharmacyRxAPIContext } from "../polandPharmacyRxAPIContext";

/** Class representing a Dictionary. */
export class Dictionary {
  private readonly client: PolandPharmacyRxAPIContext;

  /**
   * Create a Dictionary.
   * @param {PolandPharmacyRxAPIContext} client Reference to the service client.
   */
  constructor(client: PolandPharmacyRxAPIContext) {
    this.client = client;
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<Models.DictionaryGetByIdResponse>
   */
  getById(id: number, options?: msRest.RequestOptionsBase): Promise<Models.DictionaryGetByIdResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  getById(id: number, callback: msRest.ServiceCallback<Models.DictionaryDto>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  getById(id: number, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.DictionaryDto>): void;
  getById(id: number, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.DictionaryDto>, callback?: msRest.ServiceCallback<Models.DictionaryDto>): Promise<Models.DictionaryGetByIdResponse> {
    return this.client.sendOperationRequest(
      {
        id,
        options
      },
      getByIdOperationSpec,
      callback) as Promise<Models.DictionaryGetByIdResponse>;
  }

  /**
   * @param codeName
   * @param [options] The optional parameters
   * @returns Promise<Models.DictionaryGetByCodeNameResponse>
   */
  getByCodeName(codeName: string, options?: msRest.RequestOptionsBase): Promise<Models.DictionaryGetByCodeNameResponse>;
  /**
   * @param codeName
   * @param callback The callback
   */
  getByCodeName(codeName: string, callback: msRest.ServiceCallback<Models.DictionaryDto>): void;
  /**
   * @param codeName
   * @param options The optional parameters
   * @param callback The callback
   */
  getByCodeName(codeName: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.DictionaryDto>): void;
  getByCodeName(codeName: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.DictionaryDto>, callback?: msRest.ServiceCallback<Models.DictionaryDto>): Promise<Models.DictionaryGetByCodeNameResponse> {
    return this.client.sendOperationRequest(
      {
        codeName,
        options
      },
      getByCodeNameOperationSpec,
      callback) as Promise<Models.DictionaryGetByCodeNameResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.DictionaryGetByCodeNamesResponse>
   */
  getByCodeNames(options?: Models.DictionaryGetByCodeNamesOptionalParams): Promise<Models.DictionaryGetByCodeNamesResponse>;
  /**
   * @param callback The callback
   */
  getByCodeNames(callback: msRest.ServiceCallback<Models.DictionaryDto[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getByCodeNames(options: Models.DictionaryGetByCodeNamesOptionalParams, callback: msRest.ServiceCallback<Models.DictionaryDto[]>): void;
  getByCodeNames(options?: Models.DictionaryGetByCodeNamesOptionalParams | msRest.ServiceCallback<Models.DictionaryDto[]>, callback?: msRest.ServiceCallback<Models.DictionaryDto[]>): Promise<Models.DictionaryGetByCodeNamesResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      getByCodeNamesOperationSpec,
      callback) as Promise<Models.DictionaryGetByCodeNamesResponse>;
  }
}

// Operation Specifications
const serializer = new msRest.Serializer(Mappers);
const getByIdOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "dictionaries/Dictionary/{id}",
  urlParameters: [
    Parameters.id0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.DictionaryDto
    },
    default: {}
  },
  serializer
};

const getByCodeNameOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "dictionaries/Dictionary/codeName/{codeName}",
  urlParameters: [
    Parameters.codeName
  ],
  responses: {
    200: {
      bodyMapper: Mappers.DictionaryDto
    },
    default: {}
  },
  serializer
};

const getByCodeNamesOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "dictionaries/Dictionary",
  queryParameters: [
    Parameters.codeNames
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "DictionaryDto"
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};
