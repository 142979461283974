import { SettingOutlined } from '@ant-design/icons';
import PermissionValidator from '@authorization/PermissionValidator';
import { AccountRoles } from '@authorization/Roles';
import { PortalInvitationModal } from '@components/accountsManagement/PortalInvitationModal';
import { useRootData } from '@hooks/hook';
import { Button, Dropdown, Menu } from 'antd';
import EssityTooltip from 'layout/EssityTooltip';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { IAccountDetailsStore } from 'stores/AccountDetailsStore';

const SettingsMenu = () => {
	const history = useHistory();

	const [inviteModalVisible, setInviteModalVisible] = useState<boolean>(
		false
	);

	const accountDetailsStore: IAccountDetailsStore = useRootData(
		(store) => store.accountDetailsStore
	);

	const menu = (
		<Menu>
			<Menu.Item
				key="1"
				onClick={() => {
					history.push('/importedDocuments');
				}}
			>
				Imported documents
			</Menu.Item>
			<Menu.Item
				key="2"
				onClick={() => {
					history.push('/createdDocuments');
				}}
			>
				Created documents
			</Menu.Item>
			<Menu.Item
				key="3"
				onClick={() => {
					history.push('/departments');
				}}
			>
				NHF Departments
			</Menu.Item>
			<Menu.Item
				key="4"
				onClick={() => {
					history.push('/priceLists');
				}}
			>
				Price lists
			</Menu.Item>
			{accountDetailsStore.account.get() &&
				new PermissionValidator(
					accountDetailsStore.account.get()!
				).hasRole(AccountRoles.BUSINESS_ADMIN) && (
					<>
						<Menu.Item
							key="5"
							onClick={() => {
								history.push('/accountsManagement');
							}}
						>
							Accounts Management
						</Menu.Item>
						<Menu.Item
							key="6"
							onClick={() => {
								setInviteModalVisible(true);
							}}
						>
							Portal invitation
						</Menu.Item>
						<PortalInvitationModal
							visible={inviteModalVisible}
							onClose={() => {
								setInviteModalVisible(false);
							}}
						/>
					</>
				)}
		</Menu>
	);
	return (
		<EssityTooltip placement="left" title="Settings">
			<Dropdown overlay={menu} trigger={['click']}>
				<Button
					type="primary"
					shape="circle"
					size="large"
					icon={<SettingOutlined />}
				></Button>
			</Dropdown>
		</EssityTooltip>
	);
};

export default observer(SettingsMenu);
