import * as Yup from 'yup';

export const ManualOperationValidationSchema = () =>
	Yup.object().shape({
		manualOperationType: Yup.string().required(
			'Operation type is required.'
		),
		value: Yup.number().required('Operation value is required.'),
		date: Yup.date().required('Operation date is required'),
	});
