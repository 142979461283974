import { EditOutlined } from '@ant-design/icons';
import { UserAddOutlined } from '@ant-design/icons';
import PermissionValidator from '@authorization/PermissionValidator';
import { AccountRoles } from '@authorization/Roles';
import { EssityColumnProps } from '@components/shared/paginatedEssityTable/GridHelper';
import PaginatedEssityTable from '@components/shared/paginatedEssityTable/PaginatedEssityTable';
import { SieveModel } from '@components/shared/paginatedEssityTable/SieveModels';
import { TableWithTitle } from '@components/shared/TableWithTitle';
import { ajaxByUser } from '@helper/api';
import { useRootData } from '@hooks/hook';
import { getEssityApiClient } from '@services/EssityApi';
import {
	PharmacyAllOptionalParams,
	PharmacyDto,
	PortalStatus,
} from '@services/src/models';
import { Button, Popconfirm, Tooltip } from 'antd';
import React from 'react';
import { useHistory } from 'react-router';
import { IAccountDetailsStore } from 'stores/AccountDetailsStore';
import { IGridStore } from 'stores/GridStore';

import { GetPharmaciesTableColumns } from './PharmaciesTableColumns';
import styles from './styles/Pharmacies.module.less';

export const PharmaciesTable = () => {
	const history = useHistory();

	const accountDetailsStore: IAccountDetailsStore = useRootData(
		(store) => store.accountDetailsStore
	);

	const gridStore: IGridStore = useRootData((store) => store.gridStore);

	const columns: EssityColumnProps<PharmacyDto>[] = [
		...GetPharmaciesTableColumns({ allowFilterOnVoivodeship: true }),
		{
			title: '',
			key: 'operation',
			fixed: 'right',
			width: 50,
			render: (item: PharmacyDto) => (
				<Tooltip title="Edit">
					<Button
						type="primary"
						shape="circle"
						size="small"
						onClick={() => {
							history.push(`pharmacies/edit/${item.id}`);
						}}
						icon={<EditOutlined />}
					/>
				</Tooltip>
			),
		},
		{
			title: '',
			key: 'operation',
			fixed: 'right',
			width: 50,
			render: (item: PharmacyDto) =>
				accountDetailsStore.account.get() &&
				new PermissionValidator(
					accountDetailsStore.account.get()!
				).hasRole(AccountRoles.BUSINESS_ADMIN) && (
					<Tooltip title="Send invitation">
						<Popconfirm
							title="Are you sure to send invitation link ?"
							okText="Yes"
							disabled={item.portalStatus === PortalStatus.Access}
							cancelText="No"
							placement="bottom"
							onConfirm={() => {
								ajaxByUser(
									'Successfully sent invitation.',
									() =>
										getEssityApiClient().then((api) =>
											api.account
												.invitePharmacy({
													body: {
														pharmacyId: item.id,
													},
												})
												.then(() => {
													gridStore.searching.set(
														true
													);
												})
										),
									() => {}
								);
							}}
						>
							<Button
								type="primary"
								shape="circle"
								size="small"
								disabled={
									item.portalStatus === PortalStatus.Access
								}
								icon={<UserAddOutlined />}
							/>
						</Popconfirm>
					</Tooltip>
				),
		},
	];

	return (
		<TableWithTitle title="Pharmacies">
			<PaginatedEssityTable<PharmacyDto>
				className={styles.table}
				columns={columns}
				gridName="pharmacies"
				hasRowSelection={true}
				hidePersonalizationSettings={true}
				getRowKey={(r: PharmacyDto) => r.id!}
				detailsUrl="pharmacies/details"
				getPagedResult={async (sieve: SieveModel, abortSignal) => {
					const parameters: PharmacyAllOptionalParams = {
						page: sieve.page,
						pageSize: sieve.pageSize,
						sorts: sieve.sorts,
						abortSignal: abortSignal,
						filters: sieve.filters,
					};

					const api = await getEssityApiClient();
					return await api.pharmacy.all(parameters);
				}}
			/>
		</TableWithTitle>
	);
};
