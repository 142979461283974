import { BackButton } from '@components/shared/buttons/BackButton';
import { ajaxByUser } from '@helper/api';
import { DateFormat } from '@helper/formatHelpers';
import { getEssityApiClient } from '@services/EssityApi';
import {
	CreateNoteResponseDto,
	InternalTaxNoteType,
	PharmacyNotePredictedToCorrectionDto,
	PharmacyTaxNoteCreateOverpayResponse,
	PharmacyTaxNoteDetailsDto,
	PharmacyTaxNoteGroupDto,
	PharmacyTaxNoteUpdateResponse,
} from '@services/src/models';
import { Button, Col, Modal, Row, Space } from 'antd';
import { Field, Formik, FormikActions, FormikProps } from 'formik';
import {
	FDatePicker,
	FFieldLabel,
	FInput,
	FInputNumber,
	FTextArea,
} from 'forms/FormikFormItems';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import styles from '../styles/PharmacyNote.module.less';
import { OverpayNoteValidationSchema } from '../validation/OverpayValidationSchema';

interface IProps {
	editMode: boolean;
	readOnly: boolean;
	groupId: string;
	internalTaxNoteType: InternalTaxNoteType;
	overpayNoteId?: string;
	returnNoteId: string;
}

interface IState {
	note: PharmacyNotePredictedToCorrectionDto;
	group?: PharmacyTaxNoteGroupDto;
}

export const OverpayNoteDetails = (props: IProps) => {
	const [state, setState] = useState<IState>();
	const [note, setNote] = useState<PharmacyTaxNoteDetailsDto>();
	const [limitedEditMode, setLimitedEditMode] = useState<boolean>(false);
	const [isBusy, setIsBusy] = useState<boolean>();
	const history = useHistory();

	useEffect(() => {
		if (props.overpayNoteId) {
			(async () => {
				const api = await getEssityApiClient();
				const result = await api.pharmacyTaxNote.getById(
					props.overpayNoteId!
				);

				setNote(result);
				setLimitedEditMode(result.limitedEditMode!);
			})();
		}
	}, [props.overpayNoteId]);

	useEffect(() => {
		(async () => {
			if (props.groupId && props.internalTaxNoteType) {
				const api = await getEssityApiClient();
				const result = await api.pharmacyTaxNote.getPredictedReturnOverpay(
					{
						pharmacyTaxNoteId: props.returnNoteId,
					}
				);

				const group: PharmacyTaxNoteGroupDto = await api.pharmacyTaxNoteGroup.getById(
					props.groupId
				);

				setState({
					...state,
					note: result,
					group: group,
				});
			}
		})();
	}, [props.groupId, props.internalTaxNoteType]);

	const getInitialValues = () => {
		if (!props.editMode && !props.readOnly) {
			const month = moment(
				new Date(moment().year(), state?.note.month!, moment().day())
			).add(-1, 'month');

			const year = moment(
				new Date(state?.note.year!, moment().month(), moment().day())
			);

			return {
				pharmacyName: state?.note?.pharmacyName,
				placeId: state?.note?.placeId,
				month: month,
				year: year,
				noteValue: state?.note.predictedCorrectionNoteValue,
				mainPharmacyTaxNoteInternalNumber:
					state?.note.predictedCorrectionInternalNumber,
				note: state?.note.note,
			};
		}

		const month = moment(
			new Date(moment().year(), note?.month!, moment().day())
		).add(-1, 'month');

		const year = moment(
			new Date(note?.year!, moment().month(), moment().day())
		);

		return {
			pharmacyName: note?.pharmacy?.name,
			pharmacyId: note?.pharmacy?.id,
			placeId: note?.pharmacy?.placeId,
			noteDate: moment(note?.noteDate),
			month: month,
			year: year,
			internalNumber: note?.internalNumber,
			noteValue: note?.noteValue,
			mainPharmacyTaxNoteInternalNumber:
				note?.mainPharmacyTaxNoteInternalNumber,
			note: note?.note,
		};
	};

	const RenderNoteDetails = () => {
		return (
			<>
				<Row>
					<Col span={10}>
						<FFieldLabel label="Pharmacy name" />
						<Field
							component={FInput}
							placeholder="Pharmacy name"
							name="pharmacyName"
							readOnly={true}
						/>
					</Col>
				</Row>
				<Row>
					<Col span={10}>
						<FFieldLabel label="SAP ID" />
						<Field
							component={FInput}
							placeholder="SAP ID"
							name="placeId"
							readOnly={true}
						/>
					</Col>
				</Row>
				<Row>
					<Col span={10}>
						<FFieldLabel label="Note date" />
						<Field
							label="Note date"
							component={FDatePicker}
							name="noteDate"
							mode="date"
							style={{ width: '100%' }}
							disabled={props.readOnly || limitedEditMode}
							changeData={(value: any) => {}}
						/>
					</Col>
				</Row>
				{(props.readOnly || props.editMode) && (
					<Row>
						<Col span={10}>
							<FFieldLabel label="Internal number" />
							<Field
								component={FInput}
								changeData={(value: any) => {}}
								placeholder="Internal number"
								name="internalNumber"
								readOnly={true}
							/>
						</Col>
					</Row>
				)}

				<Row>
					<Col span={5} style={{ paddingRight: 5 }}>
						<FFieldLabel label="Month" />
						<Field
							component={FDatePicker}
							placeholder="Month"
							name="month"
							inputReadOnly={true}
							picker="month"
							format={DateFormat.Month}
							style={{ width: '100%' }}
						/>
					</Col>
					<Col span={5} style={{ paddingLeft: 5 }}>
						<FFieldLabel label="Year" />
						<Field
							component={FDatePicker}
							placeholder="Year"
							name="year"
							inputReadOnly={true}
							picker="year"
							format={DateFormat.Year}
							style={{ width: '100%' }}
						/>
					</Col>
				</Row>
				<Row>
					<Col span={10}>
						<FFieldLabel label="Overpay note value" />
						<Field
							component={FInputNumber}
							min={Number.MIN_SAFE_INTEGER}
							placeholder="Overpay note value"
							name="noteValue"
							readOnly={true}
						/>
					</Col>
				</Row>
				<Row>
					<Col span={16}>
						<FFieldLabel label="Note" />
						<Field
							component={FTextArea}
							placeholder="Note"
							style={{ resize: 'none' }}
							maxLength={5000}
							name="note"
							rows={5}
							maxRows={5}
							readOnly={props.readOnly && !props.editMode}
						/>
					</Col>
				</Row>
			</>
		);
	};

	const createOrUpdateNote = (values: any) => {
		setIsBusy(true);
		if (props.editMode) {
			ajaxByUser('Successfully updated overpay.', () =>
				getEssityApiClient().then((api) =>
					api.pharmacyTaxNote
						.updateOverpay({
							body: {
								pharmacyTaxNoteId: props.overpayNoteId,
								noteDate: moment(values.noteDate)
									.utc(true)
									.toDate(),
								note: values.note,
							},
						})
						.then((response: PharmacyTaxNoteUpdateResponse) => {
							history.push(
								`/pharmacyPayments/${response.pharmacyTaxNoteGroupId}/${response.pharmacyId}`
							);
						})
						.finally(() => setIsBusy(false))
				)
			);
		} else {
			ajaxByUser('Successfully added overpay.', () =>
				getEssityApiClient().then((api) =>
					api.pharmacyTaxNote
						.createOverpay({
							body: {
								pharmacyTaxNoteId: props.returnNoteId,
								noteDate:
									values.noteDate?.utc(true).toDate() ||
									undefined,
								note: values.note,
							},
						})
						.then(
							(result: PharmacyTaxNoteCreateOverpayResponse) => {
								if (result) {
									const dto = result as CreateNoteResponseDto;
									const path = `/pharmacyPayments/${dto.pharmacyTaxNoteGroupId}/${dto.pharmacyId}`;
									Modal.success({
										centered: true,
										maskClosable: true,
										title: `Created overpay with number: ${dto.pharmacyTaxNoteIdInternalNumber}`,
										onOk: () => {
											history.push(path);
										},
										onCancel: () => {
											history.push(path);
										},
									});
								}
							}
						)
						.finally(() => setIsBusy(false))
				)
			);
		}
	};

	return (
		<Space direction="vertical" className={styles.details}>
			<h2>Overpay Details</h2>
			<Space direction="vertical" className={styles.form}>
				<Formik
					validateOnChange={false}
					validateOnBlur={true}
					initialValues={getInitialValues()}
					enableReinitialize
					validationSchema={OverpayNoteValidationSchema}
					onSubmit={(values, actions: FormikActions<any>) =>
						createOrUpdateNote(values)
					}
					render={(formikProps: FormikProps<any>) => (
						<Space direction="vertical" style={{ width: '100%' }}>
							{state?.note && RenderNoteDetails()}
							<Space
								direction="horizontal"
								className={styles.actions}
							>
								<BackButton />
								<Button
									type="primary"
									shape="round"
									size="large"
									className={styles.save}
									disabled={props.readOnly || isBusy}
									onClick={() => formikProps.submitForm()}
								>
									Save
								</Button>
							</Space>
						</Space>
					)}
				></Formik>
			</Space>
		</Space>
	);
};
