import { Button } from 'antd';
import React from 'react';

export const ImportReturnFileModal = () => {
	return (
		<Button type="primary" size="large" shape="round" disabled={true}>
			Import Return file
		</Button>
	);
};
