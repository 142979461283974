import { Divider, Space } from 'antd';
import React from 'react';

import QuickFilters from '../quickFilters/QuickFilters';
import ExportCorrectionFileModal from './ExportCorrectionFileModal';
import ExportEZWMFileModal from './ExportEZWMFileModal';
import { ImportEZWMAnswerFileModal } from './ImportEZWMAnswerFileModal';
import { ImportEZWMFileModal } from './ImportEZWMFileModal';
import styles from './styles/OrderGroups.module.less';
import { ImportZPOZWFileModal } from './ZPOZW/ImportZPOZWFileModal';

export const OrderGroupsMenu = () => {
	return (
		<>
			<Divider />
			<Space direction="horizontal" className={styles.menu}>
				<ImportEZWMFileModal />
				<ImportZPOZWFileModal />
				<ExportEZWMFileModal />
				<ImportEZWMAnswerFileModal />
				<ExportCorrectionFileModal />
			</Space>
			<QuickFilters hide={false} />
			<Divider />
		</>
	);
};
