import { GlobalUserManager } from '@authentication/userManager';
import { ServiceClientCredentials, TokenCredentials } from '@azure/ms-rest-js';
import { User } from 'oidc-client';

import { PolandPharmacyRxAPI } from './src/polandPharmacyRxAPI';

declare global {
	interface Window {
		_env_: any;
	}
}

export class NoCredentials implements ServiceClientCredentials {
	signRequest(
		webResource: import('@azure/ms-rest-js').WebResource
	): Promise<import('@azure/ms-rest-js').WebResource> {
		return Promise.resolve(webResource);
	}
}

export function getEssityApiClient(): Promise<PolandPharmacyRxAPI> {
	let um = GlobalUserManager.UserManager();
	if (um) {
		let promise = um.getUser().then((user: User | null) => {
			if (user) {
				const token = new TokenCredentials(user.access_token);
				return new PolandPharmacyRxAPI(token);
			}
			return new PolandPharmacyRxAPI(new NoCredentials());
		});
		return promise;
	}

	return Promise.resolve(new PolandPharmacyRxAPI(new NoCredentials()));
}

export function getUser(): Promise<User | null> {
	let um = GlobalUserManager.UserManager();
	if (um) {
		return um.getUser();
	}

	return Promise.resolve(null);
}
