import { getEssityApiClient } from '@services/EssityApi';
import { AccountDto } from '@services/src/models';
import { observable } from 'mobx';
import { User } from 'oidc-client';

interface IAccountSettings {
	filtersLocked: boolean;
	voivodeshipFilterDictionaryIds?: string,
	settlementPeriodFilterDictionaryValueCodeName?: string;
}

export const accountDetailsStore = {
	account: observable.box<AccountDto | undefined>(undefined),
	oidcUser: observable.box<User | undefined>(undefined),
	preloader: observable.box<string | undefined>(undefined),
	settings: observable.box<IAccountSettings>({filtersLocked: true}),
	initialize: async (callback: () => void = () => {}) => {
		if (accountDetailsStore.oidcUser.get()) {
			const api = await getEssityApiClient();
			const account: AccountDto = await api.account.me();

			if (account) {
				accountDetailsStore.account.set(account);
			}
		}
	},
	setOidcUser: (oidcUser: User) => {
		accountDetailsStore.oidcUser.set(oidcUser);
	},
};

export const createAccountDetailsStore = () => {
	return accountDetailsStore;
};

export type IAccountDetailsStore = ReturnType<typeof createAccountDetailsStore>;
