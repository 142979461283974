import { filterType } from '@components/shared/paginatedEssityTable/GridHelper';
import PaginatedEssityTable from '@components/shared/paginatedEssityTable/PaginatedEssityTable';
import { SieveModel } from '@components/shared/paginatedEssityTable/SieveModels';
import { useRootData } from '@hooks/hook';
import { DictionariesConstants } from '@services/DictionariesConstants';
import { getEssityApiClient } from '@services/EssityApi';
import {
	OrderGroupAllOptionalParams,
	OrderGroupDto,
} from '@services/src/models';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { IAccountDetailsStore } from 'stores/AccountDetailsStore';
import { IDictionaryStore } from 'stores/DictionaryStore';
import { ITableColumnStore } from 'stores/TableColumnStore';

import styles from './styles/OrderGroups.module.less';

const OrderGroupsTable = () => {
	const tableColumnStore: ITableColumnStore = useRootData(
		(store) => store.tableColumnStore
	);

	const dictionaryStore: IDictionaryStore = useRootData(
		(store) => store.dictionaryStore
	);

	const accountDetailsStore: IAccountDetailsStore = useRootData(
		(store) => store.accountDetailsStore
	);

	const gridName = 'orderGroups';
	const columns = [
		{
			sorter: true,
			dataIndex: 'voivodeshipId',
			key: 'voivodeshipId',
			title: 'Voivodeship',
			filter: 'equals' as filterType,
			hideFilterIcon: true,
			render: (value: number) => {
				return dictionaryStore.getValueById(
					DictionariesConstants.Voivodeships,
					value
				);
			},
		},
		...tableColumnStore.getTable(gridName),
	];

	return (
		<PaginatedEssityTable<OrderGroupDto>
			columns={columns}
			gridName={gridName}
			hasRowSelection={true}
			hidePersonalizationSettings={true}
			getRowKey={(r: OrderGroupDto) => r.id!}
			detailsUrl="orderGroups"
			className={styles.table}
			getPagedResult={async (sieve: SieveModel, abortSignal) => {
				const parameters: OrderGroupAllOptionalParams = {
					page: sieve.page,
					pageSize: sieve.pageSize,
					sorts: sieve.sorts,
					abortSignal: abortSignal,
					filters: sieve.filters,
					settlementPeriodFilterCodeName: accountDetailsStore.settings.get()
						.settlementPeriodFilterDictionaryValueCodeName,
				};

				const api = await getEssityApiClient();
				return await api.orderGroup.all(parameters);
			}}
		/>
	);
};

export default observer(OrderGroupsTable);
