import { DocumentSource } from '@services/src/models';

export const mapings = new Map<DocumentSource, string>([
	[DocumentSource.ORDERSIMPORT, 'Orders import'],
	[DocumentSource.PHARMACIESIMPORT, 'Pharmacies import'],
	[DocumentSource.PHARMACIESIMPORTTEMPLATE, 'Pharmacies template import'],
	[DocumentSource.PRICELISTIMPORT, 'Price list import'],
	[DocumentSource.ZPOSPEXPORT, 'NHF report export'],
	[DocumentSource.UMXPHARMACIESIMPORT, 'UMX import'],
	[DocumentSource.FARMAPROMPHARMACIESIMPORT, 'Farmaprom import'],
	[DocumentSource.NHFSTATEIMPORT, 'NHF state import'],
	[DocumentSource.NOTEEXPORT, 'Template note export'],
	[DocumentSource.PAYMENTEXPORT, 'Pharmacy payment note export'],
	[DocumentSource.NOTETEMPLATEIMPORT, 'Template note import'],
	[DocumentSource.PAYMENTCONFIRMATIONSIMPORT, 'Payment confirmations import'],
	[DocumentSource.ZPOZRIMPORT, 'ZPOZR import']
]);

export const map = (value: DocumentSource): string => mapings.get(value) ?? '';
