import { EssityColumnProps, filterType } from "@components/shared/paginatedEssityTable/GridHelper";
import { UmxPharmacyDto } from "@services/src/models";

export const UMXPharmaciesTableColumns: EssityColumnProps<UmxPharmacyDto>[] = [
	{
		sorter: true,
		dataIndex: 'technicalCode',
		key: 'technicalCode',
		title: 'Login',
		filter: 'equals' as filterType,
		width: 120
	},
	{
		sorter: true,
		dataIndex: 'kodKom',
		key: 'kodKom',
		title: 'Kod Kom',
		filter: 'equals' as filterType,
		width: 120
	},
	{
		sorter: true,
		dataIndex: 'name',
		key: 'name',
		title: 'Name',
		filter: 'contains' as filterType,
		width: 190
	},
	{
		sorter: true,
		dataIndex: 'city',
		key: 'city',
		title: 'City',
		filter: 'contains' as filterType,
		width: 140
	},
	{
		sorter: true,
		dataIndex: 'street',
		key: 'street',
		title: 'Street',
		filter: 'contains' as filterType,
		width: 140
	},
	{
		sorter: true,
		dataIndex: 'postalCode',
		key: 'postalCode',
		title: 'Postal code',
		filter: 'contains' as filterType,
		width: 100
	}
];
