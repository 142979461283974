import { CloudDownloadOutlined } from '@ant-design/icons';
import { DownloadFile } from '@components/shared/file/FileDownloadFunctions';
import { LoadingView } from '@components/shared/import/LoadingView';
import { IsolatedModal } from '@components/shared/modals/IsolatedModal';
import { ajaxByUser } from '@helper/api';
import { getEssityApiClient } from '@services/EssityApi';
import { FileType } from '@services/src/models';
import * as antd from 'antd';
import { Button } from 'antd';
import { ReportDateType } from 'enums/ReportDateType';
import { CenteredRow } from 'layout/CenteredRow';
import React, { useState } from 'react';

import styles from './styles/Reports.module.less';

interface IState {
	fileType?: FileType;
	reportDateType?: ReportDateType;
}

export const PharmaciesReport = () => {
	const [visible, setVisible] = useState<boolean>(false);
	const [state, setState] = useState<IState>();
	const [isBusy, setIsBusy] = useState<boolean>();

	const menu = (
		<antd.Menu>
			<antd.Menu.Item
				key="1"
				onClick={() => {
					setState({ ...state, fileType: FileType.CSV });
					setVisible(true);
				}}
			>
				Export data as CSV
			</antd.Menu.Item>
			<antd.Menu.Item
				key="2"
				onClick={() => {
					setState({ ...state, fileType: FileType.XLSX });
					setVisible(true);
				}}
			>
				Export data as XLSX
			</antd.Menu.Item>
		</antd.Menu>
	);

	const handleCancel = () => {
		setVisible(false);
		setState(undefined);
	};

	const handleSubmit = async () => {
		setIsBusy(true);
		ajaxByUser('Successfully downloaded file', () =>
			getEssityApiClient().then((api) =>
				api.report
					.getPharmacies({
						fileType: state?.fileType,
					})
					.then((result) => {
						DownloadFile({
							response: result,
						});
					})
					.finally(() => {
						setVisible(false);
						setState(undefined);
						setIsBusy(false);
					})
			)
		);
	};

	return (
		<>
			<div className={styles.report}>
				<antd.Space direction="vertical">
					<h3>Pharmacies raw data report</h3>

					<p>Export global pharmacies data to xlsx or csv file</p>
				</antd.Space>

				<antd.Dropdown overlay={menu} placement="bottomLeft" arrow>
					<antd.Button type="primary" shape="round" size="middle">
						Run
					</antd.Button>
				</antd.Dropdown>
			</div>
			<IsolatedModal
				title="Exporting pharmacies"
				visible={visible}
				onCancel={handleCancel}
			>
				<CenteredRow className={styles.exportButton}>
					{isBusy ? (
						<LoadingView message="Creating report, please wait..." />
					) : (
						<Button
							type="primary"
							shape="round"
							size="large"
							onClick={handleSubmit}
						>
							Generate report
							<CloudDownloadOutlined />
						</Button>
					)}
				</CenteredRow>
			</IsolatedModal>
		</>
	);
};
