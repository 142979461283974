import { PharmacyEditDto } from '@services/commandMappers/Pharmacy';
import { DictionariesConstants } from '@services/DictionariesConstants';
import {
	CreatePharmacyCommand,
	UpdatePharmacyCommand,
} from '@services/src/models';
import { Col, Row } from 'antd';
import { Field, FormikProps } from 'formik';
import { DictionarySelect } from 'forms/DictionarySelect';
import { FFieldLabel, FInput, FInputNumber } from 'forms/FormikFormItems';
import React from 'react';

import styles from '../styles/PharmacyEdit.module.less';

interface IProps {
	readOnly: boolean;
	updateView: boolean;
	formikProps: FormikProps<
		CreatePharmacyCommand | UpdatePharmacyCommand | PharmacyEditDto
	>;
}

export const Contact = (props: IProps) => {
	return (
		<Col span={12}>
			<h2 className="header-text">Contact</h2>

			<div className={styles.description}>
				<Row>
					<Col span={12}>
						<div className="fieldbox">
							<FFieldLabel label="City" />
							<Field
								component={FInput}
								placeholder="City"
								name="city"
								readOnly={true}
							/>
						</div>
					</Col>
					<Col span={11} offset={1}>
						<div className="fieldbox">
							<FFieldLabel label="Mail" />
							<Field
								component={FInput}
								placeholder="Mail"
								name="mail"
								readOnly={props.readOnly}
							/>
						</div>
					</Col>
				</Row>

				<Row>
					<Col span={12}>
						<div className="fieldbox">
							<FFieldLabel label="Street" />
							<Field
								component={FInput}
								placeholder="Street"
								name="street"
								readOnly={true}
							/>
						</div>
					</Col>
					<Col span={11} offset={1}>
						<div className="fieldbox">
							<FFieldLabel label="Phone number" />
							<Field
								component={FInput}
								placeholder="Phone number"
								name="phoneNumber"
								readOnly={props.readOnly}
							/>
						</div>
					</Col>
				</Row>

				<Row>
					<Col span={12}>
						<div className="fieldbox">
							<FFieldLabel label="Voivodeship" />
							<DictionarySelect
								readOnly={true}
								formikProps={props.formikProps}
								formikName="voivodeshipId"
								dictionaryCodeName={
									DictionariesConstants.Voivodeships
								}
							/>
						</div>
					</Col>
					<Col span={11} offset={1}>
						<div className="fieldbox">
							<FFieldLabel label="Sales Representative" />
							<Field
								component={FInput}
								placeholder="Sales Representative"
								name="salesRepresentative"
								readOnly={true}
							/>
						</div>
					</Col>
				</Row>

				<Row>
					<Col span={12}>
						<div className="fieldbox">
							<FFieldLabel label="Postal code" />
							<Field
								component={FInput}
								placeholder="Postal code"
								name="postalCode"
								readOnly={true}
							/>
						</div>
					</Col>
					<Col span={11} offset={1}>
						<div className="fieldbox">
							<FFieldLabel label="District" />
							<Field
								component={FInputNumber}
								placeholder="District"
								name="district"
								precision={0}
								readOnly={true}
							/>
						</div>
					</Col>
				</Row>

				<Row>
					<Col span={12}>
						<div className="fieldbox">
							<FFieldLabel label="KAM" />
							<Field
								component={FInput}
								placeholder="KAM"
								name="kam"
								readOnly={true}
							/>
						</div>
					</Col>
				</Row>
			</div>
		</Col>
	);
};
