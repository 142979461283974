import { useRootData } from '@hooks/hook';
import { DictionaryValueDto } from '@services/src/models';
import { Select } from 'antd';
import { FormikProps } from 'formik';
import React, { CSSProperties } from 'react';
import { IDictionaryStore } from 'stores/DictionaryStore';

interface IProps {
	dictionaryCodeName: string;
	formikName: string;
	formikProps: FormikProps<any>;
	readOnly: boolean;
	style?: CSSProperties | undefined;
	excludeKeys?: string[];
	disabledCodes?: string[];
}

export const DictionarySelect = (props: IProps) => {
	const dictionaryStore: IDictionaryStore = useRootData(
		(store) => store.dictionaryStore
	);

	const getValues = () => {
		let dictionaryValues = dictionaryStore?.getDictionary(
			props.dictionaryCodeName
		)?.values;

		if (props.excludeKeys && dictionaryValues) {
			dictionaryValues = dictionaryValues.filter(
				(x) => !props.excludeKeys!.includes(x.codeName!)
			);
		}

		return dictionaryValues;
	};

	return (
		<Select
			style={props.style}
			disabled={props.readOnly}
			placeholder="Click here to select"
			defaultValue={props.formikProps.values[props.formikName] as number}
			value={props.formikProps.values[props.formikName]}
			onChange={(item: number) => {
				props.formikProps.setFieldValue(props.formikName, item);
			}}
		>
			{getValues()?.map((item: DictionaryValueDto) => {
				return (
					<Select.Option
						key={item.id}
						value={item.id!}
						disabled={props.disabledCodes?.includes(item.codeName!)}
					>
						{item.value}
					</Select.Option>
				);
			})}
		</Select>
	);
};
