import { TaxNoteOrderState } from '@services/src/models';

export const valueMap = new Map<TaxNoteOrderState, string>([
	[TaxNoteOrderState.Paid, 'Paid'],
    [TaxNoteOrderState.Assigned, 'Assigned'],
    [TaxNoteOrderState.NotAssigned, 'Not assigned'],
    [TaxNoteOrderState.ReadyToSend, 'Ready to send'],
    [TaxNoteOrderState.NotPaid, 'Not paid'],
    [TaxNoteOrderState.SentToSap, 'Sent to SAP']
]);

export const map = (value: TaxNoteOrderState): string => valueMap.get(value) ?? '';