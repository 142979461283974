import PermissionValidator from '@authorization/PermissionValidator';
import { getFullItemWidth } from '@helper/domHelpers';
import clsx from 'clsx';
import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { ToolbarItem, ToolbarStateReturn } from 'reakit/Toolbar';

import { SubMenuItemSchema } from './MenuItems';
import SubMenu from './SubMenu';

export interface IApplicationSubMenuItem {
	item: SubMenuItemSchema;
	toolbar: ToolbarStateReturn;
	setMenuItemWidth: (key: string, width: number) => void;
	permissionValidator: PermissionValidator;
}

const ApplicationSubMenuItem: React.FC<IApplicationSubMenuItem> = (props) => {
	const location = useLocation();

	const targetRef = useRef<HTMLDivElement>(null);

	const isVisible = true;

	useEffect(() => {
		if (isVisible && targetRef.current) {
			props.setMenuItemWidth(
				targetRef.current.id,
				getFullItemWidth(targetRef.current)
			);
		}
	}, [isVisible]);

	const isActive = () => {
		if (props.item.activePrefix === undefined) return false;

		if (Array.isArray(props.item.activePrefix)) {
			return props.item.activePrefix.some((prefix) =>
				location.pathname.startsWith(`/${prefix}`)
			);
		}

		return location.pathname.startsWith(`/${props.item.activePrefix}`);
	};

	return isVisible ? (
		<ToolbarItem
			{...props.toolbar}
			ref={targetRef}
			id={props.item.key}
			as={SubMenu}
			item={props.item}
			placement="bottom-end"
			className={clsx(
				'ant-menu-submenu-horizontal main-menu',
				isActive() && 'menu-item--selected'
			)}
		/>
	) : (
		<></>
	);
};

export default ApplicationSubMenuItem;
