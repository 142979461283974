import { EssityColumnProps } from '@components/shared/paginatedEssityTable/GridHelper';
import formatHelpers from '@helper/formatHelpers';
import { VoivodeshipIdentifierDto } from '@services/src/models';

export const IdentifiersTableColumns: EssityColumnProps<VoivodeshipIdentifierDto>[] = [
	{
		dataIndex: 'identifier',
		key: 'identifier',
		title: 'Dist. number',
	},
	{
		dataIndex: 'identifierStartDate',
		key: 'identifierStartDate',
		title: 'Start date',
		render: (value?: Date) => formatHelpers.formatDate(value),
	},
	{
		dataIndex: 'identifierEndDate',
		key: 'identifierEndDate',
		title: 'End date',
		render: (value?: Date) => formatHelpers.formatDate(value),
	},
];
