import { GlobalUserManager } from '@authentication/userManager';
import { baseAppUrl } from '@helper/api';
import ErrorCode from '@helper/errorCode';
import { StatusCode } from '@helper/statusCode';
import { Modal } from 'antd';
import React from 'react';

const DataObjectParser = require('dataobject-parser');
let isModalOpen = false;

export function showFormIsInvalidModal() {
	Modal.warning({
		centered: true,
		maskClosable: true,
		title: 'The form contains errors!',
	});
}

export function showMessageIsValidModal() {
	Modal.success({
		centered: true,
		maskClosable: true,
		title: 'The message is correct.',
	});
}

export function showSuccess(message: string | React.ReactNode) {
	Modal.success({
		centered: true,
		maskClosable: true,
		title: message,
	});
}

export function showWarning(message: string | React.ReactNode) {
	Modal.warn({
		centered: true,
		maskClosable: true,
		title: message,
	});
}

export function showErrorModal(message: string | React.ReactNode) {
	Modal.error({
		centered: true,
		title: message,
	});
}

export function showNotExpectedErrorModal(
	error: any,
	onNotExpectedModalConfirmation?: () => void
) {
	console.log(error);
	if (error && error!.statusCode === StatusCode.Forbidden) {
		Modal.warning({
			centered: true,
			title: 'You do not have sufficient permissions',
			onOk: () => {
				GlobalUserManager.UserManager().signoutRedirect();
			},
		});
	} else if (error && error!.statusCode === StatusCode.BadRequest) {
		let errors: any = JSON.parse(error.response.body);

		const validationMessages: string[] = [];
		Object.keys(errors).map((key) => {
			errors[key].map((message: string) => {

				const table = message.split('\r\n');

				if (table.length > 1) {
					message = table[0];
				}

				validationMessages.push(message);
			});
		});

		Modal.warning({
			centered: true,
			title: 'Validation errors',
			onOk: onNotExpectedModalConfirmation,
			content: validationMessages.map((msg: string, index: number) => {
				return <p key={index}>{msg}</p>;
			}),
		});
		return DataObjectParser.transpose(errors).data();
	} else if (error && error!.statusCode === StatusCode.Unauthorized) {
		GlobalUserManager.UserManager().signoutRedirect();
	} else if (error?.code === ErrorCode.AbortError) {
		return;
	} else if (error && error!.statusCode === StatusCode.NotFound) {
		if (!isModalOpen) {
			isModalOpen = true;
			Modal.error({
				centered: true,
				title:
					'The resource you are looking for was not found, you will be redirected to the home page.',
				onOk: () => {
					window.location.href = baseAppUrl;
					isModalOpen = false;
				},
				onCancel: () => {
					isModalOpen = false;
				},
			});
		}
	} else {
		Modal.error({
			centered: true,
			title:
				'An unexpected error has occurred. Please contact the administrator.',
		});
	}
}

export function showMessageAddedAndPreparedToSendModal() {
	Modal.success({
		centered: true,
		maskClosable: true,
		title: 'The message has been saved and submitted for sending.',
	});
}
