import React from 'react';

import { PharmaciesNoteGroupsFilters } from './PharmaciesNoteGroupsFilters';
import { PharmaciesNoteGroupsMenu } from './PharmaciesNoteGroupsMenu';
import { PharmaciesNoteGroupsTable } from './PharmaciesNoteGroupsTable';
import styles from './styles/PharmaciesNoteGroups.module.less';

export const PharmaciesNoteGroups = () => {
	return (
		<div>
			<PharmaciesNoteGroupsMenu />

			<PharmaciesNoteGroupsFilters />

			<div className={styles.tableContainer}>
				<PharmaciesNoteGroupsTable />
			</div>
		</div>
	);
};
