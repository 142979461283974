import { MissingPharmacyDto } from '@services/src/models';
import EssityTable from 'layout/EssityTable';
import React from 'react';

import { ImportFarmapromValidationTableColumns } from './ImportFarmapromValidationTableColumns';

interface IProps {
	validation: MissingPharmacyDto[] | undefined;
}

export const ImportFarmapromValidationTable = (props: IProps) => {
	return (
		<EssityTable
			columns={ImportFarmapromValidationTableColumns}
			dataSource={props.validation}
			pagination={true}
		/>
	);
};
