import { EssityColumnProps } from '@components/shared/paginatedEssityTable/GridHelper';

export const AddReturnDocumentTableColumns: EssityColumnProps<any>[] = [
	{
		dataIndex: 'orderNumber',
		key: 'orderNumber',
		title: 'Order number',
	},
	{
		dataIndex: 'pesel',
		key: 'pesel',
		title: 'PESEL',
	},
	{
		dataIndex: 'refund',
		key: 'refund',
		title: 'Refund value',
	},
];
