import { ImportFarmapromFileModal } from '@components/farmapromPharmacies/import/ImportFarmapromFileModal';
import { ImportUMXFileModal } from '@components/umx/import/ImportUMXFileModal';
import { Button, Divider, Space } from 'antd';
import React from 'react';
import { useHistory } from 'react-router';

import ImportPharmacies from './ImportPharmacies';
import styles from './styles/Pharmacies.module.less';

export const PharmaciesMenu = () => {
	const history = useHistory();

	return (
		<>
			<Divider />
			<div className={styles.menu}>
				<div></div>
				<Space direction="horizontal">
					<Button
						type="primary"
						shape="round"
						size="large"
						onClick={() => {
							history.push('pharmacies/new');
						}}
					>
						New Pharmacy
					</Button>
					<ImportPharmacies />
					<ImportUMXFileModal />
					<ImportFarmapromFileModal />
				</Space>
			</div>
			<Divider />
		</>
	);
};
