import { OrderLines } from '@components/orderLines/OrderLines';
import { IsolatedModal } from '@components/shared/modals/IsolatedModal';
import { useRootData } from '@hooks/hook';
import { SearchType } from '@services/src/models';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { IGlobalSearchStore } from 'stores/GlobalSearchStore';

import GlobalSearchFilters from './GlobalSearchFilters';
import { GlobalSearchTable } from './GlobalSearchTable';
import styles from './styles/GlobalSearch.module.less';

const GlobalSearchModal = () => {
	const globalSearchStore: IGlobalSearchStore = useRootData(
		(store) => store.globalSearchStore
	);

	const handleCancel = () => {
		globalSearchStore.visible.set(false);
	};

	const getModalTitle = (): string => {
		const searchMethod = globalSearchStore.filterData.get().searchMethod;

		switch (searchMethod) {
			case SearchType.PESEL:
				return 'Search orders by PESEL';
			case SearchType.OrderNumber:
				return 'Search orders by Order number';
			case SearchType.Status:
				return 'Search orders by Status';
		}
	};

	const filterData = globalSearchStore.filterData.get();

	return (
		<IsolatedModal
			title={getModalTitle()}
			visible={globalSearchStore.visible.get()}
			closable={filterData.selectedOrderId ? false : true}
			onCancel={handleCancel}
			width="95vw"
		>
			<>
				{filterData.selectedOrderId ? (
					<OrderLines
						orderId={filterData.selectedOrderId!}
						isInSearch={true}
						theme={styles}
					/>
				) : (
					<>
						<GlobalSearchFilters />
						<div className={styles.tableContainer}>
							<GlobalSearchTable />
						</div>
					</>
				)}
			</>
		</IsolatedModal>
	);
};

export default observer(GlobalSearchModal);
