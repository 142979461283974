import { CloseOutlined } from '@ant-design/icons';
import { IsolatedModal } from '@components/shared/modals/IsolatedModal';
import { ajaxByUser } from '@helper/api';
import { useRootData } from '@hooks/hook';
import { DictionariesConstants } from '@services/DictionariesConstants';
import { getEssityApiClient } from '@services/EssityApi';
import { MarkToCancelCommand, OrderDto } from '@services/src/models';
import { Button, Space } from 'antd';
import { Field, Formik, FormikActions, FormikProps } from 'formik';
import { DictionarySelect } from 'forms/DictionarySelect';
import { FFieldLabel, FInput } from 'forms/FormikFormItems';
import { CenteredRow } from 'layout/CenteredRow';
import React, { useState } from 'react';
import { IDictionaryStore } from 'stores/DictionaryStore';
import { IGridStore } from 'stores/GridStore';

import { OrdersPairedConfirmationModal } from './OrdersPairedConfirmationModal';

interface IProps {
	order: OrderDto;
}

export const OrdersCancelModal = (props: IProps) => {
	const [visible, setVisible] = useState<boolean>();
	const gridStore: IGridStore = useRootData((store) => store.gridStore);

	const dictionaryStore: IDictionaryStore = useRootData(
		(store) => store.dictionaryStore
	);

	const hasSelectedOthersValue = (id: number) => {
		const codeName = dictionaryStore.getItemCodeNameById(
			DictionariesConstants.OrderCancellationReason,
			id
		);

		return codeName === 'OTHERS';
	};

	const handleCancelation = (command: MarkToCancelCommand) => {
		ajaxByUser(
			'Successfully canceled order.',
			() =>
				getEssityApiClient().then((api) =>
					api.order.markToCancel({
						body: command,
					})
				),
			() => {
				setVisible(false);
				gridStore.searching.set(true);
			}
		);
	};

	const handleCancel = (actions: FormikActions<MarkToCancelCommand>) => {
		setVisible(false);
		actions.resetForm();
	};

	return (
		<>
			<Button
				type="primary"
				shape="circle"
				size="small"
				onClick={() => setVisible(true)}
				icon={<CloseOutlined />}
			/>

			<Formik
				validateOnChange={false}
				validateOnBlur={true}
				initialValues={{}}
				enableReinitialize
				validationSchema={{}}
				onSubmit={(
					values: MarkToCancelCommand,
					actions: FormikActions<MarkToCancelCommand>
				) => {
					const command: MarkToCancelCommand = {
						orderId: props.order.id,
						cancellationReasonId: values.cancellationReasonId,
						cancellationReasonText: values.cancellationReasonText,
					};

					handleCancelation(command);
				}}
				render={(formikProps: FormikProps<MarkToCancelCommand>) => (
					<IsolatedModal
						title="Order cancelation"
						onCancel={() => handleCancel(formikProps)}
						visible={visible}
						width="25vw"
					>
						<Space direction="vertical">
							<FFieldLabel label="Cancelation reason" />
							<DictionarySelect
								readOnly={false}
								formikProps={formikProps}
								style={{ width: '23vw' }}
								formikName="cancellationReasonId"
								dictionaryCodeName={
									DictionariesConstants.OrderCancellationReason
								}
								excludeKeys={['DUPLICATE']}
							/>

							{hasSelectedOthersValue(
								formikProps.values.cancellationReasonId!
							) && (
								<>
									<FFieldLabel label="Input text reason" />
									<Field
										component={FInput}
										placeholder="Input text reason"
										name="cancellationReasonText"
										readOnly={false}
									/>
								</>
							)}
						</Space>

						<CenteredRow style={{ marginTop: 15 }}>
							<Button
								type="primary"
								shape="round"
								size="large"
								disabled={
									formikProps.values.cancellationReasonId ===
										null ||
									formikProps.values.cancellationReasonId ===
										undefined ||
									props.order.realizationId === undefined
								}
								onClick={() => {
									OrdersPairedConfirmationModal({
										onAccept: () => {
											formikProps.submitForm();
										},
										mode: 'cancelation',
										nhfRealizationId: props.order
											.nhfRealizationId!,
									});
								}}
							>
								Cancel order
							</Button>
						</CenteredRow>
					</IsolatedModal>
				)}
			/>
		</>
	);
};
