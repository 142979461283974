import { DatePicker } from 'antd';
import { DatePickerProps, RangePickerProps } from 'antd/lib/date-picker';
import moment, { Moment } from 'moment';
import React from 'react';

export const disabledDate = (current: Moment) => {
	return current.isBefore(moment('2000-01-01'));
};

const { RangePicker } = DatePicker;

export const EssityDatePicker = (props: DatePickerProps) => {
	return <DatePicker disabledDate={disabledDate} {...props} />;
};

export const EssityRangePicker = (props: RangePickerProps) => {
	return <RangePicker disabledDate={disabledDate} {...props} />;
};
