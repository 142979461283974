import { toMomentDate } from '@helper/dateHelpers';
import {
	UpdateVoivodeshipCommand,
	VoivodeshipDetailsDto,
} from '@services/src/models';
import { Moment } from 'moment';

export class DepartmentEditDto {
	id?: string;
	sapId?: number;
	name?: string;
	shortName?: string;
	city?: string;
	street?: string;
	postalCode?: string;
	voivodeshipId?: number;
	voivodeshipName?: string;
	serviceProviderCode?: string;
	user?: string;
	password?: string;
	currentMainContractNumber?: string;
	currentContractNumber?: string;
	currentMainContractStartDate?: Moment;
	currentMainContractEndDate?: Moment;
	currentContractStartDate?: Moment;
	currentContractEndDate?: Moment;
	currentContractId?: string;

	constructor(dto: VoivodeshipDetailsDto | undefined) {
		if (!dto) {
			return;
		}

		this.id = dto.id;
		this.sapId = dto.sapId;
		this.name = dto.name;
		this.shortName = dto.shortName;
		this.city = dto.city;
		this.street = dto.street;
		this.postalCode = dto.postalCode;
		this.voivodeshipId = dto.voivodeshipId;
		this.voivodeshipName = dto.voivodeshipName;
		this.serviceProviderCode = dto.serviceProviderCode;
		this.user = dto.user;
		this.password = dto.password;
		this.currentContractId = dto.currentContractId;
		this.currentContractNumber = dto.currentContractNumber;
		this.currentMainContractNumber = dto.currentMainContractNumber;
		this.currentMainContractStartDate = toMomentDate(
			dto.currentMainContractStartDate
		);

		this.currentMainContractEndDate = toMomentDate(
			dto.currentMainContractEndDate
		);

		this.currentContractStartDate = toMomentDate(
			dto.currentContractStartDate
		);

		this.currentContractEndDate = toMomentDate(dto.currentContractEndDate);
	}

	toUpdateCommand(): UpdateVoivodeshipCommand {
		return {
			id: this.id,
			serviceProviderCode: this.serviceProviderCode,
			user: this.user,
			password: this.password,
			currentMainContractStartDate:
				this.currentMainContractStartDate?.toDate() || undefined,
			currentMainContractEndDate:
				this.currentMainContractEndDate?.toDate() || undefined,
			currentContractId: this.currentContractId,
		};
	}
}
