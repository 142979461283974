import { OrdersTableColumns } from '@components/orders/OrdersTableColumns';
import PaginatedEssityTable from '@components/shared/paginatedEssityTable/PaginatedEssityTable';
import { SieveModel } from '@components/shared/paginatedEssityTable/SieveModels';
import { useRootData } from '@hooks/hook';
import { getEssityApiClient } from '@services/EssityApi';
import {
	OrderDto,
	OrderGetAllPaginatedCancelledOrdersByPharmacyIdQueryOptionalParams,
	OrderGetAllPaginatedOrdersForPharmacyInDateScopeOptionalParams,
} from '@services/src/models';
import { Col, Row, Statistic } from 'antd';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { IGridStore } from 'stores/GridStore';

const gridName = 'noteOrders';

interface IProps {
	pharmacyId: string;
	year: number;
	month: number;
	onOrdersSelect: (orders: OrderDto[]) => void;
	selectedOrders: OrderDto[] | undefined;
	//pharmacyTaxNoteId: string | undefined;
	//readOnly: boolean;
}

const OrdersReturnDocumentTable = (props: IProps) => {
	//const gridStore: IGridStore = useRootData((store) => store.gridStore);
	// const [totalRefundValue, setTotalRefundValue] = useState<string>('0');
	// const [totalValue, setTotalValue] = useState<string>('0');

	// useEffect(() => {
	// 	const value: OrderDto[] = gridStore.getSelectedValues();
	// 	setTotalRefundValue(_.sumBy(value.map((x) => x.refund)).toFixed(2));
	// 	setTotalValue(_.sumBy(value.map((x) => x.value)).toFixed(2));
	// }, [gridStore.getSelectedValues()])

	return (
		<>
			<PaginatedEssityTable<OrderDto>
				columns={OrdersTableColumns}
				gridName={gridName}
				//readOnlySelection={props.readOnly}
				hasRowSelection={true}
				hidePersonalizationSettings={true}
				getRowKey={(r: OrderDto) => r.id!}
				selectedRows={props.selectedOrders}
				getPagedResult={async (sieve: SieveModel, abortSignal) => {
					if (!props.year || !props.month || !props.pharmacyId) {
						return [];
					}

					const parameters: OrderGetAllPaginatedCancelledOrdersByPharmacyIdQueryOptionalParams =
					{
						page: sieve.page,
						pageSize: sieve.pageSize,
						sorts: sieve.sorts,
						abortSignal: abortSignal,
						filters: sieve.filters,
						pharmacyId: props.pharmacyId,
						year: props.year,
						month: props.month,
					};

					const api = await getEssityApiClient();
					return await api.order.getAllPaginatedCancelledOrdersByPharmacyIdQuery(
						parameters
					);
				}}
			/>

		</>
	);
};

export default observer(OrdersReturnDocumentTable);
