/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from "@azure/ms-rest-js";
import * as Models from "../models";
import * as Mappers from "../models/maintenanceMappers";
import { PolandPharmacyRxAPIContext } from "../polandPharmacyRxAPIContext";

/** Class representing a Maintenance. */
export class Maintenance {
  private readonly client: PolandPharmacyRxAPIContext;

  /**
   * Create a Maintenance.
   * @param {PolandPharmacyRxAPIContext} client Reference to the service client.
   */
  constructor(client: PolandPharmacyRxAPIContext) {
    this.client = client;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.MaintenanceEnvironmentResponse>
   */
  environment(options?: msRest.RequestOptionsBase): Promise<Models.MaintenanceEnvironmentResponse>;
  /**
   * @param callback The callback
   */
  environment(callback: msRest.ServiceCallback<string>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  environment(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<string>): void;
  environment(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<string>, callback?: msRest.ServiceCallback<string>): Promise<Models.MaintenanceEnvironmentResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      environmentOperationSpec,
      callback) as Promise<Models.MaintenanceEnvironmentResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.MaintenanceKeyVaultNameResponse>
   */
  keyVaultName(options?: msRest.RequestOptionsBase): Promise<Models.MaintenanceKeyVaultNameResponse>;
  /**
   * @param callback The callback
   */
  keyVaultName(callback: msRest.ServiceCallback<string>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  keyVaultName(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<string>): void;
  keyVaultName(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<string>, callback?: msRest.ServiceCallback<string>): Promise<Models.MaintenanceKeyVaultNameResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      keyVaultNameOperationSpec,
      callback) as Promise<Models.MaintenanceKeyVaultNameResponse>;
  }
}

// Operation Specifications
const serializer = new msRest.Serializer(Mappers);
const environmentOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "maintenance/Maintenance/environment",
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "String"
        }
      }
    },
    default: {}
  },
  serializer
};

const keyVaultNameOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "maintenance/Maintenance/keyvaultname",
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "String"
        }
      }
    },
    default: {}
  },
  serializer
};
