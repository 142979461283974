import { EssityDatePicker } from '@components/shared/EssityDateTime';
import { IsolatedModal } from '@components/shared/modals/IsolatedModal';
import { ajaxByUser } from '@helper/api';
import { DateFormat } from '@helper/formatHelpers';
import { getEssityApiClient } from '@services/EssityApi';
import { DollarCircleFilled } from '@ant-design/icons';
import {
	PaymentConfirmationState,
	PharmacyTaxNotePaymentWithNotesDto,
} from '@services/src/models';
import { Badge, Button, Space, Switch, Tooltip } from 'antd';
import { CenteredRow } from 'layout/CenteredRow';
import moment from 'moment';
import { Moment } from 'moment';
import React, { useEffect, useState } from 'react';

interface IProps {
	payment: PharmacyTaxNotePaymentWithNotesDto;
	refresh: () => void;
}

interface ILogicalState {
	manualTransfer: boolean;
	return: boolean;
}

interface IState extends ILogicalState {
	returnDate?: Moment;
	transferDate?: Moment;
}

export const WrongAccountModal = (props: IProps) => {
	const [isVisible, setIsVisible] = useState<boolean>();

	const initialState: IState = {
		return: false,
		manualTransfer: false,
		returnDate: moment(),
		transferDate: moment(),
	};

	const [state, setState] = useState<IState>(initialState);

	const mapToLogicalState = (
		confirmationState?: PaymentConfirmationState
	): ILogicalState => {
		switch (confirmationState) {
			case PaymentConfirmationState.CONFIRMED:
				return {
					manualTransfer: false,
					return: false,
				};
			case PaymentConfirmationState.RECOVERED:
				return { manualTransfer: true, return: true };
			case PaymentConfirmationState.REFUSED:
				return { manualTransfer: false, return: true };
			default:
				return { manualTransfer: false, return: false };
		}
	};

	const mapFromLogicalState = (): PaymentConfirmationState => {
		if (!state.return && !state.manualTransfer) {
			return PaymentConfirmationState.CONFIRMED;
		} else if (state.return && !state.manualTransfer) {
			return PaymentConfirmationState.REFUSED;
		} else if (state.return && state.manualTransfer) {
			return PaymentConfirmationState.RECOVERED;
		} else {
			return PaymentConfirmationState.CONFIRMED;
		}
	};

	const [isBusy, setIsBusy] = useState<boolean>();

	const handleCancel = () => {
		setIsVisible(false);
		setState(initialState);
	};

	useEffect(() => {
		if (props.payment) {
			setState({
				returnDate: props.payment.refuseDate
					? moment(props.payment.refuseDate)
					: undefined,
				transferDate: props.payment.recoverDate
					? moment(props.payment.recoverDate)
					: undefined,
				...mapToLogicalState(props.payment.confirmationState),
			});
		}
	}, [props.payment]);

	return (
		<>

			<Tooltip title="Wrong account number">
				{props.payment.confirmationState == PaymentConfirmationState.CONFIRMED ?
					(<Button
						style={{ float: 'right' }}
						type="primary"
						shape="round"
						size="small"
						onClick={() => {
							setIsVisible(true);
						}}
					>
						Wrong account
					</Button>) : (
						<Badge
							style={{ paddingRight: '2px' }}
							count={
								<DollarCircleFilled
									style={{ fontSize: 18, color: '#F40785' }}
								/>
							}>
							<Button
								type="primary"
								shape="round"
								size="small"
								onClick={() => {
									setIsVisible(true);
								}}
							>
								Wrong account
							</Button>
						</Badge>
					)
				}
			</Tooltip>

			<IsolatedModal
				visible={isVisible}
				title="Wrong account number"
				onCancel={() => handleCancel()}
			>
				<Space direction="vertical" size="middle">
					<Space direction="horizontal">
						Information about the return has been received
						<Switch
							checkedChildren="Yes"
							unCheckedChildren="No"
							checked={state.return}
							onChange={(checked: boolean) => {
								if (checked) {
									setState({ ...state, return: true });
								} else {
									setState({
										...state,
										return: false,
										manualTransfer: false,
									});
								}
							}}
						/>
					</Space>
					<Space direction="horizontal">
						<div style={{ width: 80 }}>Return date</div>
						<EssityDatePicker
							disabled={!state.return}
							format={DateFormat.Date}
							value={state.returnDate}
							placeholder="Return date"
							onChange={(date: Moment | null) => {
								setState({
									...state,
									returnDate: date || undefined,
								});
							}}
						/>
					</Space>
					<Space direction="horizontal">
						Manual transfer has been sent
						<Switch
							checkedChildren="Yes"
							unCheckedChildren="No"
							checked={state.manualTransfer}
							onChange={(checked: boolean) => {
								if (checked && state.return) {
									setState({
										...state,
										manualTransfer: true,
									});
								} else if (!checked) {
									setState({
										...state,
										manualTransfer: false,
									});
								}
							}}
						/>
					</Space>
					<Space direction="horizontal">
						<div style={{ width: 80 }}>Transfer date</div>
						<EssityDatePicker
							format={DateFormat.Date}
							disabled={!state.manualTransfer}
							placeholder="Transfer date"
							value={state.transferDate}
							onChange={(date: Moment | null) => {
								setState({
									...state,
									transferDate: date || undefined,
								});
							}}
						/>
					</Space>
				</Space>
				<CenteredRow style={{ marginTop: 10 }}>
					<Button
						type="primary"
						shape="round"
						disabled={isBusy || state.returnDate?.isAfter(state.transferDate)}
						onClick={() => {
							setIsBusy(true);
							ajaxByUser('Successfully completed action.', () =>
								getEssityApiClient().then((api) =>
									api.pharmacyTaxNotePaymentConfirmation
										.changeState({
											body: {
												pharmacyTaxNotePaymentId:
													props.payment.paymentId,
												refuseDate: state.returnDate?.toDate(),
												recoverDate: state.transferDate?.toDate(),
												confirmationState: mapFromLogicalState(),
											},
										})
										.then(() => {
											props.refresh();
										})
										.finally(() => {
											setIsBusy(false);
											setIsVisible(false);
											setState(initialState);
										})
								)
							);
						}}
					>
						Save
					</Button>
				</CenteredRow>
			</IsolatedModal>
		</>
	);
};
