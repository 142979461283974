import _ from 'lodash';

const flattenKeys = (obj: object, path: any = []): any =>
	!_.isObject(obj)
		? { [path.join('.')]: obj }
		: _.reduce(
				obj,
				(cum, next, key) =>
					_.merge(cum, flattenKeys(next, [...path, key])),
				{}
		  );

export default {
	flattenKeys,
};
