/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from "@azure/ms-rest-js";
import * as Models from "../models";
import * as Mappers from "../models/priceMappers";
import * as Parameters from "../models/parameters";
import { PolandPharmacyRxAPIContext } from "../polandPharmacyRxAPIContext";

/** Class representing a Price. */
export class Price {
  private readonly client: PolandPharmacyRxAPIContext;

  /**
   * Create a Price.
   * @param {PolandPharmacyRxAPIContext} client Reference to the service client.
   */
  constructor(client: PolandPharmacyRxAPIContext) {
    this.client = client;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.PriceAllResponse>
   */
  all(options?: Models.PriceAllOptionalParams): Promise<Models.PriceAllResponse>;
  /**
   * @param callback The callback
   */
  all(callback: msRest.ServiceCallback<Models.ProductPriceDtoPagedResult>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  all(options: Models.PriceAllOptionalParams, callback: msRest.ServiceCallback<Models.ProductPriceDtoPagedResult>): void;
  all(options?: Models.PriceAllOptionalParams | msRest.ServiceCallback<Models.ProductPriceDtoPagedResult>, callback?: msRest.ServiceCallback<Models.ProductPriceDtoPagedResult>): Promise<Models.PriceAllResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      allOperationSpec,
      callback) as Promise<Models.PriceAllResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.PriceSearchResponse>
   */
  search(options?: Models.PriceSearchOptionalParams): Promise<Models.PriceSearchResponse>;
  /**
   * @param callback The callback
   */
  search(callback: msRest.ServiceCallback<Models.ProductPriceSearchDto[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  search(options: Models.PriceSearchOptionalParams, callback: msRest.ServiceCallback<Models.ProductPriceSearchDto[]>): void;
  search(options?: Models.PriceSearchOptionalParams | msRest.ServiceCallback<Models.ProductPriceSearchDto[]>, callback?: msRest.ServiceCallback<Models.ProductPriceSearchDto[]>): Promise<Models.PriceSearchResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      searchOperationSpec,
      callback) as Promise<Models.PriceSearchResponse>;
  }
}

// Operation Specifications
const serializer = new msRest.Serializer(Mappers);
const allOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "price/Price",
  queryParameters: [
    Parameters.filters,
    Parameters.sorts,
    Parameters.page,
    Parameters.pageSize,
    Parameters.priceListId0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.ProductPriceDtoPagedResult
    },
    default: {}
  },
  serializer
};

const searchOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "price/Price/search",
  queryParameters: [
    Parameters.priceListId1,
    Parameters.searchText1
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ProductPriceSearchDto"
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};
