import { DoneView } from '@components/shared/import/DoneView';
import { ExceptionView } from '@components/shared/import/ExceptionView';
import { LoadingView } from '@components/shared/import/LoadingView';
import { IsolatedModal } from '@components/shared/modals/IsolatedModal';
import { ImportStatus } from '@components/shared/types/importStatus';
import { ajaxCatch } from '@helper/api';
import { useRootData } from '@hooks/hook';
import { DictionariesConstants } from '@services/DictionariesConstants';
import { getEssityApiClient } from '@services/EssityApi';
import {
	PaymentState,
	VoivodeshipPaymentGroupDto,
	VoivodeshipPaymentGroupSendToSapOptionalParams,
} from '@services/src/models';
import { Button, Space, Table } from 'antd';
import { Field, Formik, FormikActions, FormikProps } from 'formik';
import { FDatePicker, FFieldLabel } from 'forms/FormikFormItems';
import { CenteredRow } from 'layout/CenteredRow';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { IDictionaryStore } from 'stores/DictionaryStore';
import { IGridStore } from 'stores/GridStore';

import { SendToSAPTableColumns } from './SendToSAPTableColumns';
import { SendToSAPValidationSchema } from './SendToSAPValidationSchema';

interface IProps {
	label: string;
	correctStatus: PaymentState;
	mode: mode;
}

export type mode = 'first' | 'again';

const SendToSAPModal = (props: IProps) => {
	const gridStore: IGridStore = useRootData((store) => store.gridStore);
	const [visible, setVisible] = useState<boolean>(false);

	const [progressState, setProgressState] = useState<ImportStatus>(
		ImportStatus.Init
	);

	const dictionaryStore: IDictionaryStore = useRootData(
		(store) => store.dictionaryStore
	);

	const handleCancel = (
		props: FormikProps<VoivodeshipPaymentGroupSendToSapOptionalParams>
	) => {
		setVisible(false);
		setProgressState(ImportStatus.Init);
		props.resetForm();
	};

	const getRecordsToSend = (): VoivodeshipPaymentGroupDto[] => {
		return gridStore.selectedValues.filter(
			(x: VoivodeshipPaymentGroupDto) =>
				x.voivodeshipPaymentState === props.correctStatus
		);
	};

	const isExportEnabled = () => {
		return (
			gridStore.selectedValues.length > 0 && getRecordsToSend().length > 0
		);
	};

	const columns = [
		{
			sorter: false,
			dataIndex: 'voivodeshipId',
			key: 'voivodeshipId',
			title: 'Voivodeship',
			hideFilterIcon: true,
			render: (value: number) => {
				return dictionaryStore.getValueById(
					DictionariesConstants.Voivodeships,
					value
				);
			},
		},
		...SendToSAPTableColumns,
	];

	const InitialView = (
		props: FormikProps<VoivodeshipPaymentGroupSendToSapOptionalParams>
	) => (
		<CenteredRow style={{ textAlign: 'center' }}>
			<Space direction="vertical" size="middle">
				<Table
					dataSource={getRecordsToSend()}
					columns={columns}
					pagination={false}
				></Table>

				<Space direction="horizontal">
					<FFieldLabel label="Posting date" />
					<Field
						label="Posting date"
						component={FDatePicker}
						name="postingDate"
						mode="date"
					/>
				</Space>

				<Button
					type="primary"
					size="large"
					shape="round"
					onClick={() => {
						if (!props.isSubmitting) {
							props.submitForm();
						}
					}}
				>
					Send notes to SAP
				</Button>
			</Space>
		</CenteredRow>
	);

	const getViewByExportStatus = (
		props: FormikProps<VoivodeshipPaymentGroupSendToSapOptionalParams>
	) => {
		switch (progressState) {
			case ImportStatus.Init:
				return <InitialView {...props} />;
			case ImportStatus.Done:
				return <DoneView message="Successfully finished." />;
			case ImportStatus.Exception:
				return <ExceptionView />;
			case ImportStatus.Loading:
				return (
					<LoadingView message="Operation in progress, please wait..." />
				);
		}
	};

	const handleRender = (
		props: FormikProps<VoivodeshipPaymentGroupSendToSapOptionalParams>
	) => {
		return (
			<IsolatedModal
				title="Sending payment packages to SAP"
				visible={visible}
				onCancel={() => handleCancel(props)}
			>
				{getViewByExportStatus(props)}
			</IsolatedModal>
		);
	};

	const handleAgainExport = (
		values: any,
		actions: FormikActions<VoivodeshipPaymentGroupSendToSapOptionalParams>
	) => {
		setProgressState(ImportStatus.Loading);

		ajaxCatch(
			() =>
				getEssityApiClient().then((api) =>
					api.voivodeshipPaymentGroup.sendToSapAgain({
						body: {
							voivodeshipPaymentGroupsWithHash: getRecordsToSend(),
							postingDate: values.postingDate.toDate(),
						},
					})
				),
			() => {
				setProgressState(ImportStatus.Done);
				gridStore.searching.set(true);
			},
			() => {
				setProgressState(ImportStatus.Exception);
			}
		).finally(() => {
			actions.setSubmitting(false);
			gridStore.setSelectedValues([]);
			gridStore.setSelectedKeys([]);
			gridStore.allRowsSelected.set(false);
		});
	};

	const handleExport = (
		values: any,
		actions: FormikActions<VoivodeshipPaymentGroupSendToSapOptionalParams>
	) => {
		setProgressState(ImportStatus.Loading);

		ajaxCatch(
			() =>
				getEssityApiClient().then((api) =>
					api.voivodeshipPaymentGroup.sendToSap({
						body: {
							voivodeshipPaymentGroupsWithHash: getRecordsToSend(),
							postingDate: values.postingDate.toDate(),
						},
					})
				),
			() => {
				setProgressState(ImportStatus.Done);
				gridStore.searching.set(true);
			},
			() => {
				setProgressState(ImportStatus.Exception);
			}
		).finally(() => {
			actions.setSubmitting(false);
			gridStore.setSelectedValues([]);
			gridStore.setSelectedKeys([]);
			gridStore.allRowsSelected.set(false);
		});
	};

	return (
		<>
			<Button
				type="primary"
				shape="round"
				size="large"
				onClick={() => setVisible(true)}
				disabled={!isExportEnabled()}
			>
				{props.label}
			</Button>

			<Formik
				validateOnChange
				validateOnBlur
				enableReinitialize
				isInitialValid={false}
				initialValues={{}}
				validationSchema={SendToSAPValidationSchema}
				onSubmit={(
					values,
					actions: FormikActions<VoivodeshipPaymentGroupSendToSapOptionalParams>
				) => {
					if (props.mode === 'first') {
						handleExport(values, actions);
					} else {
						handleAgainExport(values, actions);
					}
				}}
				render={handleRender}
			/>
		</>
	);
};

export default observer(SendToSAPModal);
