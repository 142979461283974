import React from 'react';

import { SendingPaymentsMenu } from './SendingPaymentsMenu';
import { SendingPaymentsTable } from './SendingPaymentsTable';
import styles from './styles/SendingPayments.module.less';

export const SendingPayments = () => {
	return (
		<div className={styles.sendingPayments}>
			<SendingPaymentsMenu />

			<div className={styles.tableContainer}>
				<SendingPaymentsTable />
			</div>
		</div>
	);
};
