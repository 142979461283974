import { CheckCircleTwoTone } from '@ant-design/icons';
import {
	EssityColumnProps,
	filterType,
} from '@components/shared/paginatedEssityTable/GridHelper';
import { useRootData } from '@hooks/hook';
import { DictionariesConstants } from '@services/DictionariesConstants';
import * as VoivodeshipMapper from '@services/mappers/Voivodeship';
import { PaymentState, PharmacyTaxNoteGroupDto } from '@services/src/models';
import _ from 'lodash';
import React from 'react';
import { IDictionaryStore } from 'stores/DictionaryStore';

import styles from './styles/PharmaciesNoteGroups.module.less';

const ApplyValidation = (item: string, noteGroup: PharmacyTaxNoteGroupDto) => {
	if (noteGroup.isGroupValidAccordingToFinancialLogic) {
		if (noteGroup.paymentState !== PaymentState.Created) {
			return <div className={styles.frozenRecord}>{item}</div>;
		} else {
			return item;
		}
	}
	return <div className={styles.validationError}>{item}</div>;
};

export const GetPharmaciesNoteGroupsTableColumns = (): EssityColumnProps<PharmacyTaxNoteGroupDto>[] => {
	const dictionaryStore: IDictionaryStore = useRootData(
		(store) => store.dictionaryStore
	);

	return [
		{
			sorter: true,
			dataIndex: 'voivodeshipId',
			key: 'voivodeshipId',
			title: 'Voiv.',
			filter: 'equals' as filterType,
			hideFilterIcon: true,
			width: 95,
			render: (value: number, noteGroup: PharmacyTaxNoteGroupDto) =>
				ApplyValidation(
					VoivodeshipMapper.map(
						dictionaryStore.getValueById(
							DictionariesConstants.Voivodeships,
							value
						)!
					),
					noteGroup
				),
		},
		{
			sorter: false,
			dataIndex: 'pharmacyName',
			key: 'pharmacyName',
			title: 'Pharmacy',
			filter: 'contains' as filterType,
			width: 250,
			render: (item: string, noteGroup: PharmacyTaxNoteGroupDto) =>
				ApplyValidation(item, noteGroup),
		},
		{
			sorter: false,
			dataIndex: 'placeId',
			key: 'placeId',
			title: 'SAP ID',
			filter: 'equals' as filterType,
			width: 95,
			render: (item: string, noteGroup: PharmacyTaxNoteGroupDto) =>
				ApplyValidation(item, noteGroup),
		},
		{
			sorter: true,
			dataIndex: 'month',
			key: 'month',
			title: 'Mth.',
			filter: 'equals' as filterType,
			width: 95,
			render: (item: string, noteGroup: PharmacyTaxNoteGroupDto) =>
				ApplyValidation(item, noteGroup),
		},
		{
			sorter: true,
			dataIndex: 'year',
			key: 'year',
			title: 'Yr.',
			filter: 'equals' as filterType,
			width: 95,
			render: (item: string, noteGroup: PharmacyTaxNoteGroupDto) =>
				ApplyValidation(item, noteGroup),
		},
		{
			sorter: true,
			dataIndex: 'noteValue',
			key: 'noteValue',
			title: 'Notes',
			width: 95,
			render: (item: string, noteGroup: PharmacyTaxNoteGroupDto) =>
				ApplyValidation(item, noteGroup),
		},
		{
			sorter: true,
			dataIndex: 'allOrdersValue',
			key: 'allOrdersValue',
			title: 'eZWM',
			width: 95,
			render: (item: string, noteGroup: PharmacyTaxNoteGroupDto) =>
				ApplyValidation(item, noteGroup),
		},
		{
			sorter: true,
			dataIndex: 'assignedOrdersValue',
			key: 'assignedOrdersValue',
			title: 'Assigned',
			width: 95,
			render: (item: string, noteGroup: PharmacyTaxNoteGroupDto) =>
				ApplyValidation(item, noteGroup),
		},
		{
			sorter: true,
			dataIndex: 'settledOrdersValue',
			key: 'settledOrdersValue',
			title: 'Settled',
			width: 95,
			render: (item: string, noteGroup: PharmacyTaxNoteGroupDto) =>
				ApplyValidation(item, noteGroup),
		},
		{
			sorter: true,
			dataIndex: 'correctionValue',
			key: 'correctionValue',
			title: 'Corr.',
			width: 95,
			render: (item: string, noteGroup: PharmacyTaxNoteGroupDto) =>
				ApplyValidation(item, noteGroup),
		},
		{
			sorter: true,
			dataIndex: 'sentToSap',
			key: 'sentToSap',
			title: 'SAP sent',
			width: 95,
			render: (item: string, noteGroup: PharmacyTaxNoteGroupDto) =>
				ApplyValidation(item, noteGroup),
		},
		{
			sorter: true,
			dataIndex: 'lossValue',
			key: 'lossValue',
			title: 'Loss',
			width: 95,
			render: (item: string, noteGroup: PharmacyTaxNoteGroupDto) =>
				ApplyValidation(item, noteGroup),
		},
		{
			sorter: true,
			dataIndex: 'paid',
			filter: 'equals' as filterType,
			key: 'paid',
			title: 'Paid',
			width: 95,
			render: (item: string, noteGroup: PharmacyTaxNoteGroupDto) =>
				ApplyValidation(item, noteGroup),
		},
		{
			sorter: true,
			dataIndex: 'toReturn',
			key: 'toReturn',
			title: 'To return',
			width: 95,
			render: (item: string, noteGroup: PharmacyTaxNoteGroupDto) =>
				ApplyValidation(item, noteGroup),
		},
		{
			sorter: true,
			dataIndex: 'return',
			key: 'return',
			title: 'Returned',
			width: 95,
			render: (item: string, noteGroup: PharmacyTaxNoteGroupDto) =>
				ApplyValidation(item, noteGroup),
		},
		{
			sorter: true,
			dataIndex: 'balance',
			key: 'balance',
			title: 'Balance',
			width: 95,
			render: (item: string, noteGroup: PharmacyTaxNoteGroupDto) =>
				ApplyValidation(item, noteGroup),
		},
		{
			sorter: true,
			dataIndex: 'freezed',
			key: 'freezed',
			title: 'Frozen',
			width: 65,
			render: (item: string, noteGroup: PharmacyTaxNoteGroupDto) =>
				noteGroup.freezed ? (
					<CheckCircleTwoTone
						twoToneColor="#32387e"
						style={{ fontSize: 16 }}
					/>
				) : (
					<></>
				),
		},
	];
};
