import { Space } from 'antd';
import Divider from 'antd/lib/divider';
import Search from 'antd/lib/input/Search';
import React from 'react';

import { AccountRegisterModal } from './registerAccount/AccountRegisterModal';
import styles from './styles/AccountsManagement.module.less';

interface IProps {
	onCreate: () => void;
	onSearch: (text: string) => void;
}

export const AccountsManagementMenu = (props: IProps) => {
	return (
		<>
			<Divider />
			<Space direction="horizontal" className={styles.menu}>
				<AccountRegisterModal onCreate={props.onCreate} />
				<Search
					className={styles.searchInput}
					placeholder="Enter first part of Display name or E-mail"
					enterButton
					onSearch={(value: string) => {
						props.onSearch(value);
					}}
				/>
			</Space>
			<Divider />
		</>
	);
};
