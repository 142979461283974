import { BackButton } from '@components/shared/buttons/BackButton';
import { ajaxByUser } from '@helper/api';
import { DateFormat } from '@helper/formatHelpers';
import { getEssityApiClient } from '@services/EssityApi';
import {
	CreateNoteResponseDto,
	InternalTaxNoteType,
	OrderDto,
	OrderState,
	PharmacyNotePredictedToCorrectionDto,
	PharmacyTaxNoteCreateCorrectionResponse,
	PharmacyTaxNoteDetailsDto,
	PharmacyTaxNoteGroupDto,
	PharmacyTaxNoteUpdateResponse,
} from '@services/src/models';
import { Button, Col, Modal, Row, Select, Space } from 'antd';
import { Field, Formik, FormikActions, FormikProps } from 'formik';
import {
	FDatePicker,
	FFieldLabel,
	FInput,
	FInputNumber,
	FSelect,
	FTextArea,
} from 'forms/FormikFormItems';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { PharmacyNoteAddOrdersModal } from '../addOrders/PharmacyNoteAddOrdersModal';
import styles from '../styles/PharmacyNote.module.less';
import {
	CorrectionDebitNoteValidationSchema,
	CorrectionNoteEditValidationSchema,
	CorrectionNoteValidationSchema,
} from '../validation/CorrectionNoteValidationSchema';

interface IProps {
	editMode: boolean;
	readOnly: boolean;
	groupId: string;
	internalTaxNoteType: InternalTaxNoteType;
	correctionNoteId?: string;
	setAttachment: (attachment: Blob | undefined) => void;
}

interface IState {
	notes: PharmacyNotePredictedToCorrectionDto[];
	group?: PharmacyTaxNoteGroupDto;
}

const getValidationSchema = (editMode: boolean) => {
	if (editMode) {
		return CorrectionNoteEditValidationSchema;
	} else {
		return CorrectionNoteValidationSchema;
	}
};

export const CorrectionNoteDetails = (props: IProps) => {
	const [state, setState] = useState<IState>();
	const [orders, setOrders] = useState<OrderDto[]>();
	const [correctionType, setCorrectionType] = useState<InternalTaxNoteType>();
	const [note, setNote] = useState<PharmacyTaxNoteDetailsDto>();
	const [isBusy, setIsBusy] = useState<boolean>();
	const [limitedEditMode, setLimitedEditMode] = useState<boolean>(false);
	const history = useHistory();

	useEffect(() => {
		if (props.correctionNoteId) {
			(async () => {
				const api = await getEssityApiClient();
				const result = await api.pharmacyTaxNote.getById(
					props.correctionNoteId!
				);
				setNote(result);

				setCorrectionType(result.internalTaxNoteType);
				setLimitedEditMode(result.limitedEditMode!);
				if (result.data?.length) {
					const blob = new Blob([
						new Uint8Array(
							result.data,
							result.data.byteOffset,
							result.data.length
						),
					]);
					props.setAttachment(blob);
				}

				setOrders(result.orders as OrderDto[]);
			})();
		}
	}, [props.correctionNoteId]);

	useEffect(() => {
		(async () => {
			if (props.groupId && props.internalTaxNoteType) {
				const api = await getEssityApiClient();
				const result = await api.pharmacyTaxNote.getPharmacyTaxNotesPredictedToCorrection(
					{
						pharmacyTaxNoteGroupId: props.groupId!,
						internalTaxNoteType: props.internalTaxNoteType!,
					}
				);

				const withoutNote: PharmacyNotePredictedToCorrectionDto = {
					pharmacyTaxNoteId: 'withoutNote',
					noteNumber: 'without note',
				};

				const withoutNoteLoss: PharmacyNotePredictedToCorrectionDto = {
					pharmacyTaxNoteId: 'withoutNoteLoss',
					noteNumber: 'without note - loss',
				};

				const group: PharmacyTaxNoteGroupDto = await api.pharmacyTaxNoteGroup.getById(
					props.groupId
				);

				setState({
					...state,
					notes: [...result, withoutNote, withoutNoteLoss],
					group: group,
				});
			}
		})();
	}, [props.groupId, props.internalTaxNoteType]);

	const getMainPharmacyTaxNoteInternalNumber = (
		note: PharmacyTaxNoteDetailsDto | undefined
	) => {
		if (note?.mainPharmacyTaxNoteInternalNumber) {
			return note?.mainPharmacyTaxNoteInternalNumber;
		} else {
			if (note?.internalTaxNoteType === InternalTaxNoteType.Debit) {
				return 'without note';
			} else {
				return 'without note - loss';
			}
		}
	};

	const getInitialValues = () => {
		if (!props.editMode && !props.readOnly) {
			return {};
		}

		const month = moment(
			new Date(moment().year(), note?.month!, moment().day())
		).add(-1, 'month');

		const year = moment(
			new Date(note?.year!, moment().month(), moment().day())
		);

		return {
			pharmacyName: note?.pharmacy?.name,
			pharmacyId: note?.pharmacy?.id,
			placeId: note?.pharmacy?.placeId,
			noteDate: moment(note?.noteDate),
			month: month,
			year: year,
			internalNumber: note?.internalNumber,
			correctionNoteValue: note?.noteValue,
			ordersValue: note?.assignedOrdersValue,
			mainPharmacyTaxNoteInternalNumber: getMainPharmacyTaxNoteInternalNumber(
				note
			),
			internalTaxNoteType: note?.internalTaxNoteType,
			note: note?.note,
		};
	};
	
	const RenderNoteDetails = (formikProps: FormikProps<any>) => {
		return (
			<>
				<Row>
					<Col span={10}>
						{(props.readOnly || props.editMode) && (
							<>
								<FFieldLabel label="Pharmacy note" />
								<Field
									component={FInput}
									placeholder="Pharmacy note number"
									name="mainPharmacyTaxNoteInternalNumber"
									readOnly={true}
								/>
							</>
						)}
					</Col>
				</Row>
				<Row>
					{!props.readOnly && !props.editMode && (
						<Col span={10}>
							<FFieldLabel label="Choose pharmacy note" />
							<Field
								component={FSelect}
								placeholder="Pharmacy note"
								name="pharmacyNote"
								readOnly={
									props.readOnly ||
									props.editMode ||
									limitedEditMode
								}
								children={Array.from(state!.notes).map(
									(x, i) => (
										<Select.Option
											key={i}
											value={x.pharmacyTaxNoteId!}
										>
											{x.noteNumber}
										</Select.Option>
									)
								)}
								changeData={(noteId: string) => {
									const note = state?.notes.find(
										(x) => x.pharmacyTaxNoteId === noteId
									);

									if (noteId === 'withoutNote') {
										setCorrectionType(
											InternalTaxNoteType.Debit
										);

										formikProps.setFieldValue(
											'noteDate',
											moment()
										);

										formikProps.setFieldValue(
											'pharmacyName',
											state?.group?.pharmacyName
										);

										formikProps.setFieldValue(
											'pharmacyId',
											state?.group?.pharmacyId
										);

										formikProps.setFieldValue(
											'placeId',
											state?.group?.placeId
										);
									} else if (noteId === 'withoutNoteLoss') {
										setCorrectionType(
											InternalTaxNoteType.PayLoss
										);

										formikProps.setFieldValue(
											'noteDate',
											moment()
										);

										formikProps.setFieldValue(
											'pharmacyName',
											state?.group?.pharmacyName
										);

										formikProps.setFieldValue(
											'pharmacyId',
											state?.group?.pharmacyId
										);

										formikProps.setFieldValue(
											'placeId',
											state?.group?.placeId
										);
									} else if (note) {
										formikProps.setFieldValue(
											'pharmacyTaxNoteId',
											note.pharmacyTaxNoteId
										);
										formikProps.setFieldValue(
											'placeId',
											note.placeId
										);
										formikProps.setFieldValue(
											'internalNumber',
											note.predictedCorrectionInternalNumber
										);
										formikProps.setFieldValue(
											'pharmacyName',
											note.pharmacyName
										);

										const year = moment(
											new Date(
												note!.year!,
												moment().month(),
												moment().day()
											)
										);

										formikProps.setFieldValue('year', year);

										const month = moment(
											new Date(
												moment().year(),
												note.month!,
												moment().day()
											)
										).add(-1, 'month');

										formikProps.setFieldValue(
											'month',
											month
										);

										formikProps.setFieldValue(
											'noteDate',
											moment()
										);
										formikProps.setFieldValue(
											'correctionNoteValue',
											note.predictedCorrectionNoteValue
										);
										setCorrectionType(
											InternalTaxNoteType.Correction
										);
									} else {
										formikProps.resetForm();
										setCorrectionType(undefined);
									}
								}}
							/>
						</Col>
					)}
				</Row>
				<Row>
					<Col span={10}>
						<FFieldLabel label="Pharmacy name" />
						<Field
							component={FInput}
							placeholder="Pharmacy name"
							name="pharmacyName"
							readOnly={true}
						/>
					</Col>
				</Row>
				<Row>
					<Col span={10}>
						<FFieldLabel label="SAP ID" />
						<Field
							component={FInput}
							placeholder="SAP ID"
							name="placeId"
							readOnly={true}
						/>
					</Col>
				</Row>
				<Row>
					<Col span={10}>
						<FFieldLabel label="Note date" />
						<Field
							label="Note date"
							component={FDatePicker}
							name="noteDate"
							mode="date"
							style={{ width: '100%' }}
							disabled={props.readOnly || limitedEditMode}
							changeData={(value: any) => {}}
						/>
					</Col>
				</Row>
				{(props.readOnly || props.editMode) && (
					<Row>
						<Col span={10}>
							<FFieldLabel label="Internal number" />
							<Field
								component={FInput}
								changeData={(value: any) => {}}
								placeholder="Internal number"
								name="internalNumber"
								readOnly={true}
							/>
						</Col>
					</Row>
				)}

				<Row>
					<Col span={5} style={{ paddingRight: 5 }}>
						<FFieldLabel label="Month" />
						<Field
							component={FDatePicker}
							placeholder="Month"
							name="month"
							style={{ width: '100%' }}
							inputReadOnly={
								(correctionType !== InternalTaxNoteType.Debit &&
									correctionType !==
										InternalTaxNoteType.PayLoss) ||
								props.readOnly ||
								props.editMode ||
								limitedEditMode
							}
							picker="month"
							format={DateFormat.Month}
						/>
					</Col>
					<Col span={5} style={{ paddingLeft: 5 }}>
						<FFieldLabel label="Year" />
						<Field
							component={FDatePicker}
							placeholder="Year"
							name="year"
							style={{ width: '100%' }}
							inputReadOnly={
								(correctionType !== InternalTaxNoteType.Debit &&
									correctionType !==
										InternalTaxNoteType.PayLoss) ||
								props.readOnly ||
								props.editMode ||
								limitedEditMode
							}
							picker="year"
							format={DateFormat.Year}
						/>
					</Col>
				</Row>
				<Row>
					<Col span={10}>
						<FFieldLabel label="Correction note value" />
						<Field
							component={FInputNumber}
							min={Number.MIN_SAFE_INTEGER}
							placeholder="Correction note value"
							name="correctionNoteValue"
							readOnly={
								correctionType !==
									InternalTaxNoteType.PayLoss ||
								props.readOnly ||
								limitedEditMode
							}
						/>
					</Col>
				</Row>

				<Row>
					{correctionType === InternalTaxNoteType.Debit && (
						<Col span={10}>
							<FFieldLabel label="Orders value" />
							<Row gutter={6}>
								<Col span={14}>
									<Field
										component={FInputNumber}
										placeholder="Orders value"
										min={Number.MIN_SAFE_INTEGER}
										name="ordersValue"
										readOnly={true}
									/>
								</Col>
								<Col span={10}>
									<PharmacyNoteAddOrdersModal
										readOnly={
											(props.readOnly &&
												!props.editMode) ||
											limitedEditMode
										}
										year={formikProps.values.year}
										month={formikProps.values.month}
										pharmacyId={
											formikProps.values.pharmacyId
										}
										pharmacyTaxNoteId={
											props.correctionNoteId
										}
										onOrdersSelect={(
											orders: OrderDto[]
										) => {
											setOrders(orders);

											const refundSum = _.sum(
												orders?.map((x) => x.refund!)
											);

											formikProps.setFieldValue(
												'ordersValue',
												refundSum
											);

											const correctionSum = _.sum(
												orders
													?.filter(
														(x) =>
															x.orderState ===
															OrderState.SettledNhf
													)
													?.map((x) => x.refund!)
											);

											formikProps.setFieldValue(
												'correctionNoteValue',
												correctionSum
											);
										}}
										selectedOrders={orders}
									/>
								</Col>
							</Row>
						</Col>
					)}
				</Row>
				<Row>
					<Col span={16}>
						<FFieldLabel label="Note" />
						<Field
							component={FTextArea}
							placeholder="Note"
							style={{ resize: 'none' }}
							maxLength={5000}
							name="note"
							rows={5}
							maxRows={5}
							readOnly={props.readOnly && !props.editMode}
						/>
					</Col>
				</Row>
			</>
		);
	};

	const createOrUpdateNote = (values: any) => {
		setIsBusy(true);
		if (props.editMode) {
			const ordersIds = orders?.map((x) => x.id!);

			if (note!.internalTaxNoteType! === InternalTaxNoteType.PayLoss) {
				ajaxByUser('Successfully updated pay loss note.', () =>
					getEssityApiClient().then((api) =>
						api.pharmacyTaxNote
							.updatePayLoss({
								body: {
									pharmacyTaxNoteId: props.correctionNoteId,
									noteDate: moment(values.noteDate)
										.utc(true)
										.toDate(),
									noteValue: values.correctionNoteValue,
									note: values.note,
								},
							})
							.then((response: PharmacyTaxNoteUpdateResponse) => {
								history.push(
									`/pharmacyPayments/${response.pharmacyTaxNoteGroupId}/${response.pharmacyId}`
								);
							})
							.finally(() => setIsBusy(false))
					)
				);
			} else {
				ajaxByUser('Successfully updated correction note.', () =>
					getEssityApiClient().then((api) =>
						api.pharmacyTaxNote
							.updateCorrection({
								body: {
									pharmacyTaxNoteId: props.correctionNoteId,
									noteDate: moment(values.noteDate)
										.utc(true)
										.toDate(),
									pharmacyTaxNoteOrders: ordersIds,
									note: values.note,
								},
							})
							.then((response: PharmacyTaxNoteUpdateResponse) => {
								history.push(
									`/pharmacyPayments/${response.pharmacyTaxNoteGroupId}/${response.pharmacyId}`
								);
							})
							.finally(() => setIsBusy(false))
					)
				);
			}
		} else {
			if (correctionType === InternalTaxNoteType.Debit) {
				const ordersIds = orders?.map((x) => x.id!);

				ajaxByUser('Successfully added correction note.', () =>
					getEssityApiClient().then((api) =>
						api.pharmacyTaxNote
							.createDebitCorrection({
								body: {
									pharmacyId: values.pharmacyId,
									year: values.year.year(),
									month: values.month.month() + 1,
									noteDate:
										values.noteDate?.utc(true).toDate() ||
										undefined,
									pharmacyTaxNoteOrders: ordersIds,
									note: values.note,
								},
							})
							.then(
								(
									result: PharmacyTaxNoteCreateCorrectionResponse
								) => {
									if (result) {
										const dto = result as CreateNoteResponseDto;

										const path = `/pharmacyPayments/${dto.pharmacyTaxNoteGroupId}/${dto.pharmacyId}`;

										if (dto.canBeConfirmedBy) {
											Modal.confirm({
												centered: true,
												maskClosable: true,
												content: `Dear user,
									
												Note you are trying to save has the same value as return note
												Do you want to confirm the return by note: ${dto.canBeConfirmedBy} ? `,
												title: `Confirmation`,
												onOk: () => {
													confirmValue(
														dto.pharmacyTaxNoteId!,
														path
													);
												},
												onCancel: () => {
													history.push(path);
												},
											});
										} else {
											Modal.success({
												centered: true,
												maskClosable: true,
												title: `Created new correction note with number: ${dto.pharmacyTaxNoteIdInternalNumber}`,
												onOk: () => {
													history.push(path);
												},
												onCancel: () => {
													history.push(path);
												},
											});
										}
									}
								}
							)
							.finally(() => setIsBusy(false))
					)
				);
			} else if (correctionType === InternalTaxNoteType.PayLoss) {
				ajaxByUser('Successfully added correction note.', () =>
					getEssityApiClient().then((api) =>
						api.pharmacyTaxNote
							.createPayLoss({
								body: {
									pharmacyId: values.pharmacyId,
									year: values.year.year(),
									month: values.month.month() + 1,
									noteDate:
										values.noteDate?.utc(true).toDate() ||
										undefined,
									noteValue: values.correctionNoteValue,
									note: values.note,
								},
							})
							.then(
								(
									result: PharmacyTaxNoteCreateCorrectionResponse
								) => {
									if (result) {
										const dto = result as CreateNoteResponseDto;
										const path = `/pharmacyPayments/${dto.pharmacyTaxNoteGroupId}/${dto.pharmacyId}`;
										Modal.success({
											centered: true,
											maskClosable: true,
											title: `Created new correction note with number: ${dto.pharmacyTaxNoteIdInternalNumber}`,
											onOk: () => {
												history.push(path);
											},
											onCancel: () => {
												history.push(path);
											},
										});
									}
								}
							)
							.finally(() => setIsBusy(false))
					)
				);
			} else {
				ajaxByUser('Successfully added correction note.', () =>
					getEssityApiClient().then((api) =>
						api.pharmacyTaxNote
							.createCorrection({
								body: {
									pharmacyTaxNoteId: values.pharmacyTaxNoteId,
									noteDate:
										values.noteDate?.utc(true).toDate() ||
										undefined,
									note: values.note,
								},
							})
							.then(
								(
									result: PharmacyTaxNoteCreateCorrectionResponse
								) => {
									if (result) {
										const dto = result as CreateNoteResponseDto;
										const path = `/pharmacyPayments/${dto.pharmacyTaxNoteGroupId}/${dto.pharmacyId}`;

										if (dto.canBeConfirmedBy) {
											Modal.confirm({
												centered: true,
												maskClosable: true,
												content: `Dear user,
									
												Note you are trying to save has the same value as return note
												Do you want to confirm the return by note: ${dto.canBeConfirmedBy} ? `,
												title: `Confirmation`,
												onOk: () => {
													confirmValue(
														dto.pharmacyTaxNoteId!,
														path
													);
												},
												onCancel: () => {
													history.push(path);
												},
											});
										} else {
											Modal.success({
												centered: true,
												maskClosable: true,
												title: `Created new correction note with number: ${dto.pharmacyTaxNoteIdInternalNumber}`,
												onOk: () => {
													history.push(path);
												},
												onCancel: () => {
													history.push(path);
												},
											});
										}
									}
								}
							)
							.finally(() => setIsBusy(false))
					)
				);
			}
		}
	};

	const confirmValue = (pharmacyTaxNoteId: string, path: string): void => {
		setIsBusy(true);
		ajaxByUser('Successfully confirmed value.', () =>
			getEssityApiClient().then((api) =>
				api.pharmacyTaxNote
					.assignBalancedPaymentConfirmation({
						body: {
							pharmacyTaxNoteId: pharmacyTaxNoteId,
						},
					})
					.finally(() => {
						setIsBusy(false);
						history.push(path);
					})
			)
		);
	};

	return (
		<Space direction="vertical" className={styles.details}>
			<h2>Correction Note Details</h2>
			<Space direction="vertical" className={styles.form}>
				<Formik
					validateOnChange={false}
					validateOnBlur={true}
					initialValues={getInitialValues()}
					enableReinitialize
					validationSchema={
						correctionType === InternalTaxNoteType.Debit
							? CorrectionDebitNoteValidationSchema
							: getValidationSchema(props.editMode)
					}
					onSubmit={(values, actions: FormikActions<any>) =>
						createOrUpdateNote(values)
					}
					render={(formikProps: FormikProps<any>) => (
						<Space direction="vertical" style={{ width: '100%' }}>
							{state?.notes && RenderNoteDetails(formikProps)}
							<Space
								direction="horizontal"
								className={styles.actions}
							>
								<BackButton />
								<Button
									type="primary"
									shape="round"
									size="large"
									className={styles.save}
									disabled={props.readOnly || isBusy}
									onClick={() => formikProps.submitForm()}
								>
									Save
								</Button>
							</Space>
						</Space>
					)}
				></Formik>
			</Space>
		</Space>
	);
};
