import {
	EssityColumnProps,
	filterType,
} from '@components/shared/paginatedEssityTable/GridHelper';
import * as OrderStateMapper from '@services/mappers/OrderState';
import * as TaxNoteOrderStateMapper from '@services/mappers/TaxNoteOrderState';
import { OrderDto, OrderState, TaxNoteOrderState } from '@services/src/models';
import { Tag } from 'antd';
import React from 'react';

const GetTagByOrderState = (state: OrderState) => {
	return <Tag color="#31317a">{OrderStateMapper.map(state)}</Tag>;
};

const GetTagByPaymentState = (state: TaxNoteOrderState) => {
	return <Tag color="#5055cd">{TaxNoteOrderStateMapper.map(state)}</Tag>;
};

export const OrdersTableColumns: EssityColumnProps<OrderDto>[] = [
	{
		dataIndex: 'orderState',
		key: 'orderState',
		title: 'NHF Status',
		render: (state: OrderState) => GetTagByOrderState(state),
		width: 120,
		sorter: true,
		filter: 'enum' as filterType,
		enumMapper: OrderStateMapper,
		filterDictionary: OrderState,
	},
	{
		dataIndex: 'taxNoteOrderState',
		key: 'taxNoteOrderState',
		title: 'Payment Status',
		render: (state: TaxNoteOrderState) => GetTagByPaymentState(state),
		width: 135,
		sorter: true,
		filter: 'enum' as filterType,
		enumMapper: TaxNoteOrderStateMapper,
		filterDictionary: TaxNoteOrderState,
	},
	{
		hidden: true,
		dataIndex: 'id',
		key: 'id',
		title: 'Id',
		filter: 'equals' as filterType,
	},
	{
		sorter: true,
		dataIndex: 'internalNumber',
		key: 'internalNumber',
		title: 'Internal No.',
		width: 110,
	},
	{
		sorter: true,
		dataIndex: 'nhfRealizationId',
		key: 'nhfRealizationId',
		title: 'NHF Realization ID',
		width: 140,
		filter: 'equals' as filterType,
	},
	{
		sorter: true,
		dataIndex: 'realizationId',
		key: 'realizationId',
		title: 'Realization ID',
		width: 130,
		filter: 'equals' as filterType,
	},
	{
		sorter: true,
		dataIndex: 'orderNumber',
		key: 'orderNumber',
		title: 'Order number',
		filter: 'contains' as filterType,
		width: 150,
	},
	{
		sorter: true,
		dataIndex: 'pharmacyName',
		key: 'pharmacyName',
		title: 'Pharmacy',
		filter: 'equals' as filterType,
		width: 140,
	},
	{
		sorter: false,
		dataIndex: 'placeId',
		key: 'placeId',
		title: 'SAP ID',
		width: 100,
		filter: 'equals' as filterType,
	},
	{
		dataIndex: 'pesel',
		key: 'pesel',
		title: 'PESEL',
		filter: 'equals' as filterType,
		width: 120,
	},
	{
		sorter: true,
		dataIndex: 'refund',
		key: 'refund',
		title: 'Refund',
		width: 110,
	},
	{
		sorter: true,
		dataIndex: 'value',
		key: 'value',
		title: 'Value',
		width: 80,
	},
];
