import { FarmapromPharmaciesTableColumns } from '@components/farmapromPharmacies/FarmapromPharmaciesTableColumns';
import { GetNHFSettlementsTableColumns } from '@components/nhfSettlements/NHFSettlementsTableColumns';
import { OrderGroupsTableColumns } from '@components/orderGroups/OrderGroupsTableColumns';
import { OrdersTableColumns } from '@components/orders/OrdersTableColumns';
import { PharmacyNotesTableColumns } from '@components/pharmacyNotes/PharmacyNotesTableColumns';
import { SendingPaymentsTableColumns } from '@components/sendingPayments/SendingPaymentsTableColumns';
import { UMXPharmaciesTableColumns } from '@components/umx/UMXPharmaciesTableColumns';
import { EssityColumnProps } from 'components/shared/paginatedEssityTable/GridHelper';
import { MenuSectionType } from 'layout/Menu/MenuItems';
import { action, observable } from 'mobx';

export type GridNameType =
	| 'orderGroups'
	| 'orders'
	| 'productPrices'
	| 'pharmacies'
	| 'createdDocuments'
	| 'importedDocuments'
	| 'farmapromPharmacies'
	| 'UMXPharmacies'
	| 'nhfSettlements'
	| 'pharmaciesNoteGroups'
	| 'pharmacyNotes'
	| 'pharmaciesFinder'
	| 'noteOrders'
	| 'sendingPayments';

const tableEntriesInitializer: Array<
	[GridNameType, EssityColumnProps<any>[]]
> = [
	['orderGroups', OrderGroupsTableColumns],
	['orders', OrdersTableColumns],
	['farmapromPharmacies', FarmapromPharmaciesTableColumns],
	['UMXPharmacies', UMXPharmaciesTableColumns],
	['nhfSettlements', GetNHFSettlementsTableColumns()],
	['pharmacyNotes', PharmacyNotesTableColumns],
	['sendingPayments', SendingPaymentsTableColumns],
];

const sectionsMapper: Map<string, GridNameType[]> = new Map([
	['orderGroups', ['orderGroups']],
	['orders', ['orders']],
	['nhfSettlements', ['nhfSettlements']],
]);

const tables: Map<GridNameType, EssityColumnProps<any>[]> = new Map(
	tableEntriesInitializer
);

export const tableColumnStore = {
	tables: new Map<GridNameType, EssityColumnProps<any>[]>(tables),
	currentTable: observable.box<GridNameType | undefined>(undefined),

	applyOrder: (gridName: GridNameType, orderedKeys: string[]) => {
		const orderedColumns: EssityColumnProps<any>[] = [];
		const columnsMap: Map<string, EssityColumnProps<any>> = new Map();
		const tableColumns = tables.get(gridName);

		if (!tableColumns) return;

		tableColumns.forEach((column) =>
			columnsMap.set(column.key!.toString(), column)
		);
		orderedKeys.forEach((key) => {
			const nextItem = columnsMap.get(key);
			if (nextItem) {
				orderedColumns.push(nextItem);
			}
		});

		tableColumnStore.setTable(gridName, orderedColumns);
	},

	setTable: (gridName: GridNameType, columns: EssityColumnProps<any>[]) => {
		tableColumnStore.tables.set(gridName, columns);
	},
	getTable: (gridName: GridNameType): EssityColumnProps<any>[] => {
		return tableColumnStore.tables.get(gridName) ?? [];
	},

	setCurrentTable(gridName: GridNameType | undefined) {
		tableColumnStore.currentTable.set(gridName);
	},
	getCurrentTable: () => tableColumnStore.currentTable.get(),

	isWithinSection: action((section: MenuSectionType) => {
		return sectionsMapper
			.get(section)
			?.includes(tableColumnStore.getCurrentTable()!);
	}),
};

export const createTableColumnStore = () => {
	return tableColumnStore;
};

export type ITableColumnStore = ReturnType<typeof createTableColumnStore>;
