import { CloudDownloadOutlined } from '@ant-design/icons';
import { DownloadFile } from '@components/shared/file/FileDownloadFunctions';
import { EssityColumnProps } from '@components/shared/paginatedEssityTable/GridHelper';
import PaginatedEssityTable from '@components/shared/paginatedEssityTable/PaginatedEssityTable';
import { SieveModel } from '@components/shared/paginatedEssityTable/SieveModels';
import { TableWithTitle } from '@components/shared/TableWithTitle';
import { ajaxByUser } from '@helper/api';
import { getEssityApiClient } from '@services/EssityApi';
import {
	DocumentAllOptionalParams,
	DocumentCategory,
	SourceDocumentDto,
} from '@services/src/models';
import { Button, Divider, Tooltip } from 'antd';
import React from 'react';

import { CreatedDocumentsTableColumns } from './CreatedDocumentsTableColumns';

export const CreatedDocuments = () => {
	const columns: EssityColumnProps<SourceDocumentDto>[] = [
		...CreatedDocumentsTableColumns,
		{
			title: '',
			key: 'operation',
			fixed: 'right',
			width: 50,
			render: (item: SourceDocumentDto) => (
				<Tooltip title="Download">
					<Button
						type="primary"
						shape="circle"
						size="small"
						onClick={() =>
							ajaxByUser('Successfully downloaded file', () =>
								getEssityApiClient().then((api) =>
									api.document
										.getById(item.id!)
										.then((result) => {
											DownloadFile({
												response: result,
											});
										})
								)
							)
						}
						icon={<CloudDownloadOutlined />}
					/>
				</Tooltip>
			),
		},
	];

	return (
		<>
			<Divider />
			<TableWithTitle title="Created documents">
				<PaginatedEssityTable<SourceDocumentDto>
					columns={columns}
					gridName="createdDocuments"
					hasRowSelection={false}
					hidePersonalizationSettings={true}
					getRowKey={(r: SourceDocumentDto) => r.id!}
					getPagedResult={async (sieve: SieveModel, abortSignal) => {
						const parameters: DocumentAllOptionalParams = {
							page: sieve.page,
							pageSize: sieve.pageSize,
							sorts: sieve.sorts,
							abortSignal: abortSignal,
							filters: sieve.filters,
							documentCategory: DocumentCategory.EXPORT,
						};

						const api = await getEssityApiClient();
						return await api.document.all(parameters);
					}}
				/>
			</TableWithTitle>
		</>
	);
};
