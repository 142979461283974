import {
	EssityColumnProps,
	filterType,
} from '@components/shared/paginatedEssityTable/GridHelper';
import formatHelpers from '@helper/formatHelpers';
import * as PaymentStateMapper from '@services/mappers/PaymentState';
import { PaymentState, VoivodeshipPaymentGroupDto } from '@services/src/models';
import { Space } from 'antd';
import React from 'react';

import { IdentifiersModal } from './identifiers/IdentifiersModal';

export const SendingPaymentsTableColumns: EssityColumnProps<VoivodeshipPaymentGroupDto>[] = [
	{
		sorter: true,
		key: 'internalId',
		title: 'ID',
		dataIndex: 'internalId',
		width: 250,
		filter: 'equals' as filterType,
		render: (internalId: string, group: VoivodeshipPaymentGroupDto) => (
			<Space direction="horizontal">
				{internalId}
				{group.voivodeshipPaymentState === PaymentState.SentToSap && (
					<IdentifiersModal
						previousInternalIds={group.previousInternalIds}
					/>
				)}
			</Space>
		),
	},
	{
		sorter: true,
		dataIndex: 'month',
		key: 'month',
		title: 'Month',
		width: 110,
		filter: 'equals' as filterType,
	},
	{
		sorter: true,
		dataIndex: 'year',
		key: 'year',
		title: 'Year',
		width: 100,
		filter: 'equals' as filterType,
	},
	{
		sorter: true,
		dataIndex: 'amount',
		key: 'amount',
		title: 'Pharmacy Value',
	},
	{
		sorter: true,
		dataIndex: 'voivodeshipPaymentState',
		key: 'voivodeshipPaymentState',
		title: 'Status',
		width: 110,
		filter: 'enum' as filterType,
		enumMapper: PaymentStateMapper,
		filterDictionary: PaymentState,
		render: (value: PaymentState) => {
			return PaymentStateMapper.map(value);
		},
	},
	{
		sorter: true,
		dataIndex: 'sentDate',
		key: 'sentDate',
		title: 'Sent date',
		render: (value?: Date) => formatHelpers.formatDate(value),
	},
	{
		sorter: true,
		dataIndex: 'postingDate',
		key: 'postingDate',
		title: 'Posting date',
		render: (value?: Date) => formatHelpers.formatDate(value),
	},
	{
		sorter: true,
		dataIndex: 'paymentDate',
		key: 'paymentDate',
		title: 'Payment date',
		render: (value?: Date) => formatHelpers.formatDate(value),
	},
];
