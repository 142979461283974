import { Result } from 'antd';
import React from 'react';

interface IProps {
	message?: string;
}

export const DoneView = (props: IProps) => {
	const message = props.message || 'Successfully finished import.';

	return <Result status="success" title={message} />;
};
