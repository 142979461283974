import { Row } from "antd";
import { RowProps } from "antd/lib/row";
import React from "react";
export class CenteredRow extends React.Component<RowProps> {
    static defaultProps = {
        gutter: 16
    };
    public render() {
        return <Row className={"centered-row"} justify="center" {...this.props}>{this.props.children}</Row>;
    }
}
