import { EssityColumnProps } from '@components/shared/paginatedEssityTable/GridHelper';
import PaginatedEssityTable from '@components/shared/paginatedEssityTable/PaginatedEssityTable';
import { SieveModel } from '@components/shared/paginatedEssityTable/SieveModels';
import { useRootData } from '@hooks/hook';
import { getEssityApiClient } from '@services/EssityApi';
import { OrderDto } from '@services/src/models';
import React from 'react';
import { useHistory } from 'react-router';
import { ITableColumnStore } from 'stores/TableColumnStore';

import styles from '../styles/NHFSettlementsOrders.module.less';

interface IProps {
	templateId: string;
}

export const NHFSettlementsOrdersTable = (props: IProps) => {
	const gridName = 'orders';

	const tableColumnStore: ITableColumnStore = useRootData(
		(store) => store.tableColumnStore
	);

	const columns: EssityColumnProps<OrderDto>[] = [
		...tableColumnStore.getTable(gridName),
	];

	const history = useHistory();

	return (
		<PaginatedEssityTable<OrderDto>
			className={styles.table}
			columns={columns}
			gridName={gridName}
			hasRowSelection={false}
			hidePersonalizationSettings={true}
			getRowKey={(r: OrderDto) => r.id!}
			onRow={(record: OrderDto) => {
				return {
					onDoubleClick: () => {
						history.push(`/order/${record.id}`);
					},
				};
			}}
			getPagedResult={async (sieve: SieveModel, abortSignal) => {
				const api = await getEssityApiClient();
				return await api.templateNote.orders({
					templateId: props.templateId,
					page: sieve.page,
					pageSize: sieve.pageSize,
					sorts: sieve.sorts,
					abortSignal: abortSignal,
					filters: sieve.filters,
				});
			}}
		/>
	);
};
