import { EssityColumnProps } from '@components/shared/paginatedEssityTable/GridHelper';
import { AccountDto } from '@services/src/models';
import EssityTable from 'layout/EssityTable';
import React from 'react';

import { DeleteAccountAction } from './AccountsTableActions';
import { AccountsTableColumns } from './AccountsTableColumns';
import styles from './styles/AccountsManagement.module.less';

interface IProps {
	dataSource?: AccountDto[];
	isBusy: boolean;
	onDeleteAction: (accountId: string) => void;
}

export const AccountsTable = (props: IProps) => {
	const columns: EssityColumnProps<AccountDto>[] = [
		...AccountsTableColumns,
		DeleteAccountAction(props.onDeleteAction),
	];

	return (
		<EssityTable
			columns={columns}
			className={styles.table}
			loading={props.isBusy}
			dataSource={props.dataSource}
			pagination={false}
		/>
	);
};
