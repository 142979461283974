import { IsolatedModal } from '@components/shared/modals/IsolatedModal';
import { ajaxByUser } from '@helper/api';
import { getEssityApiClient } from '@services/EssityApi';
import {
	AssignPaymentConfirmationCommand,
	PharmacyTaxNoteDto,
} from '@services/src/models';
import { Button, Col, Divider, Modal, Row } from 'antd';
import { Field, Formik, FormikActions, FormikProps } from 'formik';
import { FDatePicker, FFieldLabel, FInputNumber } from 'forms/FormikFormItems';
import moment from 'moment';
import React, { useState } from 'react';
import { AssignPaymentConfirmationValidationSchema } from './AssignPaymentConfirmationValidationSchema';

interface IProps {
	taxNote: PharmacyTaxNoteDto;
	refresh: () => void;
}

export const AddPaymentConfirmationModal = (props: IProps) => {
	const [visible, setVisible] = useState<boolean>();

	const handleCancel = () => {
		setVisible(false);
	};

	const isOverpay = (values: AssignPaymentConfirmationCommand): boolean => {
		const returnedSum = props.taxNote!.returnedValue! + values.amount!;
		return returnedSum > props.taxNote.noteValue! * -1;
	};

	const confirmValue = (values: AssignPaymentConfirmationCommand): void => {
		ajaxByUser('Successfully confirmed value.', () =>
			getEssityApiClient().then((api) =>
				api.pharmacyTaxNotePaymentConfirmation
					.assignPaymentConfirmation({
						body: {
							pharmacyTaxNoteId: props.taxNote.id,
							amount: values.amount,
							confirmationDate: moment(
								values.confirmationDate
							).toDate(),
						},
					})
					.then(() => {
						props.refresh();
						setVisible(false);
					})
			)
		);
	};

	return (
		<>
			<Row>
				<Col span={10}>
					<Button
						type="primary"
						shape="round"
						size="middle"
						style={{
							marginTop: 10,
						}}
						onClick={() =>
							setVisible(true)
						}
					>
						Add record
					</Button>
				</Col>
			</Row>

			{
				visible && (
					<IsolatedModal
						title="Add new confirmation"
						visible={visible}
						width="23vw"
						centered
						cancelButtonProps={{ hidden: true }}
						okButtonProps={{ hidden: true }}
						maskClosable={false}
						onCancel={handleCancel}
						closable={true}
						destroyOnClose
					>
						<Formik
							validateOnChange={false}
							validateOnBlur={true}
							initialValues={{}}
							enableReinitialize
							validationSchema={AssignPaymentConfirmationValidationSchema()}
							onSubmit={(
								values: AssignPaymentConfirmationCommand,
								actions: FormikActions<AssignPaymentConfirmationCommand>
							) => {
								if (isOverpay(values)) {
									Modal.confirm({
										centered: true,
										maskClosable: true,
										okText: 'Confirm',
										title: `Confirmation value is greater than return value. Are you sure you want to confirm that value?`,
										onOk: () => {
											confirmValue(values);
										},
										onCancel: () => { },
									});
								} else {
									confirmValue(values);
								}
							}}
							render={(
								formikProps: FormikProps<AssignPaymentConfirmationCommand>
							) => (
								<>

									<Divider />
									<Row>
										<Col span={23}>
											<FFieldLabel label="Returned value" />
											<Field
												component={FInputNumber}
												min={Number.MIN_SAFE_INTEGER}
												placeholder="Returned value"
												name="amount"
												readOnly={false}
											/>
										</Col>

										<Col span={23}>
											<FFieldLabel label="Returned date" />
											<Field
												component={FDatePicker}
												placeholder="Returned date"
												name="confirmationDate"
												readOnly={false}
												style={{ width: '100%' }}
											/>
										</Col>
									</Row>
									<Row>
										<Col span={23}>
											<Button
												type="primary"
												shape="round"
												size="large"
												style={{
													width: '100%',
													marginTop: 10,
												}}
												onClick={() => {
													formikProps.submitForm()
													props.refresh();
												}
												}
											>
												Confirm
											</Button>
										</Col>
									</Row>
								</>
							)}
						></Formik>
					</IsolatedModal>
				)
			}
		</>
	);
};
