import { ArrowUpOutlined } from '@ant-design/icons';
import { DownloadFileFromArray } from '@components/shared/file/FileDownloadFunctions';
import { DoneView } from '@components/shared/import/DoneView';
import { ExceptionView } from '@components/shared/import/ExceptionView';
import { LoadingView } from '@components/shared/import/LoadingView';
import { IsolatedContainer } from '@components/shared/IsolatedContainer';
import { IsolatedModal } from '@components/shared/modals/IsolatedModal';
import { ImportStatus } from '@components/shared/types/importStatus';
import { ajaxCatch } from '@helper/api';
import { DictionariesConstants } from '@services/DictionariesConstants';
import { getEssityApiClient } from '@services/EssityApi';
import {
	ExportModelGetZPOSPListOptionalParams,
	ExportModelGetZPOSPListResponse,
	FileType,
	OrderGroupDto,
} from '@services/src/models';
import { Button, Col, Modal } from 'antd';
import { Formik, FormikActions, FormikProps } from 'formik';
import { useRootData } from 'hooks/hook';
import { CenteredRow } from 'layout/CenteredRow';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { IDictionaryStore } from 'stores/DictionaryStore';
import { IGridStore } from 'stores/GridStore';

import { IsOldProcess } from './OrderGroupHelper';

const ExportCorrectionFileModal = () => {
	const [visible, setVisible] = useState<boolean>(false);

	const [progressState, setProgressState] = useState<ImportStatus>(
		ImportStatus.Init
	);

	const gridStore: IGridStore = useRootData((store) => store.gridStore);

	const dictionaryStore: IDictionaryStore = useRootData(
		(store) => store.dictionaryStore
	);

	const handleCancel = () => {
		setVisible(false);
		setProgressState(ImportStatus.Init);
	};

	const InitialView = (
		props: FormikProps<ExportModelGetZPOSPListOptionalParams>
	) => (
		<CenteredRow style={{ textAlign: 'center' }}>
			<Col span={24}>
				<h3>Correction file will include:</h3>
			</Col>
			<Col span={24}>
				{gridStore
					.getSelectedValues()
					.filter(
						(x: OrderGroupDto) =>
							x.toCorrectReportQty && x.toCorrectReportQty > 0
					)
					.map((x: OrderGroupDto) => {
						return (
							<p key={x.id}>
								<b>
									{dictionaryStore.getValueById(
										DictionariesConstants.Voivodeships,
										(x.voivodeshipId as unknown) as number
									)}
								</b>{' '}
								(Voivodeship) <b>{x.toCorrectReportQty}</b> (To
								correct quantity)
							</p>
						);
					})}
			</Col>
			<Button
				type="primary"
				size="large"
				shape="round"
				onClick={() => {
					if (!props.isSubmitting) {
						props.submitForm();
					}
				}}
			>
				Get correction file
			</Button>
		</CenteredRow>
	);

	const handleRender = (
		props: FormikProps<ExportModelGetZPOSPListOptionalParams>
	) => {
		return (
			<IsolatedModal
				title="Correction file"
				visible={visible}
				onCancel={handleCancel}
			>
				{getViewByExportStatus(props)}
			</IsolatedModal>
		);
	};

	const getViewByExportStatus = (
		props: FormikProps<ExportModelGetZPOSPListOptionalParams>
	) => {
		switch (progressState) {
			case ImportStatus.Init:
				return <InitialView {...props} />;
			case ImportStatus.Done:
				return <DoneView message="Successfully finished export." />;
			case ImportStatus.Exception:
				return (
					<ExceptionView
						title="Export error"
						message="Please contact with administrator."
					/>
				);
			case ImportStatus.Loading:
				return (
					<LoadingView message="Export file in progress, please wait..." />
				);
		}
	};

	const handleExport = (
		values: any,
		actions: FormikActions<ExportModelGetZPOSPListOptionalParams>
	) => {
		setProgressState(ImportStatus.Loading);

		const selectedValues: OrderGroupDto[] = gridStore.getSelectedValues();

		const idsToExport = selectedValues
			.filter((x) => x.toCorrectReportQty && x.toCorrectReportQty > 0)
			.map((x) => x.id);

		ajaxCatch(
			() =>
				getEssityApiClient().then((api) =>
					api.exportModel.getZPOSPList({
						fileType: 'XML' as FileType,
						checked: idsToExport as string[],
						correctReport: true,
					})
				),
			(result: ExportModelGetZPOSPListResponse) => {
				setProgressState(ImportStatus.Done);
				DownloadFileFromArray(result);
				gridStore.searching.set(true);
			},
			() => {
				setProgressState(ImportStatus.Exception);
			}
		);

		actions.setSubmitting(false);
		gridStore.setSelectedValues([]);
		gridStore.setSelectedKeys([]);
		gridStore.allRowsSelected.set(false);
	};

	const isExportEnabled = () => {
		return (
			gridStore.selectedKeys.length > 0 &&
			gridStore.selectedValues.filter(
				(x: OrderGroupDto) =>
					x.toCorrectReportQty &&
					x.toCorrectReportQty > 0 &&
					IsOldProcess(x)
			).length > 0
		);
	};

	return (
		<>
			<Button
				type="primary"
				shape="round"
				size="large"
				onClick={() => setVisible(true)}
				disabled={!isExportEnabled()}
			>
				Correction file
				<ArrowUpOutlined />
			</Button>
			<Formik
				validateOnChange
				validateOnBlur
				enableReinitialize
				isInitialValid={false}
				initialValues={{}}
				onSubmit={(
					values,
					actions: FormikActions<ExportModelGetZPOSPListOptionalParams>
				) => handleExport(values, actions)}
				render={handleRender}
			/>
		</>
	);
};

export default observer(ExportCorrectionFileModal);
