import { SelectOutlined } from '@ant-design/icons';
import { EssityColumnProps } from '@components/shared/paginatedEssityTable/GridHelper';
import PaginatedEssityTable from '@components/shared/paginatedEssityTable/PaginatedEssityTable';
import { SieveModel } from '@components/shared/paginatedEssityTable/SieveModels';
import { useRootData } from '@hooks/hook';
import { getEssityApiClient } from '@services/EssityApi';
import {
	UmxPharmacyAllOptionalParams,
	UmxPharmacyDto,
} from '@services/src/models';
import { Button, Tooltip } from 'antd';
import React from 'react';
import { IGridStore } from 'stores/GridStore';
import { ITableColumnStore } from 'stores/TableColumnStore';

const gridName = 'UMXPharmacies';

interface IProps {
	onPharmacySelect: (pharmacy: UmxPharmacyDto) => void;
	voivodeshipId: number;
}

export const UMXPharmaciesTable = (props: IProps) => {
	const gridStore: IGridStore = useRootData((store) => store.gridStore);

	const tableColumnStore: ITableColumnStore = useRootData(
		(store) => store.tableColumnStore
	);

	const columns: EssityColumnProps<UmxPharmacyDto>[] = [
		...tableColumnStore.getTable(gridName),
		{
			title: '',
			key: 'operation',
			fixed: 'right',
			width: 50,
			render: (item: UmxPharmacyDto) => (
				<Tooltip title="Select">
					<Button
						type="primary"
						shape="circle"
						size="small"
						onClick={() => {
							props.onPharmacySelect(item);
							gridStore.resetGrid(gridName);
						}}
						icon={<SelectOutlined />}
					/>
				</Tooltip>
			),
		},
	];

	return (
		<PaginatedEssityTable<UmxPharmacyDto>
			columns={columns}
			gridName={gridName}
			hasRowSelection={false}
			hidePersonalizationSettings={true}
			getRowKey={(r: UmxPharmacyDto) => r.id!}
			getPagedResult={async (sieve: SieveModel, abortSignal) => {
				const parameters: UmxPharmacyAllOptionalParams = {
					page: sieve.page,
					pageSize: sieve.pageSize,
					sorts: sieve.sorts,
					abortSignal: abortSignal,
					filters: sieve.filters,
					voivodeshipId: props.voivodeshipId,
				};

				const api = await getEssityApiClient();
				return await api.umxPharmacy.all(parameters);
			}}
		/>
	);
};
