/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from "@azure/ms-rest-js";
import * as Models from "../models";
import * as Mappers from "../models/umxPharmacyMappers";
import * as Parameters from "../models/parameters";
import { PolandPharmacyRxAPIContext } from "../polandPharmacyRxAPIContext";

/** Class representing a UmxPharmacy. */
export class UmxPharmacy {
  private readonly client: PolandPharmacyRxAPIContext;

  /**
   * Create a UmxPharmacy.
   * @param {PolandPharmacyRxAPIContext} client Reference to the service client.
   */
  constructor(client: PolandPharmacyRxAPIContext) {
    this.client = client;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.UmxPharmacyAllResponse>
   */
  all(options?: Models.UmxPharmacyAllOptionalParams): Promise<Models.UmxPharmacyAllResponse>;
  /**
   * @param callback The callback
   */
  all(callback: msRest.ServiceCallback<Models.UmxPharmacyDtoPagedResult>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  all(options: Models.UmxPharmacyAllOptionalParams, callback: msRest.ServiceCallback<Models.UmxPharmacyDtoPagedResult>): void;
  all(options?: Models.UmxPharmacyAllOptionalParams | msRest.ServiceCallback<Models.UmxPharmacyDtoPagedResult>, callback?: msRest.ServiceCallback<Models.UmxPharmacyDtoPagedResult>): Promise<Models.UmxPharmacyAllResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      allOperationSpec,
      callback) as Promise<Models.UmxPharmacyAllResponse>;
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<Models.UmxPharmacyGetByIdResponse>
   */
  getById(id: string, options?: msRest.RequestOptionsBase): Promise<Models.UmxPharmacyGetByIdResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  getById(id: string, callback: msRest.ServiceCallback<Models.UmxPharmacyDto>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  getById(id: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.UmxPharmacyDto>): void;
  getById(id: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.UmxPharmacyDto>, callback?: msRest.ServiceCallback<Models.UmxPharmacyDto>): Promise<Models.UmxPharmacyGetByIdResponse> {
    return this.client.sendOperationRequest(
      {
        id,
        options
      },
      getByIdOperationSpec,
      callback) as Promise<Models.UmxPharmacyGetByIdResponse>;
  }
}

// Operation Specifications
const serializer = new msRest.Serializer(Mappers);
const allOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "umxPharmacy/UmxPharmacy",
  queryParameters: [
    Parameters.filters,
    Parameters.sorts,
    Parameters.page,
    Parameters.pageSize,
    Parameters.voivodeshipId1
  ],
  responses: {
    200: {
      bodyMapper: Mappers.UmxPharmacyDtoPagedResult
    },
    default: {}
  },
  serializer
};

const getByIdOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "umxPharmacy/UmxPharmacy/{id}",
  urlParameters: [
    Parameters.id1
  ],
  responses: {
    200: {
      bodyMapper: Mappers.UmxPharmacyDto
    },
    default: {}
  },
  serializer
};
