import { Tooltip } from 'antd';
import { TooltipProps } from 'antd/lib/tooltip';
import React from 'react';

interface IProps {}
export type EssityTooltipProps = IProps & TooltipProps;

const EssityTooltip: React.FC<React.PropsWithChildren<
	EssityTooltipProps
>> = (props) => {
	const { trigger, children, ...restProps } = props;

	return (
		<Tooltip {...restProps} trigger={trigger} mouseEnterDelay={0.7}>
			{children}
		</Tooltip>
	);
};

EssityTooltip.defaultProps = {
	trigger: ['hover', 'focus', 'click'],
};

export default EssityTooltip;
