import { EssityColumnProps } from '@components/shared/paginatedEssityTable/GridHelper';
import { MissingPharmacyDto } from '@services/src/models';

export const ImportUMXValidationTableColumns: EssityColumnProps<MissingPharmacyDto>[] = [
	{
		dataIndex: 'id',
		key: 'id',
		title: 'ID',
	},
	{
		dataIndex: 'name',
		key: 'name',
		title: 'Name',
	},
];
