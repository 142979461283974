import styles from './styles/PriceLists.module.less';

import React, { useState } from 'react';

import PriceListsMenu from './PriceListsMenu';
import { ProductPricesTable } from './ProductPricesTable';

export const PriceLists = () => {
	const [selectedPriceListId, setSelectedPriceListId] = useState<string>();

	return (
		<div className={styles.priceLists}>
			<PriceListsMenu
				setSelectedPriceListId={(value) =>
					setSelectedPriceListId(value)
				}
			/>

			<ProductPricesTable selectedPriceListId={selectedPriceListId} />
		</div>
	);
};
