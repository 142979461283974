/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from "@azure/ms-rest-js";
import * as Models from "../models";
import * as Mappers from "../models/voivodeshipMappers";
import * as Parameters from "../models/parameters";
import { PolandPharmacyRxAPIContext } from "../polandPharmacyRxAPIContext";

/** Class representing a Voivodeship. */
export class Voivodeship {
  private readonly client: PolandPharmacyRxAPIContext;

  /**
   * Create a Voivodeship.
   * @param {PolandPharmacyRxAPIContext} client Reference to the service client.
   */
  constructor(client: PolandPharmacyRxAPIContext) {
    this.client = client;
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<Models.VoivodeshipGetByIdResponse>
   */
  getById(id: string, options?: msRest.RequestOptionsBase): Promise<Models.VoivodeshipGetByIdResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  getById(id: string, callback: msRest.ServiceCallback<Models.VoivodeshipDetailsDto>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  getById(id: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.VoivodeshipDetailsDto>): void;
  getById(id: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.VoivodeshipDetailsDto>, callback?: msRest.ServiceCallback<Models.VoivodeshipDetailsDto>): Promise<Models.VoivodeshipGetByIdResponse> {
    return this.client.sendOperationRequest(
      {
        id,
        options
      },
      getByIdOperationSpec,
      callback) as Promise<Models.VoivodeshipGetByIdResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.VoivodeshipAllResponse>
   */
  all(options?: msRest.RequestOptionsBase): Promise<Models.VoivodeshipAllResponse>;
  /**
   * @param callback The callback
   */
  all(callback: msRest.ServiceCallback<Models.VoivodeshipDetailsDto[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  all(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.VoivodeshipDetailsDto[]>): void;
  all(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.VoivodeshipDetailsDto[]>, callback?: msRest.ServiceCallback<Models.VoivodeshipDetailsDto[]>): Promise<Models.VoivodeshipAllResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      allOperationSpec,
      callback) as Promise<Models.VoivodeshipAllResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  update(options?: Models.VoivodeshipUpdateOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  update(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  update(options: Models.VoivodeshipUpdateOptionalParams, callback: msRest.ServiceCallback<void>): void;
  update(options?: Models.VoivodeshipUpdateOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      updateOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.VoivodeshipGetVoivodeshipIdentifiersResponse>
   */
  getVoivodeshipIdentifiers(options?: Models.VoivodeshipGetVoivodeshipIdentifiersOptionalParams): Promise<Models.VoivodeshipGetVoivodeshipIdentifiersResponse>;
  /**
   * @param callback The callback
   */
  getVoivodeshipIdentifiers(callback: msRest.ServiceCallback<Models.VoivodeshipIdentifierDto[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getVoivodeshipIdentifiers(options: Models.VoivodeshipGetVoivodeshipIdentifiersOptionalParams, callback: msRest.ServiceCallback<Models.VoivodeshipIdentifierDto[]>): void;
  getVoivodeshipIdentifiers(options?: Models.VoivodeshipGetVoivodeshipIdentifiersOptionalParams | msRest.ServiceCallback<Models.VoivodeshipIdentifierDto[]>, callback?: msRest.ServiceCallback<Models.VoivodeshipIdentifierDto[]>): Promise<Models.VoivodeshipGetVoivodeshipIdentifiersResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      getVoivodeshipIdentifiersOperationSpec,
      callback) as Promise<Models.VoivodeshipGetVoivodeshipIdentifiersResponse>;
  }
}

// Operation Specifications
const serializer = new msRest.Serializer(Mappers);
const getByIdOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "voivodeships/Voivodeship/{id}",
  urlParameters: [
    Parameters.id1
  ],
  responses: {
    200: {
      bodyMapper: Mappers.VoivodeshipDetailsDto
    },
    default: {}
  },
  serializer
};

const allOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "voivodeships/Voivodeship",
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "VoivodeshipDetailsDto"
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const updateOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "voivodeships/Voivodeship",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.UpdateVoivodeshipCommand
  },
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const getVoivodeshipIdentifiersOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "voivodeships/Voivodeship/identifiers",
  queryParameters: [
    Parameters.voivodeshipId1,
    Parameters.kodKom
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "VoivodeshipIdentifierDto"
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};
