import { EssityColumnProps } from '@components/shared/paginatedEssityTable/GridHelper';
import formatHelpers from '@helper/formatHelpers';

export const IdentifiersTableColumns: EssityColumnProps<any>[] = [
	{
        sorter: false,
		dataIndex: 'internalId',
		key: 'internalId',
		title: 'ID',
	},
	{
		sorter: false,
		dataIndex: 'postingDate',
		key: 'postingDate',
		title: 'Posting date',
		render: (value?: Date) => formatHelpers.formatDate(value),
	},
];
