import {
	AutocompleteSearch,
	ISearchResult,
} from '@components/shared/AutocompleteSearch';
import { useRootData } from '@hooks/hook';
import { getEssityApiClient } from '@services/EssityApi';
import { ProductPriceSearchDto } from '@services/src/models';
import React from 'react';
import { IGridStore } from 'stores/GridStore';

import styles from './styles/PriceLists.module.less';
interface IProps {
	selectedPriceListId: string;
}

export const ProductPricesSearch = (props: IProps) => {
	const gridStore: IGridStore = useRootData((store) => store.gridStore);

	const searchFunction = async (
		searchText: string
	): Promise<ISearchResult[]> => {
		if (searchText.length >= 3 && props.selectedPriceListId) {
			const api = await getEssityApiClient();
			const results: ProductPriceSearchDto[] = await api.price.search({
				searchText: searchText,
				priceListId: props.selectedPriceListId,
			});

			if (results) {
				const mapped = results.map((x: ProductPriceSearchDto) => {
					return {
						id: x.id!,
						value: `${x.productName} (${x.productId})`,
					};
				});

				if (mapped) {
					return mapped;
				}
			}
		} else if (searchText.length === 0) {
			gridStore.clearColumnFilter('productPrices', 'id');
			gridStore.searching.set(true);
		}

		return [];
	};

	return (
		<AutocompleteSearch
			onSelect={(value: string, object: any) => {
				gridStore.saveColumnFilter('productPrices', 'id', object.id);
				gridStore.searching.set(true);
			}}
			onClear={() => {
				gridStore.clearColumnFilter('productPrices', 'id');
				gridStore.searching.set(true);
			}}
			timeout={600}
			searchFunction={searchFunction}
			className={styles.searchBar}
			placeholder="Enter NHF Code or Name to search..."
		/>
	);
};
