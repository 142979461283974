import { IsolatedModal } from '@components/shared/modals/IsolatedModal';
import { ajaxByUser } from '@helper/api';
import { getEssityApiClient } from '@services/EssityApi';
import {
	PharmacyTaxNotePaymentConfirmationDto,
	UpdatePharmacyTaxNotePaymentConfirmationCommand,
} from '@services/src/models';
import { Button } from 'antd';
import { Field, Formik, FormikActions, FormikProps } from 'formik';
import {
	FDatePicker,
	FFieldLabel,
	FInputNumber,
} from 'forms/FormikFormItems';
import { CenteredRow } from 'layout/CenteredRow';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { AssignPaymentConfirmationValidationSchema } from './AssignPaymentConfirmationValidationSchema';

interface IProps {
	refresh: () => void;
	paymentConfirmation: PharmacyTaxNotePaymentConfirmationDto;
}

export const EditPaymentConfirmationModal = (props: IProps) => {
	const [visible, setVisible] = useState<boolean>();
	const [paymentConfirmation, setPaymentConfirmation] =
		useState<PharmacyTaxNotePaymentConfirmationDto>();

	useEffect(() => {
		if (visible) {
			setPaymentConfirmation(props.paymentConfirmation);
		}
	}, [visible]);

	const handleCancel = () => {
		setVisible(false);
	};

	const handleUpdate = (
		values: UpdatePharmacyTaxNotePaymentConfirmationCommand,
		actions: FormikActions<UpdatePharmacyTaxNotePaymentConfirmationCommand>
	) => {
		ajaxByUser('Successfully updated payment confirmation.', () =>
			getEssityApiClient().then((api) =>
				api.pharmacyTaxNotePaymentConfirmation
					.updatePaymentConfirmation({
						body: {
							pharmacyTaxNotePaymentConfirmationId: props.paymentConfirmation.id,
							confirmationDate: moment(values.confirmationDate).toDate(),
							amount: values.amount,

						},
					})
					.then(() => {
						setVisible(false);
						props.refresh();
						actions.resetForm();
					})
			)
		);
	};

	const getInitialValues = () => {
		return {
			id: paymentConfirmation?.id,
			confirmationDate: moment(paymentConfirmation?.confirmationDate),
			amount: paymentConfirmation?.amount,
		};
	};

	return (
		<>
			<Button
				type="primary"
				shape="round"
				size="small"
				ghost
				onClick={() => {
					setVisible(true);
				}}
			>
				Edit
			</Button>
			{visible && paymentConfirmation && (
				<IsolatedModal
					title="Edit payment confirmation"
					visible={visible}
					width="23vw"
					centered
					cancelButtonProps={{ hidden: true }}
					okButtonProps={{ hidden: true }}
					maskClosable={false}
					onCancel={handleCancel}
					closable={true}
					destroyOnClose
				>
					<Formik
						validateOnChange={false}
						validateOnBlur={true}
						initialValues={getInitialValues()}
						enableReinitialize
						validationSchema={AssignPaymentConfirmationValidationSchema}
						onSubmit={(values: any, actions: FormikActions<any>) =>
							handleUpdate(values, actions)
						}
						render={(formikProps: FormikProps<any>) => (
							<>


								<FFieldLabel label="Value" />

								<FFieldLabel label="Returned value" />
								<Field
									component={FInputNumber}
									min={Number.MIN_SAFE_INTEGER}
									placeholder="Returned value"
									name="amount"
									readOnly={false}
								/>

								<FFieldLabel label="Returned date" />
								<Field
									component={FDatePicker}
									placeholder="Returned date"
									name="confirmationDate"
									readOnly={false}
									style={{ width: '100%' }}
								/>


								<CenteredRow style={{ marginTop: '15px' }}>
									<Button
										type="primary"
										size="large"
										shape="round"
										onClick={() => formikProps.submitForm()}
									>
										Save
									</Button>
								</CenteredRow>
							</>
						)}
					></Formik>
				</IsolatedModal>
			)}
		</>
	);
};
