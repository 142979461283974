import { IsolatedModal } from '@components/shared/modals/IsolatedModal';
import { useRootData } from '@hooks/hook';
import { UmxPharmacyDto } from '@services/src/models';
import { Button, Modal } from 'antd';
import React, { useState } from 'react';
import { IGridStore } from 'stores/GridStore';

import { UMXPharmaciesTable } from './UMXPharmaciesTable';

interface IProps {
	disabled?: boolean;
	onPharmacySelect: (pharmacy: UmxPharmacyDto) => void;
	voivodeshipId: number;
}

export const UMXPharmaciesModal = (props: IProps) => {
	const [visible, setVisible] = useState<boolean>();

	const gridStore: IGridStore = useRootData((store) => store.gridStore);

	const handleCancel = () => {
		setVisible(false);
		gridStore.resetGrid('UMXPharmacies');
	};

	return (
		<>
			<Button
				type="primary"
				shape="round"
				size="small"
				style={{ width: '125px' }}
				disabled={props.disabled}
				onClick={() => {
					setVisible(true);
				}}
			>
				UMX view
			</Button>
			{visible && (
				<IsolatedModal
					title="UMX pharmacies"
					visible={visible}
					width="70vw"
					centered
					cancelButtonProps={{ hidden: true }}
					okButtonProps={{ hidden: true }}
					maskClosable={false}
					onCancel={handleCancel}
					closable={true}
					destroyOnClose
				>
					<UMXPharmaciesTable
						onPharmacySelect={(pharmacy: UmxPharmacyDto) => {
							props.onPharmacySelect(pharmacy);
							setVisible(false);
						}}
						voivodeshipId={props.voivodeshipId}
					/>
				</IsolatedModal>
			)}
		</>
	);
};
