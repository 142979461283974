import * as Yup from 'yup';

export const PharmacyValidationSchema = () =>
	Yup.object().shape({
		name: Yup.string().required('Pharmacy name is required.'),
		pharmacyChain: Yup.string().nullable(),
		taxNumber: Yup.string()
			.required('NIP is required.')
			.length(10, 'NIP must be 10 characters long.')
			.test('validNIP', 'NIP is invalid.', IsValidNIP)
			.nullable(),
		iban: Yup.string()
			.min(26, 'IBAN must be at least 26 characters long.')
			.max(30, 'IBAN can have maximum 30 characters long.')
			.required('IBAN is required.')
			.nullable(),
		placeId: Yup.number().nullable(),
		healthFundID: Yup.string().nullable(),
		dateOfJoiningEssityNet: Yup.date().nullable(),
		endDateOfEssityContract: Yup.date().nullable(),
		essityPaperContractNumber: Yup.string().nullable(),
		login: Yup.string().nullable(),
		businessContinuity: Yup.string().nullable(),
		city: Yup.string().nullable(),
		mail: Yup.string().email('Email is invalid.').nullable(),
		street: Yup.string().nullable(),
		phoneNumber: Yup.string().nullable(),
		salesRepresentative: Yup.string().nullable(),
		postalCode: Yup.string().nullable(),
		district: Yup.number().nullable(),
	});

const IsValidNIP = (value: string): boolean => {
	if (!value) {
		return false;
	}

	value = value.replace(/[\ \-]/gi, '');

	const weight = [6, 5, 7, 2, 3, 4, 5, 6, 7];

	let sum = 0;

	let controlNumber = parseInt(value.substring(9, 10));
	let weightCount = weight.length;
	for (let i = 0; i < weightCount; i++) {
		sum += parseInt(value.substr(i, 1)) * weight[i];
	}

	return sum % 11 === controlNumber;
};
