import { IsolatedModal } from '@components/shared/modals/IsolatedModal';
import { VoivodeshipContractDto } from '@services/src/models';
import { Button } from 'antd';
import React, { useState } from 'react';

import { ContractsTable } from './ContractsTable';

interface IProps {
	contracts: VoivodeshipContractDto[];
}

export const ContractsModal = (props: IProps) => {
	const [visible, setVisible] = useState<boolean>();

	const handleCancel = () => {
		setVisible(false);
	};

	return (
		<>
			<Button
				size="small"
				type="primary"
				shape="round"
				onClick={() => {
					setVisible(true);
				}}
			>
				All contracts
			</Button>
			<IsolatedModal
				title="All contracts"
				visible={visible}
				onCancel={() => handleCancel()}
			>
				<ContractsTable contracts={props.contracts} />
			</IsolatedModal>
		</>
	);
};
